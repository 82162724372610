// @react
import React, { useContext, ReactElement, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
// @libraries
import clsx from 'clsx'
import { makeStyles, Theme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import Helper from 'utils/Helper.class'
// @context
import HeaderDataContext from 'modules/global/Header/context'
import { RouteNamesExternal } from 'constants/routeNamesExternal'
// @components
import Icon from '@bit/atd.web.icons'
import colors from '@bit/atd.web.colors'
import Spacer from '@bit/atd.web.spacer'
import Grid from '@bit/atd.web.grid'
import ContextMenu from '@bit/atd.web.context-menu'
import ContextMenuItem from '@bit/atd.web.context-menu-item'
import Avatar from '@bit/atd.web.avatar'
import Button from '@bit/atd.web.button'
import Bell from '../../../components/BellComponent/index'
// @module components
import LoginForm from 'modules/auth/components/LoginForm'
import Loader from '@bit/atd.web.loaders.base'
import strings from 'lib/constants/strings'
import { Typography } from '@material-ui/core'
import {
  CustomerClassCode,
  GetUserQuery,
  GetUserQueryVariables,
} from 'lib/graphql/graphqlTypes'
import { useQuery } from '@apollo/client'
import { GET_USER_ID } from '../../../../../../graphql/queries/user.query'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: '16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  separator: {
    display: 'inline-block',
    verticalAlign: 'middle',
    width: '1px',
    height: '40px',
    backgroundColor: colors.gray400,
  },
  btnDefault: {
    fontSize: '16px',
    lineHeight: '20px',
    padding: '8px 16px',
  },
  contextMenuVariantText: {
    background: 'none',
    paddingLeft: 0,
    paddingRight: 24,
    '& svg': {
      height: '24px',
      width: '24px',
      right: 0,
    },
  },
  contextMenuVariantLogin: {
    paddingRight: '32px',
    '& svg': {
      height: '24px',
      width: '24px',
    },
  },
  profileMenu: {
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  profileMenuInfo: {
    minWidth: '7vw',
    marginLeft: '8px',
    marginBottom: '8px',
    '& span': {
      textTransform: 'uppercase',
      lineHeight: '15px',
      fontWeight: 500,
      fontSize: '12px',
    },
    '& strong': {
      fontSize: '16px',
      fontWeight: 600,
    },
    '& a': {
      fontSize: '12px',
      fontWeight: 600,
      border: 0,
    },
  },
  avatarWrapper: {
    cursor: 'pointer',
  },
  // Condensed state
  condensed: {
    paddingTop: 0,
  },
  editProfile: {
    padding: '0px',
    '&:hover': {
      background: 'transparent',
    },
  },
  cartCount: {
    fontSize: '12px',
    marginLeft: '5px',
  },
  widen: {
    width: '10vw',
  },
}))

type PropsType = {
  /** Show search panel */
  showSearch?: boolean
  /** Toggle search panel */
  toggleSearch: (open: boolean) => void
}

/**
 *
 * @param showSearch
 * @param toggleSearch
 * @constructor
 */
const UserLinks = ({ showSearch, toggleSearch }: PropsType): ReactElement => {
  /********************************************
   *  GQL HOOKS
   *********************************************/
  const { loading: userLoading } = useQuery<
    GetUserQuery,
    GetUserQueryVariables
  >(GET_USER_ID, {
    fetchPolicy: 'cache-first',
  })

  /********************************
   * HOOKS
   ********************************/

  const history = useHistory()
  const theme = useTheme()
  const classes = useStyles()
  const isMin1024 = useMediaQuery(theme.breakpoints.up('md'))
  const [isLoginInProcess, setIsLoginInProcess] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const headerContext = useContext(HeaderDataContext)
  const { condensed, isLoggedIn, onLogin, doLogout, isNewSearch, user } =
    headerContext

  useEffect(() => {
    if (isLoginInProcess || userLoading) {
      setIsLoading(true)
    } else {
      setIsLoading(false)
    }
  }, [isLoginInProcess, user, userLoading, isLoggedIn])

  /********************************
   * RENDERS
   ********************************/

  const renderUserBtns = () => {
    if (isLoading) return <Loader size={32} />
    if (!isLoggedIn) {
      return (
        <>
          <Button
            className={classes.btnDefault}
            href={RouteNamesExternal.CHECKOUT_MEMBERSHIP}
            variant="secondary"
          >
            {strings.MENU.BECOME_A_MEMBER}
          </Button>

          {!user && (
            <>
              <Spacer size={!condensed ? 16 : 8} noHeight />
              <ContextMenu
                noBorder
                className={classes.contextMenuVariantText}
                toggle={'Sign In/Register'}
                menu={
                  <>
                    <Grid container className={classes.profileMenu}>
                      <Grid item>
                        <LoginForm
                          setIsLoginInProcess={setIsLoginInProcess}
                          onLogin={onLogin}
                        />
                      </Grid>
                    </Grid>
                  </>
                }
              />
            </>
          )}
        </>
      )
    }
  }

  return (
    <div className={clsx(classes.root, { [classes.condensed]: condensed })}>
      {/* Search */}
      <Button
        variant={!condensed ? 'tertiary' : 'icon'}
        active={showSearch}
        size={'medium'}
        onClick={() => toggleSearch(!showSearch)}
      >
        <Icon glyph="magnify" size={24} />
        {(isMin1024 && (
          <>
            <Spacer size={8} noHeight />
            <span>
              {showSearch
                ? 'Close Search'
                : isNewSearch
                ? 'New Search'
                : 'Search'}
            </span>
          </>
        )) || <></>}
      </Button>

      <Spacer size={!condensed ? 16 : 8} noHeight />

      {/* Cart */}
      <Button
        variant={!condensed ? 'tertiary' : 'icon'}
        size={'medium'}
        href={RouteNamesExternal.CHECKOUT_HOME}
      >
        <>
          <Icon glyph="cart" size={24} />
        </>
        {(isMin1024 && (
          <>
            <Spacer size={8} noHeight />
            <span>Cart</span>
            <Typography variant={'h5'} className={classes.cartCount}>
              {typeof user?.cartCount === 'number' && (
                <>
                  {'  '}({user?.cartCount})
                </>
              )}
            </Typography>
          </>
        )) || <></>}
      </Button>

      <Spacer size={!condensed ? 24 : 16} noHeight />
      <span className={classes.separator} />
      <Spacer size={!condensed ? 24 : 16} noHeight />

      {renderUserBtns()}

      {isLoggedIn && user?.customerClassCode !== 'COMPANY' && (
        <Button
          className={classes.btnDefault}
          href={Helper.getPathFromRouteKey('MYATD_DASHBOARD')}
          variant="secondary"
        >
          {strings.GLOBAL.MY_ATD}
        </Button>
      )}

      {user && (
        <>
          <Spacer size={!condensed ? 16 : 8} noHeight />
          <ContextMenu
            noBorder
            className={classes.contextMenuVariantText}
            toggle={user?.firstName}
            isHover={true}
            menu={
              <>
                <Grid container className={classes.profileMenu}>
                  <Grid item>
                    <div onClick={() => console.log('test')}>
                      <Avatar src={user.avatarUrl} size={40} alt={''} />
                    </div>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      direction="column"
                      className={clsx(
                        condensed ? classes.widen : null,
                        classes.profileMenuInfo,
                      )}
                    >
                      <span>{user?.membershipType}</span>
                      <strong>
                        {user?.firstName} {user?.lastName}
                      </strong>

                      {user.customerClassCode !== CustomerClassCode.COMPANY && (
                        <ContextMenuItem
                          className={classes.editProfile}
                          href={Helper.getPathFromRouteKey(
                            'PROFILE_PERSONALINFORMATION',
                          )}
                        >
                          Edit Profile
                        </ContextMenuItem>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <ContextMenuItem
                  href={Helper.getPathFromRouteKey(
                    'MYATD_ACTIVITYHISTORY_BOOKMARKS',
                  )}
                >
                  {strings.MENU.MY_BOOKMARKS}
                </ContextMenuItem>
                <ContextMenuItem
                  href={Helper.getPathFromRouteKey(
                    'PROFILE_PERSONALINFORMATION',
                  )}
                >
                  {strings.MENU.MY_ACCOUNT}
                </ContextMenuItem>
                <ContextMenuItem
                  href={Helper.getPathFromRouteKey('PROFILE_ORDERS')}
                >
                  {strings.MENU.MY_ORDERS}
                </ContextMenuItem>
                <ContextMenuItem href={RouteNamesExternal.HELP_HOME_US}>
                  {strings.MENU.HELP}
                </ContextMenuItem>
                <ContextMenuItem separator />
                <ContextMenuItem onClick={() => doLogout && doLogout()}>
                  {strings.MENU.SIGN_OUT}
                </ContextMenuItem>
              </>
            }
          />

          <Bell />

          <Spacer size={!condensed ? 18 : 8} noHeight />

          <div
            className={classes.avatarWrapper}
            onClick={() =>
              Helper.pushPath(
                Helper.getPathFromRouteKey('PROFILE_PERSONALINFORMATION'),
                history,
              )
            }
          >
            <Avatar
              size={!condensed ? 48 : 32}
              src={user.avatarUrl}
              alt={user.textAs}
              bgColor={user.bgColor}
            >
              {user.textAs}
            </Avatar>
          </div>
        </>
      )}
    </div>
  )
}

UserLinks.defaultProps = {
  showSearch: false,
}

export default UserLinks
