// @react
import React, { useState } from 'react'
import { Router } from 'react-router-dom'
// @design
import CssBaseline from '@material-ui/core/CssBaseline'
import { MuiThemeProvider } from '@material-ui/core'
import atdTheme from '@bit/atd.web.theme'
// @common
import { HeaderSearchContext } from 'modules/common/context/headerSearch'
import { Helmet } from 'react-helmet'
import whyDidYouRender from '@welldone-software/why-did-you-render'
import history from 'utils/history'
import catchError from 'modules/common/HOC/catchError'
// @components
import HeaderWrap from 'modules/global/HeaderWrap'
import SnackbarProvider from 'modules/common/components/SnackbarProvider'
// @graphql
import { disableFragmentWarnings } from '@apollo/client/core'
import { ApolloProvider } from '@apollo/client'
import client from 'graphql/Apollo/apolloClient'
import Cookies from 'universal-cookie'
import moment from 'moment'

const cookies = new Cookies()

if (process.env.NODE_ENV === 'development') {
  whyDidYouRender(React)
}
disableFragmentWarnings()

const App = (): React.ReactElement => {
  const [isShowSearch, setIsShowSearch] = useState(false)
  const [, setIsAuth] = useState<boolean | undefined>(undefined)
  const timeZoneCookie = cookies.get('PreferredTimeZone')

  if (!timeZoneCookie || timeZoneCookie === '') {
    const getTimezone = moment.tz.guess()
    cookies.set('PreferredTimeZone', getTimezone, {
      path: '/',
      maxAge: 60 * 60 * 12,
      domain: '.td.org',
    })
  }

  return (
    <>
      <Router history={history}>
        <Helmet>
          <style>{`
            body {
             background-color: #ffffff; 
             padding-right: 0px !important;
            }`}</style>
        </Helmet>
        <ApolloProvider client={client}>
          <MuiThemeProvider theme={atdTheme}>
            <CssBaseline />
            <SnackbarProvider>
              <HeaderSearchContext.Provider
                value={{ isShowSearch, setIsShowSearch }}
              >
                {
                  <HeaderWrap
                    isShowSearch={isShowSearch}
                    setIsShowSearch={setIsShowSearch}
                    setIsAuth={setIsAuth}
                  />
                }
              </HeaderSearchContext.Provider>
            </SnackbarProvider>
          </MuiThemeProvider>
        </ApolloProvider>
      </Router>
    </>
  )
}

export default catchError(App)
