import {
  ArticleSearchItem,
  BlogSearchItem,
  ConferenceSearchItem,
  CourseSearchItem,
  IAbstractSearchItem,
  IContentSearchItem,
  MeetingSessionSearchItem,
  PageSearchItem,
  PodcastSearchItem,
  PublicationSearchItem,
  UserSearchItem,
  VideoSearchItem,
  WebinarSearchItem,
} from 'lib/graphql/graphqlTypes'
import { BookmarkableSearchItem } from '../components/ContentCard/types'

// @todo the IAbstractSearch family type guards could be combined and abstracted

/**
 *
 * @param content
 */
export function isPublicationSearchItem(
  content: IAbstractSearchItem,
): content is PublicationSearchItem {
  return (
    (content as PublicationSearchItem).__typename === 'PublicationSearchItem'
  )
}

/**
 *
 * @param content
 */
export function isArticleSearchItem(
  content: IAbstractSearchItem,
): content is ArticleSearchItem {
  return (content as ArticleSearchItem).__typename === 'ArticleSearchItem'
}

/**
 *
 * @param content
 */
export function isPageSearchItem(
  content: IAbstractSearchItem,
): content is PageSearchItem {
  return (content as PageSearchItem).__typename === 'PageSearchItem'
}

/**
 *
 * @param content
 */
export function isBlogSearchItem(
  content: IAbstractSearchItem,
): content is BlogSearchItem {
  return (content as BlogSearchItem).__typename === 'BlogSearchItem'
}

/**
 *
 * @param content
 */
export function isCourseSearchItem(
  content: IAbstractSearchItem,
): content is CourseSearchItem {
  return (content as CourseSearchItem).__typename === 'CourseSearchItem'
}

/**
 *
 * @param content
 */
export function isConferenceSearchItem(
  content: IAbstractSearchItem,
): content is ConferenceSearchItem {
  return (content as ConferenceSearchItem).__typename === 'ConferenceSearchItem'
}

/**
 *
 * @param content
 */
export function isVideoSearchItem(
  content: IAbstractSearchItem,
): content is VideoSearchItem {
  return (content as VideoSearchItem).__typename === 'VideoSearchItem'
}

/**
 *
 * @param content
 */
export function isPodcastSearchItem(
  content: IAbstractSearchItem,
): content is PodcastSearchItem {
  return (content as PodcastSearchItem).__typename === 'PodcastSearchItem'
}

/**
 *
 * @param content
 */
export function isWebinarSearchItem(
  content: IAbstractSearchItem,
): content is WebinarSearchItem {
  return (content as WebinarSearchItem).__typename === 'WebinarSearchItem'
}

/**
 *
 * @param content
 */
export function isUserSearchItem(
  content: IAbstractSearchItem,
): content is UserSearchItem {
  return (content as UserSearchItem).__typename === 'UserSearchItem'
}

/**
 *
 * @param content
 */
export function isMeetingSessionSearchItem(
  content: IAbstractSearchItem,
): content is MeetingSessionSearchItem {
  return (
    (content as MeetingSessionSearchItem).__typename ===
    'MeetingSessionSearchItem'
  )
}

/**
 *
 * @param content
 */
export function isIContentSearchItem(
  content: IAbstractSearchItem,
): content is IContentSearchItem {
  return (
    (content as BlogSearchItem).__typename === 'BlogSearchItem' ||
    (content as PageSearchItem).__typename === 'PageSearchItem' ||
    (content as ArticleSearchItem).__typename === 'ArticleSearchItem'
  )
}

/**
 *
 * @param content
 * @param typename
 */
export function isBookmarkableSearchItem(
  content: IContentSearchItem,
  typename?: string,
): content is BookmarkableSearchItem {
  if (!typename) return false
  if (content.isBookmarkable !== undefined) {
    return content.isBookmarkable
  }

  switch (typename) {
    case 'ArticleSearchItem':
    case 'BlogSearchItem':
    case 'CourseSearchItem':
    case 'ConferenceSearchItem':
    case 'VideoSearchItem':
    case 'PublicationSearchItem':
      return true
  }
  return false
}
