import { EntityType } from 'lib/graphql/graphqlTypes'

type ContentChildren = {
  label: string
  key: string
  children?: ContentChildren[]
}

export type ContentType = {
  label: string
  pluralLabel: string
  entityType: EntityType
  key: string
  index: string
  children?: ContentChildren[]
}

export const contentTypes: ContentType[] = [
  {
    label: 'Course',
    pluralLabel: 'Courses',
    entityType: EntityType.COURSE,
    key: 'course',
    index: 'courses',
    children: [
      {
        label: 'Certificate Program',
        key: 'certificate_program',
      },
      {
        label: 'Individual Course',
        key: 'individual_course',
      },
      {
        label: 'Master Program',
        key: 'master_program',
      },
      {
        label: 'Micro Course',
        key: 'micro_course',
      },
    ],
  },
  {
    label: 'Conference',
    pluralLabel: 'Conferences',
    entityType: EntityType.CONFERENCE,
    key: 'conference',
    index: 'conferences',
  },
  {
    label: 'Publication',
    pluralLabel: 'Publications',
    entityType: EntityType.PUBLICATION,
    key: 'publication',
    index: 'publications',
    children: [
      {
        label: 'Research',
        key: 'research',
        children: [
          {
            label: 'Research Report',
            key: 'research_report',
          },
          {
            label: 'Case Study',
            key: 'case_study',
          },
          {
            label: 'Whitepaper',
            key: 'whitepaper',
          },
        ],
      },
      {
        label: 'Book',
        key: 'book',
      },
      {
        label: 'Magazine',
        key: 'magazine',
        children: [
          {
            label: 'TD Magazine',
            key: 'td_magazine',
          },
        ],
      },
    ],
  },
  {
    label: 'Article',
    pluralLabel: 'Articles',
    entityType: EntityType.ARTICLE,
    key: 'article',
    index: 'articles',
    children: [
      {
        label: 'Newsletter Article',
        key: 'newsletter_article',
      },
      {
        label: 'TD Magazine',
        key: 'td_magazine',
      },
    ],
  },
  {
    label: 'Blog',
    pluralLabel: 'Blogs',
    entityType: EntityType.BLOG,
    key: 'blog',
    index: 'blogs',
    children: [
      {
        label: 'Professional Partner Content',
        key: 'professional_partner_content',
      },
      {
        label: 'Community Content',
        key: 'community_content',
      },
      {
        label: 'Press Release',
        key: 'press_release',
      },
    ],
  },
  {
    label: 'Video',
    pluralLabel: 'Videos',
    entityType: EntityType.VIDEO,
    key: 'video',
    index: 'videos',
  },
  {
    label: 'Webinar',
    pluralLabel: 'Webinars',
    entityType: EntityType.WEBINAR,
    key: 'webinar',
    index: 'webinars',
  },
  {
    label: 'Podcast',
    pluralLabel: 'Podcasts',
    entityType: EntityType.ARTICLE,
    key: 'podcast',
    index: 'podcasts',
  },
  {
    label: 'People',
    pluralLabel: 'People',
    entityType: EntityType.PUBLIC_USER,
    key: 'people',
    index: 'users',
  },
  {
    label: 'Page',
    pluralLabel: 'Pages',
    entityType: EntityType.PAGE,
    key: 'page',
    index: 'pages',
  },
  /*
   * @todo verify these entity types
   * */
  {
    label: 'Community Discussion',
    pluralLabel: 'Community Discussions',
    entityType: EntityType.PAGE,
    key: 'community_discussion',
    index: 'community_discussions',
  },
  {
    label: 'Performance Resource',
    pluralLabel: 'Performance Resources',
    entityType: EntityType.PAGE,
    key: 'performance_resource',
    index: 'performance_resources',
    children: [
      {
        label: 'Templates and Tools',
        key: 'templates_&_Tools',
      },
      {
        label: 'TD at Work Guide',
        key: 'td_at_work_guide',
      },
    ],
  },
  {
    label: 'Certification',
    pluralLabel: 'Certifications',
    entityType: EntityType.PAGE,
    key: 'certification',
    index: 'certification',
    children: [
      {
        label: 'Templates and Tools',
        key: 'templates_&_Tools',
      },
      {
        label: 'TD at Work Guide',
        key: 'td_at_work_guide',
      },
    ],
  },
]
