import React from 'react'
import Grid from '@bit/atd.web.grid'
import TextLink from '@bit/atd.web.text-link'
import { makeStyles } from '@material-ui/core/styles'
import { SponsorItem } from 'lib/graphql/graphqlTypes'

type PropsType = {
  sponsor: SponsorItem
}

const useStyles = makeStyles({
  sponsored: {
    marginTop: '8px',
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px',
    lineHeight: '16px',
    '& span': {
      fontSize: '12px',
    },
    '& img': {
      maxWidth: '65px',
      marginTop: '4px',
      width: 'auto',
      objectFit: 'contain',
    },
  },
})

const Sponsor = ({ sponsor }: PropsType) => {
  const classes = useStyles()
  const doResize = (srcImage: string) => {
    const resize = 'w=74&h=100'
    return `${srcImage
      ?.replace(
        'https://d22bbllmj4tvv8.cloudfront.net',
        'https://atd.imgix.net',
      )
      .replace(
        'http://files.astd.org.s3.amazonaws.com',
        'https://atd-s3.imgix.net',
      )
      .replace(
        'https://atd-brightspot-lower.s3.amazonaws.com',
        'https://atd-brightspot-lower.imgix.net',
        //)}?${resize}&fit=crop`
      )}?${resize}&fit=crop`
  }

  return (
    <Grid className={classes.sponsored} container>
      <Grid item xs={7}>
        <span>Sponsored By</span>
      </Grid>
      <Grid xs={5} item>
        <img
          alt={sponsor.organization || ''}
          src={doResize(sponsor.logoUrl || '')}
        />
      </Grid>
      <Grid item xs={12}>
        {sponsor?.website && (
          <TextLink target="_blank" href={sponsor.website}>
            {sponsor.organization}
          </TextLink>
        )}
      </Grid>
    </Grid>
  )
}

export default Sponsor
