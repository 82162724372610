// @react
import React, { useState } from 'react'
import { Router } from 'react-router-dom'
// @design
import { MuiThemeProvider } from '@material-ui/core'
import atdTheme from '@bit/atd.web.theme'
// @common
import whyDidYouRender from '@welldone-software/why-did-you-render'
import history from './utils/history'
import catchError from 'modules/common/HOC/catchError'
import SnackbarProvider from 'modules/common/components/SnackbarProvider'
// @components
import FooterWrap from 'modules/global/FooterWrap'
// @graphql
import client from 'graphql/Apollo/apolloClient'
import { disableFragmentWarnings } from '@apollo/client/core'
import { ApolloProvider } from '@apollo/client'
import { HeaderSearchContext } from 'modules/common/context/headerSearch'

if (process.env.NODE_ENV === 'development') {
  whyDidYouRender(React)
}
disableFragmentWarnings()

const App = (): React.ReactElement => {
  const [isShowSearch, setIsShowSearch] = useState(false)

  return (
    <>
      <Router history={history}>
        <ApolloProvider client={client}>
          <MuiThemeProvider theme={atdTheme}>
            <SnackbarProvider>
              <HeaderSearchContext.Provider
                value={{ isShowSearch, setIsShowSearch }}
              >
                {<FooterWrap />}
              </HeaderSearchContext.Provider>
            </SnackbarProvider>
          </MuiThemeProvider>
        </ApolloProvider>
      </Router>
    </>
  )
}

export default catchError(App)
