// @react
import React, { forwardRef, ReactNode } from 'react'
// @libraries
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
// @components
import colors from '@bit/atd.web.colors'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    backgroundColor: colors.white,
    border: `1px solid ${colors.gray400}`,
    boxShadow: `0px 2px 0px ${colors.gray400}`,
    borderRadius: '3px',
    overflow: 'hidden',
    color: colors.ctaSecondary,
  },
})

type PropsType = {
  children?: ReactNode
  className?: string
  tabIndex?: number
  role?: 'button'
}

const Wrap = forwardRef(
  ({ children, className, tabIndex, role }: PropsType, ref) => {
    const classes = useStyles()

    return (
      <div
        ref={ref as any}
        className={clsx(classes.root, {
          [className || '']: className,
        })}
        tabIndex={tabIndex}
        role={role}
      >
        {children}
      </div>
    )
  },
)

Wrap.displayName = 'CardWrap'

export default Wrap
