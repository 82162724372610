export const config = {
  onBoardingWaitAfterSubmit: 1500,
  debounce: 400,
  enableHeartBeat: true,
  pageSize: 12,
  cardsPageSize: 12,
  mediaQuery: {
    noSsr: true,
  },
  loginPageUrl: `https://login.td.org/Login.aspx?redirect=${encodeURIComponent(
    `${window.location.origin}/myatd/dashboard`,
  )}`,
}

export default config
