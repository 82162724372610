import React, {
  useContext,
  useState,
  ReactElement,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react'
// @common
import colors from '@bit/atd.web.colors'

import { makeStyles } from '@material-ui/core/styles'
import PrimaryLink from './PrimaryLink'

import Link from '@bit/atd.web.header-link'
import HeaderDataContext from '../../../context'
import { RouteNamesExternal } from 'constants/routeNamesExternal'
// @components
import SecondaryLinks from './SecondaryLinks'
import GroupTitle from '@bit/atd.web.header-group-title'
import Grid from '@bit/atd.web.grid'
import Button from '@bit/atd.web.button'
import Icon from '@bit/atd.web.icons'
import Helper from 'utils/Helper.class'
import Avatar from '@bit/atd.web.avatar'
import Modal from '@bit/atd.web.modal'
import LoginForm from 'modules/auth/components/LoginForm'
import TextLink from '@bit/atd.web.text-link'
import { MenuItem } from '../../../../../../lib/graphql/graphqlTypes'

const useStyles = makeStyles({
  root: {
    position: 'relative',
    overflow: 'hidden',
    flexGrow: 1,
  },
  step: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',
    transition: 'transform 0.3s ease',
    backgroundColor: colors.gray200,
    zIndex: 3,
  },
  header: {
    padding: '16px',
    backgroundColor: colors.white,
    '& h4': {
      margin: '10px 0 0',
      fontWeight: 600,
      fontSize: '20px',
      lineHeight: '28px',
    },
  },
  footer: {
    marginTop: '32px',
    padding: '16px',
  },
  socialIcons: {
    display: 'flex',
    marginBottom: '24px',
    '& a:not(:last-child)': {
      marginRight: '16px',
    },
  },
  profileMenuInfo: {
    marginLeft: '8px',
    marginBottom: '8px',
    '& span': {
      textTransform: 'uppercase',
      lineHeight: '15px',
      fontWeight: 500,
      fontSize: '12px',
    },
    '& strong': {
      fontSize: '16px',
      fontWeight: 600,
    },
    '& a': {
      fontSize: '12px',
      fontWeight: 600,
      border: 0,
    },
  },
})

type PropsType = {
  level: number
  menu?: MenuItem[]
  header?: ReactElement
  footer?: ReactElement
}

const SlideMenu = ({
  level,
  header,
  footer,
  menu,
}: PropsType): ReactElement => {
  const classes = useStyles()
  const [active, setActive] = useState(-1)

  return (
    <div
      className={classes.step}
      style={{
        transform: `translateX(${level * 100}%)`,
      }}
    >
      {header}
      {menu && (
        <div>
          {menu.map((group, idx) => {
            return (
              <React.Fragment key={idx}>
                <PrimaryLink
                  hasSubMenu={group.children ? true : false}
                  onClick={() => setActive(idx)}
                >
                  {group.children ? (
                    group.title
                  ) : (
                    <SecondaryLinks key={idx} data={group} />
                  )}
                </PrimaryLink>
                {group.children && (
                  <SlideMenu
                    level={active === idx ? 0 : level + 1}
                    header={
                      <header className={classes.header}>
                        <span onClick={() => setActive(-1)}>
                          <Icon
                            glyph="caret-left"
                            size={24}
                            color={colors.ctaPrimary}
                          />
                          Back
                        </span>
                        <h4>{group.title}</h4>
                      </header>
                    }
                    menu={group.children}
                  />
                )}
              </React.Fragment>
            )
          })}
        </div>
      )}
      {footer}
    </div>
  )
}

type WrapPropsType = {
  setShow?: Dispatch<SetStateAction<boolean>>
}

const Wrap = (props: WrapPropsType): ReactElement => {
  const headerContext = useContext(HeaderDataContext)
  const rootMenu = headerContext.data
  const classes = useStyles()
  const [showLogin, setShowLogin] = useState(false)
  const { isLoggedIn, onLogin, doLogout, user } = headerContext
  useEffect(() => {
    if (user) {
      setShowLogin(false)
    }
  }, [user])

  const loginModal = showLogin && (
    <Modal
      onClose={() => setShowLogin(false)}
      headerNoSeparator
      open={showLogin}
      fullScreen={true}
      title="Sign in / Register"
    >
      <LoginForm onLogin={onLogin} />
    </Modal>
  )
  return (
    <div className={classes.root}>
      <SlideMenu
        level={0}
        menu={rootMenu}
        header={
          <header className={classes.header}>
            {!isLoggedIn && (
              <>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      size="small"
                      variant="secondary"
                      onClick={() => setShowLogin(true)}
                    >
                      Sign in / Register
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      href={RouteNamesExternal.CHECKOUT_MEMBERSHIP}
                      fullWidth
                      size="small"
                      variant="secondary"
                    >
                      Join Now
                    </Button>
                  </Grid>
                </Grid>
                {loginModal}
              </>
            )}
            {user && (
              <Grid container spacing={2}>
                <Grid container>
                  <Grid item>
                    <Avatar
                      size={40}
                      src={user.avatarUrl}
                      alt={user.textAs}
                      bgColor={user.bgColor}
                    >
                      {user.textAs}
                    </Avatar>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      direction="column"
                      className={classes.profileMenuInfo}
                    >
                      <span>{user.membershipType}</span>
                      <strong>
                        {user.firstName} {user.lastName}
                      </strong>
                      <TextLink
                        href={Helper.getPathFromRouteKey(
                          'PROFILE_PERSONALINFORMATION',
                        )}
                        onClick={() => props.setShow && props.setShow(false)}
                        variant="secondary"
                      >
                        Edit Profile
                      </TextLink>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    href={Helper.getPathFromRouteKey('MYATD_DASHBOARD')}
                    fullWidth
                    size="small"
                    variant="primary"
                  >
                    MyATD
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    onClick={() => doLogout && doLogout()}
                    fullWidth
                    size="small"
                    variant="secondary"
                  >
                    Sign Out
                  </Button>
                </Grid>
              </Grid>
            )}
          </header>
        }
        footer={
          <footer className={classes.footer}>
            <GroupTitle>Stay Connected</GroupTitle>
            <div className={classes.socialIcons}>
              <Link href={RouteNamesExternal.SOCIAL_FACEBOOK}>
                <Icon glyph="facebook" />
              </Link>
              <Link href={RouteNamesExternal.SOCIAL_TWITTER}>
                <Icon glyph="twitter" />
              </Link>
              <Link href={RouteNamesExternal.SOCIAL_LINKEDIN}>
                <Icon glyph="linkedin" />
              </Link>
              <Link href={RouteNamesExternal.SOCIAL_PINTEREST}>
                <Icon glyph="pinterest" />
              </Link>
              <Link href={RouteNamesExternal.SOCIAL_INSTAGRAM}>
                <Icon glyph="instagram" />
              </Link>
            </div>
          </footer>
        }
      />
    </div>
  )
}

export default Wrap
