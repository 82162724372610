import { gql } from '@apollo/client'

export const CREATE_BOOKMARK = gql`
  mutation CreateBookmark($input: UpdateBookmarkInput!) {
    createBookmark(input: $input) {
      message
      status
    }
  }
`

export const DELETE_BOOKMARK = gql`
  mutation DeleteBookmark($input: UpdateBookmarkInput!) {
    deleteBookmark(input: $input) {
      message
      status
    }
  }
`
