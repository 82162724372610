// @libraries
import { makeStyles } from '@material-ui/core/styles'
// @styles
import { gradients } from '@bit/atd.web.theme'
import colors from '@bit/atd.web.colors'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      minHeight: '400px',
    },
  },

  // Background

  background: {
    backgroundColor: '#E13000',
    fontSize: 0,
    position: 'relative',
    height: '100%',
    width: '100%',
    minHeight: '110px',
    maxHeight: '320px',
    overflow: 'hidden',
    [theme.breakpoints.up('sm')]: {
      position: 'absolute',
      top: 0,
      left: 0,
      pointerEvents: 'none',
      overflow: 'hidden',
      maxHeight: 'initial',
    },

    '& picture': {
      clip: 'rect(0,auto,auto,0)',
      height: '100%',
      overflow: 'hidden',
      pointerEvents: 'none',
      position: 'relative',
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
      },
    },

    '& picture img': {
      objectFit: 'cover',
      objectPosition: 'center',
      position: 'relative',
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
      },
    },
  },
  backgroundLimitHeight: {
    [theme.breakpoints.up('sm')]: {
      maxHeight: '380px',
    },
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: '#FF4D00',
    opacity: 0.8,
  },

  // Content

  content: {
    position: 'relative',
    width: '100%',
  },
  contentInner: {
    position: 'relative',
  },

  // Card Container

  card: {
    backgroundColor: '#fff',
    '& > * ~ a': {
      marginTop: '1.5rem',
    },
    '& > h1 + a': {
      marginTop: '2rem',
    },
    '& .MuiTypography-h3': {
      color: '#2E2D31',
      fontSize: '32px',
      fontWeight: 600,
      lineHeight: '36px',
    },
    '& .MuiTypography-h4': {
      fontSize: '20px',
      fontWeight: 600,
      lineHeight: '28px',
    },
    '& h1 + $text': {
      marginTop: '1rem',
    },
    '& h2 + $text': {
      marginTop: '.5rem',
    },
  },

  text: {
    '& p': {
      fontSize: '18px',
      lineHeight: '30px',
      margin: '0 0 10px',
    },
    '& p:last-child': {
      marginBottom: 0,
    },
    '& p a': {
      color: colors.ctaPrimary,
      textDecoration: 'underline',
      textUnderlineOffset: '4px',
      '&:hover': {
        color: '#EA6E4D',
      },
      '&:visited': {
        color: '#CB2B00',
      },
    },
    '& p ~ a': {
      marginTop: '1rem',
    },
  },

  // Variants

  isVariantCentered: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      alignItems: 'center',
    },

    '& $content > *': {
      [theme.breakpoints.only('xs')]: {
        backgroundColor: colors.white,
      },
    },

    '& $contentInner': {
      [theme.breakpoints.up('sm')]: {
        paddingBottom: '80px',
        paddingTop: '50px',
        display: 'flex',
        justifyContent: 'center',
      },
      [theme.breakpoints.up('md')]: {
        justifyContent: 'flex-end',
      },
    },

    '& $card': {
      paddingBottom: '20px',
      paddingTop: '20px',
      [theme.breakpoints.up('sm')]: {
        paddingLeft: '30px',
        paddingRight: '30px',
        paddingBottom: '40px',
        paddingTop: '40px',
        maxWidth: '580px',
        minWidth: '580px',
        borderRadius: '3px',
        boxShadow: '0px 2px 0px #DBD9D6',
      },
      [theme.breakpoints.up('md')]: {
        maxWidth: '660px',
        minWidth: '660px',
      },
    },
  },
  isVariantBottom: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      alignItems: 'center',
    },

    '& $background': {
      backgroundColor: '#FF4D00',
    },

    '& .MuiContainer-root': {
      [theme.breakpoints.only('xs')]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },

    '& $contentInner': {
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      },
    },

    '& h1': {
      color: '#2E2D31',
      paddingTop: '20px',
      paddingBottom: '30px',
      fontWeight: 700,
      fontSize: '40px',
      lineHeight: '42px',
      [theme.breakpoints.only('xs')]: {
        paddingLeft: 16,
        paddingRight: 16,
      },
      [theme.breakpoints.up('sm')]: {
        color: '#fff',
        paddingTop: '100px',
        textAlign: 'center',
      },
    },

    '& $card': {
      position: 'relative',
      width: '100%',
      padding: '20px 16px',
      [theme.breakpoints.up('sm')]: {
        padding: '40px',
        boxShadow: '0px 2px 0px #DBD9D6',
        borderBottomLeftRadius: '3px',
        borderBottomRightRadius: '3px',
        marginBottom: '4px',
      },

      '&:before': {
        content: '""',
        background: gradients.brand,
        position: 'absolute',
        top: '-9px',
        left: 0,
        width: '100%',
        height: '9px',
        [theme.breakpoints.up('sm')]: {
          borderTopLeftRadius: '3px',
          borderTopRightRadius: '3px',
        },
      },
    },
  },

  // Variant Bottom - Column Count
  columnCountZero: {
    '& h1': {
      [theme.breakpoints.up('sm')]: {
        color: '#fff',
        paddingTop: '100px',
        paddingBottom: '100px',
      },
    },
  },
  columnCountOne: {
    '& $card': {
      [theme.breakpoints.up('sm')]: {
        marginTop: '100px',
      },
    },
    '& .MuiGrid-item': {
      [theme.breakpoints.up('sm')]: {
        textAlign: 'center',
        paddingLeft: '30px',
        paddingRight: '30px',
      },
    },
  },
  columnCountTwo: {
    '& $card': {
      [theme.breakpoints.up('sm')]: {
        marginTop: '40px',
      },
    },
  },
}))

export default useStyles
