// @react
import React, { ReactElement, useContext } from 'react'
// @libraries
import { makeStyles } from '@material-ui/core/styles'
// @components
import colors from '@bit/atd.web.colors/dist/colors'
import Button from '@bit/atd.web.button'
import TextLink from '@bit/atd.web.text-link'
import { TrackPrimaryLinks } from 'modules/global/Header/context/trackPrimaryLinks'

const useStyles = makeStyles({
  root: {},
  mediaLink: {
    display: 'flex',
    cursor: 'pointer',
    flexDirection: 'column',
    '& > span': {
      marginTop: 8,
    },
  },
  image: {
    width: '100%',
    borderBottom: `2px solid ${colors.ctaPrimary}`,
    '&:hover': {
      borderBottom: `2px solid ${colors.ctaPrimaryDark}`,
    },
  },
  button: {
    marginTop: 12,
  },
})

type PropsType = {
  title?: string | null
  url?: string | null
  imageUrl?: string | null
  buttonText?: string
  buttonURL?: string
}

const MediaLink = ({
  url,
  title,
  imageUrl,
  buttonText,
  buttonURL,
}: PropsType): ReactElement => {
  /********************************
   * HOOKS
   ********************************/

  const classes = useStyles()
  const activePrimaryLink = useContext(TrackPrimaryLinks)

  const handleClickButton = () => {
    analytics.track(
      'Promotion Clicked',
      {
        title: title,
        position: 'navigation',
        url: buttonURL,
        primaryNavLink: activePrimaryLink.activeNavLink,
      },
      redirectButtonClick,
    )
  }

  const redirectButtonClick = () => {
    window.location.assign(buttonURL || '')
  }

  const handleClickText = () => {
    analytics.track(
      'Promotion Clicked',
      {
        title: title,
        position: 'navigation',
        url: url,
        primaryNavLink: activePrimaryLink.activeNavLink,
      },
      redirectTextClick,
    )
  }

  const redirectTextClick = () => {
    window.location.assign(url || '')
  }
  /********************************
   * RENDER
   ********************************/

  return (
    <>
      <div className={classes.root}>
        {/* Main Media Link */}
        <TextLink
          className={classes.mediaLink}
          size={'large'}
          variant={'primary'}
          onClick={handleClickText}
        >
          {/* Image */}
          {imageUrl && <img className={classes.image} src={imageUrl} alt="" />}
          {/* Label */}
          {title && <span>{title}</span>}
        </TextLink>

        {/* Media Link Button */}
        {buttonURL && buttonText && (
          <Button
            className={classes.button}
            size="small"
            variant="secondary"
            onClick={handleClickButton}
          >
            {buttonText || title || ''}
          </Button>
        )}
      </div>

      {/*<Grid className={classes.grid} direction="column" container>*/}
      {/*  {imageUrl && (*/}
      {/*    <Grid item>*/}
      {/*      <a href={url}>*/}
      {/*        <img*/}
      {/*          className={classes.image}*/}
      {/*          width="180"*/}
      {/*          height="120"*/}
      {/*          src={imageUrl}*/}
      {/*        />*/}
      {/*      </a>*/}
      {/*    </Grid>*/}
      {/*  )}*/}

      {/*  {title && (*/}
      {/*    <Grid item xs={12}>*/}
      {/*      <TextLink size={'large'} variant={'primary'} href={url || ''}>*/}
      {/*        {title}*/}
      {/*      </TextLink>*/}
      {/*    </Grid>*/}
      {/*  )}*/}

      {/*  {buttonURL && buttonText && (*/}
      {/*    <Grid item>*/}
      {/*      <Button size="small" variant="secondary" href={buttonURL}>*/}
      {/*        {buttonText || title || ''}*/}
      {/*      </Button>*/}
      {/*    </Grid>*/}
      {/*  )}*/}
      {/*</Grid>*/}
    </>
  )
}

export default MediaLink
