// @react
import React, { ReactElement } from 'react'
// @libraries
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
// @types
import { PropsType as CardProps } from '../../index'
import { PropsType as ThumbnailProps } from '@bit/atd.web.thumbnail'
// @components
import colors from '@bit/atd.web.colors'
import Thumbnail from '@bit/atd.web.thumbnail'

const useStyles = makeStyles({
  root: {
    position: 'relative',
  },
  thumbnail: {
    '& img': {
      borderRadius: '2px',
    },
  },
  thumbnailImageOverlay: {
    '&::after': {
      content: '""',
      backgroundColor: colors.gray300,
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      opacity: '0.4',
    },
  },
  thumbnailSquare: {
    minWidth: '100px',
  },
  thumbnailVideo: {
    minWidth: '176px',
  },
  thumbnailPublication: {
    minWidth: '96px',
    '& img': {
      borderRadius: '0',
      objectFit: 'contain',
    },
  },
  thumbnailCircle: {
    minWidth: '88px',
    '& img': {
      borderRadius: '110px',
    },
  },
  thumbnailSmall: {
    '&$thumbnailSquare': {
      minWidth: '64px',
    },
    '&$thumbnailVideo': {
      minWidth: '104px',
    },
    '&$thumbnailPublication': {
      minWidth: '64px',
    },
    '&$thumbnailCircle': {
      minWidth: '64px',
    },
  },
  label: {
    borderTopRightRadius: '2px',
    borderBottomRightRadius: '2px',
    position: 'absolute',
    top: '12px',
    left: 0,
    padding: '4px 8px',
    backgroundColor: colors.white,
    color: colors.black,
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '12px',
  },
  timestamp: {
    borderTopLeftRadius: '2px',
    borderBottomLeftRadius: '2px',
    position: 'absolute',
    bottom: 0,
    right: 0,
    padding: '4px 4px',
    backgroundColor: colors.white,
    color: colors.black,
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '12px',
    letterSpacing: '0.2px',
  },
  isWithoutBorderRadius: {
    '& img': {
      borderRadius: '0',
    },
  },
})

type PropsType = {
  type?: CardProps['thumbnail']['type']
  smallerSize?: CardProps['thumbnail']['smallerSize']
  src: CardProps['thumbnail']['src']
  alt?: CardProps['thumbnail']['alt']
  icon?: CardProps['thumbnail']['icon']
  ratio?: CardProps['thumbnail']['ratio']
  label?: CardProps['thumbnail']['label']
  timestamp?: CardProps['thumbnail']['timestamp']
  classes?: {
    wrap?: string
    thumbnail?: string
    label?: string
    timestamp?: string
  }
}

const CThumbnail = ({
  alt,
  type,
  smallerSize,
  src,
  icon,
  ratio,
  label,
  timestamp,
  classes,
}: PropsType): ReactElement | null => {
  const styles = useStyles()

  let resize
  switch (type) {
    case 'square':
      resize = 'w=100&h=100'
      break
    case 'publication': // we should rename this to correct aspect ratio
      resize = 'w=74&h=100'
      break
    default:
    case 'video': // we should rename this to correct aspect ratio
      resize = 'w=190&h=90'
      break
  }

  // @todo move this to backend URL prep
  const newSrc = `${src
    ?.replace('https://d22bbllmj4tvv8.cloudfront.net', 'https://atd.imgix.net')
    .replace(
      'http://files.astd.org.s3.amazonaws.com',
      'https://atd-s3.imgix.net',
    )
    .replace(
      'https://atd-brightspot-lower.s3.amazonaws.com',
      'https://atd-brightspot-lower.imgix.net',
      // )}?${resize}&fit=crop`
    )}?${resize}`

  let ratioBasedonType = ratio
  if (type === 'video') {
    if (ratio === '3:2') {
      ratioBasedonType = '3:2' as ThumbnailProps['ratio']
    } else {
      ratioBasedonType = '16:9' as ThumbnailProps['ratio']
    }
  }

  return (
    <div className={clsx(styles.root, classes && classes.wrap)}>
      {/* Image */}
      <Thumbnail
        alt={alt}
        src={newSrc}
        icon={icon}
        ratio={ratioBasedonType}
        className={clsx(
          styles.thumbnail,
          {
            [styles.thumbnailSquare]: type === 'square' || !type,
            [styles.thumbnailPublication]: type === 'publication',
            [styles.thumbnailVideo]: type === 'video',
            [styles.thumbnailImageOverlay]:
              type === 'video' || (type === 'square' && (label || timestamp)),
            [styles.thumbnailCircle]: type === 'circle',
            [styles.thumbnailSmall]: smallerSize,
          },
          classes && classes.thumbnail,
        )}
      />

      {/* Label */}
      {label && (type === 'video' || type === 'square') && (
        <span className={clsx(styles.label, classes && classes.label)}>
          {label}
        </span>
      )}

      {/* Video Timestamp */}
      {timestamp && (type === 'video' || type === 'square') && (
        <span className={clsx(styles.timestamp, classes && classes.timestamp)}>
          {timestamp}
        </span>
      )}
    </div>
  )
}

CThumbnail.defaultProps = {
  type: 'square',
  ratio: '1:1',
}

export default CThumbnail
