import type { ReactElement, ReactNode } from 'react'
import type {
  LandingPagesPageSectionSectionBlockBlockContent,
  LandingPagesPageSectionSectionBlockBlockContentColumnModule,
  LandingPagesPageSectionSectionBlockBlockContentFeaturedModule,
  LandingPagesPageSectionSectionBlockBlockContentHeader,
  LandingPagesPageSectionSectionBlockBlockContentImageAndTextModule,
  LandingPagesPageSectionSectionBlockBlockContentImageCtaModule,
  LandingPagesPageSectionSectionBlockBlockContentSingleTextModule,
  LandingPagesPageSectionSectionBlockBlockContentStepsModule,
  LandingPagesPageSectionSectionBlockBlockContentTwoColumnHeaderModule,
  LandingPagesPageSectionSectionBlockBlockContentFormModule,
} from 'lib/graphql/graphqlTypes'
import React from 'react'
import { isOfType } from '../../typeguard'
import parse from 'html-react-parser'
import PageHeader from '../../__COMPONENTS_TO_MIGRATE/PageHeader'
import MultiColumnTextLink from '../../__COMPONENTS_TO_MIGRATE/CTA/MultiColumnTextLink'
import ImageCTA from '../../__COMPONENTS_TO_MIGRATE/CTA/ImageCTA'
import ImageText from '../../__COMPONENTS_TO_MIGRATE/ImageText'
import TextEditorBlock from '../../__COMPONENTS_TO_MIGRATE/TextEditorBlock'
import Stepper from '../../__COMPONENTS_TO_MIGRATE/Stepper'
import FeaturedContent from '../FeaturedContent'
import LandingPageForm from '../LandingPageForm'

type PropsType = {
  content: LandingPagesPageSectionSectionBlockBlockContent
}

export enum Theme {
  TABS = 'TABS',
  ACCORDION = 'ACCORDION',
}

/**
 *
 * @param content
 * @constructor
 */
const BlockChooser = ({ content }: PropsType): ReactElement => {
  /*********************************
   * Column
   **********************************/
  if (
    isOfType<LandingPagesPageSectionSectionBlockBlockContentColumnModule>(
      content,
      '__typename',
      'LandingPagesPageSectionSectionBlockBlockContentColumnModule',
    )
  ) {
    if (!content.column_module?.column_item) return <></>

    const convertedContent = content.column_module?.column_item.map((a, i) => {
      let link
      if (a?.button && a?.button?.href && a?.button?.title) {
        link = {
          href: a.button.href,
          label: a.button.title,
        }
      }
      return {
        title: a?.title || undefined,
        subtitle: parse(a?.subtitle || '') || undefined,
        link,
      }
    })

    return <MultiColumnTextLink content={convertedContent} />
  }

  /*********************************
   * Featured
   **********************************/
  if (
    isOfType<LandingPagesPageSectionSectionBlockBlockContentFeaturedModule>(
      content,
      '__typename',
      'LandingPagesPageSectionSectionBlockBlockContentFeaturedModule',
    )
  ) {
    if (!content.featured_module?.content?.length) {
      return <></>
    }

    return (
      <FeaturedContent
        title={content.featured_module.title}
        subTitle={content.featured_module.subtitle}
        content={content.featured_module.content}
        theme={content.featured_module.theme as Theme}
      />
    )
  }

  /*********************************
   * Header
   **********************************/
  if (
    isOfType<LandingPagesPageSectionSectionBlockBlockContentHeader>(
      content,
      '__typename',
      'LandingPagesPageSectionSectionBlockBlockContentHeader',
    )
  ) {
    let backgroundImage
    if (content.header?.imageConnection?.edges?.[0]?.node?.url) {
      backgroundImage = {
        src: content.header?.imageConnection?.edges?.[0]?.node?.url,
      }
    }

    let header
    if (
      content.header &&
      (content.header.title ||
        content.header?.subtitle ||
        (content.header?.button?.href && content.header.button.title))
    ) {
      header = {
        title: content.header?.title,
        subtitle: parse(content.header?.subtitle || ''),
        button:
          content.header?.button?.href && content.header.button.title
            ? {
                href: content.header.button.href,
                label: content.header.button.title,
              }
            : undefined,
      }
    }

    return (
      <PageHeader
        variant={'centered'}
        backgroundImage={backgroundImage}
        header={header}
      />
    )
  }

  /*********************************
   * ImageAndText
   **********************************/
  if (
    isOfType<LandingPagesPageSectionSectionBlockBlockContentImageAndTextModule>(
      content,
      '__typename',
      'LandingPagesPageSectionSectionBlockBlockContentImageAndTextModule',
    )
  ) {
    if (!content.image_and_text_module?.image_and_text_item?.length)
      return <></>

    const convertedContent = content.image_and_text_module.image_and_text_item.map(
      (section, i) => {
        let content
        if (section?.text) {
          content = {
            text: parse(section.text || ''),
          }
        }
        if (section?.button?.href && section?.button?.title) {
          content = {
            ...content,
            button: { href: section.button.href, label: section.button.title },
          }
        }

        let image
        if (
          section?.imageConnection?.edges?.[0]?.node?.url ||
          section?.imageConnection?.edges?.[0]?.node?.title
        ) {
          image = {
            src: section.imageConnection.edges[0].node.url,
            alt: section.imageConnection.edges[0].node.title,
          }
        }

        return {
          image,
          content,
        }
      },
    )

    return <ImageText sections={convertedContent} />
  }

  /*********************************
   * ImageCta
   **********************************/
  if (
    isOfType<LandingPagesPageSectionSectionBlockBlockContentImageCtaModule>(
      content,
      '__typename',
      'LandingPagesPageSectionSectionBlockBlockContentImageCtaModule',
    )
  ) {
    if (
      !content.image_cta_module?.imageConnection?.edges?.length ||
      !content.image_cta_module?.imageConnection?.edges[0]?.node ||
      !content.image_cta_module.imageConnection.edges[0].node.url
    )
      return <></>

    const src = content.image_cta_module.imageConnection.edges[0].node.url
    const eyebrow = content.image_cta_module?.eyebrow
    const headline = content.image_cta_module?.title

    let button
    if (
      content.image_cta_module.button?.href &&
      content.image_cta_module.button?.title
    ) {
      button = {
        href: content.image_cta_module.button?.href,
        label: content.image_cta_module.button?.title,
      }
    }
    const isReverse = content.image_cta_module.image_position === 'RIGHT'

    return (
      <>
        <ImageCTA
          isReverse={isReverse}
          image={{
            src,
          }}
          content={{
            eyebrow,
            headline,
            button,
          }}
        />
      </>
    )
  }

  /*********************************
   * TwoColumnHeader
   **********************************/
  if (
    isOfType<LandingPagesPageSectionSectionBlockBlockContentTwoColumnHeaderModule>(
      content,
      '__typename',
      'LandingPagesPageSectionSectionBlockBlockContentTwoColumnHeaderModule',
    )
  ) {
    let backgroundImage
    if (
      content.two_column_header_module?.imageConnection?.edges?.[0]?.node?.url
    ) {
      backgroundImage = {
        src:
          content.two_column_header_module?.imageConnection?.edges?.[0]?.node
            ?.url,
      }
    }

    let header
    if (content.two_column_header_module?.title) {
      header = {
        title: content.two_column_header_module?.title,
      }
    }

    let columns
    if (content.two_column_header_module?.column_item) {
      columns = content.two_column_header_module?.column_item.map((c, i) => {
        return {
          title: c?.title,
          text: parse(c?.subtitle || ''),
        }
      })
    }

    return (
      <PageHeader
        variant={'bottom'}
        backgroundImage={backgroundImage}
        header={header}
        columns={columns}
      />
    )
  }

  /*********************************
   * SingleText
   **********************************/
  if (
    isOfType<LandingPagesPageSectionSectionBlockBlockContentSingleTextModule>(
      content,
      '__typename',
      'LandingPagesPageSectionSectionBlockBlockContentSingleTextModule',
    )
  ) {
    if (!content.single_text_module || !content.single_text_module.text)
      return <></>
    return (
      <TextEditorBlock HTMLToRender={parse(content.single_text_module.text)} />
    )
  }

  /*********************************
   * Steps
   **********************************/
  if (
    isOfType<LandingPagesPageSectionSectionBlockBlockContentStepsModule>(
      content,
      '__typename',
      'LandingPagesPageSectionSectionBlockBlockContentStepsModule',
    )
  ) {
    if (!content.steps_module?.step) return <></>

    const steps = content.steps_module.step.reduce(
      (arr: { text: string | ReactElement | ReactNode }[], step) => {
        if (step?.text) arr.push({ text: parse(step?.text) })
        return arr
      },
      [],
    )

    if (!steps || !steps.length) return <></>

    let button
    if (
      content.steps_module.button?.href &&
      content.steps_module.button?.title
    ) {
      button = {
        href: content.steps_module.button.href,
        label: content.steps_module.button.title,
      }
    }

    return <Stepper steps={steps} button={button} />
  }

  /*********************************
   * Form
   **********************************/
  if (
    isOfType<LandingPagesPageSectionSectionBlockBlockContentFormModule>(
      content,
      '__typename',
      'LandingPagesPageSectionSectionBlockBlockContentFormModule',
    )
  ) {
    return content.form_module?.form_title ? <LandingPageForm /> : <></>
  }

  return <></>
}

export default BlockChooser
