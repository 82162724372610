// language=GraphQL
import { gql } from '@apollo/client'
import { FILTERS_FRAGMENT, META_FRAGMENT } from '../fragments/filters.fragment'
import { ABSTRACT_SEARCH_ITEM } from '../fragments/interface.fragment'
import { IAbstractSearchItemFragments } from '../fragments/content.fragment'

export const GET_USER_CONTENT = gql`
  query GetUserContent($input: UserContentInput) {
    userContent(input: $input) {
      meta {
        ...MetaNode
        orderBy {
          value
          order
        }
        allFilters {
          ...FiltersNode
        }
      }

      data {
        ... on IAbstractSearchItem {
          ...IAbstractSearchItem
        }
        #
        # CONTENT
        #
        ... on BlogSearchItem {
          ...IAbstractSearchItem
          ...BlogSearchItemNode
        }
        ... on ArticleSearchItem {
          ...IAbstractSearchItem
          ...ArticleSearchItemNode
        }
        ... on PageSearchItem {
          ...IAbstractSearchItem
          ...PageSearchItemNode
        }
        ... on VideoSearchItem {
          ...IAbstractSearchItem
          ...VideoSearchItemNode
        }
        ... on PublicationSearchItem {
          ...IAbstractSearchItem
          ...PublicationSearchItemNode
        }
        ... on UserSearchItem {
          ...IAbstractSearchItem
          ...UserSearchItemNode
        }
        ... on CourseSearchItem {
          ...IAbstractSearchItem
          ...CourseSearchItemNode
        }
        ... on ConferenceSearchItem {
          ...IAbstractSearchItem
          ...ConferenceSearchItemNode
        }
        ... on WebinarSearchItem {
          ...IAbstractSearchItem
          ...WebinarSearchItemNode
        }
      }
    }
  }
  ${IAbstractSearchItemFragments.videoSearchItemFragments.fragments.node}
  ${IAbstractSearchItemFragments.courseSearchItemFragments.fragments.node}
  ${IAbstractSearchItemFragments.webinarSearchItemFragments.fragments.node}
  ${IAbstractSearchItemFragments.publicationSearchItemFragments.fragments.node}
  ${IAbstractSearchItemFragments.courseSearchItemFragments.fragments.node}
  ${IAbstractSearchItemFragments.conferenceSearchItemFragments.fragments.node}
  ${IAbstractSearchItemFragments.blogSearchItemFragments.fragments.node}
  ${IAbstractSearchItemFragments.articleSearchItemFragments.fragments.node}
  ${IAbstractSearchItemFragments.userSearchItemFragments.fragments.node}
  ${IAbstractSearchItemFragments.pageSearchItemFragments.fragments.node}
  ${FILTERS_FRAGMENT}
  ${ABSTRACT_SEARCH_ITEM}
  ${META_FRAGMENT}
`

export const GET_ALL_BOOKMARKS = gql`
  query GetAllBookmarks($input: UserContentInput) {
    userContent(input: $input) {
      data {
        ... on IAbstractSearchItem {
          id
        }
      }
    }
  }
`
