export enum RouteNamesInternal {
  /**
   * main
   */
  HOME = '',

  /**
   * myATD
   */
  MYATD = 'myatd',
  DASHBOARD = 'dashboard',
  BENEFITS_CENTER = 'benefit-center',
  ACTIVITY_HISTORY = 'activity-history',
  LEARNING = 'learning',
  BOOKMARKS = 'bookmarks',
  NETWORK_ACTIVITY = 'network-activity',
  USER_CONTENT = 'contributions',

  /**
   * profile
   */
  PERSONAL_INFORMATION = 'personal-information',
  SECURITY = 'security',
  MEMBERSHIP_DETAILS = 'membership-details',
  COMMUNICATION = 'communication',
  TIME_ZONE = 'time-zone',
  ORDERS = 'orders',
  NETWORK = 'network',
  TOPICS = 'topics',
  ENTERPRISE_ADMIN = 'enterprise-admin',
  UNSUBSCRIBE = 'unsubscribe',

  /**
   * search
   */
  SEARCH = 'search',
  SEARCH_BOOKS = 'books',
  SEARCH_COURSES = '/search/courses',
  SEARCH_INSTANT = 'instant',

  /**
   * onboarding
   */
  PROFILE = 'profile',
  SIGN_UP = 'sign-up',
  ONBOARDING = 'onboarding',
  OB_CREATE_ACCOUNT = 'create-account',
  OB_PROFILE_BIO = 'profile-bio',
  OB_WORK = 'work',
  OB_EDUCATION = 'education',
  OB_CONTACT = 'contact',
  OB_COMMUNICATION = 'communication',
  OB_NETWORK = 'network',
  OB_TOPICS = 'topics',
  OB_MEMBER = 'member',

  /**
   * content stack
   */

  FLEXTEMPLATE = '',

  /**
   * Extras
   */
  ID = 'id',
  NOT_FOUND = '404',
  ERROR = 'error',
}
