/**
 *
 */
const atdBaseURL = () => {
  switch (process.env.REACT_APP_HOST_ENV) {
    case 'QA':
      return 'https://qa.td.org'
    case 'STAGING':
      return 'https://staging.td.org'
    default:
      return 'https://www.td.org'
  }
}

const ssoBaseURL = () => {
  switch (process.env.REACT_APP_HOST_ENV) {
    case 'QA':
      return 'https://login-pqa762.td.org'
    case 'STAGING':
      return 'https://login-pqa762.td.org'
    default:
      return 'https://login.td.org'
  }
}

/**
 *
 */
const QAExternalRoutes = {
  SSO_LIBRARY: `${ssoBaseURL()}/gateway/dilogin.aspx?returl=https%3A%2F%2Fcourses-staging.td.org%2Fdiweb%2Fgateway%3Ff%3Dhttps*3A*2F*2Fcourses-staging.td.org*2Fdiweb*2Fjitlearning*2Ftg*2Fatd-elements`,
  CHECKOUT_MEMBERSHIP: 'https://checkout-pqa762.td.org/membership',
  TRANSCRIPT_DOWNLOAD: `https://courses-staging.td.org/diweb/transcript/dl`,
  CHECKOUT_HOME: 'https://checkout-pqa762.td.org/',
  CM_ASSESSMENT_PERSONAL: 'https://capability.td.org/#/assess/professional',
  CM_ASSESSMENT_PERSONAL_COMMUNICATION:
    'https://capability.td.org/#/assess/professional/learning-sciences',
  CM_LEARNING_PLANS_GOAL: 'https://capability.td.org/#/learning-plan/goal',
  CM_LEARNING_PLANS_GOAL_SELF_GUIDED:
    'https://capability.td.org/#/learning-plan/goal/general-improvements/self-guided',
  CM_LEARNING_PLANS_ALL:
    'https://capability.td.org/#/learning-plan/all-resources',
}

/**
 *
 */
export const RouteNamesExternal = {
  ATD_WEB_URL: `${atdBaseURL()}`,
  ATD_404: `${atdBaseURL()}/404`,
  ATD_ABOUT_US: `${atdBaseURL()}/about`,
  ATD_ADVERTISE: `${atdBaseURL()}/advertise-with-us`,
  ATD_CAREERS: `${atdBaseURL()}/jobs-at-atd`,
  ATD_CERTIFICATIONS: `${atdBaseURL()}/certification`,
  ATD_CHAPTERS: `${atdBaseURL()}/members/chapters`,
  ATD_CHAPTERS_LEADER_COMMUNITY: `${atdBaseURL()}/chapters/clc`,
  ATD_CHAPTERS_LOCATOR: `${atdBaseURL()}/chapter-locator`,
  ATD_CONFERENCES: `${atdBaseURL()}/shop/conferences`,
  ATD_CONTRIBUTE: `${atdBaseURL()}/contribute`,
  ATD_COOKIE_POLICY: `${atdBaseURL()}/cookie-policy`,
  ATD_EDUCATION_SEARCH: `${atdBaseURL()}/education/search-all-programs`,
  ATD_ENTERPRISE_ADMIN: `${atdBaseURL()}/enterprise-options`,
  ATD_ENTERPRISE_HOME: `${atdBaseURL()}/enterprise-solutions`,
  ATD_EVENTS: `${atdBaseURL()}/events`,
  // Web Governance team has discontinued fax ATD_FAX: '1.708.299.8723',
  ATD_GLOBAL_HOME: `${atdBaseURL()}/atd-global`,
  ATD_GROUPS: `${atdBaseURL()}/groups`,
  ATD_HOME: `${atdBaseURL()}`,
  ATD_JOIN: `${atdBaseURL()}/join-atd`,
  ATD_MAIL_CUSTOMER_CARE: 'mailto:customercare@td.org',
  ATD_MEMBERSHIP_CENTER: `${atdBaseURL()}/user/member-center`,
  ATD_MERCHANDISE: `${atdBaseURL()}/store-merchandise`,
  ATD_PHONE_GLOBAL: 'tel://+18006282783',
  ATD_PHONE_US: 'tel://+17036838100',
  ATD_PRESS_ROOM: `${atdBaseURL()}/about/atd-press-room`,
  ATD_SHOP_CONFERENCES: `${atdBaseURL()}/shop/conferences`,
  ATD_STORE: `${atdBaseURL()}/store`,
  ATD_TOS: `${atdBaseURL()}/terms-and-privacy`,
  ATD_TOS_PRIVACY: `${atdBaseURL()}/privacy-notice`,
  // SSO
  SSO_HOME: `${ssoBaseURL()}`,
  SSO_CERTIFICATION_PORTAL_HOME: `${ssoBaseURL()}/jwt.aspx?pnid=2001&callbackurl=https://atd.useclarus.com/simple_sso/login`,
  SSO_LIBRARY: `${ssoBaseURL()}/gateway/dilogin.aspx?returl=https%3A%2F%2Fcourses.td.org%2Fdiweb%2Fgateway%3Ff%3Dhttps*3A*2F*2Fcourses.td.org*2Fdiweb*2Fjitlearning*2Ftg*2FATD_Micro_Courses`,
  SSO_LOGIN: `${ssoBaseURL()}/api/jwt/Authenticate`,
  SSO_LOGIN_COMPANY: `${ssoBaseURL()}/Company.aspx`,
  SSO_LOGOUT: `${ssoBaseURL()}/SignOut`,
  SSO_RECOVER_PASSWORD: `${ssoBaseURL()}/RecoverPassword.aspx`,
  SSO_REGISTER: `${ssoBaseURL()}/Register.aspx`,
  //
  MEMBER_FEEDBACK:
    'https://atdorg.formstack.com/forms/atd_members__we_want_to_hear_from_you',
  CHECKOUT_HOME: 'https://checkout.td.org/',
  CHECKOUT_MEMBERSHIP: 'https://checkout.td.org/membership',
  CM_ASSESSMENT_PERSONAL: 'https://capability.td.org/#/assess/professional',
  CM_ASSESSMENT_PERSONAL_COMMUNICATION:
    'https://capability.td.org/#/assess/professional/learning-sciences',
  CM_LEARNING_PLANS_GOAL: 'https://capability.td.org/#/learning-plan/goal',
  CM_LEARNING_PLANS_GOAL_SELF_GUIDED:
    'https://capability.td.org/#/learning-plan/goal/general-improvements/self-guided',
  CM_LEARNING_PLANS_ALL:
    'https://capability.td.org/#/learning-plan/all-resources',
  HELP_HOME_US: 'https://help.td.org/hc/en-us',
  HELP_FAQ: 'https://help.td.org/hc/en-us',
  JOBS_BANK: 'https://jobs.td.org/',
  SOCIAL_FACEBOOK: 'https://www.facebook.com/ATD',
  SOCIAL_TWITTER: 'https://twitter.com/ATD',
  SOCIAL_LINKEDIN:
    'https://www.linkedin.com/company/association-for-talent-development/',
  SOCIAL_PINTEREST: 'https://www.pinterest.ca/ATDofficial/',
  SOCIAL_INSTAGRAM: 'https://www.instagram.com/atdnational/',
  WEBCASTS_HOME: 'https://webcasts.td.org/',
  TRANSCRIPT_DOWNLOAD: `https://courses.td.org/diweb/transcript/dl`,
  ...(process.env.REACT_APP_HOST_ENV === 'QA' && QAExternalRoutes),
}
