import { Dispatch, SetStateAction } from 'react'
// @common
import Cookies from 'universal-cookie'
import axios from 'axios'
import { RouteNamesExternal } from 'constants/routeNamesExternal'
// @graphql
import client from 'graphql/Apollo/apolloClient'
import { FetchResult } from '@apollo/client'
import { LoginMutation } from 'lib/graphql/graphqlTypes'

const cookies = new Cookies()

export const doLogout = async () => {
  const allCookies = cookies.getAll()
  Object.keys(allCookies).forEach((item) => cookies.remove(item))
  await client.clearStore()
  await axios.get(RouteNamesExternal.SSO_LOGOUT, { withCredentials: true })
  window.location.href = RouteNamesExternal.ATD_WEB_URL
}
type LoginInput = {
  variables: {
    input: {
      accessToken: string
    }
  }
}
type LoginResponse = FetchResult<LoginMutation>

type PropsType = {
  data: {
    username: string
    password: string
  }
  login: (loginInput: LoginInput) => Promise<LoginResponse>
  onLogin?: (() => void) | undefined
  onError?: (arg: string) => void
  setIsLoginInProcess?: Dispatch<SetStateAction<boolean>> | undefined
}

export const doLogin = async ({
  data,
  login,
  onLogin,
  onError,
  setIsLoginInProcess,
}: PropsType) => {
  try {
    setIsLoginInProcess && setIsLoginInProcess(true)

    // getting access token
    const res = await axios.post(
      RouteNamesExternal.SSO_LOGIN,
      {
        username: data.username,
        password: data.password,
        pnid: process.env.REACT_APP_SSO_PARTNER_ID,
      },
      {
        withCredentials: true,
      },
    )

    // getting refreshToken and setting it to cookie as bsp.jwt
    const loginResponse = await login({
      variables: {
        input: {
          accessToken: res.data,
        },
      },
    })

    setIsLoginInProcess && setIsLoginInProcess(false)
    if (!loginResponse.data?.login) {
      onError && onError('Unable to authenticate')
    } else {
      onLogin && onLogin()
      return loginResponse
    }
  } catch (e: any) {
    setIsLoginInProcess && setIsLoginInProcess(false)
    onError &&
      onError(
        e.response === undefined || e === undefined
          ? 'Network Error'
          : e.response.statusText,
      )
  }
}
