import { gql } from '@apollo/client'
import { META_FRAGMENT } from '../fragments/filters.fragment'
import { ABSTRACT_SEARCH_ITEM } from '../fragments/interface.fragment'
import { allContentFragments } from '../fragments/allContent.fragment'

export const GET_FEATURED_CONTENT = gql`
  query GetFeaturedContent($input: FeaturedContentInput) {
    featuredContent(input: $input) {
      meta {
        ...MetaNode
      }

      data {
        ...AllContentItemNode
      }
    }
  }
  ${allContentFragments.fragments.node}
  ${ABSTRACT_SEARCH_ITEM}
  ${META_FRAGMENT}
`
export const GET_FEATURED_CONTENT_HEADER = gql`
  query GetFeaturedContentHeader($input: FeaturedContentInput) {
    featuredContent(input: $input) {
      meta {
        ...MetaNode
      }

      data {
        imageUrl
        title
        url
      }
    }
  }
  ${META_FRAGMENT}
`
