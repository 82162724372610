import { RefObject, useEffect } from 'react'

// Hook
const useOnClickOutside = (
  ref: RefObject<HTMLDivElement>,
  handler: (e: Event) => void,
): void => {
  useEffect(
    () => {
      const listener = (event: Event) => {
        event.stopPropagation()
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target as Node)) {
          return
        }

        handler(event)
      }

      document.addEventListener('click', listener)
      document.addEventListener('touchstart', listener)

      return () => {
        document.removeEventListener('click', listener)
        document.removeEventListener('touchstart', listener)
      }
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler],
  )
}

export default useOnClickOutside
