import FlexTemplate from './pages/FlexTemplate'

//@todo: added other inner pages to routes
const PagebuilderRoutes = {
  routes: [
    {
      key: 'FLEX_TEMPLATE',
      title: 'LandingPage',
      type: 'public',
      path: `/landing-page/:id`,
      component: FlexTemplate,
      exact: true,
    },
  ],
}

export default PagebuilderRoutes
