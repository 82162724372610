// @react
import React, { ReactElement } from 'react'
// @libraries
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
// @components
import TextLink from '@bit/atd.web.text-link'
import Spacer from '@bit/atd.web.spacer'
import colors from '@bit/atd.web.colors'

const useStyles = makeStyles({
  header: {
    display: 'flex',
    marginBottom: '32px',
    paddingBottom: '16px',
    borderBottom: `1px solid ${colors.gray400}`,
    alignItems: 'baseline',
  },
  title: {
    color: colors.black,
    margin: 0,
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '34px',
  },
})

type PropsType = {
  title: string | undefined
  url: string | undefined
  target: string | undefined
}

const Header = ({ title, url, target }: PropsType): ReactElement => {
  /********************************
   * HOOKS
   ********************************/

  const classes = useStyles()

  /********************************
   * RENDER
   ********************************/

  return (
    <header className={classes.header}>
      <Typography className={classes.title} variant={'h2'}>
        {title}
      </Typography>

      <Spacer size={16} />

      {url && (
        <TextLink
          variant="standalone"
          href={url}
          target={target === 'BLANK' ? '_blank' : ''}
        >
          Overview
        </TextLink>
      )}
    </header>
  )
}

export default Header
