// @react
import React, { ReactElement } from 'react'
// @types
import { PropsType as CardProps } from '../../index'
// @libraries
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
// @components
import Colors from '@bit/atd.web.colors'
import Icon from '@bit/atd.web.icons'
import Sponsor from '../Sponsor'

const useStyles = makeStyles({
  root: {},
  detailListTitle: {
    fontSize: '11px',
    fontWeight: 600,
    textTransform: 'uppercase',
    padding: 0,
    margin: '0 0 8px',
  },
  detailList: {
    listStyle: 'none',
    margin: '0',
    padding: '0',
    '& li': {
      display: 'flex',
      fontSize: '0',
      marginBottom: '8px',
    },
    '& li:last-child': {
      marginBottom: '0',
    },
    '& + $viewAllLink': {
      marginTop: '8px',
    },
  },
  detailIcon: {
    position: 'relative',
    paddingRight: '8px',
  },
  detailLabel: {
    fontSize: '12px',
    lineHeight: '15px',
    fontWeight: 400,
  },
  viewAllLink: {
    color: Colors.ctaPrimary,
    cursor: 'pointer',
    fontSize: '12px',
    lineHeight: '15px',
  },
})

type PropsType = {
  detailListTitle: CardProps['details']['detailListTitle']
  detailList: CardProps['details']['detailList']
  viewAllLink: CardProps['details']['viewAllLink']
  sponsors: CardProps['details']['sponsors']
  className?: string
}

const Details = ({
  className,
  detailListTitle,
  detailList,
  viewAllLink,
  sponsors,
}: PropsType): ReactElement => {
  const classes = useStyles()

  return (
    <div
      className={clsx({
        [classes.root]: true,
        [className || '']: className,
      })}
    >
      {/* Detail List Title */}
      {detailListTitle && (
        <p className={classes.detailListTitle}>{detailListTitle}</p>
      )}

      {/* Detail list */}
      {detailList && (
        <ul className={classes.detailList}>
          {detailList?.map((detail, idx) => (
            <li key={idx}>
              {detail?.icon && (
                <span className={classes.detailIcon}>
                  <Icon size={16} glyph={detail.icon} />
                </span>
              )}
              <span className={classes.detailLabel}>{detail.label}</span>
            </li>
          ))}
        </ul>
      )}

      {/* View All Link */}
      {viewAllLink && viewAllLink.label && (
        <div className={classes.viewAllLink} onClick={viewAllLink?.callback}>
          {viewAllLink.label}
        </div>
      )}

      {/* Sponsored */}
      {sponsors &&
        sponsors.map((sponsored, index) => (
          <Sponsor key={index} sponsor={sponsored} />
        ))}
    </div>
  )
}

export default Details
