// @react
import React, { ReactElement } from 'react'
// @libraries
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
// @types
import { PropsType as CardProps } from '../../index'
// @components
import Grid from '@bit/atd.web.grid'
import Lozenge from '@bit/atd.web.lozenge'
import Icon from '@bit/atd.web.icons'
import colors from '@bit/atd.web.colors'

type PropsType = {
  footer?: CardProps['footer']
  horizontal?: boolean
  className?: string
  classes?: {
    footerBar?: string
  }
}

const useStyles = makeStyles({
  footer: {
    position: 'relative',
    marginTop: 'auto',
  },
  footerBar: {
    backgroundColor: colors.gray200,
    padding: '10px 16px',
  },
  footerBarTop: {
    padding: '6px 16px 12px',
    justifyContent: 'flex-end',
  },
  communityContent: {
    display: 'flex',
    fontSize: '0',
    '& span:first-child': {
      paddingRight: '4px',
    },
    '& span:last-child': {
      fontSize: '12px',
    },
  },
  contentTypeTagWrapper: {
    display: 'flex',
    alignItems: 'end',
    paddingRight: '16px',
    '& > span:first-child': {
      alignSelf: 'center',
    },
    '& > span + span': {
      marginLeft: '4px',
    },
  },
  contentType: {
    display: 'inline-block',
    color: colors.black,
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '12px',
    textTransform: 'uppercase',
  },
  bookmarkWrapper: {
    alignSelf: 'center',
  },
  bookmark: {
    cursor: 'pointer',
    position: 'relative',
    width: '16px',
    height: '16px',
    '& > div': {
      position: 'absolute',
      padding: '8px',
      fontSize: '0',
      left: '-8px',
      top: '-8px',
    },
  },
  // states
  horizontal: {},
  removeBG: {
    '& $footerBar': {
      background: 'none',
      padding: '12px 12px',
    },
    '& $footerBarTop': {
      padding: '8px 12px 0',
    },
  },
  forceVertical: {
    '& $footerBarTop': {
      justifyContent: 'flex-start',
    },
    '& $contentTypeTagWrapper': {
      flexDirection: 'column',
      alignItems: 'start',
    },
    '& $contentTypeTagWrapper > span:first-child': {
      alignSelf: 'start',
    },
    '& $contentTypeTagWrapper > span + span': {
      marginTop: '4px',
      marginLeft: '0',
    },
  },
})

const FooterBar = ({
  className,
  classes,
  horizontal,
  footer,
}: PropsType): ReactElement | null => {
  const sClasses = useStyles()

  if (!footer) return null
  const { bookmark } = footer

  return (
    <footer
      className={clsx({
        [sClasses.footer]: true,
        [sClasses.removeBG]: footer?.removeBG,
        [sClasses.horizontal]: horizontal,
        [sClasses.forceVertical]: !horizontal,
        [className || '']: className,
      })}
    >
      {/* Community Content */}
      {footer.communityContent && (
        <Grid className={sClasses.footerBarTop} container>
          <Grid item>
            <div className={sClasses.communityContent}>
              <span>
                <Icon glyph="conferences" size={16} color={colors.black} />
              </span>{' '}
              <span>Community Content</span>
            </div>
          </Grid>
        </Grid>
      )}

      {/* FooterBar */}
      <div className={clsx(sClasses.footerBar, classes && classes.footerBar)}>
        <Grid container justify="space-between" wrap="nowrap">
          {/* Content Type / Lozenge Tag */}
          <Grid item className={sClasses.contentTypeTagWrapper}>
            {/* Content Type */}
            {footer.contentType && (
              <span className={sClasses.contentType}>{footer.contentType}</span>
            )}
            {/* Lozenge Tag */}
            {footer.tag && (
              <Lozenge
                variant={footer.tag.variant}
                size="small"
                color={footer.tag.color}
              >
                {footer.tag.text}
              </Lozenge>
            )}
          </Grid>
          {/* Bookmark */}
          <Grid item className={sClasses.bookmarkWrapper}>
            {bookmark !== undefined && (
              <div className={sClasses.bookmark}>
                <div onClick={footer.bookmark?.callback}>
                  {!bookmark.value && (
                    <Icon
                      glyph="bookmark"
                      size={16}
                      color={colors.ctaPrimary}
                    />
                  )}
                  {bookmark.value && (
                    <Icon
                      glyph="bookmark-fill"
                      size={16}
                      color={colors.ctaPrimary}
                    />
                  )}
                </div>
              </div>
            )}
          </Grid>
        </Grid>
      </div>
    </footer>
  )
}

export default FooterBar
