// @react
import React, { useContext, ReactElement, useRef } from 'react'
// @libraries
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
// @components
import colors from '@bit/atd.web.colors'
import Grid from '@bit/atd.web.grid'
import Container from '@bit/atd.web.container'
import ContextMenu from '@bit/atd.web.context-menu'
import ContextMenuItem from '@bit/atd.web.context-menu-item'
// @module components
import MegaMenuLink from '../MegaMenu/Toggle'
import MegaMenu from '../MegaMenu'
// @context
import HeaderDataContext from '../../context'
import useOnClickOutside from '../../../../common/hooks/useOnClickOutside'
import { Fade } from '@material-ui/core'

const useStyles = makeStyles({
  root: {
    padding: '8px',
    background: colors.white,
    borderTop: `1px solid ${colors.gray400}`,
  },
  wrapper: {
    fontSize: 0,
    width: '100%',
    maxWidth: 'none',
    '& > div': {
      position: 'relative',
      maxWidth: '1188px',
      margin: '0 auto',
    },
  },
  contextMenuVariantText: {
    background: 'none',
    paddingLeft: 0,
    paddingRight: 24,
    marginLeft: 24,
    '& svg': {
      height: '24px',
      width: '24px',
      right: 0,
    },
  },
  superFlair: {
    fontWeight: 'bold',
    fontSize: '11px',
    textTransform: 'uppercase',
    marginTop: '-2px',
    marginLeft: '2px',
    position: 'absolute',
  },
  descriptionText: {
    marginTop: '2px',
    fontSize: '12px',
    fontWeight: 'normal',
    lineHeight: '16px',
    maxWidth: '162px',
  },
  isEmpty: {
    padding: 0,
  },
  // Styles used when used as a condensed / nested main menu
  isCondensed: {
    padding: 0,
    borderTop: 0,
    background: 'transparent',
    '& > div': {
      paddingLeft: 16,
      paddingRight: 0,
    },
    '& $wrapper > div': {
      position: 'static',
    },
    '& $wrapper > div > div:first-child > a': {
      paddingRight: 0,
    },
    '& $contextMenuVariantText': {
      marginLeft: 12,
    },
  },
  fadeinWrapper: {
    zIndex: 5,
    position: 'relative',
  },
  flex: {
    display: 'flex',
  },
})

type PropsType = {
  // Used as nested condensed menu next to the logo
  asCondensedMenu?: boolean
  toggleMegaMenu: (e: any) => void
  showMegaMenu: boolean
}

const Bottom = ({
  asCondensedMenu,
  toggleMegaMenu,
  showMegaMenu,
}: PropsType): ReactElement => {
  /********************************
   * REFS
   ********************************/

  const ref = useRef<HTMLDivElement>(null)

  /********************************
   * HOOKS
   ********************************/

  const classes = useStyles()
  const headerContext = useContext(HeaderDataContext)
  const { condensed } = headerContext

  useOnClickOutside(ref, (event: Event) => {
    event.stopPropagation()
    showMegaMenu && toggleMegaMenu(!showMegaMenu)
  })

  /********************************
   * RENDER
   ********************************/

  const rootMenu = headerContext.data
  const leftMenuItems = rootMenu && rootMenu[0]
  const rightMenuItems = rootMenu && rootMenu.slice(1)
  const emptyMenu = !leftMenuItems && rightMenuItems && !rightMenuItems.length

  if (condensed && !asCondensedMenu) {
    return <div className={clsx(classes.root, classes.isEmpty)} />
  }

  return (
    <div
      className={clsx(classes.root, {
        [classes.isEmpty]: emptyMenu,
        [classes.isCondensed]: asCondensedMenu,
      })}
    >
      <Container className={classes.wrapper}>
        <Grid
          container
          alignItems="center"
          justify={!asCondensedMenu ? 'space-between' : 'flex-start'}
        >
          {/* Mega Menu */}
          {leftMenuItems && (
            <Grid style={{ position: 'static' }} item xs={!asCondensedMenu}>
              {/* Mega Menu - Toggle */}
              <div
                onMouseEnter={() => toggleMegaMenu(true)}
                onMouseLeave={() => toggleMegaMenu(false)}
                style={{ width: '100px' }}
              >
                <MegaMenuLink
                  big={!asCondensedMenu}
                  className={showMegaMenu ? 'active' : ''}
                  isMegaMenuVisible={showMegaMenu}
                  onClick={(event: any) => {
                    event.stopPropagation()
                    event.nativeEvent.stopImmediatePropagation()
                    toggleMegaMenu(!showMegaMenu)
                  }}
                >
                  {leftMenuItems.title || ''}
                </MegaMenuLink>
              </div>

              {/* Mega Menu */}
              <Fade in={showMegaMenu} timeout={0}>
                <div
                  onMouseEnter={() => toggleMegaMenu(true)}
                  onMouseLeave={() => toggleMegaMenu(false)}
                  className={clsx({
                    [classes.fadeinWrapper]: !asCondensedMenu,
                  })}
                >
                  <MegaMenu
                    ref={ref}
                    toggleMegaMenu={() => toggleMegaMenu}
                    asCondensedMenu={asCondensedMenu}
                  />
                </div>
              </Fade>
            </Grid>
          )}

          {/* Exposes - ContextMenu */}
          {rightMenuItems && (
            <Grid item className={classes.flex}>
              {rightMenuItems.map((menu, idx) => {
                const menuItems = menu.children
                  ? menu.children.map((item, idx) => (
                      <ContextMenuItem
                        key={idx}
                        href={item.url || ''}
                        external={item.target === 'BLANK'}
                      >
                        <>
                          {item.title}
                          <span className={classes.superFlair}>
                            {item.flair}
                          </span>
                          <div className={classes.descriptionText}>
                            {item.description}
                          </div>
                        </>
                      </ContextMenuItem>
                    ))
                  : null
                return (
                  <ContextMenu
                    className={classes.contextMenuVariantText}
                    key={idx}
                    toggle={menu.title || ''}
                    menu={menuItems}
                    noBorder
                    menuAnchor={'left'}
                    isHover={true}
                  />
                )
              })}
            </Grid>
          )}
        </Grid>
      </Container>
    </div>
  )
}

Bottom.defaultProps = {
  asCondensedMenu: false,
}

export default Bottom
