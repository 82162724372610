import { lazy } from 'react'
import { RouteNamesExternal } from 'constants/routeNamesExternal'
import { RouteNamesInternal } from 'constants/routeNamesInternal'
// import PersonalInformation from './pages/PersonalInformation'
// import Security from './pages/Security'
// import Membership from './pages/Membership'
// import Communication from './pages/Communication'
// import Network from './pages/Network'
// import Topics from './pages/Topics'
// import EnterpriseAdmin from './pages/EnterpriseAdmin'
// import Orders from './pages/Orders'
import { Route } from '../../constants/types'
const PersonalInformation = lazy(() => import('./pages/PersonalInformation'))
const Security = lazy(() => import('./pages/Security'))
const Membership = lazy(() => import('./pages/Membership'))
const Communication = lazy(() => import('./pages/Communication'))
const Network = lazy(() => import('./pages/Network'))
const Topics = lazy(() => import('./pages/Topics'))
const EnterpriseAdmin = lazy(() => import('./pages/EnterpriseAdmin'))
const Orders = lazy(() => import('./pages/Orders'))
const TimeZone = lazy(() => import('./pages/TimeZone'))

export const routes: Route[] = [
  {
    key: 'PROFILE_PERSONALINFORMATION',
    type: 'private',
    title: 'Personal Information',
    path: `/${RouteNamesInternal.PROFILE}/${RouteNamesInternal.PERSONAL_INFORMATION}`,
    component: PersonalInformation,
    exact: true,
  },
  {
    key: 'PROFILE_SECURITY',
    type: 'private',
    title: 'Login and Security',
    path: `/${RouteNamesInternal.PROFILE}/${RouteNamesInternal.SECURITY}`,
    component: Security,
    exact: true,
  },
  {
    key: 'PROFILE_MEMBERSHIPDETAILS',
    type: 'private',
    title: 'Membership',
    path: `/${RouteNamesInternal.PROFILE}/${RouteNamesInternal.MEMBERSHIP_DETAILS}`,
    component: Membership,
    exact: true,
    isHidden: (user: { membership: { isMember: boolean } }) =>
      !user?.membership?.isMember,
  },
  {
    key: 'PROFILE_PREFERENCES',
    type: 'public',
    title: 'Communication Preferences',
    path: `/${RouteNamesInternal.PROFILE}/${RouteNamesInternal.COMMUNICATION}`,
    component: Communication,
    exact: true,
  },
  {
    key: 'PROFILE_ORDERS',
    type: 'private',
    title: 'Orders and Downloads',
    path: `/${RouteNamesInternal.PROFILE}/${RouteNamesInternal.ORDERS}`,
    component: Orders,
    exact: true,
  },
  {
    key: 'PROFILE_NETWORK',
    type: 'private',
    title: 'My Network',
    path: `/${RouteNamesInternal.PROFILE}/${RouteNamesInternal.NETWORK}`,
    component: Network,
    exact: true,
  },
  {
    key: 'PROFILE_TOPICS',
    type: 'private',
    title: 'My Topics',
    path: `/${RouteNamesInternal.PROFILE}/${RouteNamesInternal.TOPICS}`,
    component: Topics,
    exact: true,
  },
  {
    key: 'TIME_ZONE_CHANGE',
    type: 'public',
    title: 'Time Zone Preferences',
    path: `/${RouteNamesInternal.PROFILE}/${RouteNamesInternal.TIME_ZONE}`,
    component: TimeZone,
    exact: true,
  },
  {
    key: 'enterprise_admin',
    type: 'external',
    title: 'Enterprise Account Management',
    path: RouteNamesExternal.ATD_ENTERPRISE_ADMIN,
    component: EnterpriseAdmin,
    exact: true,
    isHidden: (user: { isEnterpriseAdmin: boolean }) => {
      return !user?.isEnterpriseAdmin
    },
  },
]
const routesObj = { routes }
export default routesObj
