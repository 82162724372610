// @libraries
import * as Yup from 'yup'

const firstName = Yup.string()
  .required('First Name is required!')
  .matches(/^(?!test$)/, {
    message: 'That name is not allowed!',
  })

const lastName = Yup.string()
  .required('Last Name is required!')
  .matches(/^(?!test$)/, {
    message: 'That name is not allowed!',
  })
const primaryEmailAddress = Yup.string()
  .email('Please enter your email in a valid format.')
  .required('Email is required!')

const role = Yup.string().notRequired()

const industry = Yup.string().notRequired()

const companyName = Yup.string().notRequired()

const interests = Yup.string().notRequired()

const comments = Yup.string().notRequired()

const countryCode = Yup.string().required('Country is required!')

const phoneNumber = Yup.string().notRequired()

const jobTitle = Yup.string().notRequired()

const companySize = Yup.string().notRequired()

const acceptTerms = Yup.boolean().oneOf([true], 'Must accept before proceeding')

const acceptEmailConsent = Yup.boolean().oneOf([true, false]).notRequired()

const acceptThirdPartyConsent = Yup.boolean().oneOf([true, false]).notRequired()

export const validationSchema = Yup.object().shape({
  firstName,
  lastName,
  primaryEmailAddress,
  companyName,
  phoneNumber,
  industry,
  jobTitle,
  role,
  interests,
  comments,
  countryCode,
  companySize,
  acceptTerms,
  acceptEmailConsent,
  acceptThirdPartyConsent,
})
