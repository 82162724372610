import {
  ArticleSearchItem,
  ConferenceSearchItem,
  CourseSearchItem,
  IContentSearchItem,
  CoursePurchasableSearchItem,
  PublicationSearchItem,
  UserSearchItem,
  VideoSearchItem,
} from 'lib/graphql/graphqlTypes'
import { PropsType as CardProps } from '__COMPONENTS_TO_MIGRATE/CardNew'
import Helper from 'utils/Helper.class'
import Pluralize from 'react-pluralize'
import TextLink from '@bit/atd.web.text-link'
import React, { Dispatch, SetStateAction } from 'react'
import strings from 'lib/constants/strings'

type PropsType = {
  setItemListModal: Dispatch<SetStateAction<string | null>>
}

/**
 *
 * @param setItemListModal
 */
const useContentCardFormatters = ({ setItemListModal }: PropsType) => {
  /**
   * author formatter
   * @param content
   */
  const authorFormatter = (
    content:
      | IContentSearchItem
      | ArticleSearchItem
      | PublicationSearchItem
      | VideoSearchItem
      | CourseSearchItem,
  ) => {
    const byLine: CardProps['content']['byLine'] = {}
    const authors = content?.authors?.map((author) => {
      const auth = author as UserSearchItem

      const fullName = auth?.fullName
      const firstName = auth?.firstName
      const lastName = auth?.lastName

      return {
        author:
          fullName ||
          (firstName && lastName && `${firstName} ${lastName}`) ||
          (firstName && firstName) ||
          (lastName && lastName) ||
          '',
      }
    })
    const pubDate =
      content?.publishDate && Helper.smallDateFormatter(content.publishDate)

    authors && (byLine.authors = authors)
    pubDate && (byLine.pubDate = pubDate)

    return byLine
  }

  /**
   *
   * @param label
   * @param list
   * @param id
   * @param type
   */
  const generateLabelFromList = (
    label: string,
    list: Set<string | number>,
    id: string,
    type?: string,
  ) => {
    return (
      <>
        <>
          {label}
          <span>
            {` ( + `}
            <Pluralize singular={'other'} count={list.size - 1} />)
          </span>
        </>
        <div>
          <TextLink
            onClick={(e) => {
              e && e.preventDefault()
              setItemListModal(id)
            }}
            href="#"
            variant="primary"
          >
            {strings.CARD.VIEW_ALL} {type && ` ${type}s`}
          </TextLink>
        </div>
      </>
    )
  }

  /**
   * detailFormatter
   * @param content
   * @param titleUrl
   */
  const detailFormatter = (
    content: CourseSearchItem | ConferenceSearchItem,
    titleUrl: string,
  ): CardProps['details'] => {
    const detailList: CardProps['details']['detailList'] = []

    type UniqueData = {
      locations: Set<string>
      dates: Set<number>
      deliveryMethods: Set<string>
    }

    if (content.purchasableItems?.length) {
      const {
        dates,
        locations,
        deliveryMethods,
      }: UniqueData = content.purchasableItems.reduce(
        (acc: UniqueData, item: CoursePurchasableSearchItem): UniqueData => {
          const location = item?.location
          const date = item?.beginDate

          let deliveryMethod
          if (item?.deliveryMethods) {
            for (const dm of item?.deliveryMethods) {
              deliveryMethod = acc.deliveryMethods.add(dm)
            }
          }

          return {
            locations:
              typeof location === 'string'
                ? acc.locations.add(location)
                : acc.locations,
            dates: typeof date == 'number' ? acc.dates.add(date) : acc.dates,
            deliveryMethods:
              typeof deliveryMethod === 'string'
                ? deliveryMethod
                : acc.deliveryMethods,
          } as UniqueData
        },
        {
          locations: new Set([]),
          dates: new Set([]),
          deliveryMethods: new Set([]),
        },
      )

      const firstLocation = locations.values().next().value
      const firstDate = dates.values().next().value
      const locationLabel =
        locations.size > 1
          ? generateLabelFromList(
              firstLocation,
              locations,
              content.id,
              'location',
            )
          : firstLocation

      const getDateLabel = () => {
        const date = Helper.globalDateFormatter(firstDate)
        if (dates.size === 1 && firstDate === 0) return 'Anytime'
        return dates.size > 1
          ? generateLabelFromList(date, dates, content.id, 'date')
          : date
      }

      const linkOnDemand = () => {
        return <TextLink href={titleUrl}>On Demand</TextLink>
      }

      if (locationLabel !== undefined) {
        if (locationLabel === 'On Demand') {
          detailList.push({
            icon: 'video-alt',
            label: linkOnDemand(),
          })
        } else {
          detailList.push({
            icon: 'globe',
            label: locationLabel,
          })
        }
        detailList.push({
          icon: 'calendar',
          label: getDateLabel(),
        })
        if (locations.has('On Demand') && locations.size > 1) {
          detailList.push({
            icon: 'video-alt',
            label: linkOnDemand(),
          })
        }
      } else {
        if (deliveryMethods !== undefined) {
          if (deliveryMethods.has('On Demand')) {
            detailList.push({
              icon: 'video-alt',
              label: linkOnDemand(),
            })
            detailList.push({
              icon: 'calendar',
              label: getDateLabel(),
            })
          }
        }
      }
    }
    return { detailList }
  }

  return { authorFormatter, detailFormatter }
}

export default useContentCardFormatters
