import React, {
  useRef,
  useContext,
  SetStateAction,
  Dispatch,
  useEffect,
} from 'react'
import { Controller, useForm } from 'react-hook-form'
import { LoginFormValues } from 'modules/common/types'
// @common
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import config from 'constants/config'
import theme from '@bit/atd.web.theme'
import { makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery'
import strings from 'lib/constants/strings'
import { RouteNamesExternal } from 'constants/routeNamesExternal'
import Helper from 'utils/Helper.class'
// @components
import Grid from '@bit/atd.web.grid'
import InputText from '@bit/atd.web.forms.input-text'
import Button from '@bit/atd.web.button'
import InputPassword from '@bit/atd.web.forms.input-password'
import TextLink from '@bit/atd.web.text-link'
import ContentLoader from '@bit/atd.web.loaders.content-loader'
import { SnackbarTypes } from '@bit/atd.web.snackbar'
import { SnackbarContext } from 'modules/common/context/snackbar'
import { doLogin } from 'modules/auth/helpers'
import Spacer from '@bit/atd.web.spacer'
// @graphql
import { useMutation } from '@apollo/client'
import { LoginMutation, LoginMutationVariables } from 'lib/graphql/graphqlTypes'
import { LOGIN } from 'graphql/mutations/auth.mutation'
import { GET_USER } from 'graphql/queries/user.query'
import { GET_MENU } from 'graphql/queries/menu.query'

const useStyles = makeStyles({
  actions: {
    marginTop: '10px',
  },
  passwordText: {
    textAlign: 'right',
  },
})

type LoginFormProps = {
  onLogin?: () => void
  setIsLoginInProcess?: Dispatch<SetStateAction<boolean>>
}

/**
 *
 * @param onLogin
 * @param setIsLoginInProcess
 * @constructor
 */
const LoginForm = ({ onLogin, setIsLoginInProcess }: LoginFormProps) => {
  const [login] = useMutation<LoginMutation, LoginMutationVariables>(LOGIN, {
    refetchQueries: [{ query: GET_USER }, { query: GET_MENU }],
    awaitRefetchQueries: true,
  })

  const snackbar = useContext(SnackbarContext)

  /**
   *
   */
  const onError = (error: string) => {
    snackbar.setMessage({
      type: SnackbarTypes.ERROR,
      message: error,
      show: true,
    })
  }

  /**********************
   * HOOKS
   **********************/
  const isMobile = useMediaQuery(
    theme.breakpoints.down('sm'),
    config.mediaQuery,
  )

  /******************************************
   * REFS
   ******************************************/
  const emailRef = useRef<HTMLInputElement | null>(null)
  const passwordRef = useRef<HTMLInputElement | null>(null)
  const classes = useStyles()

  const validationSchema = Yup.object().shape({
    username: Yup.string().required('Email is required!'),
    password: Yup.string().required('Password is required!'),
  })

  /**
   *
   */
  const {
    handleSubmit,
    register,
    control,
    formState,
  } = useForm<LoginFormValues>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    resolver: yupResolver(validationSchema),
  })
  const { errors, isSubmitting } = formState

  /**
   *
   */
  useEffect(() => {
    if (emailRef.current) {
      register(emailRef.current)
      emailRef.current.focus()
    }
  }, [])

  /**
   *
   * @param data
   */
  const doLoginWrapper = async (data: LoginFormValues) => {
    await doLogin({
      data,
      login,
      onError,
      onLogin,
      setIsLoginInProcess,
    })
  }

  /**
   *
   */
  const submitWrapper = async () => {
    await handleSubmit(doLoginWrapper)()
  }

  return (
    <ContentLoader isLoading={isSubmitting}>
      <form>
        <Grid>
          <Grid xs={12} sm={12} item>
            <Controller
              control={control}
              defaultValue={''}
              onFocus={() => emailRef?.current?.focus()}
              name="username"
              render={({ onChange, onBlur, value, name }) => (
                <InputText
                  required
                  name={name}
                  label="Email"
                  placeholder={strings.PROFILE.ACCESS.PRIMARY_EMAIL_PLACEHOLDER}
                  type="email"
                  onBlur={onBlur}
                  onChange={onChange}
                  onKeyUp={async (event) => {
                    if (event.key === 'Enter') {
                      await submitWrapper()
                    }
                  }}
                  value={value}
                  inputRef={emailRef}
                  error={!!errors?.username}
                  errorText={errors?.username?.message}
                />
              )}
            />
          </Grid>
          <Spacer size={10} />

          <Grid xs={12} sm={12} item>
            <Controller
              control={control}
              onFocus={() => passwordRef?.current?.focus()}
              name="password"
              defaultValue={''}
              render={({ onChange, onBlur, value, name }) => (
                <InputPassword
                  required
                  name={name}
                  label="Password"
                  placeholder="Enter your password."
                  onBlur={onBlur}
                  onChange={onChange}
                  onKeyUp={async (event) => {
                    if (event.key === 'Enter') {
                      await submitWrapper()
                    }
                  }}
                  value={value}
                  inputRef={passwordRef}
                  error={!!errors?.password}
                  errorText={errors?.password?.message}
                />
              )}
            />
          </Grid>
          <Grid className={classes.passwordText} item xs={12}>
            <TextLink
              className={classes.actions}
              href={RouteNamesExternal.SSO_RECOVER_PASSWORD}
              variant="secondary"
              size="small"
            >
              {strings.MENU.FORGOT_PASSWORD}
            </TextLink>
          </Grid>
          <Grid className={classes.passwordText} xs={12} sm={12} item>
            <TextLink
              className={classes.actions}
              variant={'secondary'}
              size="small"
              href={RouteNamesExternal.SSO_LOGIN_COMPANY}
            >
              {strings.LOGIN.INSTITUTIONAL_SUBSCRIBER_CTA}
            </TextLink>
          </Grid>
          <Grid className={classes.actions} item>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Grid xs={6} sm={6} item>
                <Button
                  alignment="center"
                  fullWidth={isMobile}
                  disabled={isSubmitting}
                  onClick={submitWrapper}
                >
                  {strings.LOGIN.LOGIN}
                </Button>
              </Grid>
              <Grid item>
                <TextLink
                  className={classes.actions}
                  href={Helper.getPathFromRouteKey('ONBOARDING')}
                  variant="standalone"
                >
                  {strings.LOGIN.REGISTER}
                </TextLink>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </ContentLoader>
  )
}

export default LoginForm
