import { AlgoliaHit } from '../../../common/types'
import parse from 'html-react-parser'
import ContentWrapper from '../CardWrapper'
import React from 'react'
import { useStyles } from '../AccordionWrapper/_styles'

type PropsType = {
  contentItems: AlgoliaHit[] | undefined
  subTitle: string
  limit: number
  viewMoreLink?: {
    title: string
    href: string
  }
}
/**
 *
 * @param contentItems
 * @param type
 * @param title
 * @param subTitle
 * @param viewMoreLink
 */
const RenderContentGrid = ({
  contentItems,
  subTitle,
  limit,
  viewMoreLink,
}: PropsType) => {
  const classes = useStyles()

  return contentItems?.length ? (
    <div>
      {subTitle && (
        <div className={classes.accordionSubTitle}>{parse(subTitle)}</div>
      )}

      <ContentWrapper
        content={contentItems}
        limit={limit}
        viewMoreLink={viewMoreLink}
      />
    </div>
  ) : (
    <></>
  )
}

export default RenderContentGrid
