// @react
import React, { ReactElement } from 'react'
// @libraries
import { makeStyles } from '@material-ui/core/styles'
// @components
import Icon from '@bit/atd.web.icons'
import Spacer from '@bit/atd.web.spacer'
import Link from '@bit/atd.web.header-link'
import Grid from '@bit/atd.web.grid'
// @constants
import { RouteNamesExternal } from 'constants/routeNamesExternal'
import strings from 'lib/constants/strings'

/**
 *
 */
const useStyles = makeStyles({
  linkWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    padding: '4px 0',
    marginRight: 16,
    '&:last-child': {
      paddingRight: 0,
      marginRight: 0,
    },
  },
  menu: {
    fontWeight: 500,
  },
})

/**
 *
 * @constructor
 */
const TopLinks = (): ReactElement => {
  /********************************
   * HOOKS
   ********************************/
  const classes = useStyles()

  /********************************
   * RENDER
   ********************************/
  return (
    <Grid container justify="flex-end" alignItems="center">
      <Grid item className={classes.linkWrapper}>
        <Link
          href={RouteNamesExternal.ATD_ENTERPRISE_HOME}
          className={classes.link}
        >
          {strings.NAV.ENTERPRISE_SOLUTIONS}
        </Link>

        <Link href={RouteNamesExternal.ATD_CHAPTERS} className={classes.link}>
          {strings.NAV.CHAPTERS}
        </Link>

        <Link
          href={RouteNamesExternal.ATD_GLOBAL_HOME}
          className={classes.link}
        >
          <Icon glyph="globe" size={16} />
          <Spacer size={4} />
          <span>{strings.NAV.ATD_GLOBAL}</span>
        </Link>

        <Link href={RouteNamesExternal.HELP_HOME_US} className={classes.link}>
          {strings.NAV.ATD_CONTACT}
        </Link>
      </Grid>
    </Grid>
  )
}

export default TopLinks
