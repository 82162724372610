// @react
import React, { useRef } from 'react'
// @libraries
import {
  Control,
  Controller,
  FieldErrors,
  UseFormMethods,
} from 'react-hook-form'
// @components
import Grid from '@bit/atd.web.grid'
import InputText from '@bit/atd.web.forms.input-text'
// @module components
import makeStyles from '@material-ui/core/styles/makeStyles'
import colors from '@bit/atd.web.colors/dist/colors'
import strings from 'lib/constants/strings'
import { LandingPageFormValues } from 'modules/pageBuilder/types/landingPageForm'

const useStyles = makeStyles(() => ({
  clientError: {
    '& > a': {
      color: colors.gray500,
    },
  },
}))

type PropsType = {
  formMethods: UseFormMethods<LandingPageFormValues>
  control: Control<LandingPageFormValues>
  errors: FieldErrors
}

const EmailField = ({ control, errors }: PropsType) => {
  /******************************************
   * REFS
   ******************************************/

  const emailRef = useRef<HTMLInputElement | null>(null)
  /******************************************
   * HOOKS
   ******************************************/
  const classes = useStyles()

  /******************************************
   * RENDER
   ******************************************/
  return (
    <>
      <Controller
        control={control}
        onFocus={() => emailRef?.current?.focus()}
        name="primaryEmailAddress"
        render={({ onChange, onBlur, value }) => (
          <InputText
            name="primaryEmailAddress"
            label="Email Address"
            placeholder={strings.PROFILE.ACCESS.PRIMARY_EMAIL_PLACEHOLDER}
            type="email"
            required
            onBlur={onBlur}
            onChange={onChange}
            value={value}
            inputRef={emailRef}
            error={!!errors?.primaryEmailAddress}
            errorText={errors?.primaryEmailAddress?.message}
          />
        )}
      />
    </>
  )
}

const PhoneNumber = ({ control, errors }: PropsType) => {
  /******************************************
   * REFS
   ******************************************/

  const phoneNumberRef = useRef<HTMLInputElement | null>(null)
  /******************************************
   * HOOKS
   ******************************************/
  const classes = useStyles()

  /******************************************
   * RENDER
   ******************************************/
  return (
    <>
      <Controller
        control={control}
        onFocus={() => phoneNumberRef?.current?.focus()}
        name="phoneNumber"
        render={({ onChange, onBlur, value }) => (
          <InputText
            name="phoneNumber"
            label="Phone Number"
            placeholder="Enter your phone number."
            onBlur={onBlur}
            onChange={onChange}
            value={value}
            inputRef={phoneNumberRef}
            error={!!errors?.phoneNumber}
            errorText={errors?.phoneNumber?.message}
          />
        )}
      />
    </>
  )
}

const LandingPageAccountFields = ({
  formMethods,
  control,
  errors,
}: PropsType) => {
  /******************************************
   * REFS
   ******************************************/

  const firstNameRef = useRef<HTMLInputElement | null>(null)
  const lastNameRef = useRef<HTMLInputElement | null>(null)

  return (
    <>
      <Grid container spacing={4}>
        {/* First and Last Name */}
        <Grid xs={12} sm={6} item>
          <Controller
            control={control}
            name="firstName"
            onFocus={() => firstNameRef?.current?.focus()}
            render={({ onChange, onBlur, value }) => (
              <InputText
                name="firstName"
                label="First Name"
                placeholder={strings.PROFILE.BIO.ENTER_FIRST_NAME}
                required
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                inputRef={firstNameRef}
                error={!!errors?.firstName}
                errorText={errors?.firstName?.message}
              />
            )}
          />
        </Grid>

        <Grid xs={12} sm={6} item>
          <Controller
            control={control}
            name="lastName"
            onFoucs={() => lastNameRef?.current?.focus()}
            render={({ onChange, onBlur, value }) => (
              <InputText
                name="lastName"
                label="Last Name"
                placeholder={strings.PROFILE.BIO.ENTER_LAST_NAME}
                required
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                inputRef={lastNameRef}
                error={!!errors?.lastName}
                errorText={errors?.lastName?.message}
              />
            )}
          />
        </Grid>

        {/* Email Address */}
        <Grid xs={12} sm={6} item>
          <EmailField
            formMethods={formMethods}
            control={control}
            errors={errors}
          />
        </Grid>

        {/* Phone Number */}
        <Grid xs={12} sm={6} item>
          <PhoneNumber
            formMethods={formMethods}
            control={control}
            errors={errors}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default LandingPageAccountFields
