// @libraries
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  landingPageRoot: {
    '& ~ footer': {
      marginTop: 0,
    },
  },
}))

export default useStyles
