import { lazy } from 'react'
import { RouteNamesInternal } from 'constants/routeNamesInternal'
// import Dashboard from './pages/Dashboard'

const Dashboard = lazy(() => import('./pages/Dashboard'))

const DashboardRoutes = {
  routes: [
    {
      key: 'MYATD_DASHBOARD',
      type: 'private',
      title: 'MyATD - Dashboard',
      path: `/${RouteNamesInternal.MYATD}/${RouteNamesInternal.DASHBOARD}`,
      component: Dashboard,
      exact: true,
    },
  ],
}

export default DashboardRoutes
