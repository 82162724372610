import faker from 'faker'
import modules from 'modules'
import colors from '@bit/atd.web.colors'
import { format } from 'date-fns'
import { STATIC_ROUTES } from 'constants/routeNamesStatic'
import { contentLabels } from 'modules/benefitsCenter/components/FeaturedBenefit/contentLabels'
import { TaxonomySearchItem } from 'lib/graphql/graphqlTypes'
import qs from 'qs'
import { History } from 'history'
import Cookies from 'universal-cookie'
import moment from 'moment-timezone'
import { contentTypes } from 'constants/contentTypesMap'

const cookies = new Cookies()

export enum ActivityAction {
  COMMENT = 'COMMENT',
  CREATED = 'CREATED',
  SHARED = 'SHARED',
  FOLLOW = 'FOLLOW',
  GROUP = 'GROUP',
}
type Elem = ElemArray
type ElemArray = (Elem | string | number)[]
type ContentTypesList = {
  lvl0: string
  lvl1: string
  lvl2: string
}

/**
 *
 */
class Helper {
  /**
   *
   * @param hour
   */
  public static militaryToCivilianTime(hour: number | string) {
    switch (Number(hour)) {
      case 0:
        return '12 AM'
      case 1:
        return '1 AM'
      case 2:
        return '2 AM'
      case 3:
        return '3 AM'
      case 4:
        return '4 AM'
      case 5:
        return '5 AM'
      case 6:
        return '6 AM'
      case 7:
        return '7 AM'
      case 8:
        return '8 AM'
      case 9:
        return '9 AM'
      case 10:
        return '10 AM'
      case 11:
        return '11 AM'
      case 12:
        return '12 PM'
      case 13:
        return '1 PM'
      case 14:
        return '2 PM'
      case 15:
        return '3 PM'
      case 16:
        return '4 PM'
      case 17:
        return '5 PM'
      case 18:
        return '6 PM'
      case 19:
        return '7 PM'
      case 20:
        return '8 PM'
      case 21:
        return '9 PM'
      case 22:
        return '10 PM'
      case 23:
        return '11 PM'
      default:
        return '12 AM'
    }
  }

  private static dateCleaner(value?: number | string | Date): number {
    if (!value) {
      return 0
    }

    if (value instanceof Date) {
      return value.getTime() / 1000
    }

    if (typeof value === 'number') {
      const trunc = Math.trunc(value)
      const numDigits = Math.max(Math.floor(Math.log10(Math.abs(value))), 0) + 1
      if (numDigits >= 13) {
        return value / 1000
      }
      return trunc
    }

    return Date.parse(value) / 1000
  }

  /**
   *
   * @param url
   */
  public static openInNewTab(url: string): void {
    const newWindow = window.open(url, '_blank')
    if (newWindow) newWindow.opener = null
  }

  /**
   *
   * @param date
   */
  public static globalDateFormatter(date: number): string {
    const newDate = Helper.dateCleaner(date)
    return format(newDate * 1000, 'MMMM dd yyyy')
  }

  /**
   *
   * @param date
   */
  public static globalStartEndDateFormatter(
    startDate: number,
    endDate: number,
  ): string {
    const prefTz = cookies.get('PreferredTimeZone')
    const dateStart = Helper.dateCleaner(startDate)
    const dateEnd = Helper.dateCleaner(endDate)

    return `${moment(dateStart * 1000)
      .tz(prefTz)
      .format('MMMM D yyyy')} - ${moment(dateEnd * 1000)
      .tz(prefTz)
      .format('MMMM D yyyy')}`
  }

  /**
   *
   * @param date
   */
  public static globalHourFormatter(
    dateStart: number,
    dateEnd: number | undefined,
  ): string {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone
    const prefTz = cookies.get('PreferredTimeZone')

    const startDate = Helper.dateCleaner(dateStart)
    const endDate = Helper.dateCleaner(dateEnd)
    const startTimeToCurrentZone = new Date(
      startDate * 1000,
    ).toLocaleTimeString('en-US', {
      timeZone: prefTz ? prefTz : tz,
      hour: '2-digit',
      minute: '2-digit',
    })
    const endTimeToCurrentZone = new Date(endDate * 1000).toLocaleTimeString(
      'en-US',
      { timeZone: prefTz ? prefTz : tz, hour: '2-digit', minute: '2-digit' },
    )

    return `${this.removeFirstZero(
      startTimeToCurrentZone,
    )} - ${this.removeFirstZero(endTimeToCurrentZone)} `
  }

  public static removeFirstZero(str: string): string {
    const result = str.substring(0, 1) === '0' ? str.substring(1) : str
    return result
  }

  public static getLanguage(): string {
    const lang = navigator.language
    return lang.substring(0, 2)
  }

  public static getTimezoneName() {
    const prefTz = cookies.get('PreferredTimeZone')

    const today = new Date()
    const short = today.toLocaleDateString(undefined, {
      timeZone: prefTz,
    })
    const full = today.toLocaleDateString(undefined, {
      timeZone: prefTz,
      timeZoneName: 'long',
    })

    // Trying to remove date from the string in a locale-agnostic way
    const shortIndex = full.indexOf(short)
    if (shortIndex >= 0) {
      const trimmed =
        full.substring(0, shortIndex) +
        full.substring(shortIndex + short.length)

      // by this time `trimmed` should be the timezone's name with some punctuation -
      // trim it from both sides

      const final = trimmed.replace(/^[\s,.\-:;]+|[\s,.\-:;]+$/g, '')
      if (final.includes('Daylight')) {
        return final.replace('Daylight', '')
      } else if (final.includes('Standard')) {
        return final.replace('Standard', '')
      } else {
        return final
      }
    } else {
      // in some magic case when short representation of date is not present in the long one, just return the long one as a fallback, since it should contain the timezone's name
      return full
    }
  }

  /**
   *
   * @param dateFrom
   * @param dateTo
   */
  public static rangeDateFormatter(dateFrom?: number, dateTo?: number): string {
    const newDateFrom = Helper.dateCleaner(dateFrom)
    const newDateTo = Helper.dateCleaner(dateTo)
    return `${format(newDateFrom * 1000, 'MMM dd, yyyy')} - ${format(
      newDateTo * 1000,
      'MMM dd, yyyy',
    )}`
  }

  /**
   *
   * @param time
   * @param timeStampMultiplier
   */
  public static timestampFormatter(
    time: number | string,
    timeStampMultiplier = 1,
  ): string {
    let timeSeconds

    if (typeof time === 'string') {
      const split = time.split(':')
      const splitReverse = split.reverse()

      timeSeconds = splitReverse.reduce((acc, curr, index) => {
        const multiplier = Math.pow(60, index)
        return acc + parseInt(curr) * multiplier
      }, 0)
    } else {
      timeSeconds = Number((time * timeStampMultiplier) / 1000)
    }

    const h = Math.floor(timeSeconds / 3600)
    const m = Math.floor((timeSeconds % 3600) / 60)
    const s = Math.floor((timeSeconds % 3600) % 60)

    const hDisplay = h > 0 ? h.toString() + ':' : '00:'
    const mDisplay = m > 0 ? m.toString() + ':' : '00:'
    const sDisplay = s > 0 ? s : '00'

    return `${hDisplay}${mDisplay}${sDisplay}`
  }

  /**
   *
   * @param n
   * @param dp
   */
  public static formatThousandsWithRounding(n: number, dp: number) {
    const w = n.toFixed(dp) as string
    const k = parseInt(w)
    const b = n < 0 ? 1 : 0
    const u = Math.abs(parseFloat(w) - k)
    const d = ('' + u.toFixed(dp)).substr(2, dp)
    const s = `${k}`

    let i = s.length,
      r = ''

    while ((i -= 3) > b) {
      r = ',' + s.substr(i, 3) + r
    }

    return s.substr(0, i + 3) + r + (d ? '.' + d : '')
  }

  /**
   *
   * @param n
   * @param dp
   */
  public static formatThousandsNoRounding = function (n: number, dp: number) {
    const e = '',
      s = e + n,
      l = s.length,
      b = n < 0 ? 1 : 0,
      i = s.lastIndexOf('.')

    let j = i === -1 ? l : i,
      r = e

    const d = s.substr(j + 1, dp)

    while ((j -= 3) > b) {
      r = ',' + s.substr(j, 3) + r
    }

    return (
      s.substr(0, j + 3) +
      r +
      (dp
        ? '.' + d + (d.length < dp ? '00000'.substr(0, dp - d.length) : e)
        : e)
    )
  }

  /**
   *
   * @param date
   */
  public static smallDateFormatter(date: number): string {
    const newDate = Helper.dateCleaner(date)
    return format(newDate * 1000, 'MMMM dd, yyyy')
  }

  /**
   *
   * @param date
   */
  public static miniDateFormatter(date: number): string {
    const newDate = Helper.dateCleaner(date)
    return format(newDate * 1000, 'MMMM dd')
  }

  /**
   *
   * @param date
   */
  public static condensedDateFormatter(date: number): string {
    const newDate = Helper.dateCleaner(date)
    return format(newDate * 1000, 'MM/dd/yyyy')
  }

  /**
   *
   * @param date
   */
  public static getMonthAndYear(date: number): string {
    return format(new Date(date * 1000), 'MMMM yyyy')
  }

  /**
   *
   * @param date
   */
  public static hourDateFormatter(date: number): string {
    const newDate = Helper.dateCleaner(date)
    return format(newDate * 1000, 'h:mm a')
  }

  /**
   *
   * @param unix
   */
  public static calculateOffset(unix: number): string {
    return moment.unix(unix).format('H')
  }

  /**
   *
   * @param seconds
   */
  public static calculateOffsetHourFromSeconds(seconds: number): string {
    const finaloffset = seconds + this.getOffsetSeconds()
    return Math.ceil(finaloffset / 60 / 60) < 0
      ? '0'
      : Math.ceil(finaloffset / 60 / 60) > 23
      ? '23'
      : Math.ceil(finaloffset / 60 / 60).toString()
  }

  /**
   *
   *
   */
  public static getOffsetSeconds(): number {
    const prefTz = cookies.get('PreferredTimeZone')
    if (prefTz !== null) {
      return moment().tz(prefTz).utcOffset() * 60
    } else {
      return moment().utcOffset() * 60
    }
  }

  /**
   *
   * @param date
   */
  public static getYearFromIso(date: number): string {
    return format(date * 1000, 'yyyy')
  }

  /**
   *
   * @param bytes
   */
  public static bytesToMegaBytes(bytes: number): string {
    return (bytes / (1024 * 1024)).toFixed(2)
  }

  /**
   *
   * @param enumVar
   */
  public static enumToArray(enumVar: string[]): string[] {
    return Object.values(enumVar)
  }

  /**
   *
   * @param enumVar
   */
  public static getRandomEnum(enumVar: any): any {
    const arr = Helper.enumToArray(enumVar)
    return arr[faker.random.number({ min: 0, max: arr.length - 1 })]
  }

  /**
   *
   * @param typesArray
   */
  public static getRandomArrayVal(typesArray: ElemArray): any {
    return typesArray[Math.floor(Math.random() * typesArray.length)]
  }

  /**
   *
   * @param key
   */
  public static getPathFromRouteKey(key: string): string {
    const is_header_mode = parseInt(process.env.REACT_APP_HEADER_MODE as string)
    const res = is_header_mode
      ? STATIC_ROUTES.find((route: any) => route.key === key)
      : modules.routes.find((route) => route.key === key)

    return res?.hasOwnProperty('key') ? res.path : '/'
  }

  /**
   *
   * @param str
   */
  public static getAcronymFromSentence(str?: string): string {
    if (!str) {
      return ''
    }
    return str
      .split(/\s/)
      .reduce((response, word) => (response += word.slice(0, 1)), '')
      .toUpperCase()
  }

  /**
   * @todo this should go in the theme layer
   * @param isBrand
   */
  public static randomColorChooser(isBrand = false): string {
    if (isBrand) {
      return colors['profileAvatarYellow']
    }

    const shortlist: (keyof typeof colors)[] = [
      'profileAvatarYellow',
      'profileAvatarAmberYellow',
      'profileAvatarLime',
      'profileAvatarGreen',
      'profileAvatarBlue',
      'profileAvatarIndigio',
      'profileAvatarViolet',
      'profileAvatarDeepPurple',
      'profileAvatarPurple',
      'profileAvatarRose',
    ]
    const key = Math.ceil(Math.random() * shortlist.length)
    return colors[shortlist[key]]
  }

  /**
   *
   * @param word
   */
  public static articleChooser(word: string | null | undefined): string {
    if (!word) return ''

    const vowels = 'aeiouAEIOU'

    const first = word[0]

    if (vowels.indexOf(first) >= 0) return 'an'
    else return 'a'
  }

  /**
   *
   * @param key
   */
  public static capitalize = (key: string) =>
    key.length === 0 ? '' : `${key[0].toUpperCase()}${key.slice(1)}`

  /**
   *
   * @param date
   */
  public static getStatus = (date: number | string | Date): string => {
    const newDate = Helper.dateCleaner(date)
    return new Date(newDate * 1000) > new Date() ? 'Upcoming' : 'Attended'
  }

  /**
   *
   * @param data
   * @param key
   */
  public static constantsLabelFinder(
    data: { key: string; value: string }[],
    key: string,
  ): string {
    if (!key) return ''

    if (data.length) {
      const item = data.find((item) => item.key === key)
      if (item?.value) {
        return item.value
      }
    }

    return key
  }

  /**
   *
   * @param key
   */
  public static actionTextHelper = (key: ActivityAction): string => {
    const actionsStrings = {
      [ActivityAction.CREATED]: 'Posted ',
      [ActivityAction.SHARED]: 'Shared ',
      [ActivityAction.COMMENT]: 'Commented on your ',
      [ActivityAction.FOLLOW]: 'Is now following ',
      [ActivityAction.GROUP]: 'I group here',
    }

    if (actionsStrings.hasOwnProperty(key)) {
      return actionsStrings[key]
    }
    return 'Has updated'
  }

  /**
   *
   * @param key
   * @param value
   */
  public static labelLookup(key: string, value: string): string {
    if (!key) return ''

    const labels = {
      memberType: {
        MEMBER: 'Standard Member',
        PROFESSIONAL_PLUS: 'Professional Plus',
      },
    }
    if (!labels.hasOwnProperty(key)) {
      return key
    }

    return this.resolve(`${key}.${value}`, labels)
  }

  /**
   *
   * @param path
   * @param obj
   */
  private static resolve(path: string, obj: {}): string {
    return path.split('.').reduce(function (prev: any, curr: any) {
      return prev ? prev[curr] : ''
    }, obj)
  }

  /**
   *
   * @param schemas
   */
  public static merge(...schemas: any): any {
    const [first, ...rest] = schemas

    return rest.reduce(
      (mergedSchemas: any, schema: any) => mergedSchemas.concat(schema),
      first,
    )
  }

  /**
   *
   * @param path
   * @param history
   */
  public static pushPath(path: string, history: History): void {
    path.startsWith('/') ? history.push(path) : (window.location.href = path)
  }

  /**
   *
   * @param contentTypes
   */
  public static contentTypeFormatter = (
    contentTypes?: TaxonomySearchItem | null,
  ): string => {
    if (!contentTypes) {
      return ''
    }
    const items: (keyof ContentTypesList)[] = ['lvl2', 'lvl1', 'lvl0']

    const contentType = items.reduce(
      (acc: string, item: keyof ContentTypesList) => {
        if (acc) return acc

        const types = contentTypes[item]?.split('>')
        return types ? types[types.length - 1].replace(' ', '') : ''
      },
      '',
    )

    const contentLabel = contentLabels.find(({ key }) => key === contentType)
    return contentLabel?.label || contentType
  }

  /******************************************************
   *
   *
   *******************************************************/

  /**
   *
   * @param index
   * @param keyword
   * @param refinementList
   */
  public static constructSearchURL({
    index,
    keyword,
    refinementList,
  }: {
    index?: string
    keyword?: string
    refinementList?: { refinement: string; value: string }[]
  }): string {
    const keywordSearch = keyword ? `query=${encodeURI(keyword)}&` : ''

    const refinements: { [key: string]: number } = {}

    const query =
      refinementList
        ?.map((item) => {
          refinements[item.refinement] = refinements[item.refinement] + 1 || 0
          const refinementIndex = refinements[item.refinement] || 0

          return encodeURI(
            `refinementList[${item.refinement}][${refinementIndex}]=${item.value}`,
          )
        })
        .join('&') || ''

    return !index || index === 'master'
      ? `/search?${keywordSearch}${query}`
      : `/search/${index}?${keywordSearch}${query}`
  }

  /**
   *
   * @param taxonomy
   */
  public static getSearchLabel = (taxonomy: string): string => {
    const found = contentTypes.reduce((acc, item) => {
      if (acc !== '') {
        return acc
      }
      if (acc === '' && item.label === taxonomy) {
        return item.pluralLabel
      }
      return ''
    }, '')

    return found || taxonomy
  }

  /**
   *
   * @param taxonomy
   * @param orderBy
   * @param order
   */
  public static getSearchIndex = (
    taxonomy?: string | null,
    orderBy?: string | null,
    order?: string | null,
  ): string => {
    if (!taxonomy) {
      return 'master'
    }

    const found = contentTypes.reduce((acc, item) => {
      if (acc !== '') {
        return acc
      }
      if (acc === '' && item.label === taxonomy) {
        const foundIndex = item.index
        const appendStr =
          orderBy === 'DATE'
            ? `_orderby_date_${order ? order.toLowerCase() : 'asc'}`
            : ''

        return foundIndex + appendStr
      }
      return ''
    }, '')

    return found || taxonomy
  }

  /**
   *
   * @param index
   * @param filter
   */
  public static createSearchLink(index: string, filter: string): string {
    const createURL = (state: Record<string, unknown>) =>
      qs.stringify(state, { addQueryPrefix: true, format: 'RFC1738' })

    const searchState = {
      hierarchicalMenu: {
        'contentTypes.lvl0': filter,
      },
    }
    return `${process.env.PUBLIC_URL}/search/${index}${createURL(
      searchState,
    )}&toggle%5BmemberBenefit%5D=true&page=1`
  }

  /**
   *
   * @param state
   */
  public static createURL = (state: Record<string, unknown>) =>
    qs.stringify(state, { addQueryPrefix: true, format: 'RFC1738' })

  /**
   *
   * @param url
   */
  public static composeParametrizedUrl = ({ url }: { url: string }): string => {
    return url
  }
}

export default Helper
