// @types
import type { ReactElement } from 'react'
import type { PropsType, StepIconPropTypes } from './_types'
// @libraries
import clsx from 'clsx'
import { useTheme } from '@material-ui/core/styles'
import MuiStepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
// @components
import Container from '@bit/atd.web.container'
import Button from '@bit/atd.web.button'
// @styles
import { useRootStyles, useIconStyles, ConnectorStyle } from './_styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import colors from '@bit/atd.web.colors/dist/colors'

// move these to @colors
//  '#cc3d00' => orange
//  '#992e00' => dark orange
//  '#661f00' -> burnt orange
//  '#330f00' => brown

const newColors = {
  ...colors,
  orange: '#cc3d00',
  darkOrange: '#992e00',
  burntOrange: '#661f00',
  brown: '#330f00',
}

const iconContainerColors = [
  newColors.brandOrange,
  newColors.brandOrange,
  newColors.orange,
  newColors.darkOrange,
  newColors.burntOrange,
  newColors.brown,
]

/**
 *
 * @param props
 * @constructor
 */
const StepperIcon = (props: StepIconPropTypes): ReactElement => {
  const classes = useIconStyles()

  return (
    <div
      className={classes.root}
      style={{ backgroundColor: iconContainerColors[props?.icon as number] }}
    >
      <span>{props.icon}</span>
    </div>
  )
}

/**
 *
 * @param className
 * @param steps
 * @param button
 * @constructor
 */
const Stepper = ({ className, steps, button }: PropsType): ReactElement => {
  /******************************************
   * HOOKS
   ******************************************/

  const theme = useTheme()
  const classes = useRootStyles()
  const isMobileOnly = useMediaQuery(theme.breakpoints.only('xs'))

  /******************************************
   * RENDER
   ******************************************/

  return (
    <section className={clsx(classes.root, className && className)}>
      <Container>
        <>
          <MuiStepper
            className={clsx(classes.stepper, {
              [classes.isMoreThanThree]: steps.length > 3,
            })}
            alternativeLabel={!isMobileOnly}
            connector={!isMobileOnly ? <ConnectorStyle /> : undefined}
            orientation={isMobileOnly ? 'vertical' : 'horizontal'}
            activeStep={-1}
          >
            {steps.map((label, labelIndex) => {
              return (
                <Step className={classes.step} key={`stepKey__${labelIndex}`}>
                  <StepLabel
                    className={classes.stepLabel}
                    StepIconComponent={StepperIcon}
                  >
                    {label.text}
                  </StepLabel>
                </Step>
              )
            })}
          </MuiStepper>

          {button && (
            <div className={classes.button}>
              <Button href={button.href || ''}>{button.label}</Button>
            </div>
          )}
        </>
      </Container>
    </section>
  )
}

Stepper.defaultProps = {}

export default Stepper
