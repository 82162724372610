import { makeStyles, Theme } from '@material-ui/core/styles'
import colors from '@bit/atd.web.colors'

export const useStyles = makeStyles((theme: Theme) => ({
  boxRoot: {
    backgroundColor: 'transparent !important',
    border: 'none',
    boxShadow: 'none',
  },
  boxBody: {
    paddingBottom: '0 !important',
  },
  tabsHeader: {
    borderBottom: `1px solid ${colors.gray400}`,
    '& .MuiTabs-indicator': {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent',
      '& > span': {
        borderTopLeftRadius: '3px',
        borderTopRightRadius: '3px',
        backgroundColor: colors.ctaPrimary,
        width: 'calc(100%)',
      },
    },
    '& button': {
      paddingLeft: '0px',
      paddingRight: '0px',
      marginRight: '32px',
    },
  },
  tabsSubTitle: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#424140',
    width: '100%',
    marginBottom: '32px',
    '& > *': {
      margin: 0,
    },
    '& > * ~ *': {
      marginTop: '.5rem',
    },
    '& p a': {
      color: colors.ctaPrimary,
      textDecoration: 'underline',
      textUnderlineOffset: '4px',
      '&:hover': {
        color: '#EA6E4D',
      },
      '&:visited': {
        color: '#CB2B00',
      },
    },
  },
  label: {
    fontSize: '18px',
    fontWeight: 600,
  },
  cardsContainer: {},
  cardsContainerLast: {
    marginBottom: 0,
  },
}))
