// @libraries
import { makeStyles } from '@material-ui/core/styles'
const useStyles = makeStyles((theme) => ({
  root: {
    '& ~ $root': {
      paddingTop: 30,
      [theme.breakpoints.up('sm')]: {
        paddingTop: 60,
      },
    },
  },
  section: {
    // paddingTop: 30,
    // paddingBottom: 30,
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      alignItems: 'center',
      display: 'flex',
      minHeight: '260px',
      flexDirection: 'row-reverse',
      // paddingTop: 60,
      // paddingBottom: 60,
    },
  },
  content: {
    paddingBottom: 30,
    [theme.breakpoints.up('sm')]: {
      paddingBottom: 0,
      paddingLeft: 30,
      width: '50%',
    },
  },
  text: {
    '& + a': {
      marginTop: '16px',
    },
  },
  eyebrow: {
    color: '#424140',
    fontSize: '11px',
    lineHeight: '16px',
    fontWeight: 700,
    textTransform: 'uppercase',
    marginBottom: '4px',
  },
  headline: {
    color: '#2E2D31',
    fontSize: '32px',
    lineHeight: '36px',
    fontWeight: 700,
  },
  image: {
    fontSize: 0,
    [theme.breakpoints.up('sm')]: {
      width: '50%',
    },

    '& a': {
      display: 'block',
      position: 'relative',
    },

    '& picture': {
      clip: 'rect(0, auto, auto, 0)',
      height: '100%',
      overflow: 'hidden',
      pointerEvents: 'none',
    },
    '& img': {
      objectFit: 'cover',
      objectPosition: 'center',
    },
    '& picture,& img': {
      width: '100%',
    },
  },

  // Modifiers

  isReverse: {
    '& $section': {
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
      },
    },
    '& $content': {
      [theme.breakpoints.up('sm')]: {
        paddingLeft: 0,
        paddingRight: 30,
      },
    },
  },
  isOnlyImage: {
    '& $section': {
      justifyContent: 'center',
    },
  },
}))

export default useStyles
