import { ReactElement, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
type PropsType = {
  children: ReactElement
}

const ScrollToTop = (props: PropsType) => {
  const location = useLocation()
  const { children } = props
  useEffect(() => {
    window.scroll({ top: 0, behavior: 'smooth' })
    //window.scrollTo(0, 0)
  }, [location])

  return children
}

export default ScrollToTop
