// @react
import React, { useEffect, useRef } from 'react'
// @typings
import {
  GetConstantsQuery,
  GetConstantsQueryVariables,
  GetUserQuery,
} from 'lib/graphql/graphqlTypes'
// @graphQL
import { useQuery } from '@apollo/client'
import { GET_CONSTANTS } from 'graphql/queries/constants.query'
// @libraries
import {
  Control,
  Controller,
  FieldErrors,
  UseFormMethods,
} from 'react-hook-form'
// @components
import Grid from '@bit/atd.web.grid'
import Select from '@bit/atd.web.select'
import strings from 'lib/constants/strings'
import { LandingPageFormValues } from 'modules/pageBuilder/types/landingPageForm'
import InputText from '@bit/atd.web.forms.input-text'

type PropsType = {
  control: Control<LandingPageFormValues>
  formMethods: UseFormMethods<LandingPageFormValues>
  errors: FieldErrors
  userData?: GetUserQuery
}

const LandingPageProfessionalFields = ({ control, errors }: PropsType) => {
  /******************************************
   * REFS
   ******************************************/

  const roleRef = useRef<HTMLInputElement | null>(null)
  const industryRef = useRef<HTMLInputElement | null>(null)
  const jobTitleRef = useRef<HTMLInputElement | null>(null)
  const companyRef = useRef<HTMLInputElement | null>(null)

  /******************************************
   * GQL HOOKS
   ******************************************/

  const { error: isConstantsError, data: constantsData } = useQuery<
    GetConstantsQuery,
    GetConstantsQueryVariables
  >(GET_CONSTANTS)

  const createDataLoadedField = (
    constantsData: GetConstantsQuery,
    type: 'role' | 'industry',
  ) => {
    const { industryCodes, jobRoleCodes } = constantsData.constants
    const roleOptions = jobRoleCodes.map((item: any) => {
      return {
        value: item.key,
        label: item.value,
      }
    })
    const industryOptions = industryCodes.map((item: any) => {
      return {
        value: item.key,
        label: item.value,
      }
    })

    /******************************************
     * RENDER
     ******************************************/

    return (
      <>
        {type === 'role' && (
          <Controller
            control={control}
            onFocus={() => roleRef?.current?.focus()}
            name="role"
            render={({ onChange, onBlur, value }) => (
              <Select
                native
                name="role"
                label="Role"
                placeholder={strings.PROFILE.ACCESS.ROLE}
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                inputRef={roleRef}
                options={roleOptions}
                error={!!errors?.role}
                errorText={errors?.role?.message}
              />
            )}
          />
        )}
        {type === 'industry' && (
          <Controller
            control={control}
            name="industry"
            onFocus={() => industryRef?.current?.focus()}
            render={({ onChange, onBlur, value }) => (
              <Select
                native
                name="industry"
                label="Industry"
                placeholder={strings.PROFILE.ACCESS.INDUSTRY}
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                inputRef={industryRef}
                options={industryOptions}
                error={!!errors?.industry}
                errorText={errors?.industry?.message}
              />
            )}
          />
        )}
      </>
    )
  }

  return (
    <>
      <Grid container spacing={4}>
        {/* Role and Industry */}
        <Grid xs={12} sm={6} item>
          {(isConstantsError || !constantsData) && <p>Error</p>}
          {constantsData && createDataLoadedField(constantsData, 'industry')}
        </Grid>
        <Grid xs={12} sm={6} item>
          {(isConstantsError || !constantsData) && <p>Error</p>}
          {constantsData && createDataLoadedField(constantsData, 'role')}
        </Grid>

        <Grid xs={12} sm={6} item>
          <Controller
            control={control}
            onFocus={() => jobTitleRef?.current?.focus()}
            name="jobTitle"
            render={({ onChange, onBlur, value }) => (
              <InputText
                name="jobTitle"
                label="Job Title"
                placeholder="Enter your job title."
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                inputRef={jobTitleRef}
                error={!!errors?.jobTitle}
                errorText={errors?.jobTitle?.message}
              />
            )}
          />
        </Grid>

        <Grid xs={12} sm={6} item>
          <Controller
            control={control}
            onFocus={() => companyRef?.current?.focus()}
            name="companyName"
            render={({ onChange, onBlur, value }) => (
              <InputText
                name="companyName"
                label="Company Name"
                placeholder="Enter your company name."
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                inputRef={companyRef}
                error={!!errors?.companyName}
                errorText={errors?.companyName?.message}
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default LandingPageProfessionalFields
