import schemaSDL from 'lib/graphql/schema.graphql'
import possibleTypes from '../possibleTypes.json'
import { makeExecutableSchema } from '@graphql-tools/schema'
import { addMocksToSchema } from '@graphql-tools/mock'
import Cookies from 'universal-cookie'
import { createUploadLink } from 'apollo-upload-client'

// @apollo
import {
  ApolloClient,
  InMemoryCache,
  DefaultOptions,
  ApolloLink,
  disableFragmentWarnings,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { SchemaLink } from '@apollo/client/link/schema'
import { setContext } from '@apollo/client/link/context'
import { Address, PublicUsersPayload } from '../../lib/graphql/graphqlTypes'
import { CookieType } from '../../modules/common/types'

// @todo this doesn't seem to be working
disableFragmentWarnings()
/**
 * @type {{mutate: {errorPolicy: "all"}, query: {errorPolicy: "all"}, watchQuery: {fetchPolicy: "cache-and-network", errorPolicy: "all"}}}
 */
const defaultOptions: DefaultOptions = {
  watchQuery: {
    errorPolicy: 'all',
  },
  query: {
    fetchPolicy: 'cache-first',
    errorPolicy: 'all',
  },
  mutate: {
    errorPolicy: 'all',
  },
}

/**
 * this example is on the data: [OBJECT] type,
 * we can merge the result together, but no args are present
 * therefore this is the behaviour for all results of this type
 *
 *
 *     PublicUsersPayload: {
 *        fields: {
 *          data: {
 *            merge(existing = [], incoming: any[], args) {
 *              return [...existing, ...incoming]
 *              },
 *           },
 *        },
 *     },
 *
 *
 */
const cache = new InMemoryCache({
  ...possibleTypes,
  typePolicies: {
    PublicUsersPayload: {
      fields: {
        data: {
          merge(
            existing: PublicUsersPayload['data'] = [],
            incoming: PublicUsersPayload['data'],
          ) {
            return [...existing, ...incoming]
          },
        },
      },
    },
    Address: {
      fields: {
        formattedAddress: {
          read(existing: Address) {
            const address1 = existing.address1 || ''
            const address2 = existing.address2 || ''
            const countryCode = existing.countryCode || ''
            const postalCode = existing.postalCode || ''
            const state = existing.state || ''
            const city = existing.city || ''

            return `${address1},${address2},${city},${state},${countryCode},${postalCode}`
          },
        },
      },
    },
  },
})

/**
 * if we switch to introspection, may need buildClientSchema
 */
const logger = { log: (e: Error) => console.log(e) }

/**
 *
 */
const schema = makeExecutableSchema({
  typeDefs: schemaSDL,
  logger,
  resolverValidationOptions: {
    requireResolversForResolveType: 'warn',
  },
})

/**
 *
 */
const schemaWithMocks = addMocksToSchema({
  schema,
  // mocks,
  preserveResolvers: false,
})

/**
 * TEST USERS
 * 11125725 : joel
 * 00043488 : has authored content
 * 11649449
 * 10541490: for Learning / courses
 * 11723398: should have most info, official test user, use for mutations
 *
 *  // @todo having problems with secure cookies sharing, but it works fine on QA
 */
const cookies = new Cookies()
// const customerNumber = localStorage.getItem('CustomerNumber') || '00043488' // '11125725'
const customerNumber = cookies.get('CustomerNumber') // '11125725'
// you can override the customer number set in cookie for testing via ENV for via and additional cookie
// we don't have easy access to 'CustomerNumber' as it's a secure cookie
// the order is
// 1. ENV
// 2. cookie = spoofCustomerNumber
// 3. secure cookie (on QA only) CustomerNumber
const spoofCustomerNumber =
  process.env.REACT_APP_SPOOF_CUSTOMER_NUMBER ||
  cookies.get('spoofCustomerNumber') // '11125725'

const is_header_mode = parseInt(process.env.REACT_APP_HEADER_MODE as string)

/**
 * HTTP errors
 * @type {ApolloLink}
 */
const errorHTTPLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ extensions }) => console.log(extensions?.code))
  }
})

/**
 *
 * @type {ApolloLink}
 */
//@todo: should be moved to login, put it here to get custnumber easily
if (!is_header_mode) {
  const customerNumberHeader = spoofCustomerNumber || customerNumber
  window.analytics.identify(customerNumberHeader)
}

const httpLink = createUploadLink({
  uri: process.env.REACT_APP_API_URL,
  credentials: 'include',
})

const authLink = setContext((_, { headers }) => {
  const token: Pick<CookieType, 'bsp.jwt'> = cookies.get('bsp.jwt')
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token as string}` : '',
    },
  }
})

/**
 uri?: string | UriFunction;
 credentials?: string;
 headers?: Record<string, string>;
 link?: ApolloLink;src/constants/config.ts
 cache: ApolloCache<TCacheShape>;
 ssrForceFetchDelay?: number;
 ssrMode?: boolean;
 connectToDevTools?: boolean;
 queryDeduplication?: boolean;
 defaultOptions?: DefaultOptions;
 assumeImmutableResults?: boolean;
 resolvers?: Resolvers | Resolvers[];
 typeDefs?: string | string[] | DocumentNode | DocumentNode[];
 fragmentMatcher?: FragmentMatcher;
 name?: string;
 version?: string;
 */
const link = authLink.concat(httpLink)
const newLink = ApolloLink.from([errorHTTPLink, link])
const client = new ApolloClient({
  link: !!+(process.env.REACT_APP_APOLLO_MOCK as string)
    ? new SchemaLink({ schema: schemaWithMocks })
    : newLink,
  cache,
  // connectToDevTools: process.env.NODE_ENV === 'development',
  defaultOptions,
  typeDefs: schemaSDL,
  queryDeduplication: true,
})

export default client
