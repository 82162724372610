// @react
import React from 'react'
// @types
import type { ReactElement } from 'react'
import type { PropTypes as FooterType } from '../index'
// @libraries
import { makeStyles } from '@material-ui/core/styles'
// @components
import Link from '@bit/atd.web.header-link'
import Accordion from '@bit/atd.web.accordion'
// @constants
import { RouteNamesExternal } from 'constants/routeNamesExternal'
import Helper from '../../../../utils/Helper.class'

const useStyles = makeStyles({
  root: {
    marginBottom: '40px',
  },
  linkWrap: {
    marginBottom: '16px',
    '&:last-child': {
      marginBottom: '8px',
    },
  },
})

const MobileLinks = ({ isLoggedIn }: FooterType): ReactElement => {
  /********************************************
   *  HOOKS
   *********************************************/

  const classes = useStyles()

  /********************************************
   *  RENDER
   *********************************************/

  const links = [
    {
      title: 'About',
      content: (
        <ul>
          <li className={classes.linkWrap}>
            <Link href={RouteNamesExternal.ATD_ABOUT_US}>About Us</Link>
          </li>
          <li className={classes.linkWrap}>
            <Link href={RouteNamesExternal.HELP_FAQ}>FAQs</Link>
          </li>
          <li className={classes.linkWrap}>
            <Link href={RouteNamesExternal.ATD_CHAPTERS}>Chapters</Link>
          </li>
          <li className={classes.linkWrap}>
            <Link href={RouteNamesExternal.ATD_GLOBAL_HOME}>ATD Global</Link>
          </li>
          <li className={classes.linkWrap}>
            <Link href={RouteNamesExternal.ATD_PRESS_ROOM}>ATD Press Room</Link>
          </li>
          <li className={classes.linkWrap}>
            <Link href={RouteNamesExternal.ATD_MERCHANDISE}>Merchandise</Link>
          </li>
          <li className={classes.linkWrap}>
            <Link href={RouteNamesExternal.ATD_ADVERTISE}>
              Advertise With Us
            </Link>
          </li>
        </ul>
      ),
    },
    {
      title: 'Careers',
      content: (
        <ul>
          <li className={classes.linkWrap}>
            <Link href={RouteNamesExternal.ATD_CAREERS}>Careers at ATD</Link>
          </li>
          <li className={classes.linkWrap}>
            <Link href={RouteNamesExternal.JOBS_BANK}>ATD Job Bank</Link>
          </li>
        </ul>
      ),
    },
    {
      title: 'Contact Us',
      content: (
        <ul>
          <li className={classes.linkWrap}>
            <Link href={RouteNamesExternal.ATD_MAIL_CUSTOMER_CARE}>
              customercare@td.org
            </Link>
          </li>
          <li className={classes.linkWrap}>
            <Link href={RouteNamesExternal.ATD_PHONE_GLOBAL}>
              +1.800.628.2783
            </Link>
          </li>
          <li className={classes.linkWrap}>
            <Link href={RouteNamesExternal.ATD_PHONE_US}>+1.703.683.8100</Link>
          </li>
        </ul>
      ),
    },
  ]

  if (isLoggedIn) {
    links.push({
      title: 'Account',
      content: (
        <ul>
          <li className={classes.linkWrap}>
            <Link href={Helper.getPathFromRouteKey('MYATD_DASHBOARD')}>
              MyATD
            </Link>
          </li>
          <li className={classes.linkWrap}>
            <Link
              href={Helper.getPathFromRouteKey('PROFILE_PERSONALINFORMATION')}
            >
              My Account
            </Link>
          </li>
        </ul>
      ),
    })
  }

  return <Accordion className={classes.root} panels={links} />
}

export default MobileLinks
