import { lazy } from 'react'
// import LayoutWrapper from './pages/LayoutWrapper'
import { RouteNamesInternal } from 'constants/routeNamesInternal'
import { Route } from '../../constants/types'
const LayoutWrapper = lazy(() => import('./pages/LayoutWrapper'))

const routes: { routes: Route[] } = {
  routes: [
    {
      key: 'search',
      type: 'public',
      path: `/${RouteNamesInternal.SEARCH}`,
      component: LayoutWrapper,
      exact: true,
    },
    {
      key: 'search_content',
      type: 'public',
      path: `/${RouteNamesInternal.SEARCH}/:id`,
      component: LayoutWrapper,
      exact: true,
    },
  ],
}

export default routes
