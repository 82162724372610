// @types
import type { ReactElement } from 'react'
import type PropsType from './_types'
// @libraries
import clsx from 'clsx'
// @components
import Container from '@bit/atd.web.container'
// @styles
import useStyles from './_styles'

const TextEditorBlock = ({
  className,
  HTMLToRender,
}: PropsType): ReactElement => {
  /******************************************
   * HOOKS
   ******************************************/

  const classes = useStyles()

  /******************************************
   * RENDER
   ******************************************/

  return (
    <section className={clsx(classes.root, className && className)}>
      <Container>
        <div className={classes.containerInner}>{HTMLToRender}</div>
      </Container>
    </section>
  )
}

TextEditorBlock.defaultProps = {}

export default TextEditorBlock
