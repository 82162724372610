// @react
import React, { useState, useContext } from 'react'
// @utils
import Helper from 'utils/Helper.class'
import useBookmarkToggleAction from 'modules/common/hooks/useBookmark'
// @types
import Card, {
  PropsType as CardProps,
  Tag,
} from '__COMPONENTS_TO_MIGRATE/CardNew'
import { SnackbarTypes } from '@bit/atd.web.snackbar'
// @common
import { SnackbarContext } from 'modules/common/context/snackbar'
import SupportIconMessage from 'modules/search/config/supportIconMessage'
import colors from '@bit/atd.web.colors/dist/colors'
// @graphql
import {
  ConferenceSearchItem,
  CoursePurchasableSearchItem,
  CourseSearchItem,
  FilterOrder,
  GetUserContentQuery,
  GetUserContentQueryVariables,
  IAbstractSearchItem,
  SponsorItem,
  UserContentOrderByList,
  UserContentRelationship,
  WebinarSearchItem,
} from 'lib/graphql/graphqlTypes'
import { useQuery } from '@apollo/client'
import client from 'graphql/Apollo/apolloClient'
import { GET_ALL_BOOKMARKS } from 'graphql/queries/userContent.query'
// @components
import CardMicro from '__COMPONENTS_TO_MIGRATE/CardNew/Variants/Micro'
import Modal from '@bit/atd.web.modal'
import Button from '@bit/atd.web.button'
import Divider from '@bit/atd.web.divider'
import Grid from '@bit/atd.web.grid'
import Spacer from '@bit/atd.web.spacer'
import { GraphQLError } from 'graphql'
import useContentCardFormatters from './hooks/useContentCardFormatters'
import icons from '@bit/atd.web.icons/getIcons'
import queryString from 'query-string'
import {
  isBookmarkableSearchItem,
  isConferenceSearchItem,
  isCourseSearchItem,
  isIContentSearchItem,
  isMeetingSessionSearchItem,
  isPodcastSearchItem,
  isPublicationSearchItem,
  isUserSearchItem,
  isVideoSearchItem,
  isWebinarSearchItem,
} from 'modules/common/types/typeguard'
import strings from 'lib/constants/strings'
import { BookmarkableSearchItem, ContentTypes } from './types'

/********************************************
 *  TYPES
 *********************************************/

type SelfProps = {
  typename: ContentTypes
  item: IAbstractSearchItem
  variant?: string
  type?: CardProps['type']
  layout?: CardProps['layout']
  autoLayoutWidth?: CardProps['autoLayoutWidth']
  sponsors?: SponsorItem[]
  queryID?: string
  objectID?: string
  index?: string
  position?: number
  searchQuery?: string
  name?: string
  source?: string
}
type PropsType = SelfProps

/**
 *
 * @param props
 * @constructor
 */
const ContentCard = (props: PropsType) => {
  /********************************************
   *  PROPS
   *********************************************/
  const {
    item: contentItem,
    typename,
    variant,
    type,
    autoLayoutWidth,
    layout,
    queryID,
    objectID,
    index,
    position,
    searchQuery,
    name,
    source,
  } = props

  /********************************************
   *  STATE
   *********************************************/
  const [isLoading, setIsMutationLoading] = useState<boolean>(false)
  const [itemListModal, setItemListModal] = useState<string | null>(null)

  /********************************************
   *  HOOKS
   *********************************************/
  const snackbar = useContext(SnackbarContext)

  const { authorFormatter, detailFormatter } = useContentCardFormatters({
    setItemListModal,
  })

  const { toggleBookmarkAction } = useBookmarkToggleAction()

  /********************************************
   *  GQL HOOKS
   *********************************************/
  const vars = {
    input: {
      page: 1,
      pageSize: 100,
      filters: {
        userRelationship: UserContentRelationship.BOOKMARKED,
      },
      orderBy: {
        order: FilterOrder.DESC,
        value: UserContentOrderByList.PUBLISHED_DATE,
      },
    },
  }

  // @todo this is bad, a single query per registered card
  // we should move this to context
  const { data: contentData, refetch: contentRefetch } = useQuery<
    GetUserContentQuery,
    GetUserContentQueryVariables
  >(GET_ALL_BOOKMARKS, {
    fetchPolicy: 'network-only',
    variables: vars,
  })

  /************************************
   * CONSTANTS
   *************************************/

  const tag: Tag = contentItem.memberBenefit
    ? {
        text: 'Member Benefit',
        variant: 'subtle',
        color: 'default',
      }
    : undefined

  /************************************
   * FUNCTIONS: BOOKMARK
   *************************************/

  /**
   *
   * @param content
   */
  const getBookmark = (
    content: BookmarkableSearchItem,
  ): {
    callback: (content: BookmarkableSearchItem) => Promise<void>
    value: boolean
  } => {
    const value = !!(
      !!content?.isBookmarked ||
      contentData?.userContent.data.find(
        (subItem) => contentItem.id === subItem.id,
      )
    )

    return {
      callback: () => bookmarkCallback(content, value),
      value,
    }
  }

  /**
   * bookmarkCallback
   */
  const bookmarkCallback = async (
    content: BookmarkableSearchItem,
    isBookmarked: boolean,
  ): Promise<void> => {
    setIsMutationLoading(true)
    const res = await toggleBookmarkAction(content.id, isBookmarked)
    await bookmarkCallbackHandler(res, isBookmarked)
    setIsMutationLoading(false)
  }

  /**
   *
   * @param mutationErrors
   * @param isBookmarked
   */

  const bookmarkCallbackHandler = async (
    mutationErrors: readonly GraphQLError[] | undefined,
    isBookmarked: boolean,
  ): Promise<boolean> => {
    if (!mutationErrors) {
      snackbar.setMessage({
        type: SnackbarTypes.SUCCESS,
        message: isBookmarked
          ? strings.GLOBAL.BOOKMARK_DELETED
          : strings.GLOBAL.BOOKMARK_ADDED,
        show: true,
      })

      if (isBookmarkableSearchItem(contentItem)) {
        //  setIsDeleted(true)
        client.cache.modify({
          id: client.cache.identify(contentItem),
          fields: {
            isBookmarked() {
              return !isBookmarked
            },
          },
        })
      }

      if (contentData?.userContent) {
        await contentRefetch()
      }
      return true
    } else {
      snackbar.setMessage({
        type: SnackbarTypes.ERROR,
        message: 'Bookmark Update Error',
        show: true,
      })
      return false
    }
  }

  /************************************
   * FUNCTIONS: LIST MODAL
   *************************************/
  /**
   *
   * @param content
   */
  const listModal = (content: CourseSearchItem | ConferenceSearchItem) => {
    return (
      <Modal
        onClose={() => setItemListModal(null)}
        footerNoSeparator
        headerNoSeparator
        open={itemListModal === content.id}
        size="large"
        title="Dates & Locations"
        actions={
          <div>
            <Button variant="primary" size="medium" href={contentItem?.url}>
              More Info
            </Button>
          </div>
        }
      >
        <div style={{ position: 'relative', top: '-25px', fontWeight: 'bold' }}>
          {' '}
          {Helper.getTimezoneName()}
          <span style={{ marginLeft: '5px' }}>
            <SupportIconMessage
              timezone={Helper.getTimezoneName()}
              filled={false}
            />
          </span>
        </div>
        {listModalContent(content)}
      </Modal>
    )
  }

  /**
   *
   * @param content
   */
  const listModalContent = (
    content: CourseSearchItem | ConferenceSearchItem,
  ) => {
    return (
      <Grid container spacing={2}>
        {content.purchasableItems
          ?.filter(
            (item: CoursePurchasableSearchItem) =>
              item?.location !== 'On Demand',
          )
          .map((item: CoursePurchasableSearchItem, index) => {
            return (
              <Grid item key={index} xs={12}>
                <Grid container>
                  <Grid item xs={4} md={5} style={{ fontSize: '14px' }}>
                    {Helper.globalStartEndDateFormatter(
                      item?.unixBeginDate,
                      item?.unixEndDate,
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    md={2}
                    container
                    justifyContent="flex-start"
                    style={{ fontSize: '14px' }}
                  >
                    <div>
                      <span>
                        {Helper.globalHourFormatter(
                          item?.unixBeginDate,
                          item?.unixEndDate,
                        )}
                      </span>
                    </div>
                  </Grid>
                  <Grid item md={2} xs={1} />
                  <Grid item xs={3} style={{ textAlign: 'start' }}>
                    {item?.location}
                  </Grid>
                </Grid>
                <Spacer size={1} />
                <Divider width="100%" color={colors.gray300} height="1px" />
              </Grid>
            )
          })}
      </Grid>
    )
  }

  /********************************************
   *  RENDER
   *********************************************/
  const cardChooser = () => {
    /**
     *  @IContentSearchItem
     */
    if (isIContentSearchItem(contentItem)) {
      const byLine = authorFormatter(contentItem)

      // @todo clean this up
      const thumbnailType =
        typename === 'ArticleSearchItem'
          ? 'video'
          : contentItem.contentTypes?.lvl1 ===
            'Performance Resource > TD at Work Guide'
          ? 'publication'
          : 'square'

      return (
        <Card
          layout={layout}
          autoLayoutWidth={autoLayoutWidth}
          type={type}
          isLoading={isLoading}
          thumbnail={{
            src: contentItem?.imageUrl || '',
            type: thumbnailType,
            fullWidth: false,
          }}
          content={{
            position: position,
            name: name,
            index: index,
            queryId: queryID,
            objectId: objectID,
            searchQuery: searchQuery,
            source: source,
            title: contentItem?.title,
            titleUrl: contentItem?.url,
            titleTruncate: true,
            subTitle: contentItem?.description || '',
            subTitleTruncate: type !== 'search',
            byLine,
          }}
          details={{}}
          footer={{
            contentType: Helper.contentTypeFormatter(contentItem.contentTypes),
            tag,
            bookmark: isBookmarkableSearchItem(contentItem, typename)
              ? getBookmark(contentItem)
              : undefined,
          }}
        />
      )
    }

    /**
     * @PublicationSearchItem
     */
    if (isPublicationSearchItem(contentItem)) {
      const byLine = authorFormatter(contentItem)

      return (
        <Card
          layout={layout}
          isLoading={isLoading}
          autoLayoutWidth={autoLayoutWidth}
          type={type}
          thumbnail={{
            src: contentItem?.imageUrl || '',
            type: 'publication',
            fullWidth: false,
          }}
          content={{
            position: position,
            name: name,
            index: index,
            queryId: queryID,
            objectId: objectID,
            source: source,
            searchQuery: searchQuery,
            title: contentItem?.title,
            titleUrl: contentItem?.url,
            titleTruncate: true,
            subTitle: contentItem?.description || '',
            subTitleTruncate: type !== 'search',
            byLine,
          }}
          details={{}}
          footer={{
            contentType: Helper.contentTypeFormatter(contentItem.contentTypes),
            tag,
            bookmark: isBookmarkableSearchItem(contentItem, typename)
              ? getBookmark(contentItem)
              : undefined,
          }}
        />
      )
    }

    /**
     * @CourseSearchItem
     */
    if (isCourseSearchItem(contentItem)) {
      const details = detailFormatter(contentItem, contentItem?.url)

      switch (variant) {
        case 'micro':
          return (
            <CardMicro
              thumbnail={{
                src: contentItem?.imageUrl || '',
                label: 'In Progress',
                clickable: true,
              }}
              title={contentItem?.title}
              position={position}
              source={source}
              index={index}
              queryId={queryID}
              objectId={objectID}
              searchQuery={searchQuery}
              name={name}
              url={contentItem?.url}
            />
          )
        default:
          return (
            <>
              {listModal(contentItem)}
              <Card
                layout={layout}
                isLoading={isLoading}
                autoLayoutWidth={autoLayoutWidth}
                type={type}
                thumbnail={{
                  src: contentItem?.imageUrl || '',
                  type: 'square',
                  fullWidth: false,
                }}
                content={{
                  position: position,
                  name: name,
                  index: index,
                  queryId: queryID,
                  objectId: objectID,
                  source: source,
                  searchQuery: searchQuery,
                  title: contentItem?.title,
                  titleUrl: contentItem?.url,
                  titleTruncate: true,
                  subTitle: contentItem?.description || '',
                  subTitleTruncate: type !== 'search',
                }}
                details={details}
                footer={{
                  contentType: Helper.contentTypeFormatter(
                    contentItem.contentTypes,
                  ),
                  tag,
                  bookmark: isBookmarkableSearchItem(contentItem, typename)
                    ? getBookmark(contentItem)
                    : undefined,
                }}
              />
            </>
          )
      }
    }
    /**
     * @ConferenceSearchItem
     */
    if (isConferenceSearchItem(contentItem)) {
      const details = detailFormatter(contentItem, contentItem?.url)

      return (
        <>
          {listModal(contentItem)}
          <Card
            layout={layout}
            autoLayoutWidth={autoLayoutWidth}
            isLoading={isLoading}
            type={type}
            thumbnail={{
              src: contentItem?.imageUrl || '',
              type: 'square',
              fullWidth: false,
            }}
            content={{
              position: position,
              name: name,
              index: index,
              queryId: queryID,
              source: source,
              objectId: objectID,
              searchQuery: searchQuery,
              title: contentItem?.title,
              titleUrl: contentItem?.url,
              titleTruncate: true,
              subTitle: contentItem?.description || '',
              subTitleTruncate: type !== 'search',
            }}
            details={details}
            footer={{
              contentType: Helper.contentTypeFormatter(
                contentItem.contentTypes,
              ),
              tag,
              bookmark: isBookmarkableSearchItem(contentItem, typename)
                ? getBookmark(contentItem)
                : undefined,
            }}
          />
        </>
      )
    }
    /**
     * @isVideoSearchItem || @isPodcastSearchItem
     */
    if (isVideoSearchItem(contentItem) || isPodcastSearchItem(contentItem)) {
      const byLine = authorFormatter(contentItem)
      return (
        <Card
          layout={layout}
          autoLayoutWidth={autoLayoutWidth}
          type={type}
          isLoading={isLoading}
          thumbnail={{
            src: contentItem?.imageUrl || '',
            type: 'video',
            fullWidth: false,
            timestamp:
              (contentItem.duration &&
                Helper.timestampFormatter(contentItem.duration, 1)) ||
              '',
          }}
          content={{
            position: position,
            name: name,
            index: index,
            queryId: queryID,
            objectId: objectID,
            source: source,
            searchQuery: searchQuery,
            title: contentItem?.title,
            titleUrl: contentItem?.url,
            titleTruncate: true,
            subTitle: contentItem?.description || '',
            subTitleTruncate: type !== 'search',
            byLine,
            // @todo why? does video or podcast support sponsors?
            // either remove type assertion or fix video/podcast interface
            sponsors: (contentItem as WebinarSearchItem)?.sponsors || [],
          }}
          details={{}}
          footer={{
            contentType: Helper.contentTypeFormatter(contentItem.contentTypes),
            tag,
            bookmark: isBookmarkableSearchItem(contentItem, typename)
              ? getBookmark(contentItem)
              : undefined,
          }}
        />
      )
    }

    /**
     * @WebinarSearchItem
     */
    if (isWebinarSearchItem(contentItem)) {
      const time = Helper.globalHourFormatter(
        contentItem.start,
        contentItem.end,
      )
      const timeZone = Helper.getTimezoneName()
      const details = {
        detailList: [
          {
            icon: 'globe' as keyof typeof icons,
            label: `${Helper.smallDateFormatter(contentItem.start)}`,
          },
          {
            icon: 'clock' as keyof typeof icons,
            label: `${time} ${timeZone}`,
          },
        ],
        sponsors: contentItem?.sponsors ?? [],
      }

      return (
        <Card
          layout={layout}
          autoLayoutWidth={autoLayoutWidth}
          type={type}
          isLoading={isLoading}
          thumbnail={{
            src: contentItem?.imageUrl || '',
            type: 'video',
            fullWidth: false,
            timestamp:
              (contentItem.duration &&
                Helper.timestampFormatter(contentItem.duration, 1000 * 60)) ||
              '',
          }}
          content={{
            position: position,
            name: name,
            index: index,
            queryId: queryID,
            objectId: objectID,
            source: source,
            searchQuery: searchQuery,
            title: contentItem?.title,
            titleUrl: contentItem?.url,
            titleTruncate: true,
            subTitle: contentItem?.description || '',
            subTitleTruncate: type !== 'search',
          }}
          details={details}
          footer={{
            contentType: Helper.contentTypeFormatter(contentItem.contentTypes),
            tag,
            bookmark: isBookmarkableSearchItem(contentItem, typename)
              ? getBookmark(contentItem)
              : undefined,
          }}
        />
      )
    }

    /**
     * @UserSearchItem
     */
    if (isUserSearchItem(contentItem)) {
      return (
        <Card
          layout={layout}
          autoLayoutWidth={autoLayoutWidth}
          type={type}
          thumbnail={{
            src: contentItem?.imageUrl || '',
            type: 'square',
            fullWidth: false,
          }}
          avatar={{
            src: contentItem?.imageUrl || '',
          }}
          content={{
            position: position,
            name: name,
            index: index,
            queryId: queryID,
            objectId: objectID,
            source: source,
            searchQuery: searchQuery,
            title: contentItem?.title,
            titleUrl: contentItem?.url,
            titleTruncate: true,
            subTitle: contentItem?.description || '',
            subTitleTruncate: type !== 'search',
          }}
          details={{}}
          footer={{
            contentType: Helper.contentTypeFormatter(contentItem.contentTypes),
            tag,
          }}
        />
      )
    }
    /**
     * @MeetingSessionSearchItem
     */
    if (isMeetingSessionSearchItem(contentItem)) {
      return (
        <Card
          layout={layout}
          autoLayoutWidth={autoLayoutWidth}
          type={type}
          thumbnail={{
            src: contentItem?.imageUrl || '',
            type: 'square',
            fullWidth: false,
          }}
          avatar={{
            src: contentItem?.imageUrl || '',
          }}
          content={{
            position: position,
            name: name,
            index: index,
            queryId: queryID,
            source: source,
            objectId: objectID,
            searchQuery: searchQuery,
            title: contentItem?.title,
            titleUrl: contentItem?.url,
            titleTruncate: true,
            subTitle: contentItem?.description || '',
            subTitleTruncate: type !== 'search',
          }}
          details={{}}
          footer={{
            contentType: Helper.contentTypeFormatter(contentItem.contentTypes),
            tag,
          }}
        />
      )
    }

    return <></>
  }

  return cardChooser()
}

export default ContentCard
