// @react
import React, { ReactElement } from 'react'
// @libraries
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
// @components
import colors from '@bit/atd.web.colors'
import Grid from '@bit/atd.web.grid'
import Thumbnail from '@bit/atd.web.thumbnail'
// @card parts
import Title from '../../Parts/Title'

export const useStyles = makeStyles({
  thumbnailWrapper: {
    position: 'relative',
    '& img': {
      borderRadius: '8px',
      overflow: 'hidden',
      border: `2px solid ${colors.gray300}`,
    },
  },
  thumbnail: {
    minWidth: '170px',
  },
  label: {
    borderTopRightRadius: '2px',
    borderBottomRightRadius: '2px',
    position: 'absolute',
    top: '12px',
    left: '2px',
    padding: '4px 8px',
    backgroundColor: colors.white,
    color: colors.black,
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '12px',
  },
  title: {
    margin: '12px 0 0',
    lineHeight: '18px',
  },
})

type PropsType = {
  /** Thumbnail image - src/alt-text/clickable */
  thumbnail: {
    src: string
    alt?: string
    label?: string
    clickable?: boolean
  }
  /** Title */
  title: string
  /** URL */
  url?: string
  position?: number
  index?: string
  queryId?: string
  objectId?: string
  searchQuery?: string
  name?: string
  source?: string
}

const CardMicro = ({
  thumbnail,
  title,
  url,
  position,
  queryId,
  searchQuery,
  objectId,
  index,
  name,
  source,
}: PropsType): ReactElement => {
  const classes = useStyles()

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <div className={classes.thumbnailWrapper}>
            {thumbnail.clickable ? (
              <a href={url}>
                <Thumbnail
                  src={thumbnail.src}
                  alt={thumbnail?.alt}
                  ratio={'1:1'}
                />
                {thumbnail.label && (
                  <span className={classes.label}>{thumbnail.label}</span>
                )}
              </a>
            ) : (
              <>
                <Thumbnail
                  src={thumbnail.src}
                  alt={thumbnail?.alt}
                  ratio={'1:1'}
                />
                {thumbnail.label && (
                  <span className={classes.label}>{thumbnail.label}</span>
                )}
              </>
            )}
          </div>
        </Grid>
        <Grid item xs={12}>
          <h3 className={clsx(classes.title)}>
            <Title
              title={title}
              titleURL={url}
              position={position}
              index={index}
              queryId={queryId}
              objectId={objectId}
              searchQuery={searchQuery}
              name={name}
              source={source}
            />
          </h3>
        </Grid>
      </Grid>
    </div>
  )
}

export default CardMicro
