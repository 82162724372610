import React, { ReactElement, useState } from 'react'
import Thumbnail, {
  PropsType as ThumbnailPropsType,
} from '@bit/atd.web.thumbnail'
import { makeStyles } from '@material-ui/core/styles'
import colors from '@bit/atd.web.colors'
import parse from 'html-react-parser'
import dompurify from 'dompurify'
import Grid from '@bit/atd.web.grid'
import useSegmentAlgoliaApi from 'modules/common/hooks/useSegmentAlgoliaApi'
import Icon from '@bit/atd.web.icons'
import Spacer from '@bit/atd.web.spacer'

const useStyles = makeStyles({
  categorizedWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: '8px',
    '&:first-child': {
      marginTop: '20px',
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },
  categorizedThumbnail: {
    '& img': {
      // objectFit: 'contain',
    },
  },
  categorizedText: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '18px',
    color: colors.ctaSecondaryDarker,
  },
  textHighlighted: {
    '& em': {
      fontWeight: 600,
      color: colors.ctaPrimary,
      background: 'transparent',
    },
  },
})

type PropTypes = {
  title: string
  imageUrl: string
  url: string
  objectID: string
  category: string
  queryID?: string
  indexName: string
  position: number
  searchQuery: string
}

/**
 *
 * @param props
 * @constructor
 */
const CategorisedRow = (props: PropTypes): ReactElement => {
  const {
    title,
    imageUrl: src,
    url,
    category,
    indexName,
    searchQuery,
    position,
    queryID,
    objectID,
  } = props
  const classes = useStyles()
  let ratio = '1:1'
  let resize

  switch (category) {
    case 'publications': // we should rename this to correct aspect ratio
      resize = 'w=74&h=100'
      ratio = '2:3'
      break
    case 'conferences': // we should rename this to correct aspect ratio
      resize = 'w=100&h=74'
      ratio = '3:2'
      break
    default:
    case 'courses': // we should rename this to correct aspect ratio
      resize = 'w=100&h=100'
      ratio = '1:1'
      break
  }

  // @todo move this to backend URL prep
  const newSrc = `${src
    ?.replace('https://d22bbllmj4tvv8.cloudfront.net', 'https://atd.imgix.net')
    .replace(
      'http://files.astd.org.s3.amazonaws.com',
      'https://atd-s3.imgix.net',
    )
    .replace(
      'https://atd-brightspot-lower.s3.amazonaws.com',
      'https://atd-brightspot-lower.imgix.net',
    )}?${resize}`

  /********************************
   * FUNCTIONS
   *********************************/
  const segmentAnalytics = useSegmentAlgoliaApi(
    objectID,
    title,
    indexName,
    queryID,
    position,
    searchQuery,
    url,
    false,
    'quick-search-categorised-row',
  )

  const segmentAnalyticsNewTab = useSegmentAlgoliaApi(
    objectID,
    title,
    indexName,
    queryID,
    position,
    searchQuery,
    url,
    true,
    'quick-search-categorised-row',
  )

  /********************************
   * RENDER
   *********************************/
  return (
    <Grid container spacing={2} className={classes.categorizedWrapper}>
      <Grid item xs={2}>
        <Thumbnail
          className={classes.categorizedThumbnail}
          src={newSrc}
          ratio={ratio as ThumbnailPropsType['ratio']}
        />
      </Grid>
      <Grid item xs={10}>
        <span onClick={segmentAnalytics} className={classes.categorizedText}>
          {parse(
            dompurify.sanitize(
              `<span class="${classes.textHighlighted}">${title}</span>`,
            ),
          )}
        </span>
        <Spacer size={3} />
        <span onClick={segmentAnalyticsNewTab}>
          <Icon size={20} glyph="newtab" />
        </span>
      </Grid>
    </Grid>
  )
}

export default CategorisedRow
