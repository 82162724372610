import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
} from '@apollo/client'
import schemaSDL from '../../lib/graphql/contentStack.graphql'
const environment = process.env.REACT_APP_CONTENT_STACK_ENV || ''
const apiKey = process.env.REACT_APP_CONTENT_STACK_API_KEY || ''
const deliveryToken = process.env.REACT_APP_CONTENT_STACK_DELIVERY_TOKEN || ''

const GRAPHQL_ENDPOINT = `https://graphql.contentstack.com/stacks/${apiKey}?environment=${environment}`

const link = new HttpLink({
  uri: GRAPHQL_ENDPOINT,
  headers: {
    access_token: deliveryToken,
  },
})

const apolloClient = new ApolloClient({
  link: ApolloLink.from([link]),
  cache: new InMemoryCache(),
  typeDefs: schemaSDL,
  queryDeduplication: true,
})
export default apolloClient
