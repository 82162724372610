// language=GraphQL
import { gql } from '@apollo/client'

export const UPSERT_USER = gql`
  mutation UserUpsert($input: UpsertUserInput!) {
    userUpsert(input: $input) {
      status
      message
    }
  }
`

export const LANDING_PAGE_CONTACT_FORM_UPDATE = gql`
  mutation LandingPageContactFormUpdate($input: LandingPageContactFormInput!) {
    landingPageContactFormUpdate(input: $input) {
      status
      message
    }
  }
`

export const USER_PROFILE_UPDATE = gql`
  mutation UserProfileUpdate($input: ProfileInput!) {
    userProfileUpdate(input: $input) {
      status
      message
    }
  }
`
export const USER_JOB_UPDATE = gql`
  mutation UserJobUpdate($input: JobInput!) {
    userJobUpdate(input: $input) {
      status
      message
    }
  }
`

export const USER_EDUCATION_UPDATE = gql`
  mutation UserEducationUpsert($input: [EducationUpsertInput!]!) {
    userEducationUpsert(input: $input) {
      status
      message
    }
  }
`

export const USER_EDUCATION_DELETE = gql`
  mutation UserEducationDelete($input: EducationDeleteInput!) {
    userEducationDelete(input: $input) {
      status
      message
    }
  }
`

export const USER_ADDRESS_DELETE = gql`
  mutation UserAddressDelete($input: AddressDeleteInput!) {
    userAddressDelete(input: $input) {
      status
      message
    }
  }
`

export const USER_PHONE_DELETE = gql`
  mutation UserPhoneDelete($input: PhoneDeleteInput!) {
    userPhoneDelete(input: $input) {
      status
      message
    }
  }
`

export const USER_CONTACT_INFO_UPDATE = gql`
  mutation UserContactInfoUpdate($input: ContactInfoInput!) {
    userContactInfoUpdate(input: $input) {
      status
      message
    }
  }
`

export const USER_COMMUNICATION_PREFERENCES_UPDATE = gql`
  mutation UserCommunicationPreferencesUpdate(
    $input: CommunicationPreferencesInput!
  ) {
    communicationPreferencesUpdate(input: $input) {
      status
      message
    }
  }
`

export const USER_TOGGLE_PROFILE_PRIVACY = gql`
  mutation UserToggleProfilePrivacy($input: Boolean!) {
    toggleProfilePrivacy(input: $input) {
      message
      status
    }
  }
`

export const USER_TOGGLE_MEMBER_DIRECTORY_PRIVACY = gql`
  mutation UserToggleMemberDirectoryPrivacy($input: Boolean!) {
    toggleMemberDirectoryPrivacy(input: $input) {
      message
      status
    }
  }
`

export const UPDATE_PASSWORD = gql`
  mutation UserUpdatePassword($input: UpdatePasswordInput!) {
    updatePassword(input: $input) {
      message
      status
    }
  }
`

export const UPDATE_EMAIL = gql`
  mutation UserUpdateEmail($input: UpdateEmailInput!) {
    updateEmail(input: $input) {
      message
      status
    }
  }
`

export const UPLOAD_FILE = gql`
  mutation uploadFile($input: UpdateProfilePicture!) {
    uploadFile(input: $input) {
      message
      status
    }
  }
`

export const REMOVE_FILE = gql`
  mutation removeFile {
    removeFile {
      message
      status
    }
  }
`

export const USER_UPDATE_ORDER_AUTORENEWAL = gql`
  mutation userUpdateOrderAutorenewal($input: UpdateOrderAutoRenewalInput!) {
    updateOrderAutoRenewal(input: $input) {
      status
      message
    }
  }
`
