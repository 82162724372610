import React, { useContext, useEffect } from 'react'
import { PropsType as CardProps } from '__COMPONENTS_TO_MIGRATE/CardNew'
import ContentCard from 'modules/common/components/ContentCard'
import Grid from '@bit/atd.web.grid'
import parse, { domToReact, HTMLReactParserOptions } from 'html-react-parser'
import colors from '@bit/atd.web.colors/dist/colors'
import { Element } from 'domhandler/lib/node'
import { ContentTypes } from 'modules/common/components/ContentCard/types'
import { useHistory } from 'react-router'
import { AlgoliaHit } from '../../../../common/types'

type PropsType = {
  hit: AlgoliaHit
  index: any
  layout?: 'search' | 'featured'
  source?: string
}

/**
 *
 * @param contentType
 */
const typeNameChooser = (contentType: string): ContentTypes => {
  switch (contentType) {
    case 'Blog':
    case 'Community Content':
      return 'BlogSearchItem'
    case 'Webinar':
      return 'WebinarSearchItem'
    case 'Publication':
      return 'PublicationSearchItem'
    case 'Newsletter Article':
    case 'Article':
      return 'ArticleSearchItem'
    case 'Video':
      return 'VideoSearchItem'
    case 'Page':
    case 'TD magazine':
    case 'Performance Resource':
    case 'Press Release':
    case 'Community Discussion':
      return 'PageSearchItem'
    case 'Course':
      return 'CourseSearchItem'
    case 'Conference':
      return 'ConferenceSearchItem'
    case 'People':
      return 'UserSearchItem'
    case 'Podcast':
      return 'PodcastSearchItem'
    default:
      return 'BlogSearchItem'
  }
}

/**
 *
 * @param typeName
 * @param index
 */
const layoutChooser = (
  typeName: ContentTypes,
  index: string,
): CardProps['layout'] => {
  /**
   * there are cleaner ways to do this
   * now we're relying on the index type
   * with fallback as taxonomy content type lvl0
   * still feels pretty fragile
   * probably we should aim to implement class validators and passing through the local apollo cache for sanitization
   */

  switch (index) {
    case 'articles':
    case 'webinars':
    case 'videos':
    case 'users':
    case 'podcasts':
      return 'vertical'

    case 'blogs':
    case 'courses':
    case 'conferences':
    case 'pages':
    case 'publications':
    case 'performance_resources':
    case 'certification':
      return 'horizontal'
  }

  switch (typeName) {
    case 'BlogSearchItem':
    case 'CourseSearchItem':
    case 'ConferenceSearchItem':
    case 'PageSearchItem':
    case 'PublicationSearchItem':
      return 'horizontal'
    case 'WebinarSearchItem':
    case 'ArticleSearchItem':
    case 'VideoSearchItem':
    case 'UserSearchItem':
    case 'PodcastSearchItem':
    default:
      return 'vertical'
  }
}

/**
 *
 * @param props
 * @constructor
 */
const CustomCardHit = (props: PropsType) => {
  const { hit, index, source }: PropsType = props
  const history = useHistory()
  const __typename = typeNameChooser(hit?.contentTypes?.lvl0 || '')
  const layout = layoutChooser(__typename, index?.targetedIndex || '')
  const gridSize =
    layout === 'vertical' ? (props?.layout !== 'featured' ? 4 : 12) : 12
  const options: HTMLReactParserOptions = {
    replace: (domNode) => {
      if (domNode instanceof Element && domNode.attribs) {
        const { name, children } = domNode
        if (name === 'em') {
          return (
            <span style={{ color: colors.black, fontWeight: 'bold' }}>
              {domToReact(children, options)}
            </span>
          )
        }
      }
    },
  }

  const snippet =
    (hit?._snippetResult?.description?.value &&
      parse(hit?._snippetResult?.description?.value, options)) ||
    (hit?._snippetResult?.bodyText?.value &&
      parse(hit?._snippetResult?.bodyText?.value, options)) ||
    hit.description

  const highlightedHit = {
    __typename,
    ...hit,
    id: hit.objectID,
    title:
      (hit?._snippetResult?.title?.value &&
        parse(hit?._snippetResult?.title?.value, options)) ||
      hit.title,
    description: snippet,
  }

  const url = history.location.search.replace('?', '')
  let query: string
  let modifyQuery: string
  if (url.indexOf('query') !== -1) {
    query = url.substring(url.indexOf('query') + 6)
    if (query.indexOf('&') !== -1) {
      modifyQuery = query.substring(0, query.indexOf('&'))
    } else {
      modifyQuery = decodeURI(query)
    }
  } else {
    modifyQuery = ''
  }

  return (
    <Grid item xs={12} md={gridSize}>
      <ContentCard
        type={'search'}
        layout={layout}
        key={`${hit.objectID}_${__typename}`}
        typename={__typename}
        item={highlightedHit}
        objectID={hit.objectID}
        queryID={hit.__queryID}
        position={hit.__position}
        index={index?.targetedIndex}
        searchQuery={modifyQuery}
        name={hit.title}
        source={source}
      />
    </Grid>
  )
}

CustomCardHit.defaultProps = {
  layout: 'search',
}

export default CustomCardHit
