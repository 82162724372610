// language=GraphQL
import { gql } from '@apollo/client'
import { ABSTRACT_SEARCH_ITEM } from '../fragments/interface.fragment'
import { IAbstractSearchItemFragments } from '../fragments/content.fragment'

export const allContentFragments = {
  fragments: {
    node: gql`
      fragment AllContentItemNode on IAbstractSearchItem {
        ... on IAbstractSearchItem {
          ...IAbstractSearchItem
        }
        #
        # CONTENT
        #
        ... on BlogSearchItem {
          ...IAbstractSearchItem
          ...BlogSearchItemNode
        }
        ... on ArticleSearchItem {
          ...IAbstractSearchItem
          ...ArticleSearchItemNode
        }
        ... on PageSearchItem {
          ...IAbstractSearchItem
          ...PageSearchItemNode
        }
        ... on VideoSearchItem {
          ...IAbstractSearchItem
          ...VideoSearchItemNode
        }
        ... on PublicationSearchItem {
          ...IAbstractSearchItem
          ...PublicationSearchItemNode
        }
        ... on UserSearchItem {
          ...IAbstractSearchItem
          ...UserSearchItemNode
        }
        ... on CourseSearchItem {
          ...IAbstractSearchItem
          ...CourseSearchItemNode
        }
        ... on ConferenceSearchItem {
          ...IAbstractSearchItem
          ...ConferenceSearchItemNode
        }
        ... on WebinarSearchItem {
          ...IAbstractSearchItem
          ...WebinarSearchItemNode
        }
        ... on PodcastSearchItem {
          ...IAbstractSearchItem
          ...PodcastSearchItemNode
        }
      }
      ${IAbstractSearchItemFragments.videoSearchItemFragments.fragments.node}
      ${IAbstractSearchItemFragments.podcastSearchItemFragments.fragments.node}
      ${IAbstractSearchItemFragments.courseSearchItemFragments.fragments.node}
      ${IAbstractSearchItemFragments.webinarSearchItemFragments.fragments.node}
      ${IAbstractSearchItemFragments.publicationSearchItemFragments.fragments
        .node}
      ${IAbstractSearchItemFragments.courseSearchItemFragments.fragments.node}
      ${IAbstractSearchItemFragments.conferenceSearchItemFragments.fragments
        .node}
      ${IAbstractSearchItemFragments.blogSearchItemFragments.fragments.node}
      ${IAbstractSearchItemFragments.articleSearchItemFragments.fragments.node}
      ${IAbstractSearchItemFragments.userSearchItemFragments.fragments.node}
      ${IAbstractSearchItemFragments.pageSearchItemFragments.fragments.node}
      ${ABSTRACT_SEARCH_ITEM}
    `,
  },
}
