// @types
import type { ReactElement } from 'react'
import type {
  LandingPagesPageSectionSectionBlockBlockContentFeaturedModuleBlockContentQuery,
  LandingPagesPageSectionSectionBlockBlockContentFeaturedModuleBlockContentRelationship,
  Maybe,
} from 'lib/graphql/graphqlTypes'
import type { AlgoliaHit } from '../../../common/types'
// @libraries
import React, { useState } from 'react'
import algoliasearch from 'algoliasearch'
import useAsyncEffect from 'use-async-effect'
// @components
import Container from '@bit/atd.web.container'
// @module components
import AccordionWrapper from '../AccordionWrapper'
import TabWrapper from '../TabWrapper'
import { Theme } from '../BlockChooser'
// @utils
import { isOfType } from '../../typeguard'
import Helper from '../../../../utils/Helper.class'
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery'
import config from '../../../../constants/config'
import muiTheme from '@bit/atd.web.theme'
import RenderContentGrid from '../GridView'

/**
 * @todo this is
 * the best example of why content stack needs to be merged/stitched etc in backend
 */
export const showMoreLimit = 5
export const desktopLimit = 20
export const mobileLimit = 6

type FeaturedModuleBlockUnion =
  | LandingPagesPageSectionSectionBlockBlockContentFeaturedModuleBlockContentRelationship
  | LandingPagesPageSectionSectionBlockBlockContentFeaturedModuleBlockContentQuery

type RefinementList = { refinement: string; value: string }[]

export type FeaturedSection = {
  type: string
  subTitle: string
  title: string
  viewMoreLink?: {
    title: string
    href: string
  }
  content?: AlgoliaHit[]
}

export type FeaturedSectionBlock = {
  title?: string | null
  subTitle?: string | null
  content: FeaturedSection[]
}

type PropsType = {
  title?: string | null
  subTitle?: string | null
  theme: Theme
  content: Maybe<FeaturedModuleBlockUnion>[]
}

const client = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID as string,
  process.env.REACT_APP_ALGOLIA_SECRET as string,
)

const isFeaturedSection = (
  item: FeaturedSection | undefined,
): item is FeaturedSection => {
  return !!item
}

enum QueryType {
  QUERY = 'query',
  RELATIONSHIP = 'relationship',
}
/**
 * @todo move to utility class
 * @param args
 */
const queryBuilder = (
  args: {
    key: string
    items: string[]
  }[],
): string => {
  return args.reduce((acc, item) => {
    const subStr = item.items.length
      ? `(${item.items.reduce((subAcc: string, subItem, index: number) => {
          if (subAcc === '') {
            return `${item.key}:'${subItem}'`
          }
          return `${subAcc} OR ${item.key}:'${subItem}'`
        }, '')})`
      : ''

    if (acc === '') {
      return `${subStr}`
    }
    return `${acc} ${subStr ? 'AND' : ''} ${subStr}`
  }, '')
}

/**
 *
 * @param taxonomy
 * @param keyword
 * @param refinementList
 * @param index
 */
const viewMoreLinkBuilder = ({
  taxonomy,
  keyword,
  refinementList,
  index,
}: {
  taxonomy: string
  keyword: string
  refinementList: RefinementList
  index?: string
}) => {
  const validIndex = index || Helper.getSearchIndex(taxonomy)

  return {
    title: taxonomy
      ? `See all ${Helper.getSearchLabel(taxonomy)}`
      : 'See All Content',
    href: `${Helper.constructSearchURL({
      index: validIndex,
      refinementList,
      keyword: keyword,
    })}`,
  }
}

/**
 *
 * @param roles
 * @param topics
 * @param industries
 */
const refinementListBuilder = ({
  roles,
  topics,
  industries,
}: {
  roles?: string[]
  topics?: string[]
  industries?: string[]
}) => {
  const refinementList: RefinementList = []

  roles?.map((item) => {
    if (item) {
      refinementList.push({
        refinement: 'roles',
        value: item,
      })
    }
  })

  topics?.map((item) => {
    if (item) {
      refinementList.push({
        refinement: 'topics',
        value: item,
      })
    }
  })

  industries?.map((item) => {
    if (item) {
      refinementList.push({
        refinement: 'industries',
        value: item,
      })
    }
  })

  return refinementList
}

/**
 *
 * @param theme
 * @param content
 * @param title
 * @param subTitle
 * @constructor
 */
const FeaturedContent = ({
  theme,
  content,
  title,
  subTitle,
}: PropsType): ReactElement => {
  /******************************************
   * HOOKS
   ******************************************/
  const [resContent, setResContent] = useState<FeaturedSectionBlock>()
  const isMobile = useMediaQuery(
    muiTheme.breakpoints.down('sm'),
    config.mediaQuery,
  )
  const limit = isMobile ? mobileLimit : desktopLimit

  useAsyncEffect(async () => {
    const newContentRes = await newContent()
    setResContent(newContentRes)
  }, [content])

  /******************************************
   * RENDER
   ******************************************/

  /**
   *
   */
  const newContent = async (): Promise<FeaturedSectionBlock> => {
    const formattedContent = await Promise.all(
      content.map(
        async (item): Promise<FeaturedSection | undefined> => {
          /***************************************
           * Relationship
           ****************************************/
          if (
            isOfType<LandingPagesPageSectionSectionBlockBlockContentFeaturedModuleBlockContentRelationship>(
              item,
              '__typename',
              'LandingPagesPageSectionSectionBlockBlockContentFeaturedModuleBlockContentRelationship',
            )
          ) {
            const indexMaster = client.initIndex('master')
            let res
            if (item?.relationship?.featured_items?.length) {
              res = await indexMaster.getObjects(
                item?.relationship?.featured_items as string[],
              )
            }
            if (res?.results.length) {
              return {
                type: QueryType.RELATIONSHIP,
                subTitle: item?.relationship?.subtitle || '',
                title: item?.relationship?.title || '',
                content: res?.results,
              }
            }
          }

          /***************************************
           * Query
           ****************************************/
          if (
            isOfType<LandingPagesPageSectionSectionBlockBlockContentFeaturedModuleBlockContentQuery>(
              item,
              '__typename',
              'LandingPagesPageSectionSectionBlockBlockContentFeaturedModuleBlockContentQuery',
            )
          ) {
            let res
            const queryStr = queryBuilder([
              {
                key: 'roles',
                items: item?.query?.role as string[],
              },
              {
                key: 'topics',
                items: item?.query?.topic as string[],
              },
              {
                key: 'industries',
                items: item?.query?.industry as string[],
              },
              // {
              //   key: 'contentTypes.lvl0',
              //   items: [item?.query?.taxonomy as string],
              // },
            ])

            if (item?.query) {
              const newIndexString = Helper.getSearchIndex(
                item?.query?.taxonomy,
                item?.query?.order_by,
                item?.query?.order,
              )

              const index = client.initIndex(newIndexString)
              const indexExists = await index.exists()
              const finalIndex = indexExists
                ? index
                : client.initIndex('master')

              res = await finalIndex.search(item.query?.keyword || '', {
                hitsPerPage: limit,
                clickAnalytics: true,
                filters: queryStr,
              })

              if (res?.hits.length) {
                const refinementList = refinementListBuilder({
                  roles: item?.query?.role as string[],
                  topics: item?.query?.topic as string[],
                  industries: item?.query?.industry as string[],
                })

                const viewMoreLink = viewMoreLinkBuilder({
                  taxonomy: item?.query?.taxonomy || '',
                  keyword: item.query?.keyword || '',
                  refinementList,
                  index: finalIndex.indexName,
                })

                return {
                  type: QueryType.QUERY,
                  subTitle: item?.query?.subtitle || '',
                  title: item?.query?.title || '',
                  content: res?.hits,
                  viewMoreLink:
                    res?.hits.length >= limit ? viewMoreLink : undefined,
                }
              }
            }
          }
        },
      ),
    )

    return {
      title,
      subTitle,
      content: formattedContent.filter(isFeaturedSection),
    }
  }

  /**
   *
   * @param resContent
   */
  const renderPicker = (resContent?: FeaturedSectionBlock) => {
    if (!resContent) {
      return <></>
    }

    if (resContent && resContent.content.length === 1) {
      const item = resContent.content[0]
      return (
        <RenderContentGrid
          contentItems={item.content}
          subTitle={item.subTitle}
          limit={limit}
          viewMoreLink={item.viewMoreLink}
        />
      )
    }

    if (isMobile || theme === Theme.ACCORDION) {
      return <AccordionWrapper content={resContent} limit={limit} />
    }
    if (theme === Theme.TABS) {
      return <TabWrapper content={resContent} limit={limit} />
    }
    return <></>
  }

  /**
   *
   */
  return (
    <section>
      <Container>{renderPicker(resContent)}</Container>
    </section>
  )
}

export default FeaturedContent

//search?refinementList[topics][0]=Talent Strategy and Management&refinementList[topics][0]=Technology Application
//search?refinementList[topics][0]=Talent+Strategy+and+Management&refinementList[topics][1]=Technology+Application
