// @react
import React, { ReactElement, useContext } from 'react'
import { useHistory } from 'react-router-dom'
// @libraries
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Helper from 'utils/Helper.class'
// @components
import Grid from '@bit/atd.web.grid'
import Icon from '@bit/atd.web.icons'
import colors from '@bit/atd.web.colors'
import Spacer from '@bit/atd.web.spacer'
import Container from '@bit/atd.web.container'
import Avatar from '@bit/atd.web.avatar'
import Button from '@bit/atd.web.button'
import SearchPanel, {
  PropsType as SearchPanelProps,
} from 'modules/global/SearchPanel'
// @module components
import atdLogo from '../../assets/atd-logo-small.svg'
import { RouteNamesExternal } from 'constants/routeNamesExternal'
import HeaderDataContext from '../../context'
import Bell from '../../components/BellComponent/index'

const useStyles = makeStyles({
  wrap: {
    position: 'relative',
    color: colors.ctaSecondary,
    backgroundColor: colors.white,
    '&::after': {
      content: '""',
      position: 'relative',
      display: 'block',
      height: '2px',
      width: '100%',
      backgroundImage: `linear-gradient(90deg, ${colors.brandYellow} 0%, ${colors.brandOrange} 52.98%, ${colors.brandRed} 98.75%)`,
    },
    '& > div:first-child': {
      position: 'relative',
      zIndex: 3,
    },
  },
  top: {
    paddingTop: '16px',
    paddingBottom: '16px',
    '& img': {
      display: 'inline-block',
      verticalAlign: 'middle',
      width: 'auto',
      maxHeight: '28px',
    },
  },
  offcanvas: {
    opacity: 0,
    visibility: 'hidden',
  },
  searchPanel: {
    // position: 'absolute',
    top: '100%',
    width: '100%',
    zIndex: 2,
    // background: colors.white,
  },
  avatarWrapper: {
    cursor: 'pointer',
  },
  iconText: {
    marginLeft: 8,
  },
  searchBtn: {
    '&:focus': {
      background: 'none',
      outline: 'none',
    },
  },
  bell: {
    position: 'relative',
    top: '3px',
  },
})

type PropsType = {
  showOffcanvas?: boolean
  insideOffcanvas?: boolean
  searchPanelProps: SearchPanelProps
  onHamburgerClick: () => void
  /** Show search panel */
  showSearch?: boolean
  /** Toggle search panel */
  toggleSearch: (open: boolean) => void
}

const Top = ({
  insideOffcanvas,
  showOffcanvas,
  searchPanelProps,
  onHamburgerClick,
  showSearch,
  toggleSearch,
}: PropsType): ReactElement => {
  /********************
   * HOOKS
   *********************/
  const classes = useStyles()
  const history = useHistory()

  const headerContext = useContext(HeaderDataContext)
  const { isLoggedIn, user, isNewSearch } = headerContext

  const searchIconText = showSearch
    ? 'Close Search'
    : isNewSearch
    ? 'New Search'
    : ''

  return (
    <div
      className={clsx({
        [classes.wrap]: true,
        [classes.offcanvas]: showOffcanvas,
      })}
    >
      <Container>
        <Grid
          container
          justify="space-between"
          alignItems="center"
          className={classes.top}
        >
          {/* Logo */}
          <Grid item>
            <a href={RouteNamesExternal.ATD_WEB_URL}>
              <img src={atdLogo} alt="ATD" />
            </a>
          </Grid>

          <Grid item>
            <Grid container alignItems="center">
              {/* Search Button */}
              <Button
                variant="icon"
                onClick={() => toggleSearch(!showSearch)}
                className={classes.searchBtn}
              >
                <>
                  <Icon glyph="magnify" size={24} />
                  {searchIconText && (
                    <span className={classes.iconText}>{searchIconText}</span>
                  )}
                </>
              </Button>

              <Spacer size={8} noHeight />

              {/* Cart */}
              <Button href={RouteNamesExternal.CHECKOUT_HOME} variant="icon">
                <Icon glyph="cart" size={24} />
              </Button>

              <Spacer size={8} noHeight />

              {/* Login - show Icon */}
              {(!isLoggedIn || !user) && (
                <>
                  <Button
                    href={Helper.getPathFromRouteKey('ONBOARDING')}
                    variant="icon"
                  >
                    <Icon glyph="account" size={24} />
                  </Button>
                </>
              )}

              {/* Login - show avatar */}
              {isLoggedIn && user && (
                <>
                  {/* Bell */}
                  <span className={classes.bell}>
                    <Bell />
                  </span>

                  <Spacer size={8} noHeight />

                  <Button
                    variant="icon"
                    className={classes.avatarWrapper}
                    onClick={() =>
                      Helper.pushPath(
                        Helper.getPathFromRouteKey(
                          'PROFILE_PERSONALINFORMATION',
                        ),
                        history,
                      )
                    }
                  >
                    <Avatar
                      size={24}
                      src={user.avatarUrl}
                      alt={user.textAs}
                      bgColor={user.bgColor}
                    >
                      {user.textAs}
                    </Avatar>
                  </Button>
                </>
              )}

              <Spacer size={8} noHeight />

              {/* Menu - Open / Close */}
              <Button variant="icon" onClick={onHamburgerClick}>
                {insideOffcanvas ? (
                  <Icon glyph="close" size={24} color={colors.ctaPrimary} />
                ) : (
                  <Icon glyph="hamburger-nav" size={24} />
                )}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      {searchPanelProps.isShowSearch && (
        <SearchPanel className={classes.searchPanel} {...searchPanelProps} />
      )}
    </div>
  )
}

export default Top
