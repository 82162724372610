// @libraries
import { makeStyles } from '@material-ui/core/styles'
import colors from '@bit/atd.web.colors'

type StyleProps = {
  contentCount?: number
  titleOnly?: boolean
}

const useStyles = makeStyles((theme) => ({
  list: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    margin: '0 auto',
    padding: 0,
    listStyle: 'none',
    rowGap: '24px',
    justifyItems: 'center',
    [theme.breakpoints.up('sm')]: {
      columnGap: '40px',
      rowGap: '40px',
      gridTemplateColumns: (props: StyleProps) => {
        let value = `1fr`
        if (!props?.contentCount) return value

        switch (props.contentCount) {
          case 2:
            value = `repeat(2, 1fr)`
            break
        }
        if (props.contentCount >= 3) value = `repeat(3, 1fr)`

        return value
      },
    },
    maxWidth: (props: StyleProps) => {
      let value = `auto`
      if (!props?.contentCount) return value

      switch (props.contentCount) {
        case 1:
          value = '600px'
          break
        case 2:
          value = '860px'
          break
      }

      return value
    },
  },
  item: {
    display: 'flex',
  },
  itemInner: {
    display: 'inherit',
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
    width: '100%',
  },
  text: {
    flexGrow: 1,
    '& + $cta': {
      marginTop: '10px',
    },
  },
  title: {
    fontSize: '20px',
    lineHeight: '28px',
    fontWeight: 600,
    '& + $subtitle': {
      marginTop: '10px',
    },
    '& + $cta': {
      marginTop: '10px',
    },
  },
  subtitle: {
    fontSize: '1rem',
    lineHeight: '24px',
    '& p': {
      margin: `0 0 8px`,
    },
    '& p:last-child': {
      margin: `0`,
    },
    '& a': {
      color: colors.ctaPrimary,
      textDecoration: 'underline',
      textUnderlineOffset: '4px',
      '&:hover': {
        color: '#EA6E4D',
      },
      '&:visited': {
        color: '#CB2B00',
      },
    },
  },
  cta: {
    fontSize: '1rem',
  },
  isTitleOrSubtitle: {
    flexGrow: 0,
  },
}))

export default useStyles
