// language=GraphQL
import { gql } from '@apollo/client'
import { CONSTANTS_NODE } from '../fragments/constants.fragment'

// @todo go through all getuser instances and define appropriate fragment (fetching all data right now)
export const GET_CONSTANTS = gql`
  query GetConstants {
    constants {
      ...ConstantsNode
    }
  }
  ${CONSTANTS_NODE}
`
