// @libraries
import { makeStyles } from '@material-ui/core/styles'
import { gradients } from '@bit/atd.web.theme'
import colors from '@bit/atd.web.colors'

/**
 * from src/modules/pageBuilder/__COMPONENTS_TO_MIGRATE/CTA/ImageCTA/_styles.ts
 */
const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    '& > div': {
      padding: '40px 0',
      [theme.breakpoints.up('sm')]: {
        padding: '60px 0',
      },
    },
    '& ~ footer': {
      marginTop: 0,
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '0 0 25px 0',
    [theme.breakpoints.up('sm')]: {
      padding: '0 0 40px 0',
    },
  },
  title: {
    fontSize: '32px',
    fontWeight: 600,
    textAlign: 'center',
    color: colors.black,
    '& ~ $subTitle': {
      marginTop: '12px',
    },
  },
  subTitle: {
    fontSize: '18px',
    lineHeight: '30px',
  },
  content: {},
  theme1: {
    backgroundColor: colors.white,
  },
  // border theme
  theme2: {
    backgroundColor: colors.white,
    '&:after': {
      content: '""',
      background: gradients.brand,
      display: 'block',
      position: 'relative',
      bottom: '0',
      left: 0,
      width: '100%',
      height: '4px',
    },
  },
  theme3: {
    backgroundColor: colors.gray200,
  },
  isPadless: {
    '& > div': {
      padding: '0',
    },
    '&$theme2': {
      '&:after': {
        content: 'none',
      },
    },
  },
  isFeaturedContent: {
    '& $header': {
      justifyContent: 'left',
    },
    '& $title': {
      textAlign: 'left',
    },
  },
}))

export default useStyles
