// @react
import React, { useState, ReactElement, useEffect } from 'react'
// @components
import { PropsType as SearchPanelProps } from 'modules/global/SearchPanel'
// @module components
import Top from './Top'
import Bottom from './Bottom'
import useBodyScrollLock from 'modules/common/hooks/useBodyScrollLock'
import useKeyPress from 'modules/common/hooks/useKeyPress'

type PropsType = {
  /** Search Panel props */
  searchPanelProps: SearchPanelProps
}

const DesktopMenu = ({ searchPanelProps }: PropsType): ReactElement => {
  /********************************
   * STATE
   ********************************/

  const [showMegaMenu, setShowMegaMenu] = useState(false)
  const escKey = useKeyPress('Escape')
  useBodyScrollLock(showMegaMenu)

  useEffect(() => {
    if (escKey) {
      setShowMegaMenu(false)
    }
  }, [escKey])
  /********************************
   * RENDER
   ********************************/
  return (
    <React.Fragment>
      {/* Main Links */}
      <Top
        condensedMenu={
          <Bottom
            asCondensedMenu
            toggleMegaMenu={(e) => {
              setShowMegaMenu(e)
            }}
            showMegaMenu={showMegaMenu}
          />
        }
        searchPanelProps={searchPanelProps}
      />

      {/* Bottom Links */}
      <Bottom
        toggleMegaMenu={(e) => {
          setShowMegaMenu(e)
        }}
        showMegaMenu={showMegaMenu}
      />
    </React.Fragment>
  )
}

export default DesktopMenu
