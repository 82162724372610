// @libraries
import { makeStyles } from '@material-ui/core/styles'
// @colors
import colors from '@bit/atd.web.colors'

const useStyles = makeStyles((theme) => ({
  root: {
    //  backgroundColor: '#CACACA',
    // padding: '40px 0',
    // [theme.breakpoints.up('sm')]: {
    //   padding: '64px 0',
    // },

    // Assign Typography Styles
    '& h1,& h2,& h3,& h4': {
      marginTop: 0,
      marginBottom: '1rem',
      color: '#2E2D31',
    },
    '& h1': {
      fontSize: '40px',
      lineHeight: '42px',
    },
    '& h2': {
      fontSize: '32px',
      lineHeight: '36px',
    },
    '& h3': {
      fontSize: '28px',
      lineHeight: '34px',
    },
    '& h4': {
      fontSize: '24px',
      lineHeight: '34px',
    },
    '& p': {
      color: '#424140',
      fontSize: '18px',
      lineHeight: '30px',
      marginTop: 0,
      '&:last-child': {
        marginBottom: 0,
      },

      '& + h2, & + h3, & + h4': {
        marginTop: '2rem',
      },
    },
    '& a': {
      color: colors.ctaPrimary,
      textDecoration: 'underline',
      textUnderlineOffset: '4px',
      '&:hover': {
        color: '#EA6E4D',
      },
      '&:visited': {
        color: '#CB2B00',
      },
    },
  },
  containerInner: {
    maxWidth: '830px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}))

export default useStyles
