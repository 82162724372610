// @libraries
import { makeStyles, withStyles } from '@material-ui/core/styles'
import StepConnector from '@material-ui/core/StepConnector'
import colors from '@bit/atd.web.colors'

export const useRootStyles = makeStyles((theme) => ({
  root: {
    '&.MuiStep-vertical .MuiStepLabel-iconContainer': {
      paddingRight: 16,
    },
  },
  stepper: {
    background: 'transparent',
    padding: 0,
    '& .MuiStepConnector-vertical': {
      paddingTop: 8,
      marginLeft: 20,
    },
    '& .MuiStepConnector-lineVertical': {
      minHeight: 16,
    },
  },
  step: {},
  stepLabel: {
    '& .MuiTypography-root': {
      fontSize: '18px',
      lineHeight: '30px',
    },
    '& .MuiTypography-root p': {
      margin: 0,
    },
    '& .MuiTypography-root a': {
      color: colors.ctaPrimary,
      textDecoration: 'underline',
      textUnderlineOffset: '4px',
      '&:hover': {
        color: '#EA6E4D',
      },
      '&:visited': {
        color: '#CB2B00',
      },
    },
  },
  button: {
    marginTop: 24,
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  isMoreThanThree: {
    '& .MuiTypography-root': {
      fontSize: '16px',
      lineHeight: '24px',
    },
  },
}))

export const useIconStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#871D00',
    borderRadius: '50%',
    color: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    height: '40px',
    width: '40px',
  },
  active: {},
  completed: {},
}))

export const ConnectorStyle = withStyles({
  root: {},
  alternativeLabel: {
    top: 20,
  },
  active: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  completed: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  line: {
    height: 2,
    border: 0,
    backgroundColor: '#CACACA',
    borderRadius: 0,
  },
})(StepConnector)
