import home from './home'
import common from './common'
import auth from './auth'
import activityHistory from './activityHistory'
import benefitsCenter from './benefitsCenter'
import dashboard from './dashboard'
import onBoarding from './onBoarding'
import profile from './profile'
import search from './search'
import user from './user'
import PageBuilder from './pageBuilder'
import { Route } from '../constants/types'

const modules = [
  home,
  common,
  auth,
  activityHistory,
  benefitsCenter,
  dashboard,
  onBoarding,
  profile,
  search,
  user,
  PageBuilder,
]

const mergedModules = {
  routes: modules.reduce((acc: any[], { routes }: { routes?: any[] }) => {
    return routes ? [...acc, ...routes] : acc
  }, []),
}

export default mergedModules
