// @types
import type { ReactElement, ReactNode, ChangeEvent } from 'react'
import type { FeaturedTabBlock } from '../index'
// @libraries
import React, { useState } from 'react'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
// @components
import Box from '@bit/atd.web.box'
// @styles
import { useStyles } from './_styles'
import RenderContentGrid from '../../GridView'

interface TabPanelProps {
  children?: ReactNode
  index: number
  value: number
}

/**
 *
 * @param props
 * @constructor
 */
const TabPanel = (props: TabPanelProps): ReactElement => {
  /******************************************
   * PROPS
   ******************************************/

  const { children, value, index, ...rest } = props

  /******************************************
   * RENDERS
   ******************************************/

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...rest}
    >
      {<div style={{ marginTop: '32px' }}>{children}</div>}
    </div>
  )
}

type PropTypes = {
  content: FeaturedTabBlock
  limit: number
}

const DesktopTabs = ({ content, limit }: PropTypes) => {
  /******************************************
   * STATES
   ******************************************/

  const [currentTab, setCurrentTab] = useState(0)

  /******************************************
   * HOOKS
   ******************************************/

  const classes = useStyles()

  /******************************************
   * FUNCTIONS
   ******************************************/

  const handleChange = (
    _: ChangeEvent<Record<string, unknown>>,
    newValue: number,
  ) => {
    setCurrentTab(newValue)
  }

  /******************************************
   * RENDER
   ******************************************/

  return (
    <Box
      variant={'simple'}
      classNames={{ root: classes.boxRoot, body: classes.boxBody }}
    >
      <Tabs
        className={classes.tabsHeader}
        value={currentTab}
        onChange={handleChange}
        TabIndicatorProps={{ children: <span /> }}
      >
        {content.content.map((item) => {
          return (
            <Tab
              disableRipple
              key={item.index}
              value={item.index}
              tabIndex={item.index}
              label={item.title}
            />
          )
        })}
      </Tabs>

      {content.content.map((item) => {
        return (
          <div key={item.index}>
            <TabPanel key={item.index} value={currentTab} index={item.index}>
              <RenderContentGrid
                contentItems={item.content}
                subTitle={item.subTitle}
                limit={limit}
                viewMoreLink={item.viewMoreLink}
              />
            </TabPanel>
          </div>
        )
      })}
    </Box>
  )
}

export default DesktopTabs
