import { gql } from '@apollo/client'
import { LINK_NODE } from './link.fragment'

export const USER_PROFILE = gql`
  fragment Profile on User {
    profile {
      userName
      firstName
      lastName
      profileImageUrl
      bio
      gender
      website
      specialNeeds
    }
  }
`

export const USER_JOB = gql`
  fragment Job on User {
    job {
      userIndustry
      jobTitle
      jobFunctionCode
      jobIndustry
      companySize
      companyName
      trainingBudget
      directReportCount
    }
  }
`
export const USER_CERTIFICATION = gql`
  fragment Certification on User {
    certification {
      expiryDate
      earnedDate
      masters
      recertifyByDate
      certificationStatus
      certificationCode
    }
  }
`

export const USER_BADGE = gql`
  fragment Badge on User {
    badge {
      id
      badgeId
      imageUrl
      thumbnailUrl
      createdAt
      issuedAt
      title
      description
      url
      pending
      acceptUrl
    }
  }
`

export const USER_VISIBILITY = gql`
  fragment Visibility on User {
    visibility {
      publicProfile
      memberDirectoryListing
    }
  }
`

export const USER_EDUCATION = gql`
  fragment Education on User {
    education {
      id
      degreeType
      programType
      schoolName
      startDate
      endDate
    }
  }
`

export const USER_CONTACT_INFO = gql`
  fragment ContactInfo on User {
    contactInfo {
      primaryPhoneNumber
      secondaryPhoneNumber
      primaryEmailAddress
      address {
        id
        addressType
        isPrimary
        address1
        address2
        address3
        city
        state
        countryCode
        postalCode
        formattedAddress
      }
      phones {
        id
        isPrimary
        doNotCallFlag
        formattedPhoneAddress
        phoneAreaCode
        countryCode
        phoneCountryCode
        phoneExtension
        phoneNumber
        commLocationCodeString
        commTypeCodeString
      }
    }
  }
`

export const USER_COMMUNICATION_PREFERENCES = gql`
  fragment CommunicationPreferences on User {
    communicationPreferences {
      automatedNewsletters
      isPostalMailOptedOut
      isPhoneOptedOut
      receivePromotionalCommunication
      bimonthlyDigest
      newsletters {
        newsletterGroup {
          key
          label
          value
          frequency
        }
        newsletterGroupLabel
      }
    }
  }
`

export const USER_MEMBERSHIP = gql`
  fragment Membership on User {
    membership {
      id
      isMember
      isEnterpriseMember
      overview {
        type #candidate fields
        label
        perks {
          label
          value
        } #candidate fields
      }
      currentMembership {
        id
        memberType
        memberNumber
        memberTypeLabel
        memberStatus
        orderNumber
        orderLineNumber
        autoRenew
        initialBeginDate
        cycleBeginDate
        cycleEndDate
      }
      futureMembership {
        id
        memberType
        memberTypeLabel
        memberNumber
        memberStatus
        autoRenew
        orderNumber
        orderLineNumber
        initialBeginDate
        cycleBeginDate
        cycleEndDate
        chapterName
        chapterURL
        recordTypeCode
      }
      chapterMembership {
        id
        chapterName
        memberTypeLabel
        memberType
        memberNumber
        memberStatus
        autoRenew
        orderNumber
        orderLineNumber
        initialBeginDate
        cycleBeginDate
        cycleEndDate
        chapterURL
      }
      benefitResources {
        ...LinkNode
      }
      additionalMemberResources {
        ...LinkNode
      }
      claimResource {
        showBanner
        claimLink
      }
    }
  }
  ${LINK_NODE}
`
export const USER_CAPABILITY_MODEL = gql`
  fragment CapabilityModel on User {
    capabilityModel {
      domains {
        domainTitle
        totalProgress
        capabilities {
          capTitle
          capTotalScore
          statements {
            desc
            descScore
          }
        }
      }
      isSelfAssessmentStarted
      isSelfAssessmentComplete
      isLearningPathSelected
      isLearningPlanCreated
      isLearningPlanSelected
      isPartialPlan
      isLearningPlanComplete
      planActivitiesCount
      planActivitiesCompletedCount
      isPersonalCapabilityComplete
      isProfessionalCapabilityComplete
      isOrganizationalCapabilityComplete
      learningPathLabel
      totalScore
    }
  }
`

export const USER_TOPICS = gql`
  fragment Topics on User {
    topics {
      id
      label
      key
      url
      userStatus {
        id
        followed
        recommended
      }
    }
  }
`

export const USER_LEARNING_ACTIVITY = gql`
  fragment LearningActivity on User {
    learningActivity {
      id
      title
      publishDate
      updateDate
      description
      bodyText
      imageUrl
      url
      viewCount
      contentTypes {
        lvl0
        lvl1
        lvl2
      }
      topics
      industries
      roles
      keywords
    }
  }
`
export const USER_CART = gql`
  fragment Cart on User {
    cart {
      id
      itemTotal
    }
  }
`

export const USER_NODE = gql`
  fragment UserNode on User {
    id
    staff
    isContributor
    customerClassCode
    isEnterpriseAdmin
    ...Profile
    ...Job
    ...Certification
    ...Badge
    ...Visibility
    ...Education
    ...ContactInfo
    ...CommunicationPreferences
    ...LearningActivity
    ...Membership
    ...CapabilityModel
    ...Topics
    ...Cart
  }
  ${USER_PROFILE}
  ${USER_JOB}
  ${USER_CERTIFICATION}
  ${USER_BADGE}
  ${USER_VISIBILITY}
  ${USER_EDUCATION}
  ${USER_CONTACT_INFO}
  ${USER_COMMUNICATION_PREFERENCES}
  ${USER_LEARNING_ACTIVITY}
  ${USER_MEMBERSHIP}
  ${USER_CAPABILITY_MODEL}
  ${USER_TOPICS}
  ${USER_CART}
`
