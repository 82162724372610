// @react
import React, { useRef } from 'react'
// @libraries
import {
  Control,
  Controller,
  UseFormMethods,
  FieldErrors,
} from 'react-hook-form'
// @commons
import {
  ATD_country_options,
  Product_services,
} from 'modules/common/forms/fields/_config/addresss'
// @components
import Grid from '@bit/atd.web.grid'
import Select from '@bit/atd.web.select'
import { LandingPageFormValues } from 'modules/pageBuilder/types/landingPageForm'
import InputText from '@bit/atd.web.forms.input-text'
import {
  GetConstantsQuery,
  GetConstantsQueryVariables,
} from 'lib/graphql/graphqlTypes'
import { useQuery } from '@apollo/client'
import { GET_CONSTANTS } from 'graphql/queries/constants.query'
import strings from 'lib/constants/strings'
// @module components

type PropsType = {
  control: Control<LandingPageFormValues>
  formMethods: UseFormMethods<LandingPageFormValues>
}

type PropsErrorType = {
  errors: FieldErrors
}

/**
 *
 * @param formMethods
 * @param control
 * @param errors
 * @constructor
 */
const LandingPageContactFields = ({
  formMethods,
  control,
  errors,
}: PropsType & PropsErrorType) => {
  /******************************************
   * REFS
   ******************************************/

  const typeCommentsRef = useRef<HTMLInputElement | null>(null)
  const countryRef = useRef<HTMLInputElement | null>(null)
  const interestsRef = useRef<HTMLInputElement | null>(null)
  const companySizeRef = useRef<HTMLInputElement | null>(null)

  /******************************************
   * GQL HOOKS
   ******************************************/

  const { error: isConstantsError, data: constantsData } = useQuery<
    GetConstantsQuery,
    GetConstantsQueryVariables
  >(GET_CONSTANTS)

  const createDataLoadedField = (
    constantsData: GetConstantsQuery,
    type: 'companySize',
  ) => {
    const { companySizeCodes } = constantsData.constants
    const companySizeOptions = companySizeCodes.map((item: any) => {
      return {
        value: item.key,
        label: item.value,
      }
    })
    /******************************************
     * RENDER
     ******************************************/

    return (
      <>
        {type === 'companySize' && (
          <Controller
            control={control}
            onFocus={() => companySizeRef?.current?.focus()}
            name="companySize"
            render={({ onChange, onBlur, value }) => (
              <Select
                native
                name="companySize"
                label="Company Size"
                placeholder={strings.PROFILE.ACCESS.C_SIZE}
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                inputRef={companySizeRef}
                options={companySizeOptions}
                error={!!errors?.companySize}
                errorText={errors?.companySize?.message}
              />
            )}
          />
        )}
      </>
    )
  }
  /******************************************
   * RENDER
   ******************************************/
  return (
    <>
      <Grid container spacing={4}>
        <Grid xs={12} sm={6} item>
          {(isConstantsError || !constantsData) && <p>Error</p>}
          {constantsData && createDataLoadedField(constantsData, 'companySize')}
        </Grid>

        {/* Country */}
        <Grid xs={12} sm={6} item>
          <Controller
            control={control}
            name="countryCode"
            onFocus={() => countryRef?.current?.focus()}
            render={({ onChange, onBlur, value }) => (
              <Select
                native
                name="countryCode"
                label="Country"
                placeholder="Choose your country."
                required
                onBlur={onBlur}
                onChange={onChange}
                value={value as string}
                inputRef={countryRef}
                options={ATD_country_options}
                error={!!errors?.countryCode}
                errorText={errors?.countryCode?.message}
              />
            )}
          />
        </Grid>

        <Grid xs={12} sm={6} item>
          <Controller
            control={control}
            name="interests"
            onFocus={() => interestsRef?.current?.focus()}
            render={({ onChange, onBlur, value }) => (
              <Select
                native
                name="interests"
                label="Products and Service of Interest"
                placeholder="Choose your interests."
                onBlur={onBlur}
                onChange={onChange}
                value={value as string}
                inputRef={interestsRef}
                options={Product_services}
                error={!!errors?.interests}
                errorText={errors?.interests?.message}
              />
            )}
          />
        </Grid>

        <Grid xs={12} sm={6} item>
          <Controller
            control={control}
            onFocus={() => typeCommentsRef?.current?.focus()}
            name="comments"
            render={({ onChange, onBlur, value }) => (
              <InputText
                name="comments"
                label="Comments"
                placeholder="Enter any comments you may have."
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                inputRef={typeCommentsRef}
                error={!!errors?.comments}
                errorText={errors?.comments?.message}
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default LandingPageContactFields
