import React from 'react'
import * as Sentry from '@sentry/browser'
import Error from 'modules/common/components/Errors/Error'

interface ErrorState {
  error: any
  errorInfo: any
}

interface PropsType {
  children?: any
}

const catchError = (
  WrappedComponent: (props: PropsType) => React.ReactElement,
) => {
  // eslint-disable-next-line react/display-name
  return class extends React.Component<any, ErrorState> {
    constructor(props: any) {
      super(props)

      this.state = {
        error: null,
        errorInfo: null,
      }
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
      console.log(error)
      console.log(errorInfo)
      this.setState({ error, errorInfo })
      Sentry.captureException(error)
    }

    render() {
      if (this.state.error) {
        return <Error {...this.state} />
      }

      // return <WrappedComponent {...this.props} />
      return <WrappedComponent {...this.props} />
    }
  }
}

export default catchError
