// @types
import type {
  GetLandingPagesByUrlQuery,
  GetLandingPagesByUrlQueryVariables,
  LandingPages,
} from 'lib/graphql/graphqlTypes'
// @libraries
import React, { useEffect, useState } from 'react'
import DocumentTitle from 'react-document-title'
import { useQuery } from '@apollo/client'
import { useLocation } from 'react-router'
import { GET_LANDING_PAGE_BY_URL } from 'graphql/queries/landing-page'
import client from 'graphql/Apollo/apolloClientContentStack'
import { Helmet } from 'react-helmet'
import * as uuid from 'uuid'
// @components
import SkeletonLoader from '@bit/atd.web.loaders.skeleton'
// @module common components
import PageSection from '../../components/PageSection'
import BlockChooser from '../../components/BlockChooser'
import { RouteComponentProps } from 'react-router-dom'
// @styles
import useStyles from './_styles'

const title = ''
interface RouteParams {
  id: string
}

type Props = RouteComponentProps<RouteParams>

/**
 *
 * @constructor
 */
const FlexTemplate = (props: Props) => {
  /******************************************
   * PROPS
   ******************************************/
  const id = props.match.params.id

  /******************************************
   * STATE
   ******************************************/
  const [hideHeader, setHideHeader] = useState<boolean>(false)
  const [landingPageData, setLandingPageData] = useState<LandingPages | null>(
    null,
  )

  /******************************************
   * HOOKS
   ******************************************/

  const classes = useStyles()

  const { loading: isLoading, error: isError, data } = useQuery<
    GetLandingPagesByUrlQuery,
    GetLandingPagesByUrlQueryVariables
  >(GET_LANDING_PAGE_BY_URL, {
    variables: {
      url: `/landing-page/${id}`,
    },
    client: client,
  })

  useEffect(() => {
    if (!data?.all_landing_pages?.items) {
      return
    }

    if (
      data.all_landing_pages.items.length > 0 &&
      data.all_landing_pages.items[0]
    ) {
      setLandingPageData(data.all_landing_pages.items[0])
    }
  }, [data])

  useEffect(() => {
    if (landingPageData?.hide_site_header) {
      setHideHeader(true)
    }
  }, [landingPageData])

  /******************************************
   * RENDER
   ******************************************/

  if (isLoading) {
    return (
      <PageSection>
        <SkeletonLoader />
      </PageSection>
    )
  }

  return landingPageData ? (
    <DocumentTitle
      title={`${landingPageData.seo_fields?.meta_title || title} | ATD`}
    >
      <>
        <Helmet
          htmlAttributes={{ lang: 'en-us' }}
          title={landingPageData.seo_fields?.meta_title || ''}
          meta={[
            {
              name: 'description',
              content: landingPageData.seo_fields?.meta_description || '',
            },
            {
              name: 'title',
              content: landingPageData.seo_fields?.meta_title || '',
            },
          ]}
        />
        <main className={classes.landingPageRoot}>
          {landingPageData.page_section?.map((item, _index) => {
            let isHeader = false
            if (item && item.section_block?.content?.length === 1) {
              isHeader =
                item.section_block.content[0]?.__typename ===
                  'LandingPagesPageSectionSectionBlockBlockContentTwoColumnHeaderModule' ||
                item.section_block.content[0]?.__typename ===
                  'LandingPagesPageSectionSectionBlockBlockContentHeader'
            }

            return item ? (
              <PageSection
                key={item.section_block?.section_id || uuid.v4()}
                theme={item.section_block?.section_theme}
                title={item.section_block?.section_title}
                isHeader={isHeader}
              >
                <>
                  {item.section_block?.content?.map((subItem, subIndex) => {
                    return subItem ? (
                      <BlockChooser
                        key={
                          `${subIndex}_${item.section_block?.section_id}` ||
                          uuid.v4()
                        }
                        content={subItem}
                      />
                    ) : (
                      <></>
                    )
                  })}
                </>
              </PageSection>
            ) : (
              <></>
            )
          })}
        </main>
      </>
    </DocumentTitle>
  ) : (
    <> </>
  )
}
export default FlexTemplate
