import { useStyles } from '../AccordionWrapper/_styles'
import useShowMore from '../../../common/hooks/useShowMore'
import Grid from '@bit/atd.web.grid'
import CustomCardHit from '../../../search/components/Widgets/CustomCardHit'
import React from 'react'
import { AlgoliaHit } from '../../../common/types'
import Spacer from '@bit/atd.web.spacer'
import Button from '@bit/atd.web.button'
import { showMoreLimit } from '../FeaturedContent'

type CardWrapperPropsType = {
  limit: number
  content: AlgoliaHit[]
}

interface ContentWrapperPropsType extends CardWrapperPropsType {
  viewMoreLink?: {
    title: string
    href: string
  }
}

/**
 *
 * @param content
 * @param limit
 * @constructor
 */
const CardWrapper = ({ content, limit }: CardWrapperPropsType) => {
  /******************************************
   * HOOKS
   ******************************************/

  const classes = useStyles()

  const { visibleItems, showMoreProps, ShowMore } = useShowMore({
    limit: showMoreLimit,
    showMoreLimit: limit,
    showMore: true,
    items: content || [],
  })

  return (
    <>
      <Grid className={classes.cardsContainer} container spacing={3}>
        {visibleItems.map((item, index: number) => {
          return (
            <Grid className={classes.cards} item>
              <CustomCardHit
                key={`${index}_${item.objectID}`}
                hit={item}
                index={{ targetedIndex: index }}
                layout={'featured'}
              />
            </Grid>
          )
        })}

        {showMoreProps.amountShowMoreItems > 0 && (
          <Grid className={classes.showMore} item>
            <ShowMore key="showMore" {...showMoreProps} />
          </Grid>
        )}
      </Grid>
    </>
  )
}

const ContentWrapper = ({
  content,
  limit,
  viewMoreLink,
}: ContentWrapperPropsType) => {
  /******************************************
   * HOOKS
   ******************************************/

  return (
    <>
      <CardWrapper content={content} limit={limit} />
      {viewMoreLink && (
        <>
          <Spacer size={26} />
          <Grid item xs={12}>
            <Button variant="primary" href={viewMoreLink.href}>
              <div>{viewMoreLink.title}</div>
            </Button>
          </Grid>
        </>
      )}
    </>
  )
}
export default ContentWrapper
