// @react
import React, { ReactElement, useState } from 'react'
// @components
import Avy from '@bit/atd.web.avatar'

type PropsType = {
  size?: number
  bgColor?: string
  txtColor?: string
  imgSrc?: string
  imgAlt?: string
  className?: string
  children?: ReactElement | string
}

const Avatar = ({
  size,
  bgColor,
  txtColor,
  imgSrc,
  imgAlt,
  children,
}: PropsType): ReactElement => {
  const [avyBG] = useState(bgColor)

  return (
    <Avy
      size={size || 48}
      src={imgSrc || ''}
      alt={imgAlt}
      bgColor={avyBG}
      color={txtColor}
    >
      {children}
    </Avy>
  )
}

Avatar.defaultProps = {
  size: 48,
  imgSrc: '',
}

export default Avatar
