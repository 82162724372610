// language=GraphQL
import { gql } from '@apollo/client'
import { USER_NODE } from '../fragments/user.fragment'

// @todo go through all getuser instances and define appropriate fragment (fetching all data right now)
export const GET_USER = gql`
  query GetUser {
    user {
      ...UserNode
    }
  }
  ${USER_NODE}
`

export const EMAIL_VALIDATOR = gql`
  query GetEmailValidator($input: EmailValidatorInput!) {
    emailValidator(input: $input) {
      isValid
      message
    }
  }
`

export const COMMUNICATION_PREFERENCES = gql`
  query GetEmailCommunicationPreferences($input: PreferencesInput!) {
    emailCommunicationPreferences(input: $input) {
      automatedNewsletters
      isPostalMailOptedOut
      isPhoneOptedOut
      bimonthlyDigest
      receivePromotionalCommunication
      newsletters {
        newsletterGroup {
          key
          value
          label
          frequency
        }
        newsletterGroupLabel
      }
    }
  }
`

export const HEADER_USER_QUERY = gql`
  query GetUserQuery {
    user {
      id
      profile {
        firstName
        lastName
        profileImageUrl
      }
      membership {
        isMember
        overview {
          label
        }
      }
      customerClassCode
      cart {
        itemTotal
      }
    }
  }
`

export const CONTACT_INFO_QUERY = gql`
  query GetUserContactInfo {
    user {
      contactInfo {
        primaryEmailAddress
      }
    }
  }
`

export const GET_USER_ID = gql`
  query GetUserId {
    user {
      id
    }
  }
`

export const GET_USER_MEMBERSHIP_STATUS = gql`
  query GetUserMembershipStatus {
    user {
      membership {
        isMember
      }
    }
  }
`

export const GET_USER_DINAMIC_VALUES = gql`
  query GetUserDinamicValues {
    user {
      id
      membership {
        isMember
        currentMembership {
          id
          autoRenew
          cycleEndDate
          initialBeginDate
          memberTypeLabel
        }
        futureMembership {
          id
          autoRenew
          cycleEndDate
          memberTypeLabel
        }
        isEnterpriseMember
      }
      isContributor
    }
  }
`

export const GET_USER_TOPICS = gql`
  query GetUserTopics {
    user {
      topics {
        id
        label
        key
        url
        userStatus {
          id
          followed
          recommended
        }
      }
    }
  }
`

export const GET_USER_CAPABILITY_MODEL = gql`
  query GetUserCapabilityModel {
    user {
      capabilityModel {
        isSelfAssessmentStarted
        isSelfAssessmentComplete
        isLearningPathSelected
        isLearningPlanCreated
        isLearningPlanSelected
        isPartialPlan
        isLearningPlanComplete
        planActivitiesCount
        planActivitiesCompletedCount
        isPersonalCapabilityComplete
        isProfessionalCapabilityComplete
        isOrganizationalCapabilityComplete
        learningPathLabel
        domains {
          domainTitle
          totalProgress
          capabilities {
            capTitle
            capTotalScore
            statements {
              desc
              descScore
            }
          }
        }
        totalScore
      }
    }
  }
`

export const GET_USER_PROFILE = gql`
  query GetUserProfile {
    user {
      job {
        userIndustry
        jobTitle
        jobFunctionCode
        jobIndustry
        companyName
        companySize
        trainingBudget
        directReportCount
      }
      profile {
        firstName
        lastName
        profileImageUrl
      }
      certification {
        expiryDate
        earnedDate
        masters
        recertifyByDate
        certificationCode
        certificationStatus
      }
      badge {
        id
        badgeId
        imageUrl
        thumbnailUrl
        createdAt
        issuedAt
        title
        description
        url
        pending
        acceptUrl
      }
    }
  }
`

export const GET_USER_CONTENT = gql`
  query GetUserContentData {
    user {
      isContributor
      visibility {
        publicProfile
        memberDirectoryListing
      }
    }
  }
`

export const GET_USER_AUTO_RENEW_MERCHANT_DETAILS = gql`
  query GetUserAutoRenewLowValueTokenDetails {
    autoRenewLowValueTokenDetails {
      merchantId
      payPageID
    }
  }
`
