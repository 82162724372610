// @react
import React, { Suspense, useState, lazy } from 'react'
import { Route, BrowserRouter as Router } from 'react-router-dom'
import { Switch } from 'react-router-dom'
import modules from 'modules'
// @design
import CssBaseline from '@material-ui/core/CssBaseline'
import { MuiThemeProvider } from '@material-ui/core'
import atdTheme from '@bit/atd.web.theme'
// @common
import DocumentTitle from 'react-document-title'
import whyDidYouRender from '@welldone-software/why-did-you-render'
import History from 'utils/history-component'
import strings from 'lib/constants/strings'
import ScrollToTop from 'modules/common/components/scrollTop'
import catchError from 'modules/common/HOC/catchError'
// @components
import CookieBanner from '@bit/atd.web.banner.cookie-banner'
// import NotFound from 'modules/common/components/Errors/NotFound'
import PageLoader from '@bit/atd.web.loaders.suspense-loader'
// import PrivateRoute from 'modules/auth/components/PrivateRoute'
// import UnauthorizedRoute from 'modules/auth/components/UnauthorizedRoute'
import CurrentUserProvider from 'modules/auth/components/CurrentUserProvider'
import HeaderWrap from 'modules/global/HeaderWrap'
import SnackbarProvider from 'modules/common/components/SnackbarProvider'
// @graphql
import { disableFragmentWarnings } from '@apollo/client/core'
import { ApolloProvider } from '@apollo/client'
import client from 'graphql/Apollo/apolloClient'
import { HeaderSearchContext } from 'modules/common/context/headerSearch'
import FooterWrap from 'modules/global/FooterWrap'
import { RouteNamesExternal } from 'constants/routeNamesExternal'
import GoogleAnalytics, {
  googleAnalyticsInit,
} from 'modules/common/components/GoogleAnalytics'
import Cookies from 'universal-cookie'
import moment from 'moment'

const NotFound = lazy(() => import('modules/common/components/Errors/NotFound'))
const PrivateRoute = lazy(() => import('modules/auth/components/PrivateRoute'))
const UnauthorizedRoute = lazy(
  () => import('modules/auth/components/UnauthorizedRoute'),
)

const cookies = new Cookies()

if (process.env.NODE_ENV === 'development') {
  whyDidYouRender(React)
}
disableFragmentWarnings()
googleAnalyticsInit()

declare global {
  interface Window {
    Intercom: any
  }
}

declare global {
  interface Window {
    Intercom: any
  }
}

const App = (): React.ReactElement => {
  const [isShowSearch, setIsShowSearch] = useState(false)
  const [isAuth, setIsAuth] = useState<boolean | undefined>(undefined)
  const timeZoneCookie = cookies.get('PreferredTimeZone')

  if (!timeZoneCookie || timeZoneCookie === '') {
    const getTimezone = moment.tz.guess()
    cookies.set('PreferredTimeZone', getTimezone, {
      path: '/',
      maxAge: 60 * 60 * 12,
      domain: '.td.org',
    })
  }

  analytics.ready(function () {
    if (typeof window?.Intercom !== 'undefined') {
      setTimeout(function () {
        window?.Intercom('update', {
          hide_default_launcher: true,
        })
      }, 2000)
    }
  })

  analytics.ready(function () {
    if (typeof window?.Intercom !== 'undefined') {
      setTimeout(function () {
        window?.Intercom('update', {
          hide_default_launcher: true,
        })
      }, 2000)
    }
  })

  return (
    <>
      <Router>
        <History />
        <ScrollToTop>
          <ApolloProvider client={client}>
            <DocumentTitle title="ATD">
              <MuiThemeProvider theme={atdTheme}>
                <CssBaseline />
                <SnackbarProvider>
                  <HeaderSearchContext.Provider
                    value={{ isShowSearch, setIsShowSearch }}
                  >
                    {
                      <HeaderWrap
                        isShowSearch={isShowSearch}
                        setIsShowSearch={setIsShowSearch}
                        setIsAuth={setIsAuth}
                      />
                    }
                    <Suspense
                      fallback={
                        <div
                          style={{
                            height: '100vh',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <PageLoader />
                        </div>
                      }
                    >
                      <CurrentUserProvider
                        isAuth={isAuth}
                        setIsAuth={setIsAuth}
                      >
                        <Switch>
                          {modules.routes.map(({ type, ...restProps }) => {
                            const routeProps = {
                              ...restProps,
                              isAuth,
                            }
                            switch (type) {
                              case 'public':
                                return <Route {...routeProps} />
                              case 'private':
                                return <PrivateRoute {...routeProps} />
                              case 'unauthorized':
                                return <UnauthorizedRoute {...routeProps} />
                              default:
                                return <Route {...routeProps} />
                            }
                          })}
                          <Route component={NotFound} />
                        </Switch>
                      </CurrentUserProvider>
                    </Suspense>
                    <FooterWrap />
                  </HeaderSearchContext.Provider>
                </SnackbarProvider>
              </MuiThemeProvider>
            </DocumentTitle>
            <GoogleAnalytics />
          </ApolloProvider>
        </ScrollToTop>
      </Router>
      <CookieBanner
        domain={'.td.org'}
        cookieKey={'OptanonAlertBoxClosed'}
        description={strings.COOKIE_BANNER.DESCRIPTION}
        ctaText={strings.COOKIE_BANNER.CTA}
        externalLink={RouteNamesExternal.ATD_COOKIE_POLICY}
      />
    </>
  )
}

export default catchError(App)
