// @react
import React, { useEffect, useRef, ReactElement } from 'react'
// @types
import { PropsType as SearchPanelProps } from 'modules/global/SearchPanel'
import { CustomerClassCode, MenuItem } from 'lib/graphql/graphqlTypes'
// @common
import clsx from 'clsx'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import colors from '@bit/atd.web.colors'
// @components
import HeaderDataContext from './context'
import DesktopMenu from './Desktop'
import MobileMenu from './Mobile'

const is_header_mode = parseInt(process.env.REACT_APP_HEADER_MODE as string)

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      paddingTop: '0 !important',
      display: 'flex',
      flexDirection: 'column',
      background: 'red',
    },
    inner: {
      width: '100%',
      zIndex: 10,
    },
    isCondensed: {
      // @todo toggle between sticky and static for diff envs
      // position: 'sticky',
      position: () => (is_header_mode ? 'fixed' : 'sticky'),
      width: '100%',
      zIndex: 2,
      top: 0,
      '&::after': {
        height: '1px !important',
      },
    },
    showBorderBtm: {
      '&::after': {
        content: '""',
        position: 'relative',
        display: 'block',
        height: '2px',
        width: '100%',
        backgroundImage: `linear-gradient(90deg, ${colors.brandYellow} 0%, ${colors.brandOrange} 52.98%, ${colors.brandRed} 98.75%)`,
      },
    },
  }),
)
export type SimpleUserData = {
  firstName: string
  lastName: string
  membershipType?: string
  avatarUrl?: string
  bgColor?: string
  textAs?: string
  cartCount?: number
  customerClassCode?: CustomerClassCode
} | null

export type PropsType = {
  classes?: {
    root?: string
    inner?: string
  }
  isNewSearch?: boolean
  condensed?: boolean
  data?: MenuItem[]
  searchPanelProps: SearchPanelProps
  isLoggedIn: boolean
  doLogout: () => void
  onLogin: () => void
  user?: SimpleUserData
  isLoginInProcess?: boolean
  isUserLoading?: boolean
}

/**
 *
 * @param classes
 * @param data
 * @param condensed
 * @param isLoggedIn
 * @param onLogin
 * @param doLogout
 * @param searchPanelProps
 * @param isNewSearch
 * @param user
 * @param isUserLoading
 * @constructor
 */
const Header = ({
  classes,
  data,
  condensed,
  isLoggedIn,
  onLogin,
  doLogout,
  searchPanelProps,
  isNewSearch,
  user,
  isUserLoading,
}: PropsType): ReactElement => {
  /********************************
   * REFS
   ********************************/

  const wrapperEl = useRef<HTMLElement>(null)
  const innerEl = useRef<HTMLDivElement>(null)

  /********************************
   * HOOKS
   ********************************/

  const theme = useTheme()
  const isDesktopView = useMediaQuery(
    theme.breakpoints.up('switchToMobileHeader'),
  )
  const styles = useStyles()

  // set header wrapper padding to push the content below and
  // move overlapping content fully into view
  useEffect(() => {
    setTimeout(() => {
      if (innerEl.current && wrapperEl.current) {
        const innerHeight = innerEl.current.getBoundingClientRect().height
        wrapperEl.current.style.paddingTop = `${innerHeight}px`
      }
    }, 1) // after frame repaint, to get the correct size
  }, []) // run effect only once

  /********************************
   * RENDER
   ********************************/

  return (
    <header
      className={clsx({
        [styles.root]: true,
        [classes?.root || '']: !!classes,
        [styles.isCondensed]: condensed,
        [styles.showBorderBtm]: isDesktopView,
      })}
      ref={wrapperEl}
    >
      <div
        className={clsx(styles.inner, classes && classes.inner)}
        ref={innerEl}
      >
        <HeaderDataContext.Provider
          value={{
            data,
            condensed,
            isLoggedIn,
            doLogout,
            onLogin,
            isNewSearch,
            user,
            isUserLoading,
          }}
        >
          {isDesktopView && <DesktopMenu searchPanelProps={searchPanelProps} />}
          {!isDesktopView && <MobileMenu searchPanelProps={searchPanelProps} />}
        </HeaderDataContext.Provider>
      </div>
    </header>
  )
}

Header.defaultProps = {
  data: [],
  condensed: false,
  isLoggedIn: false,
}

export default Header
