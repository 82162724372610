import React from 'react'
import { Link } from 'react-router-dom'

export interface ErrorProps {
  error: any
  errorInfo: any
}

const Error = ({ error, errorInfo }: ErrorProps) => (
  <div>
    {/* eslint-disable-next-line react/no-unescaped-entities */}
    <h1>We're sorry, but something went wrong.</h1>
    <h3>We apologize for any inconvenience. Our team has been notified.</h3>

    {process.env.NODE_ENV !== 'production' && (
      <p>
        <span>
          {/* eslint-disable-next-line @typescript-eslint/no-unsafe-call */}
          <strong>{error && error.toString()}</strong>
        </span>

        <span>{errorInfo.componentStack}</span>
      </p>
    )}

    <div>
      <Link to="/">Return to Switchboard</Link>
    </div>
  </div>
)

export default Error
