// @types
import type { ReactElement } from 'react'
import type { FeaturedSection, FeaturedSectionBlock } from '../FeaturedContent'
// @libraries
import React from 'react'
// @components
import SkeletonLoader from '@bit/atd.web.loaders.skeleton'
// @module components
import DesktopTabs from './Desktop'

type PropsType = {
  content: FeaturedSectionBlock
  limit: number
}

export interface TabbedItem extends FeaturedSection {
  tabIndex: number
  index: number
}

export type FeaturedTabBlock = {
  title?: string | null
  subTitle?: string | null
  viewMoreLink?: string
  content: TabbedItem[]
}

/**
 *
 * @param content
 * @param limit
 * @constructor
 */
const TabWrapper = ({ content, limit }: PropsType): ReactElement => {
  /******************************************
   * RENDER
   ******************************************/

  if (!content) {
    return <SkeletonLoader />
  }

  /**
   *
   */
  const tabsItems: TabbedItem[] = content.content.map((item, index: number) => {
    return {
      tabIndex: index,
      index: index,
      ...item,
    }
  })

  /**
   *
   */
  const newContent: FeaturedTabBlock = {
    ...content,
    content: tabsItems,
  }

  return <DesktopTabs content={newContent} limit={limit} />
}

export default TabWrapper
