import React, { ReactElement, useState, useRef, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import clsx from 'clsx'
import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Grid from '@bit/atd.web.grid'
import Box from '@bit/atd.web.box'
import Button from '@bit/atd.web.button'
import InputText from '@bit/atd.web.forms.input-text'
import TextLink from '@bit/atd.web.text-link'
import Icons from '@bit/atd.web.icons'
import colors, { ColorsInterface } from '@bit/atd.web.colors'
import FeaturedRow from './FeaturedRow'
import CategorisedRow from './CategorisedRow'
import IntegratedRow from './IntegratedRow'
import useLockBodyScroll from 'modules/common/hooks/useBodyScrollLock'
import Highlighter from 'react-highlight-words'
import { Slide } from '@material-ui/core'
import useKeyPress from 'modules/common/hooks/useKeyPress'
import Helper from 'utils/Helper.class'
import useDebounce from 'modules/common/hooks/useDebounce'
import { TrackingEvents } from '../../../constants/trackingEvents'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      margin: 0,
      overflowY: 'auto',
      [theme.breakpoints.down('xs')]: {
        position: 'absolute',
        background: '#fff',
        height: 'calc(100vh - 72px)',
      },
    },
    hide: {
      display: 'none',
    },
    hover: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
    overlayMaskHidden: {
      '&::before': {
        content: '""',
        position: 'fixed',
        transition: 'opacity 0.5s',
        top: 'initial',
        left: 0,
        width: '100%',
        height: '100%',
        background: colors.black,
        opacity: 0,
        zIndex: 1,
        display: 'block',
        [theme.breakpoints.down('xs')]: {
          display: 'none',
        },
      },
    },
    inputWrapper: {
      background: colors.gray200,
      justifyContent: 'center',
      padding: '30px 0 36px',
      zIndex: 3,
      [theme.breakpoints.down('xs')]: {
        padding: '0',
        order: 1,
      },
    },
    contentWrapper: {
      paddingTop: '30px',
      paddingBottom: '30px',
      margin: '0 auto',
      width: '100%',
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center',
      background: colors.white,
      zIndex: 2,
      overflowY: 'scroll',
      [theme.breakpoints.down('xs')]: {
        order: 3,
        paddingTop: '24px',
        paddingBottom: '24px',
        padding: '0 16px',
      },
    },
    inputPanel: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexBasis: '50%',
      borderBottom: `1px solid ${colors.gray400}`,
      [theme.breakpoints.down('xs')]: {
        flexBasis: '100%',
        background: colors.white,
        borderBottom: 'none',
      },
    },
    searchInputWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flex: 1,
      [theme.breakpoints.down('xs')]: {
        margin: '16px 16px',
        background: colors.gray200,
      },
      '& > button': {
        padding: 0,
        minWidth: 'auto',
        marginRight: '8px',
        [theme.breakpoints.down('xs')]: {
          marginRight: '3px',
        },
      },
    },
    searchBtnWrapper: {
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    searchInput: {
      flex: 1,
      '& input': {
        fontSize: '24px',
        lineHeight: '32px',
        [theme.breakpoints.down('xs')]: {
          padding: '11px',
          fontWeight: 500,
          fontSize: '16px',
          lineHeight: '16px',
        },
      },
      '& fieldset': {
        border: 'none',
      },
    },
    suggestedHints: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.down('xs')]: {
        marginTop: 0,
      },
    },
    suggestedWrapper: {
      textAlign: 'center',
      marginTop: '24px',
      overflowX: 'scroll',
      scrollbarWidth: 'none',
      '-msOverflowStyle': 'none',
      [theme.breakpoints.down('xs')]: {
        marginTop: 0,
        padding: '32px 16px',
      },
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      '& > label': {
        color: colors.ctaSecondary,
        fontWeight: 500,
        fontSize: '18px',
        lineHeight: '24px',
      },
      '& > span': {
        color: colors.ctaSecondary,
        fontWeight: 600,
        fontSize: '18px',
        lineHeight: '24px',
        marginLeft: '32px',
        whiteSpace: 'nowrap',
      },
    },
    hintedText: {
      color: colors.brandOrange,
      fontWeight: 'bold',
    },
    hintsWrapper: {
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
      '& > div': {
        padding: 0,
        '& > span': {
          marginLeft: 0,
        },
      },
    },
    closeSearchBtn: {
      '&:hover, &:active': {
        background: 'transparent',
      },
      [theme.breakpoints.down('xs')]: {
        marginLeft: '3px',
        marginRight: '0 !important',
      },
    },
    leftPanel: {
      paddingLeft: '20px',
      paddingRight: '60px',
      borderRight: `1px solid ${colors.gray400}`,
      maxWidth: '600px',
      zIndex: 1,
      [theme.breakpoints.down('xs')]: {
        padding: 0,
        borderRight: 'none',
      },
    },
    rightPanel: {
      paddingLeft: '60px',
      paddingRight: '20px',
      maxWidth: '600px',
      zIndex: 1,
      [theme.breakpoints.down('xs')]: {
        padding: 0,
        marginTop: '30px',
        marginBottom: '30px',
      },
    },
    sectionBoxWrapper: {
      marginBottom: '32px',
      [theme.breakpoints.down('xs')]: {
        marginBottom: '24px',
      },
      '&:last-child': {
        marginBottom: 0,
      },
      '& > div': {
        padding: 0,
      },
      '& h4': {
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '16px',
        color: colors.black,
        textTransform: 'uppercase',
      },
      '& header': {
        marginBottom: 0,
      },
    },
    categoriesWrapper: {
      marginTop: '20px',
      [theme.breakpoints.down('xs')]: {
        marginTop: 0,
      },
    },
    viewMoreLink: {
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '18px',
      color: colors.ctaSecondary,
      '& svg': {
        marginLeft: '4px',
      },
    },
    integratedResultWrapper: {
      boxShadow: 'none',
      marginTop: '32px !important',
      '&:first-child': {
        marginTop: '0 !important',
      },
      '& > div': {
        padding: 0,
      },
    },
    emptyIntegratedResults: {
      fontWeight: 500,
      fontSize: '18px',
      lineHeight: '26px',
      color: colors.black,
      margin: 0,
    },
    totalHrefWrapper: {
      height: '52px',
      background: colors.white,
      zIndex: 3,
      [theme.breakpoints.down('xs')]: {
        order: 2,
        paddingTop: 0,
      },
    },
    totalHrefBtn: {
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '20px',
      color: colors.white,
      background: colors.ctaPrimary,
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '&:hover': {
        color: colors.white,
      },
      '& > svg': {
        color: colors.white,
        marginLeft: '8px',
      },
    },
    featuredTitle: {
      margin: '0 0 20px',
    },
    histories: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginTop: '20px',
    },
    historyItem: {
      color: colors.ctaPrimary,
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '18px',
      marginTop: '32px',
      cursor: 'pointer',
      '&:first-child': {
        marginTop: 0,
      },
    },
  }),
)

type CategorizedResult = {
  category: string
  viewHref: string
  results?: number
  content: {
    title: string
    imageUrl: string
    url: string
    objectID: string
    indexName: string
    queryID?: string
    searchQuery: string
    position: number
  }[]
}

type IntegratedResult = {
  title: string
  url: string
  description: string
  categoryIcon: string
  categoryText: string
  createdInfo: string
  objectID: string
  queryID?: string
  position: number
  indexName: string
  searchQuery: string
}

export type FeatureType = {
  imageUrl: string
  title: string
  url: string
}

export type PropsType = {
  /** className */
  className?: string
  /** Recent search histories */
  recentHistories: string[]
  /** React Elements array */
  featuredToday: FeatureType[]
  /** Suggested hints */
  suggestedHints: string[]
  /** Categorized results */
  categorizedResults: CategorizedResult[]
  /** React Elements array */
  integratedResults: IntegratedResult[]
  /** Main search page link */
  mainSearchHref: string
  /** Total results */
  totalResults: number
  /** Search key */
  searchKey: string
  /** Display mask overlay */
  showOverlay?: boolean
  /** Callback fired when the search key is changed. */
  onChange: (content: string) => void
  /** Callback fired when the search is opened or closed */
  setIsShowSearch: (isShowSearch: boolean) => void
  isShowSearch: boolean
}

/**
 *
 * @param className
 * @param recentHistories
 * @param featuredToday
 * @param suggestedHints
 * @param categorizedResults
 * @param integratedResults
 * @param mainSearchHref
 * @param totalResults
 * @param searchKey
 * @param showOverlay
 * @param onChange
 * @param setIsShowSearch
 * @param isShowSearch
 *
 */
const SearchPanel = ({
  className,
  recentHistories,
  featuredToday,
  suggestedHints,
  categorizedResults,
  integratedResults,
  mainSearchHref,
  totalResults,
  searchKey,
  showOverlay,
  onChange,
  setIsShowSearch,
  isShowSearch,
}: PropsType): ReactElement => {
  const classes = useStyles({ showOverlay })
  const theme = useTheme()
  const history = useHistory()
  const mobile = useMediaQuery(theme.breakpoints.down('xs'))
  const [showResults, setShowResults] = useState<boolean>(true)
  const [hidden] = useState<boolean>(false)

  /*************************
   * HOOKS
   **************************/

  useLockBodyScroll(isShowSearch)
  useKeyPress('Escape') && setIsShowSearch(false)
  const searchKeyAnalytics = useDebounce(searchKey, 900)

  // send tracking event to segment based on search keyword
  useEffect(() => {
    if (searchKeyAnalytics !== '') {
      analytics.track(TrackingEvents.PRODUCTS_SEARCHED, {
        query: searchKeyAnalytics,
      })
    }
  }, [searchKeyAnalytics])

  /*************************
   * FUNCTIONS
   **************************/
  const updateHistorySearch = (
    newSearch: string,
    searchHistory: string[],
  ): string[] => {
    const index = searchHistory.indexOf(newSearch)
    if (index === 0) return searchHistory
    if (index > 0) {
      searchHistory.splice(index, 1)
      return [newSearch, ...searchHistory]
    }
    return [searchKey, ...searchHistory.slice(-9)]
  }

  /*************************
   * HANDLERS
   **************************/
  const onChangeSearchKey = (value: string) => {
    onChange && onChange(value)
  }

  const onCloseSearch = () => {
    setShowResults(false)
  }

  const onSearch = () => {
    onCloseSearch()
    setIsShowSearch && setIsShowSearch(false)
    if (searchKey.length > 0) {
      const searchHistory = JSON.parse(
        localStorage.getItem('searchHistory') || '[]',
      )
      const updatedHistory = Array.isArray(searchHistory)
        ? updateHistorySearch(searchKey, searchHistory)
        : [searchKey]
      localStorage.setItem('searchHistory', JSON.stringify(updatedHistory))
    }
    Helper.pushPath(mainSearchHref, history)
  }

  /******************************************
   * REFS
   ******************************************/

  const searchRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    searchRef?.current?.focus()
  }, [searchRef.current])

  /*************************
   * RENDER
   **************************/
  const renderResults = (
    categories: CategorizedResult[],
    integrates: IntegratedResult[],
  ) => {
    return (
      <>
        {/* Categorized results */}
        <Grid item xs={12} sm={6} className={classes.leftPanel}>
          {categories.map(
            (
              { category, viewHref, content, results }: CategorizedResult,
              index: number,
            ) => {
              return (
                <Box
                  key={`${index}_${category}`}
                  classNames={{ root: classes.sectionBoxWrapper }}
                  variant="transparent"
                  header={{
                    title: category,
                    actions: (
                      <>
                        {content && content.length > 0 ? (
                          results && (
                            <TextLink
                              className={classes.viewMoreLink}
                              onClick={() => setIsShowSearch(false)}
                              href={viewHref}
                              variant="secondary"
                              icon={'arrow-right'}
                              iconColor={
                                colors.ctaPrimary as ColorsInterface['color']
                              }
                            >
                              View {results} Results
                            </TextLink>
                          )
                        ) : (
                          <span>No Results</span>
                        )}
                      </>
                    ),
                    actionsAlignment: 'right',
                  }}
                >
                  <div className={classes.categoriesWrapper}>
                    {content?.map((contentProps) => (
                      <CategorisedRow
                        category={category}
                        key={contentProps.objectID}
                        {...contentProps}
                      />
                    ))}
                  </div>
                </Box>
              )
            },
          )}
        </Grid>

        {/* Integrated results */}
        <Grid item xs={12} sm={6} className={classes.rightPanel}>
          {integrates.length > 0 ? (
            integrates.map((result, index) => {
              return (
                <Box
                  key={`integrated_${index}`}
                  variant="transparent"
                  classNames={{ root: classes.integratedResultWrapper }}
                >
                  <IntegratedRow {...result} />
                </Box>
              )
            })
          ) : (
            <p className={classes.emptyIntegratedResults}>
              There are no search results that match “{searchKey}”<br />
              Please try a new search.
            </p>
          )}
        </Grid>
      </>
    )
  }

  const renderHistories = (
    recent: string[],
    suggested: string[],
    featured: FeatureType[],
  ) => {
    return (
      <>
        {/* Recent searches */}
        <Grid item xs={12} sm={6} className={classes.leftPanel}>
          <Box
            classNames={{ root: classes.sectionBoxWrapper }}
            key={0}
            variant="transparent"
            header={{
              title: 'RECENT SEARCHES',
            }}
          >
            <div className={classes.histories}>
              {recent.map((recentSearch, index) => {
                return (
                  <span
                    key={index}
                    className={classes.historyItem}
                    onClick={() => onChangeSearchKey(recentSearch)}
                  >
                    {recentSearch}
                  </span>
                )
              })}
            </div>
          </Box>
          <Box
            classNames={{ root: classes.sectionBoxWrapper }}
            variant="transparent"
            header={{ title: 'SUGGESTED SEARCHES' }}
          >
            <div className={classes.histories}>
              {suggested.map((suggestedSearch, index) => {
                return (
                  <span
                    key={`suggested_${index}`}
                    className={classes.historyItem}
                    onClick={() => onChangeSearchKey(suggestedSearch)}
                  >
                    {suggestedSearch}
                  </span>
                )
              })}
            </div>
          </Box>
        </Grid>

        {/* Suggested searches */}
        <Grid item xs={12} sm={6} className={classes.rightPanel}>
          <Box
            variant="transparent"
            classNames={{ body: classes.sectionBoxWrapper }}
          >
            <h4 className={classes.featuredTitle}>featured today</h4>
            {featured.map((featuredProps) => (
              <FeaturedRow key={featuredProps.title} {...featuredProps} />
            ))}
          </Box>
        </Grid>
      </>
    )
  }

  const res = (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.overlayMaskHidden]: true,
        [classes.hide]: hidden,
        [className || '']: className,
      })}
    >
      <Grid container className={classes.inputWrapper}>
        <Grid item xs={12} className={classes.inputPanel}>
          <div className={classes.searchInputWrapper}>
            <InputText
              className={classes.searchInput}
              value={searchKey}
              placeholder="Your search query..."
              inputRef={searchRef}
              onFocus={() => searchRef?.current?.focus()}
              onChange={(event) => onChangeSearchKey(event.target.value)}
              onKeyUp={(event) => {
                event.preventDefault()
                if (event.key === 'Enter') {
                  onSearch()
                }
              }}
            />
            {searchKey && (
              <Button variant="tertiary" onClick={() => onChangeSearchKey('')}>
                <Icons glyph="close" size={16} color={colors.gray800} />
              </Button>
            )}
          </div>
          <div className={classes.searchBtnWrapper}>
            <Button onClick={onSearch}>Search</Button>
          </div>
        </Grid>
        <Grid item xs={12} className={classes.suggestedHints}>
          <Box
            classNames={{ root: classes.hintsWrapper }}
            variant="transparent"
            topBorderWidth={mobile ? 2 : 0}
          >
            {suggestedHints.length > 0 && searchKey && (
              <div className={classes.suggestedWrapper}>
                <label>Suggested:</label>
                {suggestedHints.map((hint, index) => {
                  return (
                    <Highlighter
                      className={classes.hover}
                      onClick={() => {
                        onChangeSearchKey(hint)
                      }}
                      key={`${hint}_${index}`}
                      highlightClassName={classes.hintedText}
                      searchWords={[searchKey]}
                      autoEscape={false}
                      highlightTag={'span'}
                      textToHighlight={hint}
                    />
                  )
                })}
              </div>
            )}
          </Box>
        </Grid>
      </Grid>
      {showResults && (
        <Grid container className={classes.contentWrapper}>
          {searchKey
            ? renderResults(categorizedResults, integratedResults)
            : renderHistories(recentHistories, suggestedHints, featuredToday)}
        </Grid>
      )}
      {searchKey && totalResults > 0 && (
        <Grid className={classes.totalHrefWrapper}>
          <TextLink
            className={classes.totalHrefBtn}
            href={'#'}
            onClick={onSearch}
            variant="secondary"
            icon={'arrow-right'}
            iconColor={colors.ctaPrimary as ColorsInterface['color']}
          >
            View All Results
          </TextLink>
        </Grid>
      )}
    </div>
  )
  return (
    <Slide
      direction={'down'}
      in={isShowSearch}
      timeout={350}
      mountOnEnter
      unmountOnExit
    >
      {res}
    </Slide>
  )
  //return res
}

SearchPanel.defaultProps = {
  recentHistories: [],
  featuredToday: [],
  suggestedHints: [],
  categorizedResults: [],
  integratedResults: [],
  mainSearchHref: '',
  showOverlay: false,
}

export default SearchPanel
