// @react
import React, { ReactElement } from 'react'
// @libraries
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
// @components
import colors from '@bit/atd.web.colors'
import Button from '@bit/atd.web.button'
import GroupTitle from '@bit/atd.web.header-group-title'
import Link from '@bit/atd.web.header-link'
import { MenuItem } from 'lib/graphql/graphqlTypes'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignContent: 'stretch',
    width: '100%',
    margin: 'auto',
    '& ul': {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  menuColumns: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignContent: 'stretch',
    width: 220,
    '&:not(:first-child)': {
      paddingLeft: 32,
    },
  },
  group: {
    marginBottom: 32,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  linkWrap: {
    marginBottom: '16px',
    '&:last-child': {
      marginBottom: 0,
    },
    '& a': {
      position: 'relative',
    },
  },
  descriptionText: {
    color: colors.black,
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '15px',
    marginBottom: 4,
  },
  superFlair: {
    color: colors.black,
    fontWeight: 600,
    fontSize: '10px',
    textTransform: 'uppercase',
    marginTop: '-2px',
    marginLeft: '2px',
    position: 'absolute',
  },
  isConferenceLayout: {
    flexWrap: 'wrap',
    '& $menuColumns': {
      flexGrow: 0,
    },
    '& $menuColumns:last-child': {
      paddingLeft: '0 !important',
      paddingTop: 32,
      maxWidth: '100%',
      flexBasis: '100%',
    },
  },
  isTopicLayout: {
    display: 'block',
    columns: 3,
    columnGap: '32px',
    '& $menuColumns': {
      display: 'block',
      width: '100%',
    },
    '& $group:last-child': {
      marginTop: 32,
    },
  },
  isCondensed: {
    '& $menuColumns': {
      width: 190,
      '&:not(:first-child)': {
        paddingLeft: 24,
      },
    },
  },
})

type PropsType = {
  data?: MenuItem[] | null
  activeTitle: string
  isCondensed?: boolean
}

/**
 *
 * @param linkItem
 * @param key
 * @param classes
 * @constructor
 */
const RenderLink = (linkItem: MenuItem, key: number, classes: any) => {
  switch (linkItem.type) {
    case 'MEDIA':
      return <></>
    case 'BUTTON':
      return (
        <li key={key} className={classes.linkWrap}>
          <Button
            href={linkItem.url || undefined}
            variant="secondary"
            size="small"
          >
            {linkItem.title || ''}
          </Button>
        </li>
      )
    case 'GROUP':
      const groupLinks =
        linkItem.children &&
        linkItem.children.map((link, idy) => {
          return RenderLink(link, idy, classes)
        })

      return (
        <div key={key} className={classes.group}>
          {linkItem.title && <GroupTitle>{linkItem.title}</GroupTitle>}
          <ul>{groupLinks}</ul>
        </div>
      )
    case 'DEFAULT':
    default:
      return (
        <li key={key} className={classes.linkWrap}>
          {linkItem.description && (
            <div className={classes.descriptionText}>
              {linkItem.description}
            </div>
          )}
          <Link href={linkItem.url || ''}>
            <>
              {linkItem.title}
              {linkItem.flair && (
                <>
                  {' '}
                  <span className={classes.superFlair}>{linkItem.flair}</span>
                </>
              )}
            </>
          </Link>
        </li>
      )
  }
}

const SecondaryLinks = ({
  data,
  activeTitle,
  isCondensed,
}: PropsType): ReactElement => {
  /********************************
   * HOOKS
   ********************************/

  const classes = useStyles({ activeTitle })

  /********************************
   * RENDER
   ********************************/

  // First child item is actually a Header menu item / type = DEFAULT
  // so we skip it?
  const items = data?.slice(1)
  const renderedLinks = items?.map((item, idx) => {
    return RenderLink(item, idx, classes)
  })

  const renderColumns = () => {
    const col = []
    let columnCount = 2

    switch (activeTitle) {
      case 'Topics':
        columnCount = 1
        break
    }

    for (let i = 0; i < columnCount; i++) {
      col.push([])
    }

    // if certain menu item...
    switch (activeTitle) {
      case 'Topics':
        return renderedLinks?.reduce((p, c) => {
          p[0].push(c as never)
          return p
        }, col)
      default:
        return renderedLinks?.reduce((p, c, i) => {
          p[i <= (renderedLinks.length - 2) / 2 ? 0 : 1].push(c as never) // in order
          return p
        }, col)
    }
  }

  return (
    <div
      className={clsx(classes.root, {
        [classes.isTopicLayout]: activeTitle === 'Topics',
        [classes.isCondensed]: isCondensed,
      })}
    >
      <>
        {renderColumns()?.map((col, i) => {
          return (
            <div className={classes.menuColumns} key={i}>
              {col.map((child, i) => {
                return <React.Fragment key={i}>{child}</React.Fragment>
              })}
            </div>
          )
        })}
      </>
    </div>
  )
}

export default SecondaryLinks
