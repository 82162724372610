import type { Theme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'
import colors from '@bit/atd.web.colors'

export const useStyles = makeStyles((theme: Theme) => ({
  boxBody: {
    '& > div': {
      paddingBottom: '0 !important', // @todo fix this component level
    },
  },
  accordionRoot: {
    padding: 0,
    '& > div:first-child': {
      borderTop: 'none',
    },
    '& > div': {
      borderWidth: '1px',
    },
    '& .MuiAccordion-root > .MuiButtonBase-root': {
      minHeight: '64px',
      paddingLeft: '24px',
      paddingRight: '24px',
    },
    '& .MuiAccordionDetails-root': {
      borderTop: '1px solid #dbd9d6',
      padding: 0,
    },
    '& .MuiAccordionSummary-content > h3': {
      fontSize: '18px',
      fontWeight: 600,
      lineHeight: '24px',
    },
    '& .MuiAccordionSummary-content > .MuiSvgIcon-root': {
      height: '24px',
      width: '24px',
    },
    '& .slick-slide > div > div > div': {
      height: '100%',
    },
  },
  accordionBody: {
    paddingTop: '24px',
    paddingLeft: '24px',
    paddingRight: '24px',
    paddingBottom: '24px',
  },
  accordionSubTitle: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#424140',
    width: '100%',
    marginBottom: '24px',
    [theme.breakpoints.up('sm')]: {
      paddingTop: '8px',
      paddingLeft: '0',
      marginBottom: '24px',
    },
    '& > *': {
      margin: 0,
    },
    '& > * ~ *': {
      marginTop: '.5rem',
    },
    '& p a': {
      color: colors.ctaPrimary,
      textDecoration: 'underline',
      textUnderlineOffset: '4px',
      '&:hover': {
        color: '#EA6E4D',
      },
      '&:visited': {
        color: '#CB2B00',
      },
    },
  },
  cardsContainer: {},
  cards: {
    width: '100%',
  },
  showMore: {
    textAlign: 'center',
    width: '100%',
    margin: '0 0 24px',
  },
  isCarousel: {
    paddingRight: '0',
    '& $accordionSubTitle': {
      paddingRight: '24px',
    },
  },
}))
