// @react
import React, { ReactElement } from 'react'
import { makeStyles } from '@material-ui/core/styles'
// @common
import colors from '@bit/atd.web.colors'
import dompurify from 'dompurify'
// @components
import Icons, { PropsType as IconProps } from '@bit/atd.web.icons'
// @module components
import parse from 'html-react-parser'
import TextLink from '@bit/atd.web.text-link'
import useSegmentAlgoliaApi from 'modules/common/hooks/useSegmentAlgoliaApi'
import Icon from '@bit/atd.web.icons'

const useStyles = makeStyles({
  textHighlighted: {
    '& em': {
      fontWeight: 600,
      color: colors.ctaPrimary,
      background: 'transparent',
    },
  },
  integratedTitle: {
    color: colors.ctaSecondaryDarker,
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '18px',
    cursor: 'pointer',
  },
  integratedDescription: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    color: colors.textActive,
    marginTop: '11px',
    marginBottom: 0,
  },
  integratedDetails: {
    marginTop: '11px',
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      color: colors.ctaSecondaryDarker,
      width: '16px',
      height: '16px',
    },
    '& > span': {
      fontWeight: 500,
      fontSize: '11px',
      lineHeight: '16px',
      color: colors.ctaSecondaryDarker,
      textTransform: 'uppercase',
      marginLeft: '9px',
      '& b': {
        marginRight: '5px',
      },
    },
  },
  newTab: {
    display: 'flex',
    justifyContent: 'space-between',
  },
})

type PropTypes = {
  title: string
  url: string
  description: string
  categoryIcon: string
  categoryText: string
  createdInfo: string
  queryID?: string
  position: number
  indexName: string
  searchQuery: string
  objectID: string
}

const IntegratedRow = ({
  title,
  description,
  categoryIcon,
  categoryText,
  createdInfo,
  url,
  queryID,
  indexName,
  position,
  searchQuery,
  objectID,
}: PropTypes): ReactElement => {
  /********************************
   * HOOKS
   *********************************/
  const classes = useStyles()

  const segmentAnalytics = useSegmentAlgoliaApi(
    objectID,
    title,
    indexName,
    queryID,
    position,
    searchQuery,
    url,
    false,
    'quick-search-integrated-row',
  )

  const segmentAnalyticsNewTab = useSegmentAlgoliaApi(
    objectID,
    title,
    indexName,
    queryID,
    position,
    searchQuery,
    url,
    true,
    'quick-search-integrated-row',
  )
  /********************************
   * RENDER
   *********************************/
  return (
    <div>
      <div className={classes.newTab}>
        <TextLink
          className={classes.integratedTitle}
          onClick={segmentAnalytics}
        >
          {parse(
            dompurify.sanitize(
              `<span class="${classes.textHighlighted}">${title}</span>`,
            ),
          )}
        </TextLink>
        <span onClick={segmentAnalyticsNewTab}>
          <Icon size={20} glyph="newtab" />
        </span>
      </div>

      {description && (
        <p className={classes.integratedDescription}>
          {parse(
            dompurify.sanitize(
              `<span class="${classes.textHighlighted}">${description}</span>`,
            ),
          )}
        </p>
      )}
      <div className={classes.integratedDetails}>
        <Icons
          size={14}
          glyph={categoryIcon as IconProps['glyph']}
          color={colors.gray200}
        />
        <>
          <span>{categoryText}</span> <span> {createdInfo}</span>
        </>
      </div>
    </div>
  )
}

export default IntegratedRow
