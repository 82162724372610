import { RouteNamesInternal } from 'constants/routeNamesInternal'

const REACT_APP_BASE_DOMAIN = process.env.REACT_APP_BASE_DOMAIN as string

/**
 *
 */
export const STATIC_ROUTES = [
  {
    key: 'ONBOARDING',
    path: `${REACT_APP_BASE_DOMAIN}/${RouteNamesInternal.ONBOARDING}`,
  },
  {
    key: 'MYATD_DASHBOARD',
    path: `${REACT_APP_BASE_DOMAIN}/${RouteNamesInternal.MYATD}/${RouteNamesInternal.DASHBOARD}`,
  },
  { key: 'home', path: REACT_APP_BASE_DOMAIN },
  {
    key: 'MYATD_ACTIVITYHISTORY_LEARNING',
    path: `${REACT_APP_BASE_DOMAIN}/${RouteNamesInternal.MYATD}/${RouteNamesInternal.ACTIVITY_HISTORY}/${RouteNamesInternal.LEARNING}`,
  },
  {
    key: 'MYATD_BENEFITS_CENTER',
    path: `${REACT_APP_BASE_DOMAIN}/${RouteNamesInternal.MYATD}/${RouteNamesInternal.BENEFITS_CENTER}`,
  },
  {
    key: 'search',
    path: `${REACT_APP_BASE_DOMAIN}/${RouteNamesInternal.SEARCH}`,
  },
  {
    key: 'PROFILE_PERSONALINFORMATION',
    path: `${REACT_APP_BASE_DOMAIN}/${RouteNamesInternal.PROFILE}/${RouteNamesInternal.PERSONAL_INFORMATION}`,
  },
  {
    key: 'PROFILE_ORDERS',
    path: `${REACT_APP_BASE_DOMAIN}/${RouteNamesInternal.PROFILE}/${RouteNamesInternal.ORDERS}`,
  },
  {
    key: 'MYATD_ACTIVITYHISTORY_BOOKMARKS',
    path: `${REACT_APP_BASE_DOMAIN}/${RouteNamesInternal.MYATD}/${RouteNamesInternal.ACTIVITY_HISTORY}/${RouteNamesInternal.BOOKMARKS}`,
  },
]
