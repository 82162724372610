import { lazy } from 'react'
// import Learning from './pages/Learning'
// import Bookmarks from './pages/Bookmarks'
// import NetworkActivity from './pages/NetworkActivity'
// import UserContent from './pages/UserContent'
import { RouteNamesInternal } from 'constants/routeNamesInternal'
import { Route } from '../../constants/types'

const Learning = lazy(() => import('./pages/Learning'))
const Bookmarks = lazy(() => import('./pages/Bookmarks'))
const NetworkActivity = lazy(() => import('./pages/NetworkActivity'))
const UserContent = lazy(() => import('./pages/UserContent'))

export const routes: Route[] = [
  {
    key: 'MYATD_ACTIVITYHISTORY_LEARNING',
    type: 'private',
    title: 'Learning',
    path: `/${RouteNamesInternal.MYATD}/${RouteNamesInternal.ACTIVITY_HISTORY}/${RouteNamesInternal.LEARNING}`,
    component: Learning,
    exact: true,
  },
  {
    key: 'MYATD_ACTIVITYHISTORY_BOOKMARKS',
    type: 'private',
    title: 'Bookmarks',
    path: `/${RouteNamesInternal.MYATD}/${RouteNamesInternal.ACTIVITY_HISTORY}/${RouteNamesInternal.BOOKMARKS}`,
    component: Bookmarks,
    exact: true,
  },
  {
    key: 'MYATD_ACTIVITYHISTORY_NETWORKACTIVITY',
    type: 'private',
    title: 'Network Activity',
    path: `/${RouteNamesInternal.MYATD}/${RouteNamesInternal.ACTIVITY_HISTORY}/${RouteNamesInternal.NETWORK_ACTIVITY}`,
    component: NetworkActivity,
    exact: true,
  },
  {
    key: 'MYATD_ACTIVITYHISTORY_USERCONTENT',
    type: 'private',
    title: 'Contributions',
    path: `/${RouteNamesInternal.MYATD}/${RouteNamesInternal.ACTIVITY_HISTORY}/${RouteNamesInternal.USER_CONTENT}`,
    component: UserContent,
    exact: true,
    isHidden: (user: {
      isContributor: boolean
      visibility: {
        publicProfile: boolean
      }
    }) => {
      return !user?.visibility?.publicProfile || !user?.isContributor
    },
  },
]
const exportedRoutes = { routes }
export default exportedRoutes
