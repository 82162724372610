// @react
import React, { useState, useCallback, ReactElement, useEffect } from 'react'
// @common
import config from 'constants/config'
import { doLogout } from 'modules/auth/helpers'
import useDebounce from 'modules/common/hooks/useDebounce'
import algoliasearch from 'algoliasearch'
import { matchPath, useLocation } from 'react-router-dom'
import Helper from 'utils/Helper.class'
// @components
import { Waypoint } from 'react-waypoint'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import icons from '@bit/atd.web.icons/dist/getIcons'
import { SearchResponse, Hit } from '@algolia/client-search'
import { PropsType as IconProps } from '@bit/atd.web.icons'
import Header, { SimpleUserData } from 'modules/global/Header'
import TextLink from '@bit/atd.web.text-link'
import TopBanner from '../TopBanner'
import { ByLine } from '__COMPONENTS_TO_MIGRATE/CardNew'
// @graphql
import { useQuery } from '@apollo/client'
import { HEADER_USER_QUERY } from 'graphql/queries/user.query'
import {
  GetFeaturedContentQuery,
  GetFeaturedContentQueryVariables,
  GetMenuQuery,
  GetMenuQueryVariables,
  GetUserQuery,
  GetUserQueryVariables,
  UserSearchItem,
} from 'lib/graphql/graphqlTypes'
import { GET_MENU } from 'graphql/queries/menu.query'
import { GET_FEATURED_CONTENT_HEADER } from 'graphql/queries/featuredContent.query'

const client = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID as string,
  process.env.REACT_APP_ALGOLIA_SECRET as string,
)

type PropsType = {
  isShowSearch: boolean
  setIsShowSearch: (isShowSearch: boolean) => void
  setIsAuth: (value: boolean) => void
}

type MappedCourses = {
  results?: number
  hits?: Hit<any>[]
}

type CategorizedResult = {
  category: string
  viewHref: string
  results?: number
  content: {
    title: string
    imageUrl: string
    objectID: string
    url: string
    indexName: string
    queryID?: string
    searchQuery: string
    position: number
  }[]
}

const searchHref = Helper.getPathFromRouteKey('search')

/**
 *
 * @param category
 */
const getIconForCategory = (category: string) =>
  icons[category as IconProps['glyph']] ? category : 'articles'

/**
 *
 * @param authorsContent
 * @param publishDate
 */
const authorFormatter = (
  authorsContent: UserSearchItem[],
  publishDate: number,
) => {
  const byLine: ByLine = {}

  const authors = authorsContent?.map((author: UserSearchItem) => {
    const auth = author as UserSearchItem

    const fullName = auth?.fullName
    const firstName = auth?.firstName
    const lastName = auth?.lastName

    return {
      author:
        fullName ||
        (firstName && lastName && `${firstName} ${lastName}`) ||
        (firstName && firstName) ||
        (lastName && lastName) ||
        '',
    }
  })
  const pubDate = Helper.smallDateFormatter(publishDate)

  authors && (byLine.authors = authors)
  pubDate && (byLine.pubDate = pubDate)

  return byline(byLine)
}

/**
 *
 * @param byLine
 */
const byline = (byLine: ByLine) => {
  const authorsCount = (byLine.authors && byLine.authors.length) || 0

  return (
    <p>
      {!!byLine?.authors && !!byLine?.authors.length && (
        <>
          <span>By </span>
          {byLine?.authors?.map((author, index: number) => (
            <React.Fragment key={`${index}_${author.author}`}>
              {author.url && (
                <TextLink variant={'secondary'} href={author.url}>
                  {author}
                </TextLink>
              )}
              {!author.url && <span>{author.author}</span>}
              {index < authorsCount - 2 && ', '}
              {index === authorsCount - 2 && ' & '}
            </React.Fragment>
          ))}
        </>
      )}
      {byLine?.authors && byLine?.pubDate && ' '}
      {byLine?.pubDate &&
        `${!!byLine?.authors?.length ? 'on' : 'Published on'} ${
          byLine?.pubDate
        }`}
    </p>
  )
}

const defaultTopOffset = -125
const HeaderWrap = ({
  isShowSearch,
  setIsShowSearch,
  setIsAuth,
}: PropsType): ReactElement => {
  /**
   *
   */
  const searchHistory = localStorage.getItem('searchHistory')

  /******************************
   * STATE
   *******************************/
  const [sticky, setSticky] = useState(false)
  const [searchKey, setSearchKey] = useState('')
  const [courses, setCourses] = useState<CategorizedResult>()
  const [publications, setPublications] = useState<CategorizedResult>()
  const [conferences, setConferences] = useState<CategorizedResult>()
  const [suggestedHints, setSuggestedHints] = useState<string[]>([])
  const [content, setContent] = useState<MappedCourses>({})
  const [featuredToday, setFeaturedToday] = useState<any[]>([])
  const [topOffset, setTopOffset] = useState(defaultTopOffset)

  /********************************************
   *  HOOKS
   *********************************************/
  const searchText = useDebounce(searchKey)
  const theme = useTheme()
  const location = useLocation()
  const collapseHeaderMQ = useMediaQuery(
    theme.breakpoints.between('switchToMobileHeader', 'sm'),
    config.mediaQuery,
  )
  const isOnboarding = !!matchPath(location.pathname, {
    path: Helper.getPathFromRouteKey('ONBOARDING'),
    exact: false,
    strict: false,
  })

  /********************************************
   *  GQL HOOKS
   *********************************************/
  const { data: userData, loading } = useQuery<
    GetUserQuery,
    GetUserQueryVariables
  >(HEADER_USER_QUERY, {
    fetchPolicy: 'cache-first',
  })

  const { data: menuData } = useQuery<GetMenuQuery, GetMenuQueryVariables>(
    GET_MENU,
    {
      skip: isOnboarding,
    },
  )

  const { data: featuredData } = useQuery<
    GetFeaturedContentQuery,
    GetFeaturedContentQueryVariables
  >(GET_FEATURED_CONTENT_HEADER, {
    variables: {
      input: {
        page: 0,
        pageSize: 10,
      },
    },
  })

  const algoliaAnalytics = {
    clickAnalytics: true,
    enablePersonalization: true,
    userToken: userData?.user?.id,
  }

  useEffect(() => {
    if (isShowSearch) {
      /**
       * init course index
       */
      const courseIndex = client.initIndex('courses')
      courseIndex
        .search(searchText, algoliaAnalytics)
        .then((res) => setCourses(mapSearchResponse(res, 'courses')))
        .catch((er) => console.log(er))

      /**
       * init publications index
       */
      const publicationsIndex = client.initIndex('publications')
      publicationsIndex
        .search(searchText, algoliaAnalytics)
        .then((res) => setPublications(mapSearchResponse(res, 'publications')))
        .catch((er) => console.log(er))

      /**
       * init conferences index
       */
      const conferencesIndex = client.initIndex('conferences')
      conferencesIndex
        .search(searchText, algoliaAnalytics)
        .then((res) => setConferences(mapSearchResponse(res, 'conferences')))
        .catch((er) => console.log(er))

      /**
       * init suggestions index
       */
      const suggestionsIndex = client.initIndex('master_query_suggestions')
      suggestionsIndex
        .search(searchText, algoliaAnalytics)
        .then(({ hits }) =>
          setSuggestedHints(hits.slice(-5).map(({ query }: Hit<any>) => query)),
        )
        .catch((er) => console.log(er))

      /**
       * init content index
       */
      const contentIndex = client.initIndex('content')
      contentIndex
        .search(searchText, { ...algoliaAnalytics, hitsPerPage: 5 })
        .then((res) => setContent(mapContent(res, 'content')))
        .catch((er) => console.log(er))

      /**
       * init featuredToday Index
       *
       * // @todo does this need segment search GET parameters as well?
       *
       */
      const featuredTodayIndex = client.initIndex('master')
      featuredTodayIndex
        .search(searchText, algoliaAnalytics)
        .then(() => setFeaturedToday(featuredData?.featuredContent?.data || []))
        .catch((er) => console.log(er))
    }
  }, [searchText, isShowSearch])

  /**
   *
   */
  const onWaypointPositionChange = useCallback((current: any) => {
    const isAbove = current.currentPosition === 'above'
    setSticky(isAbove)
    setTopOffset(isAbove ? 0 : defaultTopOffset)
  }, [])

  /********************************************
   *  FUNCTIONS
   *********************************************/

  /**
   *
   * @param res
   * @param index
   */
  const mapSearchResponse = (
    res: SearchResponse<unknown>,
    indexName: string,
  ) => {
    const { hits, nbHits, queryID } = res
    const searchQuery = searchText
    return {
      category: indexName,
      content: hits
        .slice(0, 2)
        .map(
          (
            { _highlightResult, imageUrl, url, objectID }: Hit<any>,
            index: number,
          ) => ({
            title: _highlightResult.title.value,
            imageUrl,
            url: Helper.composeParametrizedUrl({
              url,
            }),
            objectID,
            queryID,
            indexName,
            searchQuery,
            position: index + 1,
          }),
        ),
      viewHref: `${searchHref}/${encodeURIComponent(
        indexName,
      )}?query=${encodeURIComponent(searchText)}`,
      results: nbHits,
    }
  }

  /**
   *
   * @param res
   * @param indexName
   */
  const mapContent = (res: SearchResponse<unknown>, indexName = 'ys') => {
    const { hits, nbHits, queryID } = res
    const searchQuery = searchText
    return {
      hits: hits.map(
        (
          {
            url,
            contentTypesArray,
            publishDate,
            authors = [],
            _snippetResult: { title, description },
            objectID,
          }: Hit<any>,
          index: number,
        ) => {
          const category = contentTypesArray
            ? contentTypesArray[contentTypesArray.length - 1]
            : ''
          return {
            url: Helper.composeParametrizedUrl({
              url,
            }),
            objectID,
            title: title?.value || '',
            categoryText: category,
            categoryIcon: getIconForCategory(category),
            description: description?.value || '',
            createdInfo: authorFormatter(authors, publishDate),
            position: index + 1,
            queryID,
            indexName,
            searchQuery,
          }
        },
      ),
      results: nbHits,
    }
  }

  /***************************
   * MARSHAL DATA
   ****************************/
  const categorizedResults = [courses, publications, conferences].reduce(
    (results: CategorizedResult[], item) =>
      item ? [...results, item] : results,
    [],
  )

  const recentHistories = (
    searchHistory ? JSON.parse(searchHistory) : []
  ).slice(-10)
  const mainSearchHref = location.pathname.startsWith(searchHref)
    ? location.pathname
    : searchHref

  const userInfo: SimpleUserData = userData?.user
    ? Object.assign(
        {
          membershipType: undefined,
          avatarUrl: undefined,
        },
        {
          firstName: userData.user.profile.firstName,
          lastName: userData.user.profile.lastName,
          membershipType: userData.user.membership.overview?.label,
          avatarUrl: userData.user.profile.profileImageUrl,
          bgColor: Helper.randomColorChooser(),
          textAs: Helper.getAcronymFromSentence(
            `${userData.user.profile.firstName} ${userData.user.profile.lastName}`,
          ),
          cartCount: userData.user.cart?.itemTotal,
          customerClassCode: userData.user.customerClassCode,
        },
      )
    : null

  /********************************************
   *  RENDER
   *********************************************/

  if (isOnboarding) return <></>
  return (
    <>
      <Waypoint
        onPositionChange={onWaypointPositionChange}
        topOffset={topOffset}
      />
      {menuData?.menu.bannerData && (
        <TopBanner data={menuData?.menu.bannerData} />
      )}
      <Header
        data={menuData?.menu.menuData}
        condensed={sticky || collapseHeaderMQ}
        doLogout={doLogout}
        onLogin={() => setIsAuth(true)}
        isLoggedIn={!!userData?.user}
        user={userInfo}
        isUserLoading={loading}
        isNewSearch={location.pathname.startsWith('/search')}
        searchPanelProps={{
          searchKey,
          suggestedHints,
          recentHistories,
          featuredToday,
          categorizedResults,
          integratedResults: content?.hits || [],
          mainSearchHref: `${mainSearchHref}?query=${encodeURIComponent(
            searchKey,
          )}`,
          totalResults: [courses, publications, conferences, content].reduce(
            (total: number, item) => total + (item?.results || 0),
            0,
          ),
          showOverlay: true,
          onChange: (value: string) => setSearchKey(value),
          isShowSearch,
          setIsShowSearch,
        }}
      />
    </>
  )
}

export default HeaderWrap
