// @react
import React, { ReactElement } from 'react'
// @types
import { PropsType as CardProps } from '../../index'
// @libraries
import Shiitake from 'shiitake'

type PropsType = {
  subTitle: CardProps['content']['subTitle']
  truncateLines?: number
}

const SubTitle = ({ subTitle, truncateLines }: PropsType): ReactElement => {
  return (
    <>
      {truncateLines !== 0 && truncateLines ? (
        <Shiitake
          lines={truncateLines}
          throttleRate={400}
          tagName="span"
          attributes={{ title: subTitle as string }}
        >
          {subTitle}
        </Shiitake>
      ) : (
        subTitle
      )}
    </>
  )
}

SubTitle.defaultProps = {
  truncateLines: 0,
}

export default SubTitle
