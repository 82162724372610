// import Home from './pages/Home'
import { lazy } from 'react'
const Home = lazy(() => import('./pages/Home'))

const HomeRoutes = {
  routes: [
    {
      key: 'home',
      type: 'unauthorized',
      title: 'Home',
      path: `/`,
      component: Home,
      exact: true,
    },
  ],
}
export default HomeRoutes
