// @react
import React, { ReactElement, useContext } from 'react'
// @libraries
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
// @components
import colors from '@bit/atd.web.colors'
import Button from '@bit/atd.web.button'
import Icon from '@bit/atd.web.icons'
import { TrackPrimaryLinks } from 'modules/global/Header/context/trackPrimaryLinks'

const useStyles = makeStyles({
  root: {
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
    padding: 0,
    boxShadow: 'none',
    border: 'none',
    borderRadius: 0,
    opacity: 1,
    fontWeight: 500,
    textAlign: 'left',
    backgroundColor: colors.gray200,
    // creates active line
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      width: '4px',
      backgroundColor: colors.ctaPrimary,
      transform: 'translateX(-4px)',
      transition: 'transform 0.2s ease',
    },
    '&:hover': {
      opacity: 1,
      boxShadow: 'none',
      border: 'none',
      color: colors.black,
      background: 'rgba(225, 48, 0, 0.06)',
    },
    '&:focus': {
      boxShadow: 'none',
    },
    '&:active': {
      color: colors.black,
      background: colors.white,
      boxShadow: 'none',
      border: 'none',
      '& svg path': {
        fill: colors.ctaPrimary,
      },
    },
    '& .MuiButton-label': {
      display: 'flex',
      marginLeft: '24px',
      padding: '20px 24px 22px 0',
      width: '100%',
      alignItems: 'flex-start',
      flexDirection: 'column',
      '& > span': {
        flexDirection: 'column',
      },
    },
    '&:not(:last-child) .MuiButton-label': {
      borderBottom: `1px solid ${colors.gray400}`,
    },
  },
  title: {
    alignItems: 'center',
    display: 'flex',
    width: '100%',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '18px',
    '& span': {
      marginRight: '1.5em',
      flexGrow: 1,
    },
  },
  subtitle: {
    alignSelf: 'flex-start',
    paddingTop: '4px',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '18px',
    '&:empty': {
      padding: 0,
    },
  },
  isActive: {
    backgroundColor: colors.white,
    color: colors.black,
    '&::before': {
      transform: 'translateX(0)',
    },
    '&:hover': {
      background: colors.white,
    },
  },
})

type PropsType = {
  title: string
  subtitle?: string
  active?: boolean
  onClick?: () => void
}

const PrimaryLink = ({
  title,
  subtitle,
  onClick,
  active,
}: PropsType): ReactElement => {
  /********************************
   * HOOKS
   ********************************/

  const classes = useStyles()
  const activePrimaryLink = useContext(TrackPrimaryLinks)
  if (active) {
    activePrimaryLink.activeNavLink = title
  }
  /********************************
   * RENDER
   ********************************/

  return (
    <Button
      variant="secondary"
      className={clsx(classes.root, {
        [classes.isActive]: active,
      })}
      onClick={onClick}
    >
      <span className={classes.title}>
        <span>{title}</span>
        <Icon glyph="caret-right-small" size={24} color={colors.ctaPrimary} />
      </span>
      <span className={classes.subtitle}>{subtitle}</span>
    </Button>
  )
}

export default PrimaryLink
