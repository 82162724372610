// @todo this is the recommended structure for fragments...
// still doesn't get rid of false positive warning about unique fragment names
import { gql } from '@apollo/client'

/************************************
 *  CONTENT
 *************************************/

const contentSearchItemFragments = {
  fragments: {
    node: gql`
      fragment ContentSearchItemNode on IContentSearchItem {
        periodical
        issue
        column
        part
      }
    `,
  },
}

const articleSearchItemFragments = {
  fragments: {
    node: gql`
      fragment ArticleSearchItemNode on ArticleSearchItem {
        id
        ...ContentSearchItemNode
        isBookmarked
      }
      ${contentSearchItemFragments.fragments.node}
    `,
  },
}

const blogSearchItemFragments = {
  fragments: {
    node: gql`
      fragment BlogSearchItemNode on BlogSearchItem {
        ...ContentSearchItemNode
        isBookmarked
      }
      ${contentSearchItemFragments.fragments.node}
    `,
  },
}

const pageSearchItemFragments = {
  fragments: {
    node: gql`
      fragment PageSearchItemNode on PageSearchItem {
        ...ContentSearchItemNode
        isBookmarked
      }
      ${contentSearchItemFragments.fragments.node}
    `,
  },
}

const videoSearchItemFragments = {
  fragments: {
    node: gql`
      fragment VideoSearchItemNode on VideoSearchItem {
        ...ContentSearchItemNode
        publisher
        videoUrl
        videoType
        brightcoveName
        brightcoveId
        shortDescription
        longDescription
        videoPublishDate
        videoUpdateDate
        speaker
        brightcoveState
        duration
        isBookmarked
      }
      ${contentSearchItemFragments.fragments.node}
    `,
  },
}
/************************************
 *  PODCAST
 *************************************/

const podcastSearchItemFragments = {
  fragments: {
    node: gql`
      fragment PodcastSearchItemNode on PodcastSearchItem {
        subTitle
        duration
        season
        episode
      }
    `,
  },
}

/************************************
 *  WEBINAR
 *************************************/
/* children */
const sponsorItemFragments = {
  fragments: {
    node: gql`
      fragment SponsorItemNode on WebinarSearchItem {
        sponsors {
          organization
          website
          url
          logoUrl
        }
      }
    `,
  },
}

const presenterItemFragments = {
  fragments: {
    node: gql`
      fragment PresenterItemNode on WebinarSearchItem {
        presenters {
          fullName
          email
          organization
          positionTitle
          biography
          profileImageUrl
        }
      }
    `,
  },
}

const webinarSearchItemFragments = {
  fragments: {
    node: gql`
      fragment WebinarSearchItemNode on WebinarSearchItem {
        start
        end
        duration
        ...SponsorItemNode
        ...PresenterItemNode
        isPrivate
        host
        registeredCount
        webinarStatus
      }
      ${presenterItemFragments.fragments.node}
      ${sponsorItemFragments.fragments.node}
    `,
  },
}

/************************************
 *  COMMERCE
 *************************************/
const commerceSearchItemFragments = {
  fragments: {
    node: gql`
      fragment CommerceSearchItemNode on ICommerceSearchItem {
        listPrice
        memberPrice
        #        purchasableItems {
        #          ... on CoursePurchasableSearchItem {
        #          }
        #
        #        }
        availableAsBenefit
      }
    `,
  },
}

const publicationSearchItemFragments = {
  fragments: {
    node: gql`
      fragment PublicationSearchItemNode on PublicationSearchItem {
        ...CommerceSearchItemNode
        purchasableItems {
          productId
          productCode
          listPrice
          memberPrice
          externalUrl
          onSale
          memberBenefit
          numberAvailable
        }
        isBookmarked
      }
      ${commerceSearchItemFragments.fragments.node}
    `,
  },
}

/************************************
 *  MEETING SEARCH ITEM
 *************************************/
const meetingSearchItemFragments = {
  fragments: {
    node: gql`
      fragment MeetingSearchItemNode on IMeetingSearchItem {
        ...CommerceSearchItemNode
        purchasableItems {
          productId
          productCode
          listPrice
          memberPrice
          externalUrl
          onSale
          memberBenefit
          numberAvailable
          ... on CoursePurchasableSearchItem {
            sessionDescription
            location
            beginDate
            endDate
            deliveryMethods
            duration
            facilityName
            facilityAddress
            facilityDescription
          }
        }
        programCode
        subTitle
        eventRegions
        levels
        languages
      }
      ${commerceSearchItemFragments.fragments.node}
    `,
  },
}

const conferenceSearchItemFragments = {
  fragments: {
    node: gql`
      fragment ConferenceSearchItemNode on ConferenceSearchItem {
        ...MeetingSearchItemNode
        isBookmarked
      }
      ${meetingSearchItemFragments.fragments.node}
    `,
  },
}

const courseSearchItemFragments = {
  fragments: {
    node: gql`
      fragment CourseSearchItemNode on CourseSearchItem {
        ...MeetingSearchItemNode
        isBookmarked
      }
      ${meetingSearchItemFragments.fragments.node}
    `,
  },
}

/************************************
 *  USER
 *************************************/
const userSearchItemFragments = {
  fragments: {
    node: gql`
      fragment UserSearchItemNode on UserSearchItem {
        firstName
        lastName
        fullName
        bio
        customerNumber
        member
        staff
        jobTitle
        company
        countryCode
        isFollowingCurrentUser
        isFollowedByCurrentUser
      }
    `,
  },
}

export const IAbstractSearchItemFragments = {
  articleSearchItemFragments,
  blogSearchItemFragments,
  conferenceSearchItemFragments,
  courseSearchItemFragments,
  publicationSearchItemFragments,
  userSearchItemFragments,
  videoSearchItemFragments,
  webinarSearchItemFragments,
  pageSearchItemFragments,
  podcastSearchItemFragments,
}
