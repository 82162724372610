// @react
import React, { useEffect } from 'react'
// @components
import PageLoader from '@bit/atd.web.loaders.suspense-loader'
import { makeStyles } from '@material-ui/core/styles'
// @graphql
import { GetUserQuery, GetUserQueryVariables } from 'lib/graphql/graphqlTypes'
import { useQuery } from '@apollo/client'
import { GET_USER_ID } from 'graphql/queries/user.query'

const useStyles = makeStyles({
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
})

type PropTypes = {
  children: React.ReactElement
  isAuth: boolean | undefined
  setIsAuth: (isAuth: boolean) => void
}

const CurrentUserProvider = (props: PropTypes) => {
  const { isAuth, setIsAuth, children } = props
  const classes = useStyles()
  const { loading: isLoading, data } = useQuery<
    GetUserQuery,
    GetUserQueryVariables
  >(GET_USER_ID, {
    fetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
  })

  useEffect(() => {
    if (!isLoading) {
      setIsAuth(!!data?.user)
    }
  }, [isLoading, data])

  return isLoading && !isAuth ? (
    <div className={classes.loader}>{/* <PageLoader /> */}</div>
  ) : (
    children
  )
}

export default CurrentUserProvider
