import {
  ReactComponentElement,
  createContext,
  Dispatch,
  SetStateAction,
} from 'react'
import { SnackbarTypes } from '@bit/atd.web.snackbar'

export type SnackbarMessageType = {
  message: string
  icon?: ReactComponentElement<any>
  type: SnackbarTypes
  show: boolean
  anchorOrigin?: {
    vertical?: string
    horizontal?: string
  }
}

export const SnackbarContext = createContext<{
  setMessage: Dispatch<SetStateAction<SnackbarMessageType>>
}>({
  setMessage: () => null,
})
