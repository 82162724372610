// @react
import React, { ReactElement, useEffect, useRef, useState } from 'react'
// @types
import { PropsType as CardProps } from '../../index'
// @components
import Truncate from 'react-truncate'
import TextLink from '@bit/atd.web.text-link'
import useSegmentAlgoliaApi from 'modules/common/hooks/useSegmentAlgoliaApi'
import { makeStyles } from '@material-ui/core/styles'
import Icon from '@bit/atd.web.icons'

const useStyles = makeStyles({
  titleClicked: {
    cursor: 'pointer',
  },
  newTab: {
    display: 'flex',
    justifyContent: 'space-between',
  },
})

type PropsType = {
  title: CardProps['content']['title']
  titleURL?: CardProps['content']['titleUrl']
  variant?: 'primary' | 'secondary'
  truncateLines?: number
  target?: string
  position?: number
  searchQuery?: string
  queryId?: string
  objectId?: string
  index?: string
  name?: string
  source?: string
}

const Title = ({
  title,
  titleURL,
  truncateLines,
  variant,
  target,
  position,
  index,
  queryId,
  searchQuery,
  objectId,
  name,
  source,
}: PropsType): ReactElement => {
  const [width, setWidth] = useState(0)
  const classes = useStyles()
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setWidth(ref?.current?.offsetWidth || 0)
  }, [ref?.current?.offsetWidth])

  const segmentAnalytics = useSegmentAlgoliaApi(
    objectId,
    name,
    index,
    queryId,
    position,
    searchQuery,
    titleURL,
    false,
    source === undefined ? 'site-search-page' : source,
  )

  const segmentAnalyticsNewTab = useSegmentAlgoliaApi(
    objectId,
    name,
    index,
    queryId,
    position,
    searchQuery,
    titleURL,
    true,
    source === undefined ? 'site-search-page' : source,
  )

  return (
    <div ref={ref}>
      {/* If Link */}
      {titleURL && (
        <>
          <div className={classes.newTab}>
            <TextLink
              className={classes.titleClicked}
              onClick={segmentAnalytics}
              variant={variant}
              target={target}
            >
              {truncateLines !== 0 && truncateLines ? (
                <Truncate
                  key={width}
                  lines={truncateLines}
                  ellipsis={'...'}
                  width={width}
                >
                  {title}
                </Truncate>
              ) : (
                title
              )}
            </TextLink>
            <span
              className={classes.titleClicked}
              onClick={segmentAnalyticsNewTab}
            >
              <Icon size={20} glyph="newtab" />
            </span>
          </div>
        </>
      )}

      {/* If Text */}
      {!titleURL &&
        (truncateLines !== 0 && truncateLines ? (
          <Truncate
            key={width}
            lines={truncateLines}
            ellipsis={'...'}
            width={width}
          >
            {title}
          </Truncate>
        ) : (
          title
        ))}
    </div>
  )
}

Title.defaultProps = {
  titleLinkVariant: 'primary',
  truncateLines: 0,
}

export default Title
