// @libraries
import { makeStyles } from '@material-ui/core/styles'
import colors from '@bit/atd.web.colors'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',

    '& .MuiContainer-root': {
      [theme.breakpoints.only('xs')]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  },
  section: {
    position: 'relative',
    marginBottom: '30px',
    [theme.breakpoints.up('sm')]: {
      alignItems: 'center',
      display: 'flex',
      minHeight: '360px',
      marginBottom: '60px',
    },
    '&:last-child': {
      marginBottom: 0,
    },
    '&:nth-child(even)': {
      flexDirection: 'row-reverse',
    },
    '&:nth-child(even) $image': {
      left: 0,
    },
  },
  image: {
    fontSize: 0,
    [theme.breakpoints.up('sm')]: {
      position: 'absolute',
      right: 0,
      width: '60%',
      height: '100%',
    },

    '& picture': {
      clip: 'rect(0, auto, auto, 0)',
      height: '100%',
      overflow: 'hidden',
      pointerEvents: 'none',
    },
    '& img': {
      objectFit: 'cover',
      objectPosition: 'center',
    },
    '& picture,& img': {
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        position: 'absolute',
        height: '100%',
      },
    },
  },
  card: {
    boxShadow: '0px 2px 0px #DBD9D6',
    backgroundColor: '#fff',
    zIndex: 0,
    padding: '30px 16px 40px',
    [theme.breakpoints.up('sm')]: {
      borderRadius: '3px',
      padding: '60px 30px',
      width: '50%',
      margin: '30px 0',
    },

    '& p': {
      fontSize: '18px',
      lineHeight: '30px',
    },
    '& p a': {
      color: colors.ctaPrimary,
      textDecoration: 'underline',
      textUnderlineOffset: '4px',
      '&:hover': {
        color: '#EA6E4D',
      },
      '&:visited': {
        color: '#CB2B00',
      },
    },

    '& a': {
      marginTop: '12px',
    },
  },
  text: {
    fontSize: '18px',
    lineHeight: '30px',
    '& p': {
      fontSize: 'inherit',
      lineHeight: 'inherit',
      margin: '0 0 16px',
    },
    '& p:last-child': {
      marginBottom: 0,
    },
  },

  // Modifiers

  isReverse: {
    '& $section': {
      flexDirection: 'row-reverse',
    },
    '& $image': {
      left: 0,
    },
    '& $section:nth-child(even)': {
      flexDirection: 'row',
    },
    '& $section:nth-child(even) $image': {
      left: 'auto',
      right: 0,
    },
  },

  isOnLightBG: {
    '& $card': {
      [theme.breakpoints.up('sm')]: {
        borderTop: '1px solid #DBD9D6',
        borderLeft: '1px solid #DBD9D6',
        borderRight: '1px solid #DBD9D6',
      },
    },
  },
}))

export default useStyles
