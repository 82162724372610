import Thumbnail from '@bit/atd.web.thumbnail'
import React, { ReactElement } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import colors from '@bit/atd.web.colors'
import TextLink from '@bit/atd.web.text-link'

const useStyles = makeStyles({
  featuredWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: '8px',
  },
  featuredThumbnail: {
    width: '42px',
    height: '42px',
    minWidth: '42px',
    paddingBottom: 0,
    '& img': {
      objectFit: 'contain',
    },
  },
  featuredText: {
    marginLeft: '16px',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '18px',
    color: colors.ctaSecondaryDarker,
    display: 'inline-block',
  },
})

type PropTypes = {
  imageUrl: string
  title: string
  url: string
}

const FeaturedRow = (props: PropTypes): ReactElement => {
  const { title, url, imageUrl } = props
  const classes = useStyles()

  const resize = (srcImage: string): string => {
    return `${srcImage
      ?.replace(
        'https://d22bbllmj4tvv8.cloudfront.net',
        'https://atd.imgix.net',
      )
      .replace(
        'http://files.astd.org.s3.amazonaws.com',
        'https://atd-s3.imgix.net',
      )
      .replace(
        'https://atd-brightspot-lower.s3.amazonaws.com',
        'https://atd-brightspot-lower.imgix.net',
      )}?w=300&h=170&fit=crop`
  }
  return (
    <div className={classes.featuredWrapper}>
      <Thumbnail
        className={classes.featuredThumbnail}
        src={resize(imageUrl)}
        ratio="1:1"
      />
      <TextLink variant={'secondary'} href={url}>
        <span className={classes.featuredText}>{title}</span>
      </TextLink>
    </div>
  )
}

export default FeaturedRow
