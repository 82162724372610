// @react
import React, { useState, useEffect, ReactElement } from 'react'
// @types
import { PropsType as SearchPanelProps } from 'modules/global/SearchPanel'
// @module components
import Top from './Top'
import Offcanvas from './Offcanvas'

type PropsType = {
  /** Search Panel props */
  searchPanelProps: SearchPanelProps
}

const MobileMenu = ({ searchPanelProps }: PropsType): ReactElement => {
  const [show, setShow] = useState(false)

  // Disable/enable body scroll based on display state
  useEffect(() => {
    document.body.style.overflow = show ? 'hidden' : ''
  }, [show])

  return (
    <>
      <Top
        showSearch={searchPanelProps.isShowSearch}
        toggleSearch={(open: boolean) =>
          searchPanelProps.setIsShowSearch &&
          searchPanelProps.setIsShowSearch(open)
        }
        showOffcanvas={show}
        onHamburgerClick={() => setShow(true)}
        searchPanelProps={searchPanelProps}
      />

      <Offcanvas show={show} setShow={setShow}>
        <Top
          showSearch={searchPanelProps.isShowSearch}
          toggleSearch={(open: boolean) =>
            searchPanelProps.setIsShowSearch &&
            searchPanelProps.setIsShowSearch(open)
          }
          insideOffcanvas
          onHamburgerClick={() => setShow(false)}
          searchPanelProps={searchPanelProps}
        />
      </Offcanvas>
    </>
  )
}

export default MobileMenu
