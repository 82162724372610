// @react
import React, {
  ReactElement,
  useEffect,
  useState,
  useContext,
  useRef,
} from 'react'
// @types
import {
  LandingPageContactFormUpdateMutation,
  LandingPageContactFormUpdateMutationVariables,
} from 'lib/graphql/graphqlTypes'
import { SnackbarTypes } from '@bit/atd.web.snackbar'
import { LandingPageFormValues } from 'modules/pageBuilder/types/landingPageForm'
// @common
import { SnackbarContext } from 'modules/common/context/snackbar'
// @graphQL
import { ApolloQueryResult, useMutation, useQuery } from '@apollo/client'
import { LANDING_PAGE_CONTACT_FORM_UPDATE } from 'graphql/mutations/user.mutation'
// @libraries
import { useForm } from 'react-hook-form'
import { DevTool } from '@hookform/devtools'
import { makeStyles } from '@material-ui/core/styles'
import { vsprintf } from 'sprintf-js'
import strings from 'lib/constants/strings'
import countryISOCode from 'i18n-iso-countries'
// @components
import Container from '@bit/atd.web.container'
import Grid from '@bit/atd.web.grid'
import Spacer from '@bit/atd.web.spacer'
import Button from '@bit/atd.web.button'
import Box from '@bit/atd.web.box'
import ConditionalLoader from '@bit/atd.web.loaders.conditional-loader'
// @module components
import LandingPageAccountFields from './LandingPageAccountFields'
import LandingPageProfessionalFields from './LandingPageProfessionalFields'
import LandingPageContactFields from './LandingPageContactFields'
import LandingPageAcceptTermsFields from './LandingPageAcceptTermsFields'
import { yupResolver } from '@hookform/resolvers/yup'
import { validationSchema } from './validations'
import FormSubmitted from './FormSubmitted'

const useStyles = makeStyles(() => ({
  containerForm: {
    maxWidth: '100%',
    margin: '0 auto',
    '& .MuiPaper-root': {
      width: '100%',
    },
    boxShadow: '0px 2px 0px #dbd9d6',
    border: '1px solid #dbd9d6',
  },
  removeBorderBox: {
    border: 'none',
  },
  ActionButtons: {
    display: 'flex',
    '& button:last-child': {
      marginLeft: '12px',
    },
  },
}))

/**
 *
 */
const LandingPageForm = (): ReactElement => {
  /******************************************
   * STATE
   ******************************************/

  const [isMutationSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [hideForm, setHideForm] = useState<boolean>(false)

  /******************************************
   * REF
   ******************************************/

  const formSubmitted = useRef<HTMLDivElement | null>(null)

  /******************************************
   * HOOKS
   ******************************************/

  const snackbar = useContext(SnackbarContext)
  const classes = useStyles()

  /******************************************
   * GQL HOOKS
   ******************************************/

  // Mutation
  const [landingPageContactFormUpdate] = useMutation<
    LandingPageContactFormUpdateMutation,
    LandingPageContactFormUpdateMutationVariables
  >(LANDING_PAGE_CONTACT_FORM_UPDATE)

  /******************************************
   * HOOKS
   ******************************************/

  // React Hook Form
  const formMethods = useForm<LandingPageFormValues>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      firstName: '',
      lastName: '',
      primaryEmailAddress: '',
      companyName: '',
      phoneNumber: '',
      industry: '',
      jobTitle: '',
      role: '',
      countryCode: '',
      companySize: '',
      comments: '',
      interests: '',
      acceptTerms: false,
      acceptEmailConsent: false,
      acceptThirdPartyConsent: false,
    },
  })
  const { control, formState, reset, setError, clearErrors } = formMethods
  const { errors } = formState

  /******************************************
   * FUNCTIONS
   ******************************************/

  // on FormSubmit
  const handleFormSubmit = async (data: LandingPageFormValues) => {
    /**
     * if location is USA isConsent and isThirdPartyConsent are null
     * only show accept terms, checkbox does not map to API response
     *
     * if location is Canada show Canada specific language and pass it's consent values to endpoint
     * if location is any other country including a GDPR country show the general
     * consent language and pass it's consent values to endpoint
     *
     * note: some countries do not display third party checkbox like South Africa
     * pass null for isThirdPartyConsent
     */

    const countryCode = countryISOCode.alpha2ToAlpha3(data.countryCode)

    const isConsent = (() => {
      //when true for south africa email consent is opted out, when false email consent is agreed to
      if (countryCode === 'ZAF' && data.acceptEmailConsent === true) {
        return false
      } else if (countryCode === 'ZAF' && data.acceptEmailConsent === false) {
        return true
      }
      if (data.acceptEmailConsent === true) {
        return true
      }
      if (countryCode === 'USA') {
        return null
      }
      return false
    })()

    const isThirdPartyConsent = (() => {
      if (data.acceptThirdPartyConsent === true) {
        return true
      }
      if (countryCode === 'USA' || countryCode === 'ZAF') {
        return null
      }
      return false
    })()

    const { errors: mutationErrors } = await landingPageContactFormUpdate({
      variables: {
        input: {
          firstName: data.firstName,
          lastName: data.lastName,
          primaryEmailAddress: data.primaryEmailAddress,
          companyName: data.companyName,
          phoneNumber: data.phoneNumber,
          jobTitle: data.jobTitle,
          role: data.role,
          companySize: data.companySize,
          industry: data.industry,
          acceptTerms: data.acceptTerms,
          countryCode,
          isThirdPartyConsent,
          isConsent,
          comments: data.comments,
          interests: data.interests,
        },
      },
    })

    if (!mutationErrors) {
      snackbar.setMessage({
        type: SnackbarTypes.SUCCESS,
        message: vsprintf(strings.SUCCESS.GENERIC_VARIABLE_UPDATE_SUCCESS, [
          'Account',
        ]),
        show: true,
      })
      setHideForm(true)
    } else {
      snackbar.setMessage({
        type: SnackbarTypes.ERROR,
        message: strings.ERRORS.GENERIC_UPDATE_ERROR,
        show: true,
      })
      console.log('mutation error', mutationErrors)
    }
  }

  const handleClick = () => {
    formSubmitted?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    })
  }

  /******************************************
   * RENDER
   ******************************************/

  const box = (
    content: ReactElement,
    title?: string,
    subtitle?: string,
  ): ReactElement => {
    return title || subtitle ? (
      <Box
        classNames={{ root: classes.removeBorderBox }}
        header={{
          title: title,
          subtitle: subtitle,
        }}
      >
        {content}
      </Box>
    ) : (
      <Box classNames={{ root: classes.removeBorderBox }}>{content}</Box>
    )
  }

  return (
    <div ref={formSubmitted}>
      {process.env.DEBUG === '1' && <DevTool control={control} />}
      <Spacer size={20} fullWidth />
      {/* Form - RHF */}

      {hideForm === true ? (
        <FormSubmitted />
      ) : (
        <form>
          <Container>
            <Grid className={classes.containerForm} justify="center" container>
              {box(
                <LandingPageAccountFields
                  formMethods={formMethods}
                  control={control}
                  errors={errors}
                />,
                'Contact Us',
                'Fill out your information below to connect with an ATD Account Executive.',
              )}

              {box(
                <LandingPageProfessionalFields
                  formMethods={formMethods}
                  control={control}
                  errors={errors}
                />,
              )}

              {box(
                <LandingPageContactFields
                  formMethods={formMethods}
                  control={control}
                  errors={errors}
                />,
              )}

              {box(
                <LandingPageAcceptTermsFields
                  formMethods={formMethods}
                  control={control}
                  errors={errors}
                />,
              )}

              {/* Submit Button */}
              {box(
                <Container>
                  <Grid container alignItems="center" justify="center">
                    <Grid item>
                      <ConditionalLoader condition={!isMutationSubmitting}>
                        <Button
                          onClick={formMethods.handleSubmit(async (data) => {
                            setIsSubmitting(true)
                            await handleFormSubmit(data)
                            setIsSubmitting(false)
                            handleClick()
                          })}
                          size="medium"
                        >
                          Submit
                        </Button>
                      </ConditionalLoader>
                    </Grid>
                  </Grid>
                </Container>,
              )}
            </Grid>
          </Container>
          <Spacer size={20} fullWidth />
        </form>
      )}
    </div>
  )
}

export default LandingPageForm
