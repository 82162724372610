// @react
import React, { ReactElement } from 'react'
// @types
import { PropsType as CardProps } from '../../index'
// @libraries
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
// @components
import Colors from '@bit/atd.web.colors'
import TextLink from '@bit/atd.web.text-link'
// @card parts
import Title from '../Title'
import SubTitle from '../SubTitle'
import Sponsor from '../Sponsor'

const useStyles = makeStyles({
  root: {
    '& > *:not(:last-child)': {
      margin: '0 0 4px',
    },
  },
  dateAlert: {
    color: Colors.brandRed,
    fontSize: '12px',
    lineHeight: '15px',
    fontWeight: 600,
    margin: 0,
  },
  title: {
    fontSize: '18px',
    lineHeight: '22px',
    fontWeight: 600,
    margin: 0,
  },
  truncateTitle: {},
  subTitle: {
    color: Colors.black,
    fontSize: '14px',
    lineHeight: '18px',
    fontWeight: 400,
    margin: 0,
    '& span': {
      fontSize: '14px',
      lineHeight: '18px',
    },
  },
  description: {
    color: Colors.black,
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 400,
    margin: 0,
  },
  byLine: {
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 400,
    margin: 0,
  },
})

type PropsType = {
  dateAlert?: CardProps['content']['dateAlert']
  title: CardProps['content']['title']
  titleUrl?: CardProps['content']['titleUrl']
  titleTruncateLines?: number
  subTitle?: CardProps['content']['subTitle']
  subTitleTruncateLines?: number
  description?: CardProps['content']['description']
  byLine?: CardProps['content']['byLine']
  sponsors: CardProps['content']['sponsors']
  className?: string
  position?: CardProps['content']['position']
  index?: CardProps['content']['index']
  objectId?: CardProps['content']['objectId']
  source?: CardProps['content']['source']
  queryId?: CardProps['content']['queryId']
  searchQuery?: CardProps['content']['searchQuery']
  name?: CardProps['content']['name']
}

const Details = ({
  dateAlert,
  title,
  titleUrl,
  titleTruncateLines,
  subTitle,
  subTitleTruncateLines,
  description,
  byLine,
  sponsors,
  className,
  position,
  searchQuery,
  index,
  source,
  queryId,
  objectId,
  name,
}: PropsType): ReactElement | null => {
  const classes = useStyles()

  return (
    <div
      className={clsx({
        [classes.root]: true,
        [className || '']: className,
      })}
    >
      {/* dateAlert - Upcoming Notice */}
      {dateAlert && <p className={classes.dateAlert}>{dateAlert}</p>}

      {/* title - title/title with url */}
      {title && (
        <h3 className={clsx(classes.title)}>
          <Title
            source={source}
            position={position}
            name={name}
            index={index}
            queryId={queryId}
            objectId={objectId}
            searchQuery={searchQuery}
            title={title}
            titleURL={titleUrl}
            truncateLines={titleTruncateLines}
          />
        </h3>
      )}

      {/* SubTitle - Search Snippet/SubTitle/Lead-In */}
      {subTitle && (
        <p className={classes.subTitle}>
          <SubTitle subTitle={subTitle} truncateLines={subTitleTruncateLines} />
        </p>
      )}

      {/* Description - Description/URL */}
      {description && <p className={classes.description}>{description}</p>}

      {/* byLine - Authors/Publication Date */}
      {byLine && (
        <p className={classes.byLine}>
          {!!byLine?.authors && !!byLine?.authors.length && (
            <>
              <span>By </span>
              {byLine?.authors?.map(({ author, url }, index) => (
                <React.Fragment key={`${index}_${author}`}>
                  {url && (
                    <TextLink variant={'secondary'} href={url}>
                      {author}
                    </TextLink>
                  )}
                  {!url && <span>{author}</span>}
                  {byLine?.authors && (
                    <>
                      {index < byLine?.authors.length - 2 && ', '}
                      {index === byLine?.authors.length - 2 && ' & '}
                    </>
                  )}
                </React.Fragment>
              ))}
            </>
          )}
          {byLine?.authors && byLine?.pubDate && ' '}
          {byLine?.pubDate &&
            `${!!byLine?.authors?.length ? 'on' : 'Published on'} ${
              byLine?.pubDate
            }`}
        </p>
      )}

      {/* Sponsored */}
      {sponsors &&
        sponsors.map((sponsored, index) => (
          <Sponsor key={index} sponsor={sponsored} />
        ))}
    </div>
  )
}

export default Details
