import { gql } from '@apollo/client'

export const MENU_CHILD_NODE = gql`
  fragment MenuChildNode on MenuItem {
    type
    title
    description
    url
    target
    buttonText
    buttonUrl
    imageUrl
    flair
  }
`

export const BANNER_NODE = gql`
  fragment BannerNode on Banner {
    name
    isCountdown
    countdownScript
    desktopImageUrl
    desktopLink
    mobileImageUrl
    mobileLink
    backgroundHexColor
  }
`

export const GET_MENU = gql`
  query GetMenu {
    menu {
      bannerData {
        ...BannerNode
      }
      menuData {
        type
        title
        description
        url
        target
        children {
          ...MenuChildNode
          children {
            ...MenuChildNode
            children {
              ...MenuChildNode
            }
          }
        }
      }
    }
  }
  ${MENU_CHILD_NODE}
  ${BANNER_NODE}
`
