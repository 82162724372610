import { AlgoliaHit } from '../../../common/types'
import parse from 'html-react-parser'
import ContentWrapper from '../CardWrapper'
import React from 'react'
import clsx from 'clsx'
import Carousel from '@bit/atd.web.carousel'
import Button from '@bit/atd.web.button'
import CustomCardHit from '../../../search/components/Widgets/CustomCardHit'
import { useStyles } from '../AccordionWrapper/_styles'

type PropsType = {
  contentItems: AlgoliaHit[] | undefined
  subTitle: string
  limit: number
  viewMoreLink?: {
    title: string
    href: string
  }
}

/**
 *
 * @param contentItems
 * @param type
 * @param title
 * @param subTitle
 * @param viewMoreLink
 */
const RenderContentCarousel = ({
  contentItems,
  subTitle,
  limit,
  viewMoreLink,
}: PropsType) => {
  const classes = useStyles()
  // If only one item in carousel...
  if (contentItems && contentItems?.length <= 1) {
    return (
      <div className={classes.accordionBody}>
        {subTitle && (
          <div className={classes.accordionSubTitle}>{parse(subTitle)}</div>
        )}
        <ContentWrapper
          content={contentItems}
          limit={limit}
          viewMoreLink={viewMoreLink}
        />
      </div>
    )
  }

  // else init carousel
  return contentItems?.length ? (
    <div
      className={clsx(classes.accordionBody, {
        [classes.isCarousel]: true,
      })}
    >
      {subTitle && (
        <div className={classes.accordionSubTitle}>{parse(subTitle)}</div>
      )}

      <Carousel
        showDots
        sameHeight
        showBtn={
          <Button variant="primary" href={viewMoreLink?.href}>
            <span>{viewMoreLink?.title}</span>
          </Button>
        }
        advancedItems={1}
      >
        {contentItems?.slice(0, limit).map((item, index) => {
          return (
            <CustomCardHit
              key={`${index}_${item.objectID}`}
              hit={item}
              index={{ targetedIndex: index }}
              layout={'featured'}
            />
          )
        })}
      </Carousel>
    </div>
  ) : null
}

export default RenderContentCarousel
