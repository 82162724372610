import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** This field accepts both ISODateString and ISODateTimeStringexample: 1992-08-14 or 1992-08-14T03:42:00.000Z */
  DateTime: any;
  EpochDate: any;
  Upload: any;
};

export type Address = IAddress & {
  __typename?: 'Address';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  address3?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  postalCode: Scalars['String'];
  formattedAddress?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  addressType: Scalars['String'];
  isPrimary: Scalars['Boolean'];
};

export type AddressDeleteInput = {
  addressId: Scalars['String'];
  address1: Scalars['String'];
  city: Scalars['String'];
  countryCode: Scalars['String'];
  postalCode: Scalars['String'];
  state: Scalars['String'];
};

export type AddressInput = {
  addressId?: Maybe<Scalars['String']>;
  isPrimary: Scalars['Boolean'];
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  address3?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  countryCode: Scalars['String'];
  addressType: Scalars['String'];
  postalCode: Scalars['String'];
};

export type AlgoliaContentInput = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  index?: Maybe<Scalars['String']>;
  filters?: Maybe<Scalars['String']>;
};

export type AlgoliaContentMeta = IMeta & {
  __typename?: 'AlgoliaContentMeta';
  count: Scalars['Int'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export type AlgoliaContentPayload = {
  __typename?: 'AlgoliaContentPayload';
  meta: AlgoliaContentMeta;
  data?: Maybe<Array<IAbstractSearchItem>>;
};

export type AllLandingPages = {
  __typename?: 'AllLandingPages';
  total?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Maybe<LandingPages>>>;
};

export type AllSysAsset = {
  __typename?: 'AllSysAsset';
  total?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Maybe<SysAsset>>>;
};

export type AllTestalgolia = {
  __typename?: 'AllTestalgolia';
  total?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Maybe<Testalgolia>>>;
};

export type ArticleSearchItem = IContentSearchItem & IAbstractSearchItem & {
  __typename?: 'ArticleSearchItem';
  id: Scalars['ID'];
  title: Scalars['String'];
  publishDate: Scalars['EpochDate'];
  updateDate: Scalars['EpochDate'];
  description?: Maybe<Scalars['String']>;
  bodyText?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  viewCount: Scalars['Int'];
  contentTypes?: Maybe<TaxonomySearchItem>;
  topics?: Maybe<Array<Scalars['String']>>;
  primaryTopic?: Maybe<Scalars['String']>;
  topicCodes?: Maybe<Array<Scalars['String']>>;
  industryCodes?: Maybe<Array<Scalars['String']>>;
  roleCodes?: Maybe<Array<Scalars['String']>>;
  contentTypesArray?: Maybe<Array<Scalars['String']>>;
  industries?: Maybe<Array<Scalars['String']>>;
  roles?: Maybe<Array<Scalars['String']>>;
  keywords?: Maybe<Array<Scalars['String']>>;
  authors?: Maybe<Array<UserSearchItem>>;
  featured?: Maybe<Scalars['Boolean']>;
  memberBenefit?: Maybe<Scalars['Boolean']>;
  isBookmarkable: Scalars['Boolean'];
  _snippetResult?: Maybe<SnippetResult>;
  periodical?: Maybe<Scalars['String']>;
  issue?: Maybe<Scalars['String']>;
  column?: Maybe<Scalars['String']>;
  part?: Maybe<Scalars['String']>;
  isBookmarked: Scalars['Boolean'];
};

export type AtdNewslettersInput = {
  key: Scalars['String'];
  value: Scalars['Boolean'];
};

export type AutoRenewLowValueTokenDetails = {
  __typename?: 'AutoRenewLowValueTokenDetails';
  merchantId: Scalars['String'];
  payPageID: Scalars['String'];
  orderSource: Scalars['String'];
};

export type Badge = {
  __typename?: 'Badge';
  id: Scalars['ID'];
  badgeId: Scalars['String'];
  imageUrl: Scalars['String'];
  thumbnailUrl: Scalars['String'];
  createdAt: Scalars['EpochDate'];
  issuedAt: Scalars['EpochDate'];
  title: Scalars['String'];
  description: Scalars['String'];
  url: Scalars['String'];
  pending: Scalars['Boolean'];
  acceptUrl: Scalars['String'];
};

export type Banner = {
  __typename?: 'Banner';
  name: Scalars['String'];
  isCountdown: Scalars['Boolean'];
  countdownScript?: Maybe<Scalars['String']>;
  desktopImageUrl: Scalars['String'];
  desktopLink: Scalars['String'];
  mobileImageUrl: Scalars['String'];
  mobileLink: Scalars['String'];
  backgroundHexColor: Scalars['String'];
};

export type Benefit = {
  __typename?: 'Benefit';
  benefitType: Scalars['String'];
  used: Scalars['Float'];
  benefitCount: Scalars['Float'];
};

export enum BenefitType {
  NONE = 'NONE',
  MEMBER = 'MEMBER',
  PROFESSIONAL_PLUS = 'PROFESSIONAL_PLUS'
}

export type BlogSearchItem = IContentSearchItem & IAbstractSearchItem & {
  __typename?: 'BlogSearchItem';
  id: Scalars['ID'];
  title: Scalars['String'];
  publishDate: Scalars['EpochDate'];
  updateDate: Scalars['EpochDate'];
  description?: Maybe<Scalars['String']>;
  bodyText?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  viewCount: Scalars['Int'];
  contentTypes?: Maybe<TaxonomySearchItem>;
  topics?: Maybe<Array<Scalars['String']>>;
  primaryTopic?: Maybe<Scalars['String']>;
  topicCodes?: Maybe<Array<Scalars['String']>>;
  industryCodes?: Maybe<Array<Scalars['String']>>;
  roleCodes?: Maybe<Array<Scalars['String']>>;
  contentTypesArray?: Maybe<Array<Scalars['String']>>;
  industries?: Maybe<Array<Scalars['String']>>;
  roles?: Maybe<Array<Scalars['String']>>;
  keywords?: Maybe<Array<Scalars['String']>>;
  authors?: Maybe<Array<UserSearchItem>>;
  featured?: Maybe<Scalars['Boolean']>;
  memberBenefit?: Maybe<Scalars['Boolean']>;
  isBookmarkable: Scalars['Boolean'];
  _snippetResult?: Maybe<SnippetResult>;
  periodical?: Maybe<Scalars['String']>;
  issue?: Maybe<Scalars['String']>;
  column?: Maybe<Scalars['String']>;
  part?: Maybe<Scalars['String']>;
  isBookmarked: Scalars['Boolean'];
};

export type Capability = {
  __typename?: 'Capability';
  capTitle?: Maybe<Scalars['String']>;
  capTotalScore?: Maybe<Scalars['Int']>;
  statements?: Maybe<Array<Statements>>;
};

export type CapabilityModel = {
  __typename?: 'CapabilityModel';
  isSelfAssessmentStarted: Scalars['Boolean'];
  isSelfAssessmentComplete: Scalars['Boolean'];
  isLearningPathSelected: Scalars['Boolean'];
  isLearningPlanCreated: Scalars['Boolean'];
  isLearningPlanSelected: Scalars['Boolean'];
  isPartialPlan: Scalars['Boolean'];
  isLearningPlanComplete: Scalars['Boolean'];
  planActivitiesCount: Scalars['Int'];
  planActivitiesCompletedCount: Scalars['Int'];
  isPersonalCapabilityComplete: Scalars['Boolean'];
  isProfessionalCapabilityComplete: Scalars['Boolean'];
  isOrganizationalCapabilityComplete: Scalars['Boolean'];
  learningPathLabel?: Maybe<Scalars['String']>;
  domains: Array<Domain>;
  totalScore: Scalars['Int'];
};

export type Cart = {
  __typename?: 'Cart';
  id: Scalars['ID'];
  items?: Maybe<Array<Scalars['String']>>;
  wishListItems?: Maybe<Array<Scalars['String']>>;
  isMember: Scalars['Boolean'];
  loggedIn: Scalars['Boolean'];
  itemTotal: Scalars['Float'];
  subTotal: Scalars['Float'];
  formattedSubTotal: Scalars['String'];
  checkoutUrl: Scalars['String'];
};

export type Certification = {
  __typename?: 'Certification';
  expiryDate?: Maybe<Scalars['EpochDate']>;
  earnedDate?: Maybe<Scalars['EpochDate']>;
  masters?: Maybe<Scalars['String']>;
  recertifyByDate?: Maybe<Scalars['EpochDate']>;
  certificationCode?: Maybe<CertificationType>;
  certificationStatus?: Maybe<Scalars['String']>;
};

export enum CertificationType {
  APTD = 'APTD',
  CPTD = 'CPTD',
  MASTERS = 'MASTERS'
}

export type ChapterMembership = IMembership & {
  __typename?: 'ChapterMembership';
  id: Scalars['ID'];
  memberType: Scalars['String'];
  memberTypeLabel: Scalars['String'];
  memberNumber: Scalars['Int'];
  memberStatus: MemberStatus;
  autoRenew: Scalars['Boolean'];
  orderNumber: Scalars['String'];
  orderLineNumber: Scalars['String'];
  initialBeginDate: Scalars['EpochDate'];
  cycleBeginDate: Scalars['EpochDate'];
  cycleEndDate: Scalars['EpochDate'];
  recordTypeCode?: Maybe<Scalars['String']>;
  chapterName: Scalars['String'];
  chapterURL: Scalars['String'];
};

export type ClaimResource = {
  __typename?: 'ClaimResource';
  showBanner: Scalars['Boolean'];
  claimLink: Scalars['String'];
};

export type CommunicationPreferences = {
  __typename?: 'CommunicationPreferences';
  isPostalMailOptedOut: Scalars['Boolean'];
  automatedNewsletters: Scalars['Boolean'];
  isPhoneOptedOut: Scalars['Boolean'];
  bimonthlyDigest: Scalars['Boolean'];
  receivePromotionalCommunication: Scalars['Boolean'];
  newsletters: Array<NewsletterGroup>;
};

export type CommunicationPreferencesInput = {
  email: Scalars['String'];
  universalOptOut: Scalars['Boolean'];
  bimonthlyDigest: Scalars['Boolean'];
  isPostalMailOptedOut?: Maybe<Scalars['Boolean']>;
  isPhoneOptedOut?: Maybe<Scalars['Boolean']>;
  receivePromotionalCommunication: Scalars['Boolean'];
  automatedNewsletters?: Maybe<Scalars['Boolean']>;
  newsletterGroups: Array<AtdNewslettersInput>;
};

export type Conference = {
  __typename?: 'Conference';
  eventName: Scalars['String'];
  startDate: Scalars['EpochDate'];
  endDate: Scalars['EpochDate'];
  location: Scalars['String'];
  productUrl: Scalars['String'];
};

export type ConferencePayload = {
  __typename?: 'ConferencePayload';
  meta?: Maybe<LearningActivityMeta>;
  data: Array<Conference>;
};

export type ConferenceSearchItem = IAbstractSearchItem & ICommerceSearchItem & IMeetingSearchItem & {
  __typename?: 'ConferenceSearchItem';
  id: Scalars['ID'];
  title: Scalars['String'];
  publishDate: Scalars['EpochDate'];
  updateDate: Scalars['EpochDate'];
  description?: Maybe<Scalars['String']>;
  bodyText?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  viewCount: Scalars['Int'];
  contentTypes?: Maybe<TaxonomySearchItem>;
  topics?: Maybe<Array<Scalars['String']>>;
  primaryTopic?: Maybe<Scalars['String']>;
  topicCodes?: Maybe<Array<Scalars['String']>>;
  industryCodes?: Maybe<Array<Scalars['String']>>;
  roleCodes?: Maybe<Array<Scalars['String']>>;
  contentTypesArray?: Maybe<Array<Scalars['String']>>;
  industries?: Maybe<Array<Scalars['String']>>;
  roles?: Maybe<Array<Scalars['String']>>;
  keywords?: Maybe<Array<Scalars['String']>>;
  authors?: Maybe<Array<UserSearchItem>>;
  featured?: Maybe<Scalars['Boolean']>;
  memberBenefit?: Maybe<Scalars['Boolean']>;
  isBookmarkable: Scalars['Boolean'];
  _snippetResult?: Maybe<SnippetResult>;
  listPrice: Scalars['Int'];
  memberPrice: Scalars['Int'];
  purchasableItems?: Maybe<Array<IPurchasableSearchItem>>;
  availableAsBenefit: Scalars['Boolean'];
  programCode: Scalars['String'];
  subTitle: Scalars['String'];
  eventRegions: Array<Scalars['String']>;
  levels: Array<Scalars['String']>;
  languages: Array<Scalars['String']>;
  isBookmarked: Scalars['Boolean'];
};

export type ConstantsPayload = {
  __typename?: 'ConstantsPayload';
  topics: Array<Topic>;
  industryCodes: Array<List>;
  jobRoleCodes: Array<List>;
  companySizeCodes: Array<List>;
  directReportCountCodes: Array<List>;
  budgetSizeCodes: Array<List>;
  specialNeeds: Array<Scalars['String']>;
  allDemographicCodes: Array<List>;
  programDegreeCodes: Array<List>;
  programTypeCodes: Array<List>;
  gdprCountryCodes: Array<Scalars['String']>;
};

export type ContactInfo = {
  __typename?: 'ContactInfo';
  primaryPhoneNumber?: Maybe<Scalars['String']>;
  secondaryPhoneNumber?: Maybe<Scalars['String']>;
  primaryEmailAddress?: Maybe<Scalars['String']>;
  address?: Maybe<Array<Address>>;
  phones?: Maybe<Array<Phone>>;
};

export type ContactInfoInput = {
  primaryEmailAddress?: Maybe<Scalars['String']>;
  address?: Maybe<Array<AddressInput>>;
  phones?: Maybe<Array<PhoneInput>>;
  isConsent?: Maybe<Scalars['Boolean']>;
  isThirdPartyConsent?: Maybe<Scalars['Boolean']>;
};

export enum ContentType {
  BOOK = 'BOOK',
  CONFERENCE = 'CONFERENCE',
  COURSE = 'COURSE',
  ARTICLE = 'ARTICLE',
  BLOG = 'BLOG',
  PUBLICATION = 'PUBLICATION',
  PRODUCT = 'PRODUCT',
  PUBLIC_USER = 'PUBLIC_USER',
  WEBINAR = 'WEBINAR'
}

export type CourseBadge = {
  __typename?: 'CourseBadge';
  id: Scalars['ID'];
  badge_id: Scalars['String'];
  image_url: Scalars['String'];
  thumbnail_url: Scalars['String'];
  title: Scalars['String'];
  description: Scalars['String'];
  url: Scalars['String'];
  created_at: Scalars['String'];
  issued_at: Scalars['String'];
  pending: Scalars['String'];
  accept_url: Scalars['String'];
};

export type CourseCertificate = {
  __typename?: 'CourseCertificate';
  name: Scalars['String'];
  url: Scalars['String'];
  issueDate: Scalars['Float'];
  expiryDate: Scalars['Float'];
};

export type CourseCredit = {
  __typename?: 'CourseCredit';
  type: Scalars['String'];
  value: Scalars['Float'];
  creditYear: Scalars['Int'];
};

export type CoursePurchasableSearchItem = IPurchasableSearchItem & {
  __typename?: 'CoursePurchasableSearchItem';
  id: Scalars['ID'];
  productId: Scalars['Int'];
  productCode: Scalars['String'];
  listPrice: Scalars['String'];
  memberPrice: Scalars['String'];
  externalUrl: Scalars['String'];
  onSale: Scalars['Boolean'];
  memberBenefit: Scalars['Boolean'];
  numberAvailable: Scalars['Int'];
  sessionDescription?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  beginDate?: Maybe<Scalars['EpochDate']>;
  endDate?: Maybe<Scalars['EpochDate']>;
  unixBeginDate?: Maybe<Scalars['EpochDate']>;
  unixEndDate?: Maybe<Scalars['EpochDate']>;
  deliveryMethods?: Maybe<Array<Scalars['String']>>;
  duration?: Maybe<Scalars['Int']>;
  facilityName?: Maybe<Scalars['String']>;
  facilityAddress?: Maybe<Scalars['String']>;
  facilityDescription?: Maybe<Scalars['String']>;
};

export type CourseSearchItem = IAbstractSearchItem & ICommerceSearchItem & IMeetingSearchItem & {
  __typename?: 'CourseSearchItem';
  id: Scalars['ID'];
  title: Scalars['String'];
  publishDate: Scalars['EpochDate'];
  updateDate: Scalars['EpochDate'];
  description?: Maybe<Scalars['String']>;
  bodyText?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  viewCount: Scalars['Int'];
  contentTypes?: Maybe<TaxonomySearchItem>;
  topics?: Maybe<Array<Scalars['String']>>;
  primaryTopic?: Maybe<Scalars['String']>;
  topicCodes?: Maybe<Array<Scalars['String']>>;
  industryCodes?: Maybe<Array<Scalars['String']>>;
  roleCodes?: Maybe<Array<Scalars['String']>>;
  contentTypesArray?: Maybe<Array<Scalars['String']>>;
  industries?: Maybe<Array<Scalars['String']>>;
  roles?: Maybe<Array<Scalars['String']>>;
  keywords?: Maybe<Array<Scalars['String']>>;
  authors?: Maybe<Array<UserSearchItem>>;
  featured?: Maybe<Scalars['Boolean']>;
  memberBenefit?: Maybe<Scalars['Boolean']>;
  isBookmarkable: Scalars['Boolean'];
  _snippetResult?: Maybe<SnippetResult>;
  listPrice: Scalars['Int'];
  memberPrice: Scalars['Int'];
  purchasableItems?: Maybe<Array<IPurchasableSearchItem>>;
  availableAsBenefit: Scalars['Boolean'];
  programCode: Scalars['String'];
  subTitle: Scalars['String'];
  eventRegions: Array<Scalars['String']>;
  levels: Array<Scalars['String']>;
  languages: Array<Scalars['String']>;
  isBookmarked: Scalars['Boolean'];
};

export enum CourseStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  UPCOMING = 'UPCOMING',
  COMPLETED = 'COMPLETED'
}

export type CurrentMembership = IMembership & {
  __typename?: 'CurrentMembership';
  id: Scalars['ID'];
  memberType: Scalars['String'];
  memberTypeLabel: Scalars['String'];
  memberNumber: Scalars['Int'];
  memberStatus: MemberStatus;
  autoRenew: Scalars['Boolean'];
  orderNumber: Scalars['String'];
  orderLineNumber: Scalars['String'];
  initialBeginDate: Scalars['EpochDate'];
  cycleBeginDate: Scalars['EpochDate'];
  cycleEndDate: Scalars['EpochDate'];
  recordTypeCode?: Maybe<Scalars['String']>;
};

export enum CustomerClassCode {
  COMPANY = 'COMPANY',
  STAFF = 'STAFF',
  INDIV = 'INDIV',
  ADVERTISER = 'ADVERTISER',
  AGENCY = 'AGENCY',
  BENCH = 'BENCH',
  BOARD = 'BOARD',
  CHAPTER = 'CHAPTER',
  CHATPER_LEADER_COMMUNITY = 'CHATPER_LEADER_COMMUNITY',
  CHP = 'CHP',
  DISTRIBUTOR = 'DISTRIBUTOR',
  EXHIBITOR = 'EXHIBITOR',
  FACILITATOR = 'FACILITATOR',
  FACILITY_HOTEL = 'FACILITY_HOTEL',
  GROUP = 'GROUP',
  IND = 'IND',
  LIBRARY = 'LIBRARY',
  MEDIA = 'MEDIA',
  PRODUCER = 'PRODUCER',
  PROSPECT = 'PROSPECT',
  SUB_AGENCY = 'SUB_AGENCY',
  VIP = 'VIP',
  VOLCOM = 'VOLCOM',
  WEB = 'WEB'
}


export type Domain = {
  __typename?: 'Domain';
  domainTitle: Scalars['String'];
  totalProgress: Scalars['Float'];
  capabilities?: Maybe<Array<Capability>>;
};

export type Education = {
  __typename?: 'Education';
  id: Scalars['ID'];
  degreeType?: Maybe<Scalars['String']>;
  programType?: Maybe<Scalars['String']>;
  schoolName?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['EpochDate']>;
  endDate?: Maybe<Scalars['EpochDate']>;
};

export type EducationDeleteInput = {
  educationId: Scalars['Int'];
};

export type EducationUpsertInput = {
  educationId?: Maybe<Scalars['Int']>;
  degreeType?: Maybe<Scalars['String']>;
  programType?: Maybe<Scalars['String']>;
  schoolName?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['EpochDate']>;
  endDate: Scalars['EpochDate'];
};

export type EmailValidatorInput = {
  email: Scalars['String'];
};

export type EmailValidatorPayload = {
  __typename?: 'EmailValidatorPayload';
  isValid: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export enum EntityType {
  MEETING_SESSION = 'MEETING_SESSION',
  PUBLIC_USER = 'PUBLIC_USER',
  ARTICLE = 'ARTICLE',
  BLOG = 'BLOG',
  PUBLICATION = 'PUBLICATION',
  CONFERENCE = 'CONFERENCE',
  COURSE = 'COURSE',
  VIDEO = 'VIDEO',
  PAGE = 'PAGE',
  WEBINAR = 'WEBINAR'
}

export type EntrySystemField = {
  __typename?: 'EntrySystemField';
  publish_details?: Maybe<SystemPublishDetails>;
  created_at?: Maybe<Scalars['DateTime']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  updated_by?: Maybe<Scalars['String']>;
  uid?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  version?: Maybe<Scalars['Int']>;
  content_type_uid?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};


export type FeaturedBenefitPayload = {
  __typename?: 'FeaturedBenefitPayload';
  title: Scalars['String'];
  subTitle: Scalars['String'];
  benefit: IAbstractSearchItem;
};

export type FeaturedContentInput = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type FeaturedContentMeta = IMeta & {
  __typename?: 'FeaturedContentMeta';
  count: Scalars['Int'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export type FeaturedContentPayload = {
  __typename?: 'FeaturedContentPayload';
  meta: FeaturedContentMeta;
  data?: Maybe<Array<IAbstractSearchItem>>;
};

export type FilterListItem = {
  __typename?: 'FilterListItem';
  value?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export enum FilterOrder {
  ASC = 'ASC',
  DESC = 'DESC'
}

export enum FilterType {
  KEYWORD = 'KEYWORD',
  LIST = 'LIST',
  DATE = 'DATE'
}

export type Filters = {
  __typename?: 'Filters';
  key?: Maybe<Scalars['String']>;
  type?: Maybe<FilterType>;
  label?: Maybe<Scalars['String']>;
  allLabel?: Maybe<Scalars['String']>;
  values?: Maybe<Array<FilterListItem>>;
};

export type FutureMembership = IMembership & {
  __typename?: 'FutureMembership';
  id: Scalars['ID'];
  memberType: Scalars['String'];
  memberTypeLabel: Scalars['String'];
  memberNumber: Scalars['Int'];
  memberStatus: MemberStatus;
  autoRenew: Scalars['Boolean'];
  orderNumber: Scalars['String'];
  orderLineNumber: Scalars['String'];
  initialBeginDate: Scalars['EpochDate'];
  cycleBeginDate: Scalars['EpochDate'];
  cycleEndDate: Scalars['EpochDate'];
  recordTypeCode?: Maybe<Scalars['String']>;
  chapterName: Scalars['String'];
  chapterURL: Scalars['String'];
};

export enum Gender {
  M = 'M',
  F = 'F',
  O = 'O'
}

export type GroupMeta = {
  __typename?: 'GroupMeta';
  groupUrl: Scalars['String'];
  groupName: Scalars['String'];
  groupAction: Scalars['String'];
};

export type IAbstractSearchItem = {
  id: Scalars['ID'];
  title: Scalars['String'];
  publishDate: Scalars['EpochDate'];
  updateDate: Scalars['EpochDate'];
  description?: Maybe<Scalars['String']>;
  bodyText?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  viewCount: Scalars['Int'];
  contentTypes?: Maybe<TaxonomySearchItem>;
  topics?: Maybe<Array<Scalars['String']>>;
  primaryTopic?: Maybe<Scalars['String']>;
  topicCodes?: Maybe<Array<Scalars['String']>>;
  industryCodes?: Maybe<Array<Scalars['String']>>;
  roleCodes?: Maybe<Array<Scalars['String']>>;
  contentTypesArray?: Maybe<Array<Scalars['String']>>;
  industries?: Maybe<Array<Scalars['String']>>;
  roles?: Maybe<Array<Scalars['String']>>;
  keywords?: Maybe<Array<Scalars['String']>>;
  authors?: Maybe<Array<UserSearchItem>>;
  featured?: Maybe<Scalars['Boolean']>;
  memberBenefit?: Maybe<Scalars['Boolean']>;
  isBookmarkable: Scalars['Boolean'];
  _snippetResult?: Maybe<SnippetResult>;
};

export type IAddress = {
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  address3?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  postalCode: Scalars['String'];
  formattedAddress?: Maybe<Scalars['String']>;
};

export type ICommerceSearchItem = {
  id: Scalars['ID'];
  title: Scalars['String'];
  publishDate: Scalars['EpochDate'];
  updateDate: Scalars['EpochDate'];
  description?: Maybe<Scalars['String']>;
  bodyText?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  viewCount: Scalars['Int'];
  contentTypes?: Maybe<TaxonomySearchItem>;
  topics?: Maybe<Array<Scalars['String']>>;
  primaryTopic?: Maybe<Scalars['String']>;
  topicCodes?: Maybe<Array<Scalars['String']>>;
  industryCodes?: Maybe<Array<Scalars['String']>>;
  roleCodes?: Maybe<Array<Scalars['String']>>;
  contentTypesArray?: Maybe<Array<Scalars['String']>>;
  industries?: Maybe<Array<Scalars['String']>>;
  roles?: Maybe<Array<Scalars['String']>>;
  keywords?: Maybe<Array<Scalars['String']>>;
  authors?: Maybe<Array<UserSearchItem>>;
  featured?: Maybe<Scalars['Boolean']>;
  memberBenefit?: Maybe<Scalars['Boolean']>;
  isBookmarkable: Scalars['Boolean'];
  _snippetResult?: Maybe<SnippetResult>;
  listPrice: Scalars['Int'];
  memberPrice: Scalars['Int'];
  purchasableItems?: Maybe<Array<IPurchasableSearchItem>>;
  availableAsBenefit: Scalars['Boolean'];
};

export type IContentSearchItem = {
  id: Scalars['ID'];
  title: Scalars['String'];
  publishDate: Scalars['EpochDate'];
  updateDate: Scalars['EpochDate'];
  description?: Maybe<Scalars['String']>;
  bodyText?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  viewCount: Scalars['Int'];
  contentTypes?: Maybe<TaxonomySearchItem>;
  topics?: Maybe<Array<Scalars['String']>>;
  primaryTopic?: Maybe<Scalars['String']>;
  topicCodes?: Maybe<Array<Scalars['String']>>;
  industryCodes?: Maybe<Array<Scalars['String']>>;
  roleCodes?: Maybe<Array<Scalars['String']>>;
  contentTypesArray?: Maybe<Array<Scalars['String']>>;
  industries?: Maybe<Array<Scalars['String']>>;
  roles?: Maybe<Array<Scalars['String']>>;
  keywords?: Maybe<Array<Scalars['String']>>;
  authors?: Maybe<Array<UserSearchItem>>;
  featured?: Maybe<Scalars['Boolean']>;
  memberBenefit?: Maybe<Scalars['Boolean']>;
  isBookmarkable: Scalars['Boolean'];
  _snippetResult?: Maybe<SnippetResult>;
  periodical?: Maybe<Scalars['String']>;
  issue?: Maybe<Scalars['String']>;
  column?: Maybe<Scalars['String']>;
  part?: Maybe<Scalars['String']>;
};

export type IMeetingSearchItem = {
  id: Scalars['ID'];
  title: Scalars['String'];
  publishDate: Scalars['EpochDate'];
  updateDate: Scalars['EpochDate'];
  description?: Maybe<Scalars['String']>;
  bodyText?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  viewCount: Scalars['Int'];
  contentTypes?: Maybe<TaxonomySearchItem>;
  topics?: Maybe<Array<Scalars['String']>>;
  primaryTopic?: Maybe<Scalars['String']>;
  topicCodes?: Maybe<Array<Scalars['String']>>;
  industryCodes?: Maybe<Array<Scalars['String']>>;
  roleCodes?: Maybe<Array<Scalars['String']>>;
  contentTypesArray?: Maybe<Array<Scalars['String']>>;
  industries?: Maybe<Array<Scalars['String']>>;
  roles?: Maybe<Array<Scalars['String']>>;
  keywords?: Maybe<Array<Scalars['String']>>;
  authors?: Maybe<Array<UserSearchItem>>;
  featured?: Maybe<Scalars['Boolean']>;
  memberBenefit?: Maybe<Scalars['Boolean']>;
  isBookmarkable: Scalars['Boolean'];
  _snippetResult?: Maybe<SnippetResult>;
  listPrice: Scalars['Int'];
  memberPrice: Scalars['Int'];
  purchasableItems?: Maybe<Array<IPurchasableSearchItem>>;
  availableAsBenefit: Scalars['Boolean'];
  programCode: Scalars['String'];
  subTitle: Scalars['String'];
  eventRegions: Array<Scalars['String']>;
  levels: Array<Scalars['String']>;
  languages: Array<Scalars['String']>;
};

export type IMembership = {
  id: Scalars['ID'];
  memberType: Scalars['String'];
  memberTypeLabel: Scalars['String'];
  memberNumber: Scalars['Int'];
  memberStatus: MemberStatus;
  autoRenew: Scalars['Boolean'];
  orderNumber: Scalars['String'];
  orderLineNumber: Scalars['String'];
  initialBeginDate: Scalars['EpochDate'];
  cycleBeginDate: Scalars['EpochDate'];
  cycleEndDate: Scalars['EpochDate'];
  recordTypeCode?: Maybe<Scalars['String']>;
};

export type IMeta = {
  count: Scalars['Int'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export type IPurchasableSearchItem = {
  id: Scalars['ID'];
  productId: Scalars['Int'];
  productCode: Scalars['String'];
  listPrice: Scalars['String'];
  memberPrice: Scalars['String'];
  externalUrl: Scalars['String'];
  onSale: Scalars['Boolean'];
  memberBenefit: Scalars['Boolean'];
  numberAvailable: Scalars['Int'];
};

export type Job = {
  __typename?: 'Job';
  userIndustry?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  jobFunctionCode?: Maybe<Scalars['String']>;
  jobIndustry?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  companySize?: Maybe<Scalars['String']>;
  trainingBudget?: Maybe<Scalars['String']>;
  directReportCount?: Maybe<Scalars['String']>;
};

export type JobInput = {
  companyName?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  jobFunctionCode?: Maybe<Scalars['String']>;
  jobIndustry?: Maybe<Scalars['String']>;
  companySize?: Maybe<Scalars['String']>;
  trainingBudget?: Maybe<Scalars['String']>;
  directReportCount?: Maybe<Scalars['String']>;
};

export type LandingPageContactFormInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  primaryEmailAddress: Scalars['String'];
  countryCode: Scalars['String'];
  companyName?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  companySize?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  interests?: Maybe<Scalars['String']>;
  isConsent?: Maybe<Scalars['Boolean']>;
  isThirdPartyConsent?: Maybe<Scalars['Boolean']>;
  acceptTerms: Scalars['Boolean'];
};

export type LandingPages = {
  __typename?: 'LandingPages';
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  hide_site_header?: Maybe<Scalars['Boolean']>;
  page_section?: Maybe<Array<Maybe<LandingPagesPageSection>>>;
  seo_fields?: Maybe<Seo>;
  system?: Maybe<EntrySystemField>;
};

export enum LandingPagesOrderBy {
  CREATED_AT_ASC = 'created_at_ASC',
  CREATED_AT_DESC = 'created_at_DESC',
  UPDATED_AT_ASC = 'updated_at_ASC',
  UPDATED_AT_DESC = 'updated_at_DESC'
}

export type LandingPagesPageSection = LandingPagesPageSectionSectionBlock;

export type LandingPagesPageSectionSectionBlock = {
  __typename?: 'LandingPagesPageSectionSectionBlock';
  section_block?: Maybe<LandingPagesPageSectionSectionBlockBlock>;
};

export type LandingPagesPageSectionSectionBlockBlock = {
  __typename?: 'LandingPagesPageSectionSectionBlockBlock';
  section_id?: Maybe<Scalars['String']>;
  section_title?: Maybe<Scalars['String']>;
  section_theme?: Maybe<Scalars['String']>;
  content?: Maybe<Array<Maybe<LandingPagesPageSectionSectionBlockBlockContent>>>;
};

export type LandingPagesPageSectionSectionBlockBlockContent = LandingPagesPageSectionSectionBlockBlockContentHeader | LandingPagesPageSectionSectionBlockBlockContentStepsModule | LandingPagesPageSectionSectionBlockBlockContentFeaturedModule | LandingPagesPageSectionSectionBlockBlockContentImageCtaModule | LandingPagesPageSectionSectionBlockBlockContentColumnModule | LandingPagesPageSectionSectionBlockBlockContentTwoColumnHeaderModule | LandingPagesPageSectionSectionBlockBlockContentImageAndTextModule | LandingPagesPageSectionSectionBlockBlockContentSingleTextModule | LandingPagesPageSectionSectionBlockBlockContentFormModule;

export type LandingPagesPageSectionSectionBlockBlockContentColumnModule = {
  __typename?: 'LandingPagesPageSectionSectionBlockBlockContentColumnModule';
  column_module?: Maybe<LandingPagesPageSectionSectionBlockBlockContentColumnModuleBlock>;
};

export type LandingPagesPageSectionSectionBlockBlockContentColumnModuleBlock = {
  __typename?: 'LandingPagesPageSectionSectionBlockBlockContentColumnModuleBlock';
  column_item?: Maybe<Array<Maybe<LandingPagesPageSectionSectionBlockBlockContentColumnModuleBlockColumnItem>>>;
};

export type LandingPagesPageSectionSectionBlockBlockContentColumnModuleBlockColumnItem = {
  __typename?: 'LandingPagesPageSectionSectionBlockBlockContentColumnModuleBlockColumnItem';
  title?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  button?: Maybe<Link>;
};

export type LandingPagesPageSectionSectionBlockBlockContentColumnModuleBlockColumnItemWhere = {
  title?: Maybe<Scalars['String']>;
  title_ne?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_exists?: Maybe<Scalars['Boolean']>;
  subtitle?: Maybe<Scalars['String']>;
  subtitle_ne?: Maybe<Scalars['String']>;
  subtitle_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  subtitle_nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  subtitle_exists?: Maybe<Scalars['Boolean']>;
  button?: Maybe<LinkWhere>;
  button_exists?: Maybe<Scalars['Boolean']>;
};

export type LandingPagesPageSectionSectionBlockBlockContentColumnModuleBlockWhere = {
  column_item?: Maybe<LandingPagesPageSectionSectionBlockBlockContentColumnModuleBlockColumnItemWhere>;
  column_item_exists?: Maybe<Scalars['Boolean']>;
};

export type LandingPagesPageSectionSectionBlockBlockContentFeaturedModule = {
  __typename?: 'LandingPagesPageSectionSectionBlockBlockContentFeaturedModule';
  featured_module?: Maybe<LandingPagesPageSectionSectionBlockBlockContentFeaturedModuleBlock>;
};

export type LandingPagesPageSectionSectionBlockBlockContentFeaturedModuleBlock = {
  __typename?: 'LandingPagesPageSectionSectionBlockBlockContentFeaturedModuleBlock';
  title?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  theme?: Maybe<Scalars['String']>;
  content?: Maybe<Array<Maybe<LandingPagesPageSectionSectionBlockBlockContentFeaturedModuleBlockContent>>>;
};

export type LandingPagesPageSectionSectionBlockBlockContentFeaturedModuleBlockContent = LandingPagesPageSectionSectionBlockBlockContentFeaturedModuleBlockContentRelationship | LandingPagesPageSectionSectionBlockBlockContentFeaturedModuleBlockContentQuery;

export type LandingPagesPageSectionSectionBlockBlockContentFeaturedModuleBlockContentQuery = {
  __typename?: 'LandingPagesPageSectionSectionBlockBlockContentFeaturedModuleBlockContentQuery';
  query?: Maybe<LandingPagesPageSectionSectionBlockBlockContentFeaturedModuleBlockContentQueryBlock>;
};

export type LandingPagesPageSectionSectionBlockBlockContentFeaturedModuleBlockContentQueryBlock = {
  __typename?: 'LandingPagesPageSectionSectionBlockBlockContentFeaturedModuleBlockContentQueryBlock';
  title?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  taxonomy?: Maybe<Scalars['String']>;
  topic?: Maybe<Array<Maybe<Scalars['String']>>>;
  industry?: Maybe<Array<Maybe<Scalars['String']>>>;
  role?: Maybe<Array<Maybe<Scalars['String']>>>;
  keyword?: Maybe<Scalars['String']>;
  order_by?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
};

export type LandingPagesPageSectionSectionBlockBlockContentFeaturedModuleBlockContentQueryBlockWhere = {
  title?: Maybe<Scalars['String']>;
  title_ne?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_exists?: Maybe<Scalars['Boolean']>;
  subtitle?: Maybe<Scalars['String']>;
  subtitle_ne?: Maybe<Scalars['String']>;
  subtitle_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  subtitle_nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  subtitle_exists?: Maybe<Scalars['Boolean']>;
  taxonomy?: Maybe<Scalars['String']>;
  taxonomy_ne?: Maybe<Scalars['String']>;
  taxonomy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  taxonomy_nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  taxonomy_exists?: Maybe<Scalars['Boolean']>;
  topic?: Maybe<Scalars['String']>;
  topic_ne?: Maybe<Scalars['String']>;
  topic_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  topic_nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  topic_exists?: Maybe<Scalars['Boolean']>;
  industry?: Maybe<Scalars['String']>;
  industry_ne?: Maybe<Scalars['String']>;
  industry_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  industry_nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  industry_exists?: Maybe<Scalars['Boolean']>;
  role?: Maybe<Scalars['String']>;
  role_ne?: Maybe<Scalars['String']>;
  role_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  role_nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  role_exists?: Maybe<Scalars['Boolean']>;
  keyword?: Maybe<Scalars['String']>;
  keyword_ne?: Maybe<Scalars['String']>;
  keyword_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  keyword_nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  keyword_exists?: Maybe<Scalars['Boolean']>;
  order_by?: Maybe<Scalars['String']>;
  order_by_ne?: Maybe<Scalars['String']>;
  order_by_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  order_by_nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  order_by_exists?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['String']>;
  order_ne?: Maybe<Scalars['String']>;
  order_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  order_nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  order_exists?: Maybe<Scalars['Boolean']>;
};

export type LandingPagesPageSectionSectionBlockBlockContentFeaturedModuleBlockContentRelationship = {
  __typename?: 'LandingPagesPageSectionSectionBlockBlockContentFeaturedModuleBlockContentRelationship';
  relationship?: Maybe<LandingPagesPageSectionSectionBlockBlockContentFeaturedModuleBlockContentRelationshipBlock>;
};

export type LandingPagesPageSectionSectionBlockBlockContentFeaturedModuleBlockContentRelationshipBlock = {
  __typename?: 'LandingPagesPageSectionSectionBlockBlockContentFeaturedModuleBlockContentRelationshipBlock';
  title?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  featured_items?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type LandingPagesPageSectionSectionBlockBlockContentFeaturedModuleBlockContentRelationshipBlockWhere = {
  title?: Maybe<Scalars['String']>;
  title_ne?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_exists?: Maybe<Scalars['Boolean']>;
  subtitle?: Maybe<Scalars['String']>;
  subtitle_ne?: Maybe<Scalars['String']>;
  subtitle_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  subtitle_nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  subtitle_exists?: Maybe<Scalars['Boolean']>;
  featured_items?: Maybe<Scalars['String']>;
  featured_items_ne?: Maybe<Scalars['String']>;
  featured_items_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  featured_items_nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  featured_items_exists?: Maybe<Scalars['Boolean']>;
};

export type LandingPagesPageSectionSectionBlockBlockContentFeaturedModuleBlockContentWhere = {
  relationship?: Maybe<LandingPagesPageSectionSectionBlockBlockContentFeaturedModuleBlockContentRelationshipBlockWhere>;
  relationship_exists?: Maybe<Scalars['Boolean']>;
  query?: Maybe<LandingPagesPageSectionSectionBlockBlockContentFeaturedModuleBlockContentQueryBlockWhere>;
  query_exists?: Maybe<Scalars['Boolean']>;
};

export type LandingPagesPageSectionSectionBlockBlockContentFeaturedModuleBlockWhere = {
  title?: Maybe<Scalars['String']>;
  title_ne?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_exists?: Maybe<Scalars['Boolean']>;
  subtitle?: Maybe<Scalars['String']>;
  subtitle_ne?: Maybe<Scalars['String']>;
  subtitle_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  subtitle_nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  subtitle_exists?: Maybe<Scalars['Boolean']>;
  theme?: Maybe<Scalars['String']>;
  theme_ne?: Maybe<Scalars['String']>;
  theme_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  theme_nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  theme_exists?: Maybe<Scalars['Boolean']>;
  content?: Maybe<LandingPagesPageSectionSectionBlockBlockContentFeaturedModuleBlockContentWhere>;
  content_exists?: Maybe<Scalars['Boolean']>;
};

export type LandingPagesPageSectionSectionBlockBlockContentFormModule = {
  __typename?: 'LandingPagesPageSectionSectionBlockBlockContentFormModule';
  form_module?: Maybe<LandingPagesPageSectionSectionBlockBlockContentFormModuleBlock>;
};

export type LandingPagesPageSectionSectionBlockBlockContentFormModuleBlock = {
  __typename?: 'LandingPagesPageSectionSectionBlockBlockContentFormModuleBlock';
  form_title?: Maybe<Scalars['String']>;
};

export type LandingPagesPageSectionSectionBlockBlockContentFormModuleBlockWhere = {
  form_title?: Maybe<Scalars['String']>;
  form_title_exists?: Maybe<Scalars['Boolean']>;
};

export type LandingPagesPageSectionSectionBlockBlockContentHeader = {
  __typename?: 'LandingPagesPageSectionSectionBlockBlockContentHeader';
  header?: Maybe<LandingPagesPageSectionSectionBlockBlockContentHeaderBlock>;
};

export type LandingPagesPageSectionSectionBlockBlockContentHeaderBlock = {
  __typename?: 'LandingPagesPageSectionSectionBlockBlockContentHeaderBlock';
  title?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  imageConnection?: Maybe<SysAssetConnection>;
  button?: Maybe<Link>;
};

export type LandingPagesPageSectionSectionBlockBlockContentHeaderBlockWhere = {
  title?: Maybe<Scalars['String']>;
  title_ne?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_exists?: Maybe<Scalars['Boolean']>;
  subtitle?: Maybe<Scalars['String']>;
  subtitle_ne?: Maybe<Scalars['String']>;
  subtitle_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  subtitle_nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  subtitle_exists?: Maybe<Scalars['Boolean']>;
  image?: Maybe<SysAssetWhere>;
  image_exists?: Maybe<Scalars['Boolean']>;
  button?: Maybe<LinkWhere>;
  button_exists?: Maybe<Scalars['Boolean']>;
};

export type LandingPagesPageSectionSectionBlockBlockContentImageAndTextModule = {
  __typename?: 'LandingPagesPageSectionSectionBlockBlockContentImageAndTextModule';
  image_and_text_module?: Maybe<LandingPagesPageSectionSectionBlockBlockContentImageAndTextModuleBlock>;
};

export type LandingPagesPageSectionSectionBlockBlockContentImageAndTextModuleBlock = {
  __typename?: 'LandingPagesPageSectionSectionBlockBlockContentImageAndTextModuleBlock';
  image_and_text_item?: Maybe<Array<Maybe<LandingPagesPageSectionSectionBlockBlockContentImageAndTextModuleBlockImageAndTextItem>>>;
};

export type LandingPagesPageSectionSectionBlockBlockContentImageAndTextModuleBlockImageAndTextItem = {
  __typename?: 'LandingPagesPageSectionSectionBlockBlockContentImageAndTextModuleBlockImageAndTextItem';
  imageConnection?: Maybe<SysAssetConnection>;
  text?: Maybe<Scalars['String']>;
  button?: Maybe<Link>;
};

export type LandingPagesPageSectionSectionBlockBlockContentImageAndTextModuleBlockImageAndTextItemWhere = {
  image?: Maybe<SysAssetWhere>;
  image_exists?: Maybe<Scalars['Boolean']>;
  text?: Maybe<Scalars['String']>;
  text_ne?: Maybe<Scalars['String']>;
  text_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  text_nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  text_exists?: Maybe<Scalars['Boolean']>;
  button?: Maybe<LinkWhere>;
  button_exists?: Maybe<Scalars['Boolean']>;
};

export type LandingPagesPageSectionSectionBlockBlockContentImageAndTextModuleBlockWhere = {
  image_and_text_item?: Maybe<LandingPagesPageSectionSectionBlockBlockContentImageAndTextModuleBlockImageAndTextItemWhere>;
  image_and_text_item_exists?: Maybe<Scalars['Boolean']>;
};

export type LandingPagesPageSectionSectionBlockBlockContentImageCtaModule = {
  __typename?: 'LandingPagesPageSectionSectionBlockBlockContentImageCtaModule';
  image_cta_module?: Maybe<LandingPagesPageSectionSectionBlockBlockContentImageCtaModuleBlock>;
};

export type LandingPagesPageSectionSectionBlockBlockContentImageCtaModuleBlock = {
  __typename?: 'LandingPagesPageSectionSectionBlockBlockContentImageCtaModuleBlock';
  title?: Maybe<Scalars['String']>;
  imageConnection?: Maybe<SysAssetConnection>;
  image_position?: Maybe<Scalars['String']>;
  eyebrow?: Maybe<Scalars['String']>;
  button?: Maybe<Link>;
};

export type LandingPagesPageSectionSectionBlockBlockContentImageCtaModuleBlockWhere = {
  title?: Maybe<Scalars['String']>;
  title_ne?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_exists?: Maybe<Scalars['Boolean']>;
  image?: Maybe<SysAssetWhere>;
  image_exists?: Maybe<Scalars['Boolean']>;
  image_position?: Maybe<Scalars['String']>;
  image_position_ne?: Maybe<Scalars['String']>;
  image_position_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  image_position_nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  image_position_exists?: Maybe<Scalars['Boolean']>;
  eyebrow?: Maybe<Scalars['String']>;
  eyebrow_ne?: Maybe<Scalars['String']>;
  eyebrow_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  eyebrow_nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  eyebrow_exists?: Maybe<Scalars['Boolean']>;
  button?: Maybe<LinkWhere>;
  button_exists?: Maybe<Scalars['Boolean']>;
};

export type LandingPagesPageSectionSectionBlockBlockContentSingleTextModule = {
  __typename?: 'LandingPagesPageSectionSectionBlockBlockContentSingleTextModule';
  single_text_module?: Maybe<LandingPagesPageSectionSectionBlockBlockContentSingleTextModuleBlock>;
};

export type LandingPagesPageSectionSectionBlockBlockContentSingleTextModuleBlock = {
  __typename?: 'LandingPagesPageSectionSectionBlockBlockContentSingleTextModuleBlock';
  text?: Maybe<Scalars['String']>;
};

export type LandingPagesPageSectionSectionBlockBlockContentSingleTextModuleBlockWhere = {
  text?: Maybe<Scalars['String']>;
  text_ne?: Maybe<Scalars['String']>;
  text_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  text_nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  text_exists?: Maybe<Scalars['Boolean']>;
};

export type LandingPagesPageSectionSectionBlockBlockContentStepsModule = {
  __typename?: 'LandingPagesPageSectionSectionBlockBlockContentStepsModule';
  steps_module?: Maybe<LandingPagesPageSectionSectionBlockBlockContentStepsModuleBlock>;
};

export type LandingPagesPageSectionSectionBlockBlockContentStepsModuleBlock = {
  __typename?: 'LandingPagesPageSectionSectionBlockBlockContentStepsModuleBlock';
  step?: Maybe<Array<Maybe<LandingPagesPageSectionSectionBlockBlockContentStepsModuleBlockStep>>>;
  button?: Maybe<Link>;
};

export type LandingPagesPageSectionSectionBlockBlockContentStepsModuleBlockStep = {
  __typename?: 'LandingPagesPageSectionSectionBlockBlockContentStepsModuleBlockStep';
  text?: Maybe<Scalars['String']>;
};

export type LandingPagesPageSectionSectionBlockBlockContentStepsModuleBlockStepWhere = {
  text?: Maybe<Scalars['String']>;
  text_ne?: Maybe<Scalars['String']>;
  text_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  text_nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  text_exists?: Maybe<Scalars['Boolean']>;
};

export type LandingPagesPageSectionSectionBlockBlockContentStepsModuleBlockWhere = {
  step?: Maybe<LandingPagesPageSectionSectionBlockBlockContentStepsModuleBlockStepWhere>;
  step_exists?: Maybe<Scalars['Boolean']>;
  button?: Maybe<LinkWhere>;
  button_exists?: Maybe<Scalars['Boolean']>;
};

export type LandingPagesPageSectionSectionBlockBlockContentTwoColumnHeaderModule = {
  __typename?: 'LandingPagesPageSectionSectionBlockBlockContentTwoColumnHeaderModule';
  two_column_header_module?: Maybe<LandingPagesPageSectionSectionBlockBlockContentTwoColumnHeaderModuleBlock>;
};

export type LandingPagesPageSectionSectionBlockBlockContentTwoColumnHeaderModuleBlock = {
  __typename?: 'LandingPagesPageSectionSectionBlockBlockContentTwoColumnHeaderModuleBlock';
  title?: Maybe<Scalars['String']>;
  imageConnection?: Maybe<SysAssetConnection>;
  column_item?: Maybe<Array<Maybe<LandingPagesPageSectionSectionBlockBlockContentTwoColumnHeaderModuleBlockColumnItem>>>;
};

export type LandingPagesPageSectionSectionBlockBlockContentTwoColumnHeaderModuleBlockColumnItem = {
  __typename?: 'LandingPagesPageSectionSectionBlockBlockContentTwoColumnHeaderModuleBlockColumnItem';
  title?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
};

export type LandingPagesPageSectionSectionBlockBlockContentTwoColumnHeaderModuleBlockColumnItemWhere = {
  title?: Maybe<Scalars['String']>;
  title_ne?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_exists?: Maybe<Scalars['Boolean']>;
  subtitle?: Maybe<Scalars['String']>;
  subtitle_ne?: Maybe<Scalars['String']>;
  subtitle_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  subtitle_nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  subtitle_exists?: Maybe<Scalars['Boolean']>;
};

export type LandingPagesPageSectionSectionBlockBlockContentTwoColumnHeaderModuleBlockWhere = {
  title?: Maybe<Scalars['String']>;
  title_ne?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_exists?: Maybe<Scalars['Boolean']>;
  image?: Maybe<SysAssetWhere>;
  image_exists?: Maybe<Scalars['Boolean']>;
  column_item?: Maybe<LandingPagesPageSectionSectionBlockBlockContentTwoColumnHeaderModuleBlockColumnItemWhere>;
  column_item_exists?: Maybe<Scalars['Boolean']>;
};

export type LandingPagesPageSectionSectionBlockBlockContentWhere = {
  header?: Maybe<LandingPagesPageSectionSectionBlockBlockContentHeaderBlockWhere>;
  header_exists?: Maybe<Scalars['Boolean']>;
  steps_module?: Maybe<LandingPagesPageSectionSectionBlockBlockContentStepsModuleBlockWhere>;
  steps_module_exists?: Maybe<Scalars['Boolean']>;
  featured_module?: Maybe<LandingPagesPageSectionSectionBlockBlockContentFeaturedModuleBlockWhere>;
  featured_module_exists?: Maybe<Scalars['Boolean']>;
  form_module?: Maybe<LandingPagesPageSectionSectionBlockBlockContentFormModuleBlockWhere>;
  form_module_exists?: Maybe<Scalars['Boolean']>;
  image_cta_module?: Maybe<LandingPagesPageSectionSectionBlockBlockContentImageCtaModuleBlockWhere>;
  image_cta_module_exists?: Maybe<Scalars['Boolean']>;
  column_module?: Maybe<LandingPagesPageSectionSectionBlockBlockContentColumnModuleBlockWhere>;
  column_module_exists?: Maybe<Scalars['Boolean']>;
  two_column_header_module?: Maybe<LandingPagesPageSectionSectionBlockBlockContentTwoColumnHeaderModuleBlockWhere>;
  two_column_header_module_exists?: Maybe<Scalars['Boolean']>;
  image_and_text_module?: Maybe<LandingPagesPageSectionSectionBlockBlockContentImageAndTextModuleBlockWhere>;
  image_and_text_module_exists?: Maybe<Scalars['Boolean']>;
  single_text_module?: Maybe<LandingPagesPageSectionSectionBlockBlockContentSingleTextModuleBlockWhere>;
  single_text_module_exists?: Maybe<Scalars['Boolean']>;
};

export type LandingPagesPageSectionSectionBlockBlockWhere = {
  section_id?: Maybe<Scalars['String']>;
  section_id_ne?: Maybe<Scalars['String']>;
  section_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  section_id_nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  section_id_exists?: Maybe<Scalars['Boolean']>;
  section_title?: Maybe<Scalars['String']>;
  section_title_ne?: Maybe<Scalars['String']>;
  section_title_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  section_title_nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  section_title_exists?: Maybe<Scalars['Boolean']>;
  section_theme?: Maybe<Scalars['String']>;
  section_theme_ne?: Maybe<Scalars['String']>;
  section_theme_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  section_theme_nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  section_theme_exists?: Maybe<Scalars['Boolean']>;
  content?: Maybe<LandingPagesPageSectionSectionBlockBlockContentWhere>;
  content_exists?: Maybe<Scalars['Boolean']>;
};

export type LandingPagesPageSectionWhere = {
  section_block?: Maybe<LandingPagesPageSectionSectionBlockBlockWhere>;
  section_block_exists?: Maybe<Scalars['Boolean']>;
};

export type LandingPagesWhere = {
  created_at?: Maybe<Scalars['DateTime']>;
  created_at_in?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  created_at_nin?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  created_at_ne?: Maybe<Scalars['DateTime']>;
  created_at_lt?: Maybe<Scalars['DateTime']>;
  created_at_lte?: Maybe<Scalars['DateTime']>;
  created_at_gt?: Maybe<Scalars['DateTime']>;
  created_at_gte?: Maybe<Scalars['DateTime']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  updated_at_in?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  updated_at_nin?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  updated_at_ne?: Maybe<Scalars['DateTime']>;
  updated_at_lt?: Maybe<Scalars['DateTime']>;
  updated_at_lte?: Maybe<Scalars['DateTime']>;
  updated_at_gt?: Maybe<Scalars['DateTime']>;
  updated_at_gte?: Maybe<Scalars['DateTime']>;
  locale?: Maybe<Scalars['String']>;
  locale_ne?: Maybe<Scalars['String']>;
  locale_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  locale_nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  locale_exists?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Scalars['String']>;
  uid_ne?: Maybe<Scalars['String']>;
  uid_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  uid_nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  uid_exists?: Maybe<Scalars['Boolean']>;
  tags_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  tags_nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Scalars['String']>;
  title_ne?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_exists?: Maybe<Scalars['Boolean']>;
  url?: Maybe<Scalars['String']>;
  url_ne?: Maybe<Scalars['String']>;
  url_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  url_nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  url_exists?: Maybe<Scalars['Boolean']>;
  hide_site_header?: Maybe<Scalars['Boolean']>;
  hide_site_header_exists?: Maybe<Scalars['Boolean']>;
  hide_site_header_ne?: Maybe<Scalars['Float']>;
  page_section?: Maybe<LandingPagesPageSectionWhere>;
  page_section_exists?: Maybe<Scalars['Boolean']>;
  seo_fields?: Maybe<SeoWhere>;
  seo_fields_exists?: Maybe<Scalars['Boolean']>;
  AND?: Maybe<Array<Maybe<LandingPagesWhere>>>;
  OR?: Maybe<Array<Maybe<LandingPagesWhere>>>;
};

export type LearningActivityFilterInput = {
  type?: Maybe<Array<ContentType>>;
  createdDateStart?: Maybe<Scalars['EpochDate']>;
  createdDateEnd?: Maybe<Scalars['EpochDate']>;
  statusSort?: Maybe<Scalars['String']>;
  titleSort?: Maybe<Scalars['String']>;
  dateFilters?: Maybe<LearningActivityFilterInputDate>;
  dateRangeFilters?: Maybe<LearningActivityFilterInputDateRange>;
};

export type LearningActivityFilterInputDate = {
  dateSort?: Maybe<Scalars['Float']>;
  dateSortType?: Maybe<Scalars['String']>;
};

export type LearningActivityFilterInputDateRange = {
  startDate?: Maybe<Scalars['Float']>;
  endDate?: Maybe<Scalars['Float']>;
};

export type LearningActivityFilterOutput = {
  __typename?: 'LearningActivityFilterOutput';
  type?: Maybe<Array<ContentType>>;
  createdDateStart?: Maybe<Scalars['EpochDate']>;
  createdDateEnd?: Maybe<Scalars['EpochDate']>;
};

export type LearningActivityInput = {
  pageSize?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  filters?: Maybe<LearningActivityFilterInput>;
  orderBy?: Maybe<LearningActivityOrderByInput>;
};

export type LearningActivityMeta = IMeta & {
  __typename?: 'LearningActivityMeta';
  count: Scalars['Int'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalPages: Scalars['Int'];
  filters?: Maybe<LearningActivityFilterOutput>;
  orderBy?: Maybe<LearningActivityOrderByOutput>;
};

export type LearningActivityOrderByInput = {
  value?: Maybe<LearningActivityOrderByList>;
  order?: Maybe<FilterOrder>;
};

export enum LearningActivityOrderByList {
  TITLE = 'TITLE',
  STATUS = 'STATUS'
}

export type LearningActivityOrderByOutput = {
  __typename?: 'LearningActivityOrderByOutput';
  value?: Maybe<LearningActivityOrderByList>;
  order?: Maybe<FilterOrder>;
};

export type LearningCertificate = {
  __typename?: 'LearningCertificate';
  title: Scalars['String'];
  status: Scalars['String'];
  customerId: Scalars['String'];
};

export type LearningCertificatePayload = {
  __typename?: 'LearningCertificatePayload';
  meta?: Maybe<LearningActivityMeta>;
  data: Array<LearningCertificate>;
};

export type LearningCoursePayload = {
  __typename?: 'LearningCoursePayload';
  meta?: Maybe<LearningActivityMeta>;
  data: Array<UserLearningActivityItem>;
};

export type LearningSummaryItem = {
  __typename?: 'LearningSummaryItem';
  id: Scalars['ID'];
  url?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type: Scalars['String'];
  date: Scalars['EpochDate'];
};

export type LearningSummaryPayload = {
  __typename?: 'LearningSummaryPayload';
  data: Array<LearningSummaryItem>;
};

export type Link = {
  __typename?: 'Link';
  href?: Maybe<Scalars['String']>;
  linkType?: Maybe<LinkType>;
  title: Scalars['String'];
  url: Scalars['String'];
};

export enum LinkType {
  INTERNAL = 'INTERNAL',
  EXTERNAL = 'EXTERNAL',
  DOWNLOAD = 'DOWNLOAD'
}

export type LinkWhere = {
  title?: Maybe<Scalars['String']>;
  title_ne?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_exists?: Maybe<Scalars['Boolean']>;
  href?: Maybe<Scalars['String']>;
  href_ne?: Maybe<Scalars['String']>;
  href_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  href_nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  href_exists?: Maybe<Scalars['Boolean']>;
};

export type List = {
  __typename?: 'List';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type LoginInput = {
  accessToken: Scalars['String'];
};

export type MediaFormatSearchItem = {
  __typename?: 'MediaFormatSearchItem';
  name?: Maybe<Scalars['String']>;
  fileFormats?: Maybe<Array<Scalars['String']>>;
  productInfo?: Maybe<ProductInfoSearchItem>;
};

export type MeetingSessionSearchItem = IAbstractSearchItem & {
  __typename?: 'MeetingSessionSearchItem';
  id: Scalars['ID'];
  title: Scalars['String'];
  publishDate: Scalars['EpochDate'];
  updateDate: Scalars['EpochDate'];
  description?: Maybe<Scalars['String']>;
  bodyText?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  viewCount: Scalars['Int'];
  contentTypes?: Maybe<TaxonomySearchItem>;
  topics?: Maybe<Array<Scalars['String']>>;
  primaryTopic?: Maybe<Scalars['String']>;
  topicCodes?: Maybe<Array<Scalars['String']>>;
  industryCodes?: Maybe<Array<Scalars['String']>>;
  roleCodes?: Maybe<Array<Scalars['String']>>;
  contentTypesArray?: Maybe<Array<Scalars['String']>>;
  industries?: Maybe<Array<Scalars['String']>>;
  roles?: Maybe<Array<Scalars['String']>>;
  keywords?: Maybe<Array<Scalars['String']>>;
  authors?: Maybe<Array<UserSearchItem>>;
  featured?: Maybe<Scalars['Boolean']>;
  memberBenefit?: Maybe<Scalars['Boolean']>;
  isBookmarkable: Scalars['Boolean'];
  _snippetResult?: Maybe<SnippetResult>;
  sessionDescription?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  beginDate?: Maybe<Scalars['EpochDate']>;
  endDate?: Maybe<Scalars['EpochDate']>;
  deliveryMethods?: Maybe<Array<Scalars['String']>>;
  duration?: Maybe<Scalars['String']>;
  facilityName?: Maybe<Scalars['String']>;
  facilityAddress?: Maybe<Scalars['String']>;
  facilityDescription?: Maybe<Scalars['String']>;
  facilitators?: Maybe<Array<UserSearchItem>>;
  productInfo?: Maybe<ProductInfoSearchItem>;
  status?: Maybe<CourseStatus>;
};

export enum MemberStatus {
  NEW = 'NEW',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  FUTURE = 'FUTURE',
  RENEWED = 'RENEWED'
}

export type Membership = {
  __typename?: 'Membership';
  id: Scalars['ID'];
  isMember: Scalars['Boolean'];
  isEnterpriseMember: Scalars['Boolean'];
  overview?: Maybe<MembershipOverview>;
  currentMembership?: Maybe<CurrentMembership>;
  futureMembership?: Maybe<Array<FutureMembership>>;
  chapterMembership?: Maybe<Array<ChapterMembership>>;
  benefitResources: Array<Link>;
  benefits: Array<Benefit>;
  additionalMemberResources: Array<Link>;
  claimResource: ClaimResource;
};

export type MembershipOverview = {
  __typename?: 'MembershipOverview';
  type?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  perks?: Maybe<Array<Perk>>;
};

export type MenuItem = {
  __typename?: 'MenuItem';
  type?: Maybe<MenuItemType>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  buttonText?: Maybe<Scalars['String']>;
  buttonUrl?: Maybe<Scalars['String']>;
  flair?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  target?: Maybe<TargetType>;
  children?: Maybe<Array<MenuItem>>;
};

export enum MenuItemType {
  PARENT = 'PARENT',
  GROUP = 'GROUP',
  DEFAULT = 'DEFAULT',
  BUTTON = 'BUTTON',
  MEDIA = 'MEDIA'
}

export type MenuPayload = {
  __typename?: 'MenuPayload';
  menuData: Array<MenuItem>;
  bannerData?: Maybe<Banner>;
};

export type Mutation = {
  __typename?: 'Mutation';
  landingPageContactFormUpdate: ResMsg;
  updateEmail: ResMsg;
  userUpsert: ResMsg;
  userProfileUpdate: ResMsg;
  userJobUpdate: ResMsg;
  userEducationUpsert: ResMsg;
  userEducationDelete: ResMsg;
  userContactInfoUpdate: ResMsg;
  userPhoneDelete: ResMsg;
  userAddressDelete: ResMsg;
  communicationPreferencesUpdate: ResMsg;
  subscribeTopic: ResMsg;
  unsubscribeTopic: ResMsg;
  toggleProfilePrivacy: ResMsg;
  toggleMemberDirectoryPrivacy: ResMsg;
  updatePassword: ResMsg;
  uploadFile: ResMsg;
  removeFile: ResMsg;
  updateOrderAutoRenewal: ResMsg;
  login: Scalars['Boolean'];
  subscribeUserGroup: ResMsg;
  unsubscribeUserGroup: ResMsg;
  createBookmark: ResMsg;
  deleteBookmark: ResMsg;
  subscribePublicUser: ResMsg;
  unsubscribePublicUser: ResMsg;
};


export type MutationLandingPageContactFormUpdateArgs = {
  input: LandingPageContactFormInput;
};


export type MutationUpdateEmailArgs = {
  input: UpdateEmailInput;
};


export type MutationUserUpsertArgs = {
  input: UpsertUserInput;
};


export type MutationUserProfileUpdateArgs = {
  input: ProfileInput;
};


export type MutationUserJobUpdateArgs = {
  input: JobInput;
};


export type MutationUserEducationUpsertArgs = {
  input: Array<EducationUpsertInput>;
};


export type MutationUserEducationDeleteArgs = {
  input: EducationDeleteInput;
};


export type MutationUserContactInfoUpdateArgs = {
  input: ContactInfoInput;
};


export type MutationUserPhoneDeleteArgs = {
  input: PhoneDeleteInput;
};


export type MutationUserAddressDeleteArgs = {
  input: AddressDeleteInput;
};


export type MutationCommunicationPreferencesUpdateArgs = {
  input: CommunicationPreferencesInput;
};


export type MutationSubscribeTopicArgs = {
  input: SubscribeTopicInput;
};


export type MutationUnsubscribeTopicArgs = {
  input: SubscribeTopicInput;
};


export type MutationToggleProfilePrivacyArgs = {
  input: Scalars['Boolean'];
};


export type MutationToggleMemberDirectoryPrivacyArgs = {
  input: Scalars['Boolean'];
};


export type MutationUpdatePasswordArgs = {
  input: UpdatePasswordInput;
};


export type MutationUploadFileArgs = {
  input: UpdateProfilePicture;
};


export type MutationUpdateOrderAutoRenewalArgs = {
  input: UpdateOrderAutoRenewalInput;
};


export type MutationLoginArgs = {
  input: LoginInput;
};


export type MutationSubscribeUserGroupArgs = {
  input: SubscribeUserGroupInput;
};


export type MutationUnsubscribeUserGroupArgs = {
  input: SubscribeUserGroupInput;
};


export type MutationCreateBookmarkArgs = {
  input: UpdateBookmarkInput;
};


export type MutationDeleteBookmarkArgs = {
  input: UpdateBookmarkInput;
};


export type MutationSubscribePublicUserArgs = {
  input: SubscribePublicUserInput;
};


export type MutationUnsubscribePublicUserArgs = {
  input: SubscribePublicUserInput;
};

export type NetworkActivityFilterInput = {
  type?: Maybe<Array<ContentType>>;
  createdDateStart?: Maybe<Scalars['EpochDate']>;
  createdDateEnd?: Maybe<Scalars['EpochDate']>;
};

export type NetworkActivityFilterOutput = {
  __typename?: 'NetworkActivityFilterOutput';
  type?: Maybe<Array<ContentType>>;
  createdDateStart?: Maybe<Scalars['EpochDate']>;
  createdDateEnd?: Maybe<Scalars['EpochDate']>;
};

export type NetworkActivityInput = {
  pageSize?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  filters?: Maybe<NetworkActivityFilterInput>;
  orderBy?: Maybe<NetworkActivityOrderByInput>;
};

export type NetworkActivityItem = {
  __typename?: 'NetworkActivityItem';
  id: Scalars['ID'];
  content?: Maybe<IAbstractSearchItem>;
  user: UserSearchItem;
  date: Scalars['EpochDate'];
  action: Scalars['String'];
  groupMeta?: Maybe<GroupMeta>;
};

export type NetworkActivityMeta = IMeta & {
  __typename?: 'NetworkActivityMeta';
  count: Scalars['Int'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalPages: Scalars['Int'];
  filters?: Maybe<NetworkActivityFilterOutput>;
  orderBy?: Maybe<NetworkActivityOrderByOutput>;
};

export type NetworkActivityOrderByInput = {
  value?: Maybe<NetworkActivityOrderByList>;
  order?: Maybe<FilterOrder>;
};

export enum NetworkActivityOrderByList {
  CREATED_DATE = 'CREATED_DATE'
}

export type NetworkActivityOrderByOutput = {
  __typename?: 'NetworkActivityOrderByOutput';
  value?: Maybe<NetworkActivityOrderByList>;
  order?: Maybe<FilterOrder>;
};

export type NetworkActivityPayload = {
  __typename?: 'NetworkActivityPayload';
  meta?: Maybe<NetworkActivityMeta>;
  data: Array<NetworkActivityItem>;
};

export type NewsletterGroup = {
  __typename?: 'NewsletterGroup';
  newsletterGroup: Array<NewsletterItem>;
  newsletterGroupLabel?: Maybe<Scalars['String']>;
};

export type NewsletterItem = {
  __typename?: 'NewsletterItem';
  key: Scalars['String'];
  value: Scalars['Boolean'];
  label?: Maybe<Scalars['String']>;
  frequency: Scalars['String'];
};

export type Order = {
  __typename?: 'Order';
  id: Scalars['ID'];
  date?: Maybe<Scalars['EpochDate']>;
  totalPrice: Scalars['Int'];
  shippedTo?: Maybe<Scalars['String']>;
  orderNumber: Scalars['String'];
  receiptUrl?: Maybe<Scalars['String']>;
  lineItems: Array<OrderLineItem>;
};

export type OrderInfoPayload = {
  __typename?: 'OrderInfoPayload';
  billAddressId: Scalars['String'];
};

export type OrderInvoiceUrlPayload = {
  __typename?: 'OrderInvoiceUrlPayload';
  url: Scalars['String'];
};

export type OrderLineItem = {
  __typename?: 'OrderLineItem';
  id: Scalars['ID'];
  itemUrl: Scalars['String'];
  downloadUrl?: Maybe<Scalars['String']>;
  productId: Scalars['Int'];
  title: Scalars['String'];
  price: Scalars['Int'];
  imageUrl?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  orderLineNumber: Scalars['Int'];
  orderNumber: Scalars['String'];
  autoRenew: Scalars['Boolean'];
};

export type OrderNumberInput = {
  orderNumber: Scalars['String'];
};

export enum OrderType {
  DIGITAL = 'DIGITAL',
  SUBSCRIPTION = 'SUBSCRIPTION'
}

export type OrdersFilterInput = {
  search?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['Int']>;
  orderType?: Maybe<OrderType>;
  dateRange?: Maybe<Scalars['Float']>;
};

export type OrdersFilterOutput = {
  __typename?: 'OrdersFilterOutput';
  search?: Maybe<Scalars['String']>;
};

export type OrdersInput = {
  filters?: Maybe<OrdersFilterInput>;
  orderBy?: Maybe<OrdersOrderByInput>;
  pageSize?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};

export type OrdersInvoiceUrlInput = {
  orderNumber: Scalars['String'];
};

export type OrdersMeta = IMeta & {
  __typename?: 'OrdersMeta';
  count: Scalars['Int'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalPages: Scalars['Int'];
  filters?: Maybe<OrdersFilterOutput>;
  orderBy?: Maybe<OrdersOrderByOutput>;
};

export type OrdersOrderByInput = {
  value?: Maybe<UsersOrderByList>;
  order?: Maybe<FilterOrder>;
};

export type OrdersOrderByOutput = {
  __typename?: 'OrdersOrderByOutput';
  value?: Maybe<UsersOrderByList>;
  order?: Maybe<FilterOrder>;
};

export type OrdersPayload = {
  __typename?: 'OrdersPayload';
  meta?: Maybe<OrdersMeta>;
  data: Array<Order>;
  years: Array<Scalars['Float']>;
};

export type PageSearchItem = IContentSearchItem & IAbstractSearchItem & {
  __typename?: 'PageSearchItem';
  id: Scalars['ID'];
  title: Scalars['String'];
  publishDate: Scalars['EpochDate'];
  updateDate: Scalars['EpochDate'];
  description?: Maybe<Scalars['String']>;
  bodyText?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  viewCount: Scalars['Int'];
  contentTypes?: Maybe<TaxonomySearchItem>;
  topics?: Maybe<Array<Scalars['String']>>;
  primaryTopic?: Maybe<Scalars['String']>;
  topicCodes?: Maybe<Array<Scalars['String']>>;
  industryCodes?: Maybe<Array<Scalars['String']>>;
  roleCodes?: Maybe<Array<Scalars['String']>>;
  contentTypesArray?: Maybe<Array<Scalars['String']>>;
  industries?: Maybe<Array<Scalars['String']>>;
  roles?: Maybe<Array<Scalars['String']>>;
  keywords?: Maybe<Array<Scalars['String']>>;
  authors?: Maybe<Array<UserSearchItem>>;
  featured?: Maybe<Scalars['Boolean']>;
  memberBenefit?: Maybe<Scalars['Boolean']>;
  isBookmarkable: Scalars['Boolean'];
  _snippetResult?: Maybe<SnippetResult>;
  periodical?: Maybe<Scalars['String']>;
  issue?: Maybe<Scalars['String']>;
  column?: Maybe<Scalars['String']>;
  part?: Maybe<Scalars['String']>;
  isBookmarked: Scalars['Boolean'];
};

export type Perk = {
  __typename?: 'Perk';
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type Phone = {
  __typename?: 'Phone';
  id: Scalars['ID'];
  isPrimary: Scalars['Boolean'];
  doNotCallFlag: Scalars['Boolean'];
  formattedPhoneAddress: Scalars['String'];
  phoneAreaCode: Scalars['String'];
  countryCode: Scalars['String'];
  phoneCountryCode: Scalars['String'];
  phoneExtension: Scalars['String'];
  phoneNumber: Scalars['String'];
  commTypeCodeString: Scalars['String'];
  commLocationCodeString: Scalars['String'];
};

export type PhoneDeleteInput = {
  commTypeCodeString: Scalars['String'];
  commLocationCodeString: Scalars['String'];
};

export type PhoneInput = {
  phoneId: Scalars['String'];
  isPrimary: Scalars['Boolean'];
  doNotCallFlag: Scalars['Boolean'];
  phoneAreaCode: Scalars['String'];
  countryCode: Scalars['String'];
  phoneCountryCode: Scalars['String'];
  phoneNumber: Scalars['String'];
  commTypeCodeString: Scalars['String'];
  commLocationCodeString: Scalars['String'];
  phoneExtension: Scalars['String'];
};

export type PodcastSearchItem = IAbstractSearchItem & {
  __typename?: 'PodcastSearchItem';
  id: Scalars['ID'];
  title: Scalars['String'];
  publishDate: Scalars['EpochDate'];
  updateDate: Scalars['EpochDate'];
  description?: Maybe<Scalars['String']>;
  bodyText?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  viewCount: Scalars['Int'];
  contentTypes?: Maybe<TaxonomySearchItem>;
  topics?: Maybe<Array<Scalars['String']>>;
  primaryTopic?: Maybe<Scalars['String']>;
  topicCodes?: Maybe<Array<Scalars['String']>>;
  industryCodes?: Maybe<Array<Scalars['String']>>;
  roleCodes?: Maybe<Array<Scalars['String']>>;
  contentTypesArray?: Maybe<Array<Scalars['String']>>;
  industries?: Maybe<Array<Scalars['String']>>;
  roles?: Maybe<Array<Scalars['String']>>;
  keywords?: Maybe<Array<Scalars['String']>>;
  authors?: Maybe<Array<UserSearchItem>>;
  featured?: Maybe<Scalars['Boolean']>;
  memberBenefit?: Maybe<Scalars['Boolean']>;
  isBookmarkable: Scalars['Boolean'];
  _snippetResult?: Maybe<SnippetResult>;
  subTitle: Scalars['String'];
  duration?: Maybe<Scalars['Int']>;
  season?: Maybe<Scalars['Int']>;
  episode?: Maybe<Scalars['Int']>;
  isBookmarked: Scalars['Boolean'];
};

export type PreferencesInput = {
  email: Scalars['String'];
};

export type PresenterItem = {
  __typename?: 'PresenterItem';
  fullName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  positionTitle?: Maybe<Scalars['String']>;
  biography?: Maybe<Scalars['String']>;
  profileImageUrl?: Maybe<Scalars['String']>;
};

export type ProductInfoSearchItem = {
  __typename?: 'ProductInfoSearchItem';
  id: Scalars['ID'];
  productId?: Maybe<Scalars['String']>;
  productCode?: Maybe<Scalars['String']>;
  nonMemberPrice?: Maybe<Scalars['String']>;
  memberPrice?: Maybe<Scalars['String']>;
  externalUrl?: Maybe<Scalars['String']>;
  onSale?: Maybe<Scalars['Boolean']>;
  memberBenefit?: Maybe<Scalars['Boolean']>;
  numberAvailable?: Maybe<Scalars['String']>;
};

export type Profile = {
  __typename?: 'Profile';
  userName: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  profileImageUrl?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  website?: Maybe<Scalars['String']>;
  specialNeeds?: Maybe<SpecialNeeds>;
};

export type ProfileInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  bio?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  website?: Maybe<Scalars['String']>;
};

export enum PublicUserConnectionType {
  FOLLOWED = 'FOLLOWED',
  FOLLOWING = 'FOLLOWING',
  RECOMMENDED = 'RECOMMENDED'
}

export type PublicUsersFilterInput = {
  type: PublicUserConnectionType;
};

export type PublicUsersFilterOutput = {
  __typename?: 'PublicUsersFilterOutput';
  type: PublicUserConnectionType;
};

export type PublicUsersInput = {
  pageSize?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  filters: PublicUsersFilterInput;
  orderBy?: Maybe<PublicUsersOrderByInput>;
};

export type PublicUsersMeta = IMeta & {
  __typename?: 'PublicUsersMeta';
  count: Scalars['Int'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalPages: Scalars['Int'];
  filters: PublicUsersFilterOutput;
  orderBy?: Maybe<PublicUsersOrderByOutput>;
};

export type PublicUsersOrderByInput = {
  value?: Maybe<PublicUsersOrderByList>;
  order?: Maybe<FilterOrder>;
};

export enum PublicUsersOrderByList {
  CREATED_DATE = 'CREATED_DATE'
}

export type PublicUsersOrderByOutput = {
  __typename?: 'PublicUsersOrderByOutput';
  value?: Maybe<PublicUsersOrderByList>;
  order?: Maybe<FilterOrder>;
};

export type PublicUsersPayload = {
  __typename?: 'PublicUsersPayload';
  meta?: Maybe<PublicUsersMeta>;
  data: Array<UserSearchItem>;
};

export type PublicationPurchasableSearchItem = IPurchasableSearchItem & {
  __typename?: 'PublicationPurchasableSearchItem';
  id: Scalars['ID'];
  productId: Scalars['Int'];
  productCode: Scalars['String'];
  listPrice: Scalars['String'];
  memberPrice: Scalars['String'];
  externalUrl: Scalars['String'];
  onSale: Scalars['Boolean'];
  memberBenefit: Scalars['Boolean'];
  numberAvailable: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  fileFormats?: Maybe<Array<Scalars['String']>>;
};

export type PublicationSearchItem = ICommerceSearchItem & IAbstractSearchItem & {
  __typename?: 'PublicationSearchItem';
  id: Scalars['ID'];
  title: Scalars['String'];
  publishDate: Scalars['EpochDate'];
  updateDate: Scalars['EpochDate'];
  description?: Maybe<Scalars['String']>;
  bodyText?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  viewCount: Scalars['Int'];
  contentTypes?: Maybe<TaxonomySearchItem>;
  topics?: Maybe<Array<Scalars['String']>>;
  primaryTopic?: Maybe<Scalars['String']>;
  topicCodes?: Maybe<Array<Scalars['String']>>;
  industryCodes?: Maybe<Array<Scalars['String']>>;
  roleCodes?: Maybe<Array<Scalars['String']>>;
  contentTypesArray?: Maybe<Array<Scalars['String']>>;
  industries?: Maybe<Array<Scalars['String']>>;
  roles?: Maybe<Array<Scalars['String']>>;
  keywords?: Maybe<Array<Scalars['String']>>;
  authors?: Maybe<Array<UserSearchItem>>;
  featured?: Maybe<Scalars['Boolean']>;
  memberBenefit?: Maybe<Scalars['Boolean']>;
  isBookmarkable: Scalars['Boolean'];
  _snippetResult?: Maybe<SnippetResult>;
  listPrice: Scalars['Int'];
  memberPrice: Scalars['Int'];
  purchasableItems?: Maybe<Array<IPurchasableSearchItem>>;
  availableAsBenefit: Scalars['Boolean'];
  isBookmarked: Scalars['Boolean'];
};

export type Query = {
  __typename?: 'Query';
  activity: NetworkActivityPayload;
  algoliaContent: AlgoliaContentPayload;
  all_assets?: Maybe<AllSysAsset>;
  all_landing_pages?: Maybe<AllLandingPages>;
  all_testalgolia?: Maybe<AllTestalgolia>;
  assets?: Maybe<SysAsset>;
  autoRenewLowValueTokenDetails: AutoRenewLowValueTokenDetails;
  conferences: ConferencePayload;
  constants: ConstantsPayload;
  courseCertificates: LearningCertificatePayload;
  emailCommunicationPreferences: CommunicationPreferences;
  emailValidator: EmailValidatorPayload;
  featuredBenefit: FeaturedBenefitPayload;
  featuredContent: FeaturedContentPayload;
  landing_pages?: Maybe<LandingPages>;
  learningCourse: LearningCoursePayload;
  learningSummary: LearningSummaryPayload;
  menu: MenuPayload;
  order: Order;
  orderInfo: OrderInfoPayload;
  orderInvoiceUrl: OrderInvoiceUrlPayload;
  orders: OrdersPayload;
  publicUsers: PublicUsersPayload;
  recommendedContent: RecommendedContentPayload;
  testalgolia?: Maybe<Testalgolia>;
  user?: Maybe<User>;
  userBenefits: UserBenefitsPayload;
  userContent: UserContentPayload;
  userGroups: UserGroupsPayload;
  webinars: WebinarsPayload;
};


export type QueryActivityArgs = {
  input?: Maybe<NetworkActivityInput>;
};


export type QueryAlgoliaContentArgs = {
  input?: Maybe<AlgoliaContentInput>;
};


export type QueryAll_AssetsArgs = {
  where?: Maybe<SysAssetWhere>;
  locale?: Maybe<Scalars['String']>;
  order_by?: Maybe<Array<Maybe<SysAssetOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  fallback_locale?: Maybe<Scalars['Boolean']>;
};


export type QueryAll_Landing_PagesArgs = {
  where?: Maybe<LandingPagesWhere>;
  locale?: Maybe<Scalars['String']>;
  order_by?: Maybe<Array<Maybe<LandingPagesOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  fallback_locale?: Maybe<Scalars['Boolean']>;
};


export type QueryAll_TestalgoliaArgs = {
  where?: Maybe<TestalgoliaWhere>;
  locale?: Maybe<Scalars['String']>;
  order_by?: Maybe<Array<Maybe<TestalgoliaOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  fallback_locale?: Maybe<Scalars['Boolean']>;
};


export type QueryAssetsArgs = {
  uid: Scalars['String'];
  locale?: Maybe<Scalars['String']>;
  fallback_locale?: Maybe<Scalars['Boolean']>;
};


export type QueryConferencesArgs = {
  input?: Maybe<LearningActivityInput>;
};


export type QueryCourseCertificatesArgs = {
  input?: Maybe<LearningActivityInput>;
};


export type QueryEmailCommunicationPreferencesArgs = {
  input: PreferencesInput;
};


export type QueryEmailValidatorArgs = {
  input: EmailValidatorInput;
};


export type QueryFeaturedContentArgs = {
  input?: Maybe<FeaturedContentInput>;
};


export type QueryLanding_PagesArgs = {
  uid: Scalars['String'];
  locale?: Scalars['String'];
  fallback_locale?: Maybe<Scalars['Boolean']>;
};


export type QueryLearningCourseArgs = {
  input?: Maybe<LearningActivityInput>;
};


export type QueryOrderArgs = {
  id: Scalars['String'];
};


export type QueryOrderInfoArgs = {
  input?: Maybe<OrderNumberInput>;
};


export type QueryOrderInvoiceUrlArgs = {
  input?: Maybe<OrdersInvoiceUrlInput>;
};


export type QueryOrdersArgs = {
  input?: Maybe<OrdersInput>;
};


export type QueryPublicUsersArgs = {
  input: PublicUsersInput;
};


export type QueryRecommendedContentArgs = {
  input?: Maybe<RecommendedContentInput>;
};


export type QueryTestalgoliaArgs = {
  uid: Scalars['String'];
  locale?: Scalars['String'];
  fallback_locale?: Maybe<Scalars['Boolean']>;
};


export type QueryUserBenefitsArgs = {
  input?: Maybe<UserBenefitsInput>;
};


export type QueryUserContentArgs = {
  input?: Maybe<UserContentInput>;
};


export type QueryUserGroupsArgs = {
  input?: Maybe<UserGroupInput>;
};


export type QueryWebinarsArgs = {
  input?: Maybe<LearningActivityInput>;
};

export type RecommendedContentInput = {
  pageSize?: Maybe<Scalars['Int']>;
};

export type RecommendedContentItem = {
  __typename?: 'RecommendedContentItem';
  topic?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Float']>;
  data?: Maybe<Array<IAbstractSearchItem>>;
};

export type RecommendedContentMeta = IMeta & {
  __typename?: 'RecommendedContentMeta';
  count: Scalars['Int'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export type RecommendedContentPayload = {
  __typename?: 'RecommendedContentPayload';
  meta: RecommendedContentMeta;
  data: Array<RecommendedContentItem>;
};

export type ResMsg = {
  __typename?: 'ResMsg';
  status: Scalars['Int'];
  message: Scalars['String'];
};

export type Seo = {
  __typename?: 'Seo';
  meta_title?: Maybe<Scalars['String']>;
  meta_description?: Maybe<Scalars['String']>;
  meta_imageConnection?: Maybe<SysAssetConnection>;
  no_index?: Maybe<Scalars['Boolean']>;
  no_follow?: Maybe<Scalars['Boolean']>;
};

export type SeoWhere = {
  meta_title?: Maybe<Scalars['String']>;
  meta_title_ne?: Maybe<Scalars['String']>;
  meta_title_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta_title_nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta_title_exists?: Maybe<Scalars['Boolean']>;
  meta_description?: Maybe<Scalars['String']>;
  meta_description_ne?: Maybe<Scalars['String']>;
  meta_description_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta_description_nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta_description_exists?: Maybe<Scalars['Boolean']>;
  meta_image?: Maybe<SysAssetWhere>;
  meta_image_exists?: Maybe<Scalars['Boolean']>;
  no_index?: Maybe<Scalars['Boolean']>;
  no_index_exists?: Maybe<Scalars['Boolean']>;
  no_index_ne?: Maybe<Scalars['Float']>;
  no_follow?: Maybe<Scalars['Boolean']>;
  no_follow_exists?: Maybe<Scalars['Boolean']>;
  no_follow_ne?: Maybe<Scalars['Float']>;
};

export type SnippetItem = {
  __typename?: 'SnippetItem';
  value?: Maybe<Scalars['String']>;
  matchLevel?: Maybe<Scalars['String']>;
};

export type SnippetResult = {
  __typename?: 'SnippetResult';
  title?: Maybe<SnippetItem>;
  description?: Maybe<SnippetItem>;
  bodyText?: Maybe<SnippetItem>;
};

export enum SpecialNeeds {
  HEARING = 'HEARING',
  MOBILITY = 'MOBILITY',
  VISUAL = 'VISUAL'
}

export type SponsorItem = {
  __typename?: 'SponsorItem';
  organization?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
};

export type Statements = {
  __typename?: 'Statements';
  desc?: Maybe<Scalars['String']>;
  descScore?: Maybe<Scalars['Int']>;
};

export type SubscribePublicUserInput = {
  userId: Scalars['ID'];
};

export type SubscribeTopicInput = {
  topicKey: Scalars['String'];
};

export type SubscribeUserGroupInput = {
  groupId: Scalars['ID'];
};

export type SysAsset = {
  __typename?: 'SysAsset';
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  content_type?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  file_size?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  dimension?: Maybe<SysAssetDimension>;
  system?: Maybe<SysAssetSystemField>;
};


export type SysAssetUrlArgs = {
  transform?: Maybe<SysAssetTransformUrl>;
};

export enum SysAssetAutoValues {
  WEBP = 'WEBP'
}

export type SysAssetConnection = {
  __typename?: 'SysAssetConnection';
  totalCount?: Maybe<Scalars['Int']>;
  edges?: Maybe<Array<Maybe<SysAssetEdge>>>;
};

export type SysAssetDimension = {
  __typename?: 'SysAssetDimension';
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
};

export type SysAssetDimensionWhere = {
  width?: Maybe<Scalars['Int']>;
  width_ne?: Maybe<Scalars['Int']>;
  width_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  width_nin?: Maybe<Array<Maybe<Scalars['Int']>>>;
  width_exists?: Maybe<Scalars['Boolean']>;
  width_lt?: Maybe<Scalars['Int']>;
  width_lte?: Maybe<Scalars['Int']>;
  width_gt?: Maybe<Scalars['Int']>;
  width_gte?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  height_ne?: Maybe<Scalars['Int']>;
  height_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  height_nin?: Maybe<Array<Maybe<Scalars['Int']>>>;
  height_exists?: Maybe<Scalars['Boolean']>;
  height_lt?: Maybe<Scalars['Int']>;
  height_lte?: Maybe<Scalars['Int']>;
  height_gt?: Maybe<Scalars['Int']>;
  height_gte?: Maybe<Scalars['Int']>;
};

export enum SysAssetDisableValues {
  /** UPSCALE is always enabled, in which the image is upscaled if the output image (by specifying the width or height) is bigger than the source image */
  UPSCALE = 'UPSCALE'
}

export enum SysAssetDispositionValues {
  /** Allows to download an image */
  ATTACHMENT = 'ATTACHMENT',
  /** Allows an image to be rendered on page */
  INLINE = 'INLINE'
}

export type SysAssetEdge = {
  __typename?: 'SysAssetEdge';
  node?: Maybe<SysAsset>;
};

export enum SysAssetFitValues {
  BOUNDS = 'BOUNDS',
  CROP = 'CROP'
}

export enum SysAssetImageFormats {
  /** Convert an image to GIF format */
  GIF = 'GIF',
  /** Convert an image to PNG format */
  PNG = 'PNG',
  /** Convert an image to JPEG format */
  JPG = 'JPG',
  /** A Progressive JPEG is an image file created using a compression method that displays higher detail in progression */
  PJPG = 'PJPG',
  /** WEBP images are usually lower in size and have good quality */
  WEBP = 'WEBP',
  /** WEBP Lossless format */
  WEBPLL = 'WEBPLL',
  /** WEBP Lossy format */
  WEBPLY = 'WEBPLY'
}

export enum SysAssetOrderBy {
  CREATED_AT_ASC = 'created_at_ASC',
  CREATED_AT_DESC = 'created_at_DESC',
  UPDATED_AT_ASC = 'updated_at_ASC',
  UPDATED_AT_DESC = 'updated_at_DESC'
}

export enum SysAssetOrientValues {
  /** Set image to default */
  DEFAULT = 'DEFAULT',
  /** Flip image horizontally */
  HORIZONTALLY = 'HORIZONTALLY',
  /** Flip image horizontally and vertically */
  BOTH = 'BOTH',
  /** Flip image vertically */
  VERTICALLY = 'VERTICALLY',
  /** Flip image horizontally and then rotate 90 degrees towards left */
  ROTATE90LEFT = 'ROTATE90LEFT',
  /** Rotate image 90 degrees towards right */
  ROTATE90RIGHT = 'ROTATE90RIGHT'
}

export enum SysAssetOverlayAlignValues {
  /** Align the overlay image to the top of the actual image */
  TOP = 'TOP',
  /** Align the overlay image to the bottom of the actual image */
  BOTTOM = 'BOTTOM',
  /** Align the overlay image to the left of the actual image */
  LEFT = 'LEFT',
  /** Align the overlay image to the right of the actual image */
  RIGHT = 'RIGHT',
  /** Align the overlay image to the middle (vertically) of the actual image */
  MIDDLE = 'MIDDLE',
  /** Align the overlay image to the center (horizontally) of the actual image */
  CENTER = 'CENTER'
}

export enum SysAssetOverlayRepeatValues {
  /** Horizontal repetition */
  X = 'X',
  /** Vertical repetition */
  Y = 'Y',
  /** Horizontal and vertical repetition */
  BOTH = 'BOTH'
}

export type SysAssetSystemField = {
  __typename?: 'SysAssetSystemField';
  publish_details?: Maybe<SystemPublishDetails>;
  created_at?: Maybe<Scalars['DateTime']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  updated_by?: Maybe<Scalars['String']>;
  uid?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  version?: Maybe<Scalars['Int']>;
  content_type_uid?: Maybe<Scalars['String']>;
};

export type SysAssetTransformUrl = {
  height?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['String']>;
  /** Format parameter lets you converts a given image from one format to another */
  format?: Maybe<SysAssetImageFormats>;
  /** The disable parameter disables the functionality that is enabled by default */
  disable?: Maybe<SysAssetDisableValues>;
  /** Fit parameter enables you to fit the given image properly within the specified height and width */
  fit?: Maybe<SysAssetFitValues>;
  quality?: Maybe<Scalars['Int']>;
  crop?: Maybe<Scalars['String']>;
  trim?: Maybe<Scalars['String']>;
  /** The orient parameter lets you control the cardinal orientation of the given image */
  orient?: Maybe<SysAssetOrientValues>;
  overlay?: Maybe<Scalars['String']>;
  overlay_align?: Maybe<SysAssetOverlayAlignValues>;
  /** The overlay_repeat parameter lets you define how the overlay image will be repeated on the given image */
  overlay_repeat?: Maybe<SysAssetOverlayRepeatValues>;
  /** When the auto parameter is set to webp, it enables WebP image support. WebP images have higher compression rate with minimum loss of quality. */
  auto?: Maybe<SysAssetAutoValues>;
  /** The disposition parameter lets you allow image to download or render.  */
  disposition?: Maybe<SysAssetDispositionValues>;
  /** The dpr parameter lets you deliver images with appropriate size to devices that come with a defined device pixel ratio. The device pixel ratio of any device determines the screen resolution that its CSS would interpret */
  dpr?: Maybe<Scalars['String']>;
  /** The bg-color parameter lets you set a backgroud color for the given image. This is useful when applying padding or for replacing the transparent pixels of an image */
  bg_color?: Maybe<Scalars['String']>;
  /** This parameter lets you add extra pixels to the edges of an image. You can specify values for top, right, bottom, and left padding for an image */
  pad?: Maybe<Scalars['String']>;
  /** The value for this parameter can be set in pixels or percentage. For pixel value, use any whole number between 1 and 8192. For percentage value, use any decimal number between 0.0 and 0.99. When height is defined in percentage, it relative to the output image */
  overlay_height?: Maybe<Scalars['String']>;
  /** The value for this parameter can be set in pixels or percentage. For pixel value, use any whole number between 1 and 8192. For percentage value, use any decimal number between 0.0 and 0.99. When width is defined in percentage, it is relative to the output image */
  overlay_width?: Maybe<Scalars['String']>;
};

export type SysAssetWhere = {
  title?: Maybe<Scalars['String']>;
  title_ne?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_exists?: Maybe<Scalars['Boolean']>;
  url?: Maybe<Scalars['String']>;
  url_ne?: Maybe<Scalars['String']>;
  url_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  url_nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  url_exists?: Maybe<Scalars['Boolean']>;
  filename?: Maybe<Scalars['String']>;
  filename_ne?: Maybe<Scalars['String']>;
  filename_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  filename_nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  filename_exists?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  description_ne?: Maybe<Scalars['String']>;
  description_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  description_nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  description_exists?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Scalars['String']>;
  uid_ne?: Maybe<Scalars['String']>;
  uid_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  uid_nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  uid_exists?: Maybe<Scalars['Boolean']>;
  file_size?: Maybe<Scalars['Int']>;
  file_size_ne?: Maybe<Scalars['Int']>;
  file_size_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  file_size_nin?: Maybe<Array<Maybe<Scalars['Int']>>>;
  file_size_exists?: Maybe<Scalars['Boolean']>;
  file_size_lt?: Maybe<Scalars['Int']>;
  file_size_lte?: Maybe<Scalars['Int']>;
  file_size_gt?: Maybe<Scalars['Int']>;
  file_size_gte?: Maybe<Scalars['Int']>;
  dimension_exists?: Maybe<Scalars['Boolean']>;
  dimension?: Maybe<SysAssetDimensionWhere>;
  tags?: Maybe<Scalars['String']>;
  tags_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  tags_nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  AND?: Maybe<Array<Maybe<SysAssetWhere>>>;
  OR?: Maybe<Array<Maybe<SysAssetWhere>>>;
};

export type SystemPublishDetails = {
  __typename?: 'SystemPublishDetails';
  environment?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['DateTime']>;
  user?: Maybe<Scalars['String']>;
};

export enum TargetType {
  SELF = 'SELF',
  BLANK = 'BLANK'
}

export type TaxonomySearchItem = {
  __typename?: 'TaxonomySearchItem';
  lvl0?: Maybe<Scalars['String']>;
  lvl1?: Maybe<Scalars['String']>;
  lvl2?: Maybe<Scalars['String']>;
};

export type Testalgolia = {
  __typename?: 'Testalgolia';
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<Scalars['String']>>>;
  system?: Maybe<EntrySystemField>;
};

export enum TestalgoliaOrderBy {
  CREATED_AT_ASC = 'created_at_ASC',
  CREATED_AT_DESC = 'created_at_DESC',
  UPDATED_AT_ASC = 'updated_at_ASC',
  UPDATED_AT_DESC = 'updated_at_DESC'
}

export type TestalgoliaWhere = {
  created_at?: Maybe<Scalars['DateTime']>;
  created_at_in?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  created_at_nin?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  created_at_ne?: Maybe<Scalars['DateTime']>;
  created_at_lt?: Maybe<Scalars['DateTime']>;
  created_at_lte?: Maybe<Scalars['DateTime']>;
  created_at_gt?: Maybe<Scalars['DateTime']>;
  created_at_gte?: Maybe<Scalars['DateTime']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  updated_at_in?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  updated_at_nin?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  updated_at_ne?: Maybe<Scalars['DateTime']>;
  updated_at_lt?: Maybe<Scalars['DateTime']>;
  updated_at_lte?: Maybe<Scalars['DateTime']>;
  updated_at_gt?: Maybe<Scalars['DateTime']>;
  updated_at_gte?: Maybe<Scalars['DateTime']>;
  locale?: Maybe<Scalars['String']>;
  locale_ne?: Maybe<Scalars['String']>;
  locale_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  locale_nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  locale_exists?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Scalars['String']>;
  uid_ne?: Maybe<Scalars['String']>;
  uid_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  uid_nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  uid_exists?: Maybe<Scalars['Boolean']>;
  tags_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  tags_nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Scalars['String']>;
  title_ne?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_exists?: Maybe<Scalars['Boolean']>;
  url?: Maybe<Scalars['String']>;
  url_ne?: Maybe<Scalars['String']>;
  url_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  url_nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  url_exists?: Maybe<Scalars['Boolean']>;
  items?: Maybe<Scalars['String']>;
  items_ne?: Maybe<Scalars['String']>;
  items_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  items_nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  items_exists?: Maybe<Scalars['Boolean']>;
  AND?: Maybe<Array<Maybe<TestalgoliaWhere>>>;
  OR?: Maybe<Array<Maybe<TestalgoliaWhere>>>;
};

export type Topic = {
  __typename?: 'Topic';
  id: Scalars['String'];
  label: Scalars['String'];
  key: Scalars['String'];
  url: Scalars['String'];
  userStatus: UserTopicStatus;
};

export type UpdateBookmarkInput = {
  bookmarkId: Scalars['ID'];
};

export type UpdateEmailInput = {
  email: Scalars['String'];
};

export type UpdateOrderAutoRenewalInput = {
  orderNumber: Scalars['String'];
  payPageRegistrationId?: Maybe<Scalars['String']>;
  orderLineNumber: Scalars['String'];
  isAutoRenewal: Scalars['Boolean'];
  cardType?: Maybe<Scalars['String']>;
  cardNumber?: Maybe<Scalars['String']>;
  creditCardSecurityCode?: Maybe<Scalars['String']>;
  nameOnCard?: Maybe<Scalars['String']>;
  expirationYear?: Maybe<Scalars['Int']>;
  expirationMonth?: Maybe<Scalars['Int']>;
};

export type UpdatePasswordInput = {
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
};

export type UpdateProfilePicture = {
  file: Scalars['Upload'];
  awsFileKey: Scalars['String'];
};


export type UpsertUserInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  addressId?: Maybe<Scalars['String']>;
  primaryEmailAddress: Scalars['String'];
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  countryCode: Scalars['String'];
  postalCode: Scalars['String'];
  addressType: Scalars['String'];
  jobFunctionCode: Scalars['String'];
  userIndustry: Scalars['String'];
  isPostalMailOptedOut: Scalars['Boolean'];
  automatedNewsletters: Scalars['Boolean'];
  isPhoneOptedOut: Scalars['Boolean'];
  isConsent?: Maybe<Scalars['Boolean']>;
  isThirdPartyConsent?: Maybe<Scalars['Boolean']>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  staff: Scalars['Boolean'];
  isContributor: Scalars['Boolean'];
  profile: Profile;
  job: Job;
  certification: Array<Certification>;
  badge: Array<Badge>;
  visibility: Visibility;
  education: Array<Education>;
  contactInfo: ContactInfo;
  communicationPreferences: CommunicationPreferences;
  cart?: Maybe<Cart>;
  membership: Membership;
  customerClassCode: CustomerClassCode;
  isEnterpriseAdmin: Scalars['Boolean'];
  capabilityModel: CapabilityModel;
  topics: Array<Topic>;
  learningActivity?: Maybe<Array<IAbstractSearchItem>>;
};

export type UserBenefitGroup = {
  __typename?: 'UserBenefitGroup';
  id: Scalars['ID'];
  key: Scalars['String'];
  url: Scalars['String'];
  count: Scalars['Int'];
  remainingCount: Scalars['Int'];
  data: Array<IAbstractSearchItem>;
};

export type UserBenefitsFilterInput = {
  benefitType?: Maybe<Array<BenefitType>>;
  contentType?: Maybe<Array<ContentType>>;
  entityType?: Maybe<Array<EntityType>>;
};

export enum UserBenefitsGroupBy {
  CONTENT_TYPE = 'CONTENT_TYPE'
}

export type UserBenefitsInput = {
  pageSize?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  filters?: Maybe<UserBenefitsFilterInput>;
  orderBy?: Maybe<UserBenefitsOrderByInput>;
  groupBy?: Maybe<UserBenefitsGroupBy>;
};

export type UserBenefitsMeta = IMeta & {
  __typename?: 'UserBenefitsMeta';
  count: Scalars['Int'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalPages: Scalars['Int'];
  allFilters?: Maybe<Array<Filters>>;
  orderBy?: Maybe<UserBenefitsOrderByOutput>;
  groupBy?: Maybe<Scalars['String']>;
};

export type UserBenefitsOrderByInput = {
  value?: Maybe<UserBenefitsOrderByList>;
  order?: Maybe<FilterOrder>;
};

export enum UserBenefitsOrderByList {
  PUBLISHED_DATE = 'PUBLISHED_DATE',
  UPDATED_DATE = 'UPDATED_DATE'
}

export type UserBenefitsOrderByOutput = {
  __typename?: 'UserBenefitsOrderByOutput';
  value?: Maybe<UserBenefitsOrderByList>;
  order?: Maybe<FilterOrder>;
};

export type UserBenefitsPayload = {
  __typename?: 'UserBenefitsPayload';
  meta: UserBenefitsMeta;
  data: Array<UserBenefitGroup>;
};

export type UserContentFilterInput = {
  userRelationship?: Maybe<UserContentRelationship>;
  contentType?: Maybe<Array<ContentType>>;
  entityType?: Maybe<Array<EntityType>>;
};

export type UserContentInput = {
  pageSize?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  filters?: Maybe<UserContentFilterInput>;
  orderBy?: Maybe<UserContentOrderByInput>;
};

export type UserContentMeta = IMeta & {
  __typename?: 'UserContentMeta';
  count: Scalars['Int'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalPages: Scalars['Int'];
  allFilters?: Maybe<Array<Filters>>;
  orderBy?: Maybe<UserContentOrderByOutput>;
};

export type UserContentOrderByInput = {
  value?: Maybe<UserContentOrderByList>;
  order?: Maybe<FilterOrder>;
};

export enum UserContentOrderByList {
  PUBLISHED_DATE = 'PUBLISHED_DATE',
  UPDATED_DATE = 'UPDATED_DATE'
}

export type UserContentOrderByOutput = {
  __typename?: 'UserContentOrderByOutput';
  value?: Maybe<UserContentOrderByList>;
  order?: Maybe<FilterOrder>;
};

export type UserContentPayload = {
  __typename?: 'UserContentPayload';
  meta: UserContentMeta;
  data: Array<IAbstractSearchItem>;
};

export enum UserContentRelationship {
  AUTHORED = 'AUTHORED',
  BOOKMARKED = 'BOOKMARKED',
  FEATURED = 'FEATURED',
  RECOMMENDED = 'RECOMMENDED'
}

export type UserGroup = {
  __typename?: 'UserGroup';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  avatarUrl?: Maybe<Scalars['String']>;
};

export type UserGroupInput = {
  pageSize?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};

export type UserGroupMeta = IMeta & {
  __typename?: 'UserGroupMeta';
  count: Scalars['Int'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export type UserGroupsPayload = {
  __typename?: 'UserGroupsPayload';
  meta: UserGroupMeta;
  data: Array<UserGroup>;
};

export type UserLearningActivityItem = {
  __typename?: 'UserLearningActivityItem';
  id: Scalars['ID'];
  subid: Scalars['String'];
  name: Scalars['String'];
  completion: Scalars['String'];
  startDate: Scalars['EpochDate'];
  endDate: Scalars['EpochDate'];
  completionDate?: Maybe<Scalars['EpochDate']>;
  lastVisitDate?: Maybe<Scalars['EpochDate']>;
  courseStartDate?: Maybe<Scalars['EpochDate']>;
  courseEndDate?: Maybe<Scalars['EpochDate']>;
  calendarUrl?: Maybe<Scalars['String']>;
  score: Scalars['Float'];
  timeSpent?: Maybe<Scalars['String']>;
  section?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  term?: Maybe<Scalars['String']>;
  viewUrl: Scalars['String'];
  launchUrl?: Maybe<Scalars['String']>;
  completionPercent: Scalars['Int'];
  credits?: Maybe<Array<CourseCredit>>;
  certificates?: Maybe<Array<CourseCertificate>>;
  badges?: Maybe<Array<CourseBadge>>;
};

export type UserSearchItem = IAbstractSearchItem & {
  __typename?: 'UserSearchItem';
  id: Scalars['ID'];
  title: Scalars['String'];
  publishDate: Scalars['EpochDate'];
  updateDate: Scalars['EpochDate'];
  description?: Maybe<Scalars['String']>;
  bodyText?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  viewCount: Scalars['Int'];
  contentTypes?: Maybe<TaxonomySearchItem>;
  topics?: Maybe<Array<Scalars['String']>>;
  primaryTopic?: Maybe<Scalars['String']>;
  topicCodes?: Maybe<Array<Scalars['String']>>;
  industryCodes?: Maybe<Array<Scalars['String']>>;
  roleCodes?: Maybe<Array<Scalars['String']>>;
  contentTypesArray?: Maybe<Array<Scalars['String']>>;
  industries?: Maybe<Array<Scalars['String']>>;
  roles?: Maybe<Array<Scalars['String']>>;
  keywords?: Maybe<Array<Scalars['String']>>;
  authors?: Maybe<Array<UserSearchItem>>;
  featured?: Maybe<Scalars['Boolean']>;
  memberBenefit?: Maybe<Scalars['Boolean']>;
  isBookmarkable: Scalars['Boolean'];
  _snippetResult?: Maybe<SnippetResult>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  fullName?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  customerNumber?: Maybe<Scalars['String']>;
  member: Scalars['String'];
  staff: Scalars['Boolean'];
  jobTitle?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  isFollowingCurrentUser: Scalars['Boolean'];
  isFollowedByCurrentUser: Scalars['Boolean'];
};

export type UserTopicStatus = {
  __typename?: 'UserTopicStatus';
  id: Scalars['String'];
  followed: Scalars['Boolean'];
  recommended: Scalars['Boolean'];
};

export enum UsersOrderByList {
  LAST_NAME = 'LAST_NAME',
  FIRST_NAME = 'FIRST_NAME',
  CREATED_DATE = 'CREATED_DATE'
}

export type VideoSearchItem = IContentSearchItem & IAbstractSearchItem & {
  __typename?: 'VideoSearchItem';
  id: Scalars['ID'];
  title: Scalars['String'];
  publishDate: Scalars['EpochDate'];
  updateDate: Scalars['EpochDate'];
  description?: Maybe<Scalars['String']>;
  bodyText?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  viewCount: Scalars['Int'];
  contentTypes?: Maybe<TaxonomySearchItem>;
  topics?: Maybe<Array<Scalars['String']>>;
  primaryTopic?: Maybe<Scalars['String']>;
  topicCodes?: Maybe<Array<Scalars['String']>>;
  industryCodes?: Maybe<Array<Scalars['String']>>;
  roleCodes?: Maybe<Array<Scalars['String']>>;
  contentTypesArray?: Maybe<Array<Scalars['String']>>;
  industries?: Maybe<Array<Scalars['String']>>;
  roles?: Maybe<Array<Scalars['String']>>;
  keywords?: Maybe<Array<Scalars['String']>>;
  authors?: Maybe<Array<UserSearchItem>>;
  featured?: Maybe<Scalars['Boolean']>;
  memberBenefit?: Maybe<Scalars['Boolean']>;
  isBookmarkable: Scalars['Boolean'];
  _snippetResult?: Maybe<SnippetResult>;
  periodical?: Maybe<Scalars['String']>;
  issue?: Maybe<Scalars['String']>;
  column?: Maybe<Scalars['String']>;
  part?: Maybe<Scalars['String']>;
  publisher?: Maybe<Scalars['String']>;
  videoUrl?: Maybe<Scalars['String']>;
  videoType?: Maybe<Scalars['String']>;
  brightcoveName?: Maybe<Scalars['String']>;
  brightcoveId?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  longDescription?: Maybe<Scalars['String']>;
  videoPublishDate?: Maybe<Scalars['EpochDate']>;
  videoUpdateDate?: Maybe<Scalars['EpochDate']>;
  speaker?: Maybe<Scalars['String']>;
  brightcoveState?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  isBookmarked: Scalars['Boolean'];
};

export type Visibility = {
  __typename?: 'Visibility';
  publicProfile?: Maybe<Scalars['Boolean']>;
  memberDirectoryListing?: Maybe<Scalars['Boolean']>;
};

export type WebinarItem = {
  __typename?: 'WebinarItem';
  webinarId: Scalars['String'];
  date: Scalars['String'];
  title: Scalars['String'];
  role: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

export type WebinarSearchItem = IAbstractSearchItem & {
  __typename?: 'WebinarSearchItem';
  id: Scalars['ID'];
  title: Scalars['String'];
  publishDate: Scalars['EpochDate'];
  updateDate: Scalars['EpochDate'];
  description?: Maybe<Scalars['String']>;
  bodyText?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  viewCount: Scalars['Int'];
  contentTypes?: Maybe<TaxonomySearchItem>;
  topics?: Maybe<Array<Scalars['String']>>;
  primaryTopic?: Maybe<Scalars['String']>;
  topicCodes?: Maybe<Array<Scalars['String']>>;
  industryCodes?: Maybe<Array<Scalars['String']>>;
  roleCodes?: Maybe<Array<Scalars['String']>>;
  contentTypesArray?: Maybe<Array<Scalars['String']>>;
  industries?: Maybe<Array<Scalars['String']>>;
  roles?: Maybe<Array<Scalars['String']>>;
  keywords?: Maybe<Array<Scalars['String']>>;
  authors?: Maybe<Array<UserSearchItem>>;
  featured?: Maybe<Scalars['Boolean']>;
  memberBenefit?: Maybe<Scalars['Boolean']>;
  isBookmarkable: Scalars['Boolean'];
  _snippetResult?: Maybe<SnippetResult>;
  start?: Maybe<Scalars['EpochDate']>;
  end?: Maybe<Scalars['EpochDate']>;
  duration?: Maybe<Scalars['Int']>;
  sponsors?: Maybe<Array<SponsorItem>>;
  presenters?: Maybe<Array<PresenterItem>>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  host?: Maybe<Scalars['String']>;
  registeredCount?: Maybe<Scalars['Int']>;
  webinarStatus?: Maybe<WebinarStatus>;
  isBookmarked: Scalars['Boolean'];
};

export enum WebinarStatus {
  UPCOMING = 'UPCOMING',
  STARTED = 'STARTED',
  COMPLETED = 'COMPLETED'
}

export type WebinarsPayload = {
  __typename?: 'WebinarsPayload';
  meta?: Maybe<LearningActivityMeta>;
  data: Array<WebinarItem>;
};

type AllContentItemNode_ArticleSearchItem_Fragment = (
  { __typename?: 'ArticleSearchItem' }
  & IAbstractSearchItem_ArticleSearchItem_Fragment
  & IAbstractSearchItem_ArticleSearchItem_Fragment
  & ArticleSearchItemNodeFragment
);

type AllContentItemNode_BlogSearchItem_Fragment = (
  { __typename?: 'BlogSearchItem' }
  & IAbstractSearchItem_BlogSearchItem_Fragment
  & IAbstractSearchItem_BlogSearchItem_Fragment
  & BlogSearchItemNodeFragment
);

type AllContentItemNode_ConferenceSearchItem_Fragment = (
  { __typename?: 'ConferenceSearchItem' }
  & IAbstractSearchItem_ConferenceSearchItem_Fragment
  & IAbstractSearchItem_ConferenceSearchItem_Fragment
  & ConferenceSearchItemNodeFragment
);

type AllContentItemNode_CourseSearchItem_Fragment = (
  { __typename?: 'CourseSearchItem' }
  & IAbstractSearchItem_CourseSearchItem_Fragment
  & IAbstractSearchItem_CourseSearchItem_Fragment
  & CourseSearchItemNodeFragment
);

type AllContentItemNode_MeetingSessionSearchItem_Fragment = (
  { __typename?: 'MeetingSessionSearchItem' }
  & IAbstractSearchItem_MeetingSessionSearchItem_Fragment
);

type AllContentItemNode_PageSearchItem_Fragment = (
  { __typename?: 'PageSearchItem' }
  & IAbstractSearchItem_PageSearchItem_Fragment
  & IAbstractSearchItem_PageSearchItem_Fragment
  & PageSearchItemNodeFragment
);

type AllContentItemNode_PodcastSearchItem_Fragment = (
  { __typename?: 'PodcastSearchItem' }
  & IAbstractSearchItem_PodcastSearchItem_Fragment
  & IAbstractSearchItem_PodcastSearchItem_Fragment
  & PodcastSearchItemNodeFragment
);

type AllContentItemNode_PublicationSearchItem_Fragment = (
  { __typename?: 'PublicationSearchItem' }
  & IAbstractSearchItem_PublicationSearchItem_Fragment
  & IAbstractSearchItem_PublicationSearchItem_Fragment
  & PublicationSearchItemNodeFragment
);

type AllContentItemNode_UserSearchItem_Fragment = (
  { __typename?: 'UserSearchItem' }
  & IAbstractSearchItem_UserSearchItem_Fragment
  & IAbstractSearchItem_UserSearchItem_Fragment
  & UserSearchItemNodeFragment
);

type AllContentItemNode_VideoSearchItem_Fragment = (
  { __typename?: 'VideoSearchItem' }
  & IAbstractSearchItem_VideoSearchItem_Fragment
  & IAbstractSearchItem_VideoSearchItem_Fragment
  & VideoSearchItemNodeFragment
);

type AllContentItemNode_WebinarSearchItem_Fragment = (
  { __typename?: 'WebinarSearchItem' }
  & IAbstractSearchItem_WebinarSearchItem_Fragment
  & IAbstractSearchItem_WebinarSearchItem_Fragment
  & WebinarSearchItemNodeFragment
);

export type AllContentItemNodeFragment = AllContentItemNode_ArticleSearchItem_Fragment | AllContentItemNode_BlogSearchItem_Fragment | AllContentItemNode_ConferenceSearchItem_Fragment | AllContentItemNode_CourseSearchItem_Fragment | AllContentItemNode_MeetingSessionSearchItem_Fragment | AllContentItemNode_PageSearchItem_Fragment | AllContentItemNode_PodcastSearchItem_Fragment | AllContentItemNode_PublicationSearchItem_Fragment | AllContentItemNode_UserSearchItem_Fragment | AllContentItemNode_VideoSearchItem_Fragment | AllContentItemNode_WebinarSearchItem_Fragment;

export type ConstantsNodeFragment = (
  { __typename?: 'ConstantsPayload' }
  & Pick<ConstantsPayload, 'gdprCountryCodes'>
  & { topics: Array<(
    { __typename?: 'Topic' }
    & Pick<Topic, 'id' | 'label' | 'key' | 'url'>
    & { userStatus: (
      { __typename?: 'UserTopicStatus' }
      & Pick<UserTopicStatus, 'followed' | 'recommended'>
    ) }
  )>, industryCodes: Array<(
    { __typename?: 'List' }
    & Pick<List, 'key' | 'value'>
  )>, jobRoleCodes: Array<(
    { __typename?: 'List' }
    & Pick<List, 'key' | 'value'>
  )>, companySizeCodes: Array<(
    { __typename?: 'List' }
    & Pick<List, 'key' | 'value'>
  )>, directReportCountCodes: Array<(
    { __typename?: 'List' }
    & Pick<List, 'key' | 'value'>
  )>, budgetSizeCodes: Array<(
    { __typename?: 'List' }
    & Pick<List, 'key' | 'value'>
  )>, allDemographicCodes: Array<(
    { __typename?: 'List' }
    & Pick<List, 'key' | 'value'>
  )>, programDegreeCodes: Array<(
    { __typename?: 'List' }
    & Pick<List, 'key' | 'value'>
  )>, programTypeCodes: Array<(
    { __typename?: 'List' }
    & Pick<List, 'key' | 'value'>
  )> }
);

type ContentSearchItemNode_ArticleSearchItem_Fragment = (
  { __typename?: 'ArticleSearchItem' }
  & Pick<ArticleSearchItem, 'periodical' | 'issue' | 'column' | 'part'>
);

type ContentSearchItemNode_BlogSearchItem_Fragment = (
  { __typename?: 'BlogSearchItem' }
  & Pick<BlogSearchItem, 'periodical' | 'issue' | 'column' | 'part'>
);

type ContentSearchItemNode_PageSearchItem_Fragment = (
  { __typename?: 'PageSearchItem' }
  & Pick<PageSearchItem, 'periodical' | 'issue' | 'column' | 'part'>
);

type ContentSearchItemNode_VideoSearchItem_Fragment = (
  { __typename?: 'VideoSearchItem' }
  & Pick<VideoSearchItem, 'periodical' | 'issue' | 'column' | 'part'>
);

export type ContentSearchItemNodeFragment = ContentSearchItemNode_ArticleSearchItem_Fragment | ContentSearchItemNode_BlogSearchItem_Fragment | ContentSearchItemNode_PageSearchItem_Fragment | ContentSearchItemNode_VideoSearchItem_Fragment;

export type ArticleSearchItemNodeFragment = (
  { __typename?: 'ArticleSearchItem' }
  & Pick<ArticleSearchItem, 'id' | 'isBookmarked'>
  & ContentSearchItemNode_ArticleSearchItem_Fragment
);

export type BlogSearchItemNodeFragment = (
  { __typename?: 'BlogSearchItem' }
  & Pick<BlogSearchItem, 'isBookmarked'>
  & ContentSearchItemNode_BlogSearchItem_Fragment
);

export type PageSearchItemNodeFragment = (
  { __typename?: 'PageSearchItem' }
  & Pick<PageSearchItem, 'isBookmarked'>
  & ContentSearchItemNode_PageSearchItem_Fragment
);

export type VideoSearchItemNodeFragment = (
  { __typename?: 'VideoSearchItem' }
  & Pick<VideoSearchItem, 'publisher' | 'videoUrl' | 'videoType' | 'brightcoveName' | 'brightcoveId' | 'shortDescription' | 'longDescription' | 'videoPublishDate' | 'videoUpdateDate' | 'speaker' | 'brightcoveState' | 'duration' | 'isBookmarked'>
  & ContentSearchItemNode_VideoSearchItem_Fragment
);

export type PodcastSearchItemNodeFragment = (
  { __typename?: 'PodcastSearchItem' }
  & Pick<PodcastSearchItem, 'subTitle' | 'duration' | 'season' | 'episode'>
);

export type SponsorItemNodeFragment = (
  { __typename?: 'WebinarSearchItem' }
  & { sponsors?: Maybe<Array<(
    { __typename?: 'SponsorItem' }
    & Pick<SponsorItem, 'organization' | 'website' | 'url' | 'logoUrl'>
  )>> }
);

export type PresenterItemNodeFragment = (
  { __typename?: 'WebinarSearchItem' }
  & { presenters?: Maybe<Array<(
    { __typename?: 'PresenterItem' }
    & Pick<PresenterItem, 'fullName' | 'email' | 'organization' | 'positionTitle' | 'biography' | 'profileImageUrl'>
  )>> }
);

export type WebinarSearchItemNodeFragment = (
  { __typename?: 'WebinarSearchItem' }
  & Pick<WebinarSearchItem, 'start' | 'end' | 'duration' | 'isPrivate' | 'host' | 'registeredCount' | 'webinarStatus'>
  & SponsorItemNodeFragment
  & PresenterItemNodeFragment
);

type CommerceSearchItemNode_ConferenceSearchItem_Fragment = (
  { __typename?: 'ConferenceSearchItem' }
  & Pick<ConferenceSearchItem, 'listPrice' | 'memberPrice' | 'availableAsBenefit'>
);

type CommerceSearchItemNode_CourseSearchItem_Fragment = (
  { __typename?: 'CourseSearchItem' }
  & Pick<CourseSearchItem, 'listPrice' | 'memberPrice' | 'availableAsBenefit'>
);

type CommerceSearchItemNode_PublicationSearchItem_Fragment = (
  { __typename?: 'PublicationSearchItem' }
  & Pick<PublicationSearchItem, 'listPrice' | 'memberPrice' | 'availableAsBenefit'>
);

export type CommerceSearchItemNodeFragment = CommerceSearchItemNode_ConferenceSearchItem_Fragment | CommerceSearchItemNode_CourseSearchItem_Fragment | CommerceSearchItemNode_PublicationSearchItem_Fragment;

export type PublicationSearchItemNodeFragment = (
  { __typename?: 'PublicationSearchItem' }
  & Pick<PublicationSearchItem, 'isBookmarked'>
  & { purchasableItems?: Maybe<Array<(
    { __typename?: 'CoursePurchasableSearchItem' }
    & Pick<CoursePurchasableSearchItem, 'productId' | 'productCode' | 'listPrice' | 'memberPrice' | 'externalUrl' | 'onSale' | 'memberBenefit' | 'numberAvailable'>
  ) | (
    { __typename?: 'PublicationPurchasableSearchItem' }
    & Pick<PublicationPurchasableSearchItem, 'productId' | 'productCode' | 'listPrice' | 'memberPrice' | 'externalUrl' | 'onSale' | 'memberBenefit' | 'numberAvailable'>
  )>> }
  & CommerceSearchItemNode_PublicationSearchItem_Fragment
);

type MeetingSearchItemNode_ConferenceSearchItem_Fragment = (
  { __typename?: 'ConferenceSearchItem' }
  & Pick<ConferenceSearchItem, 'programCode' | 'subTitle' | 'eventRegions' | 'levels' | 'languages'>
  & { purchasableItems?: Maybe<Array<(
    { __typename?: 'CoursePurchasableSearchItem' }
    & Pick<CoursePurchasableSearchItem, 'sessionDescription' | 'location' | 'beginDate' | 'endDate' | 'deliveryMethods' | 'duration' | 'facilityName' | 'facilityAddress' | 'facilityDescription' | 'productId' | 'productCode' | 'listPrice' | 'memberPrice' | 'externalUrl' | 'onSale' | 'memberBenefit' | 'numberAvailable'>
  ) | (
    { __typename?: 'PublicationPurchasableSearchItem' }
    & Pick<PublicationPurchasableSearchItem, 'productId' | 'productCode' | 'listPrice' | 'memberPrice' | 'externalUrl' | 'onSale' | 'memberBenefit' | 'numberAvailable'>
  )>> }
  & CommerceSearchItemNode_ConferenceSearchItem_Fragment
);

type MeetingSearchItemNode_CourseSearchItem_Fragment = (
  { __typename?: 'CourseSearchItem' }
  & Pick<CourseSearchItem, 'programCode' | 'subTitle' | 'eventRegions' | 'levels' | 'languages'>
  & { purchasableItems?: Maybe<Array<(
    { __typename?: 'CoursePurchasableSearchItem' }
    & Pick<CoursePurchasableSearchItem, 'sessionDescription' | 'location' | 'beginDate' | 'endDate' | 'deliveryMethods' | 'duration' | 'facilityName' | 'facilityAddress' | 'facilityDescription' | 'productId' | 'productCode' | 'listPrice' | 'memberPrice' | 'externalUrl' | 'onSale' | 'memberBenefit' | 'numberAvailable'>
  ) | (
    { __typename?: 'PublicationPurchasableSearchItem' }
    & Pick<PublicationPurchasableSearchItem, 'productId' | 'productCode' | 'listPrice' | 'memberPrice' | 'externalUrl' | 'onSale' | 'memberBenefit' | 'numberAvailable'>
  )>> }
  & CommerceSearchItemNode_CourseSearchItem_Fragment
);

export type MeetingSearchItemNodeFragment = MeetingSearchItemNode_ConferenceSearchItem_Fragment | MeetingSearchItemNode_CourseSearchItem_Fragment;

export type ConferenceSearchItemNodeFragment = (
  { __typename?: 'ConferenceSearchItem' }
  & Pick<ConferenceSearchItem, 'isBookmarked'>
  & MeetingSearchItemNode_ConferenceSearchItem_Fragment
);

export type CourseSearchItemNodeFragment = (
  { __typename?: 'CourseSearchItem' }
  & Pick<CourseSearchItem, 'isBookmarked'>
  & MeetingSearchItemNode_CourseSearchItem_Fragment
);

export type UserSearchItemNodeFragment = (
  { __typename?: 'UserSearchItem' }
  & Pick<UserSearchItem, 'firstName' | 'lastName' | 'fullName' | 'bio' | 'customerNumber' | 'member' | 'staff' | 'jobTitle' | 'company' | 'countryCode' | 'isFollowingCurrentUser' | 'isFollowedByCurrentUser'>
);

export type FiltersNodeFragment = (
  { __typename?: 'Filters' }
  & Pick<Filters, 'key' | 'type' | 'label' | 'allLabel'>
  & { values?: Maybe<Array<(
    { __typename?: 'FilterListItem' }
    & Pick<FilterListItem, 'value' | 'label' | 'isActive'>
  )>> }
);

type MetaNode_AlgoliaContentMeta_Fragment = (
  { __typename?: 'AlgoliaContentMeta' }
  & Pick<AlgoliaContentMeta, 'count' | 'pageSize' | 'page' | 'totalPages'>
);

type MetaNode_FeaturedContentMeta_Fragment = (
  { __typename?: 'FeaturedContentMeta' }
  & Pick<FeaturedContentMeta, 'count' | 'pageSize' | 'page' | 'totalPages'>
);

type MetaNode_LearningActivityMeta_Fragment = (
  { __typename?: 'LearningActivityMeta' }
  & Pick<LearningActivityMeta, 'count' | 'pageSize' | 'page' | 'totalPages'>
);

type MetaNode_NetworkActivityMeta_Fragment = (
  { __typename?: 'NetworkActivityMeta' }
  & Pick<NetworkActivityMeta, 'count' | 'pageSize' | 'page' | 'totalPages'>
);

type MetaNode_OrdersMeta_Fragment = (
  { __typename?: 'OrdersMeta' }
  & Pick<OrdersMeta, 'count' | 'pageSize' | 'page' | 'totalPages'>
);

type MetaNode_PublicUsersMeta_Fragment = (
  { __typename?: 'PublicUsersMeta' }
  & Pick<PublicUsersMeta, 'count' | 'pageSize' | 'page' | 'totalPages'>
);

type MetaNode_RecommendedContentMeta_Fragment = (
  { __typename?: 'RecommendedContentMeta' }
  & Pick<RecommendedContentMeta, 'count' | 'pageSize' | 'page' | 'totalPages'>
);

type MetaNode_UserBenefitsMeta_Fragment = (
  { __typename?: 'UserBenefitsMeta' }
  & Pick<UserBenefitsMeta, 'count' | 'pageSize' | 'page' | 'totalPages'>
);

type MetaNode_UserContentMeta_Fragment = (
  { __typename?: 'UserContentMeta' }
  & Pick<UserContentMeta, 'count' | 'pageSize' | 'page' | 'totalPages'>
);

type MetaNode_UserGroupMeta_Fragment = (
  { __typename?: 'UserGroupMeta' }
  & Pick<UserGroupMeta, 'count' | 'pageSize' | 'page' | 'totalPages'>
);

export type MetaNodeFragment = MetaNode_AlgoliaContentMeta_Fragment | MetaNode_FeaturedContentMeta_Fragment | MetaNode_LearningActivityMeta_Fragment | MetaNode_NetworkActivityMeta_Fragment | MetaNode_OrdersMeta_Fragment | MetaNode_PublicUsersMeta_Fragment | MetaNode_RecommendedContentMeta_Fragment | MetaNode_UserBenefitsMeta_Fragment | MetaNode_UserContentMeta_Fragment | MetaNode_UserGroupMeta_Fragment;

export type TaxonomyLevelsFragment = (
  { __typename?: 'TaxonomySearchItem' }
  & Pick<TaxonomySearchItem, 'lvl0' | 'lvl1' | 'lvl2'>
);

type IAbstractSearchItem_ArticleSearchItem_Fragment = (
  { __typename?: 'ArticleSearchItem' }
  & Pick<ArticleSearchItem, 'id' | 'title' | 'publishDate' | 'updateDate' | 'description' | 'bodyText' | 'imageUrl' | 'url' | 'viewCount' | 'topics' | 'primaryTopic' | 'topicCodes' | 'industryCodes' | 'roleCodes' | 'contentTypesArray' | 'industries' | 'roles' | 'keywords' | 'featured' | 'memberBenefit' | 'isBookmarkable'>
  & { contentTypes?: Maybe<(
    { __typename?: 'TaxonomySearchItem' }
    & TaxonomyLevelsFragment
  )>, authors?: Maybe<Array<(
    { __typename?: 'UserSearchItem' }
    & Pick<UserSearchItem, 'firstName' | 'lastName'>
  )>>, _snippetResult?: Maybe<(
    { __typename?: 'SnippetResult' }
    & { description?: Maybe<(
      { __typename?: 'SnippetItem' }
      & Pick<SnippetItem, 'value'>
    )> }
  )> }
);

type IAbstractSearchItem_BlogSearchItem_Fragment = (
  { __typename?: 'BlogSearchItem' }
  & Pick<BlogSearchItem, 'id' | 'title' | 'publishDate' | 'updateDate' | 'description' | 'bodyText' | 'imageUrl' | 'url' | 'viewCount' | 'topics' | 'primaryTopic' | 'topicCodes' | 'industryCodes' | 'roleCodes' | 'contentTypesArray' | 'industries' | 'roles' | 'keywords' | 'featured' | 'memberBenefit' | 'isBookmarkable'>
  & { contentTypes?: Maybe<(
    { __typename?: 'TaxonomySearchItem' }
    & TaxonomyLevelsFragment
  )>, authors?: Maybe<Array<(
    { __typename?: 'UserSearchItem' }
    & Pick<UserSearchItem, 'firstName' | 'lastName'>
  )>>, _snippetResult?: Maybe<(
    { __typename?: 'SnippetResult' }
    & { description?: Maybe<(
      { __typename?: 'SnippetItem' }
      & Pick<SnippetItem, 'value'>
    )> }
  )> }
);

type IAbstractSearchItem_ConferenceSearchItem_Fragment = (
  { __typename?: 'ConferenceSearchItem' }
  & Pick<ConferenceSearchItem, 'id' | 'title' | 'publishDate' | 'updateDate' | 'description' | 'bodyText' | 'imageUrl' | 'url' | 'viewCount' | 'topics' | 'primaryTopic' | 'topicCodes' | 'industryCodes' | 'roleCodes' | 'contentTypesArray' | 'industries' | 'roles' | 'keywords' | 'featured' | 'memberBenefit' | 'isBookmarkable'>
  & { contentTypes?: Maybe<(
    { __typename?: 'TaxonomySearchItem' }
    & TaxonomyLevelsFragment
  )>, authors?: Maybe<Array<(
    { __typename?: 'UserSearchItem' }
    & Pick<UserSearchItem, 'firstName' | 'lastName'>
  )>>, _snippetResult?: Maybe<(
    { __typename?: 'SnippetResult' }
    & { description?: Maybe<(
      { __typename?: 'SnippetItem' }
      & Pick<SnippetItem, 'value'>
    )> }
  )> }
);

type IAbstractSearchItem_CourseSearchItem_Fragment = (
  { __typename?: 'CourseSearchItem' }
  & Pick<CourseSearchItem, 'id' | 'title' | 'publishDate' | 'updateDate' | 'description' | 'bodyText' | 'imageUrl' | 'url' | 'viewCount' | 'topics' | 'primaryTopic' | 'topicCodes' | 'industryCodes' | 'roleCodes' | 'contentTypesArray' | 'industries' | 'roles' | 'keywords' | 'featured' | 'memberBenefit' | 'isBookmarkable'>
  & { contentTypes?: Maybe<(
    { __typename?: 'TaxonomySearchItem' }
    & TaxonomyLevelsFragment
  )>, authors?: Maybe<Array<(
    { __typename?: 'UserSearchItem' }
    & Pick<UserSearchItem, 'firstName' | 'lastName'>
  )>>, _snippetResult?: Maybe<(
    { __typename?: 'SnippetResult' }
    & { description?: Maybe<(
      { __typename?: 'SnippetItem' }
      & Pick<SnippetItem, 'value'>
    )> }
  )> }
);

type IAbstractSearchItem_MeetingSessionSearchItem_Fragment = (
  { __typename?: 'MeetingSessionSearchItem' }
  & Pick<MeetingSessionSearchItem, 'id' | 'title' | 'publishDate' | 'updateDate' | 'description' | 'bodyText' | 'imageUrl' | 'url' | 'viewCount' | 'topics' | 'primaryTopic' | 'topicCodes' | 'industryCodes' | 'roleCodes' | 'contentTypesArray' | 'industries' | 'roles' | 'keywords' | 'featured' | 'memberBenefit' | 'isBookmarkable'>
  & { contentTypes?: Maybe<(
    { __typename?: 'TaxonomySearchItem' }
    & TaxonomyLevelsFragment
  )>, authors?: Maybe<Array<(
    { __typename?: 'UserSearchItem' }
    & Pick<UserSearchItem, 'firstName' | 'lastName'>
  )>>, _snippetResult?: Maybe<(
    { __typename?: 'SnippetResult' }
    & { description?: Maybe<(
      { __typename?: 'SnippetItem' }
      & Pick<SnippetItem, 'value'>
    )> }
  )> }
);

type IAbstractSearchItem_PageSearchItem_Fragment = (
  { __typename?: 'PageSearchItem' }
  & Pick<PageSearchItem, 'id' | 'title' | 'publishDate' | 'updateDate' | 'description' | 'bodyText' | 'imageUrl' | 'url' | 'viewCount' | 'topics' | 'primaryTopic' | 'topicCodes' | 'industryCodes' | 'roleCodes' | 'contentTypesArray' | 'industries' | 'roles' | 'keywords' | 'featured' | 'memberBenefit' | 'isBookmarkable'>
  & { contentTypes?: Maybe<(
    { __typename?: 'TaxonomySearchItem' }
    & TaxonomyLevelsFragment
  )>, authors?: Maybe<Array<(
    { __typename?: 'UserSearchItem' }
    & Pick<UserSearchItem, 'firstName' | 'lastName'>
  )>>, _snippetResult?: Maybe<(
    { __typename?: 'SnippetResult' }
    & { description?: Maybe<(
      { __typename?: 'SnippetItem' }
      & Pick<SnippetItem, 'value'>
    )> }
  )> }
);

type IAbstractSearchItem_PodcastSearchItem_Fragment = (
  { __typename?: 'PodcastSearchItem' }
  & Pick<PodcastSearchItem, 'id' | 'title' | 'publishDate' | 'updateDate' | 'description' | 'bodyText' | 'imageUrl' | 'url' | 'viewCount' | 'topics' | 'primaryTopic' | 'topicCodes' | 'industryCodes' | 'roleCodes' | 'contentTypesArray' | 'industries' | 'roles' | 'keywords' | 'featured' | 'memberBenefit' | 'isBookmarkable'>
  & { contentTypes?: Maybe<(
    { __typename?: 'TaxonomySearchItem' }
    & TaxonomyLevelsFragment
  )>, authors?: Maybe<Array<(
    { __typename?: 'UserSearchItem' }
    & Pick<UserSearchItem, 'firstName' | 'lastName'>
  )>>, _snippetResult?: Maybe<(
    { __typename?: 'SnippetResult' }
    & { description?: Maybe<(
      { __typename?: 'SnippetItem' }
      & Pick<SnippetItem, 'value'>
    )> }
  )> }
);

type IAbstractSearchItem_PublicationSearchItem_Fragment = (
  { __typename?: 'PublicationSearchItem' }
  & Pick<PublicationSearchItem, 'id' | 'title' | 'publishDate' | 'updateDate' | 'description' | 'bodyText' | 'imageUrl' | 'url' | 'viewCount' | 'topics' | 'primaryTopic' | 'topicCodes' | 'industryCodes' | 'roleCodes' | 'contentTypesArray' | 'industries' | 'roles' | 'keywords' | 'featured' | 'memberBenefit' | 'isBookmarkable'>
  & { contentTypes?: Maybe<(
    { __typename?: 'TaxonomySearchItem' }
    & TaxonomyLevelsFragment
  )>, authors?: Maybe<Array<(
    { __typename?: 'UserSearchItem' }
    & Pick<UserSearchItem, 'firstName' | 'lastName'>
  )>>, _snippetResult?: Maybe<(
    { __typename?: 'SnippetResult' }
    & { description?: Maybe<(
      { __typename?: 'SnippetItem' }
      & Pick<SnippetItem, 'value'>
    )> }
  )> }
);

type IAbstractSearchItem_UserSearchItem_Fragment = (
  { __typename?: 'UserSearchItem' }
  & Pick<UserSearchItem, 'id' | 'title' | 'publishDate' | 'updateDate' | 'description' | 'bodyText' | 'imageUrl' | 'url' | 'viewCount' | 'topics' | 'primaryTopic' | 'topicCodes' | 'industryCodes' | 'roleCodes' | 'contentTypesArray' | 'industries' | 'roles' | 'keywords' | 'featured' | 'memberBenefit' | 'isBookmarkable'>
  & { contentTypes?: Maybe<(
    { __typename?: 'TaxonomySearchItem' }
    & TaxonomyLevelsFragment
  )>, authors?: Maybe<Array<(
    { __typename?: 'UserSearchItem' }
    & Pick<UserSearchItem, 'firstName' | 'lastName'>
  )>>, _snippetResult?: Maybe<(
    { __typename?: 'SnippetResult' }
    & { description?: Maybe<(
      { __typename?: 'SnippetItem' }
      & Pick<SnippetItem, 'value'>
    )> }
  )> }
);

type IAbstractSearchItem_VideoSearchItem_Fragment = (
  { __typename?: 'VideoSearchItem' }
  & Pick<VideoSearchItem, 'id' | 'title' | 'publishDate' | 'updateDate' | 'description' | 'bodyText' | 'imageUrl' | 'url' | 'viewCount' | 'topics' | 'primaryTopic' | 'topicCodes' | 'industryCodes' | 'roleCodes' | 'contentTypesArray' | 'industries' | 'roles' | 'keywords' | 'featured' | 'memberBenefit' | 'isBookmarkable'>
  & { contentTypes?: Maybe<(
    { __typename?: 'TaxonomySearchItem' }
    & TaxonomyLevelsFragment
  )>, authors?: Maybe<Array<(
    { __typename?: 'UserSearchItem' }
    & Pick<UserSearchItem, 'firstName' | 'lastName'>
  )>>, _snippetResult?: Maybe<(
    { __typename?: 'SnippetResult' }
    & { description?: Maybe<(
      { __typename?: 'SnippetItem' }
      & Pick<SnippetItem, 'value'>
    )> }
  )> }
);

type IAbstractSearchItem_WebinarSearchItem_Fragment = (
  { __typename?: 'WebinarSearchItem' }
  & Pick<WebinarSearchItem, 'id' | 'title' | 'publishDate' | 'updateDate' | 'description' | 'bodyText' | 'imageUrl' | 'url' | 'viewCount' | 'topics' | 'primaryTopic' | 'topicCodes' | 'industryCodes' | 'roleCodes' | 'contentTypesArray' | 'industries' | 'roles' | 'keywords' | 'featured' | 'memberBenefit' | 'isBookmarkable'>
  & { contentTypes?: Maybe<(
    { __typename?: 'TaxonomySearchItem' }
    & TaxonomyLevelsFragment
  )>, authors?: Maybe<Array<(
    { __typename?: 'UserSearchItem' }
    & Pick<UserSearchItem, 'firstName' | 'lastName'>
  )>>, _snippetResult?: Maybe<(
    { __typename?: 'SnippetResult' }
    & { description?: Maybe<(
      { __typename?: 'SnippetItem' }
      & Pick<SnippetItem, 'value'>
    )> }
  )> }
);

export type IAbstractSearchItemFragment = IAbstractSearchItem_ArticleSearchItem_Fragment | IAbstractSearchItem_BlogSearchItem_Fragment | IAbstractSearchItem_ConferenceSearchItem_Fragment | IAbstractSearchItem_CourseSearchItem_Fragment | IAbstractSearchItem_MeetingSessionSearchItem_Fragment | IAbstractSearchItem_PageSearchItem_Fragment | IAbstractSearchItem_PodcastSearchItem_Fragment | IAbstractSearchItem_PublicationSearchItem_Fragment | IAbstractSearchItem_UserSearchItem_Fragment | IAbstractSearchItem_VideoSearchItem_Fragment | IAbstractSearchItem_WebinarSearchItem_Fragment;

type IContentSearchItem_ArticleSearchItem_Fragment = (
  { __typename?: 'ArticleSearchItem' }
  & Pick<ArticleSearchItem, 'periodical' | 'issue' | 'column' | 'part'>
);

type IContentSearchItem_BlogSearchItem_Fragment = (
  { __typename?: 'BlogSearchItem' }
  & Pick<BlogSearchItem, 'periodical' | 'issue' | 'column' | 'part'>
);

type IContentSearchItem_PageSearchItem_Fragment = (
  { __typename?: 'PageSearchItem' }
  & Pick<PageSearchItem, 'periodical' | 'issue' | 'column' | 'part'>
);

type IContentSearchItem_VideoSearchItem_Fragment = (
  { __typename?: 'VideoSearchItem' }
  & Pick<VideoSearchItem, 'periodical' | 'issue' | 'column' | 'part'>
);

export type IContentSearchItemFragment = IContentSearchItem_ArticleSearchItem_Fragment | IContentSearchItem_BlogSearchItem_Fragment | IContentSearchItem_PageSearchItem_Fragment | IContentSearchItem_VideoSearchItem_Fragment;

export type LinkNodeFragment = (
  { __typename?: 'Link' }
  & Pick<Link, 'url' | 'title' | 'linkType'>
);

export type OrderLineItemNodeFragment = (
  { __typename?: 'Order' }
  & { lineItems: Array<(
    { __typename?: 'OrderLineItem' }
    & Pick<OrderLineItem, 'id' | 'price' | 'title' | 'imageUrl' | 'itemUrl' | 'downloadUrl' | 'orderLineNumber' | 'orderNumber' | 'type' | 'autoRenew'>
  )> }
);

export type OrderNodeFragment = (
  { __typename?: 'Order' }
  & Pick<Order, 'id' | 'date' | 'totalPrice' | 'shippedTo' | 'orderNumber' | 'receiptUrl'>
  & OrderLineItemNodeFragment
);

export type ProfileFragment = (
  { __typename?: 'User' }
  & { profile: (
    { __typename?: 'Profile' }
    & Pick<Profile, 'userName' | 'firstName' | 'lastName' | 'profileImageUrl' | 'bio' | 'gender' | 'website' | 'specialNeeds'>
  ) }
);

export type JobFragment = (
  { __typename?: 'User' }
  & { job: (
    { __typename?: 'Job' }
    & Pick<Job, 'userIndustry' | 'jobTitle' | 'jobFunctionCode' | 'jobIndustry' | 'companySize' | 'companyName' | 'trainingBudget' | 'directReportCount'>
  ) }
);

export type CertificationFragment = (
  { __typename?: 'User' }
  & { certification: Array<(
    { __typename?: 'Certification' }
    & Pick<Certification, 'expiryDate' | 'earnedDate' | 'masters' | 'recertifyByDate' | 'certificationStatus' | 'certificationCode'>
  )> }
);

export type BadgeFragment = (
  { __typename?: 'User' }
  & { badge: Array<(
    { __typename?: 'Badge' }
    & Pick<Badge, 'id' | 'badgeId' | 'imageUrl' | 'thumbnailUrl' | 'createdAt' | 'issuedAt' | 'title' | 'description' | 'url' | 'pending' | 'acceptUrl'>
  )> }
);

export type VisibilityFragment = (
  { __typename?: 'User' }
  & { visibility: (
    { __typename?: 'Visibility' }
    & Pick<Visibility, 'publicProfile' | 'memberDirectoryListing'>
  ) }
);

export type EducationFragment = (
  { __typename?: 'User' }
  & { education: Array<(
    { __typename?: 'Education' }
    & Pick<Education, 'id' | 'degreeType' | 'programType' | 'schoolName' | 'startDate' | 'endDate'>
  )> }
);

export type ContactInfoFragment = (
  { __typename?: 'User' }
  & { contactInfo: (
    { __typename?: 'ContactInfo' }
    & Pick<ContactInfo, 'primaryPhoneNumber' | 'secondaryPhoneNumber' | 'primaryEmailAddress'>
    & { address?: Maybe<Array<(
      { __typename?: 'Address' }
      & Pick<Address, 'id' | 'addressType' | 'isPrimary' | 'address1' | 'address2' | 'address3' | 'city' | 'state' | 'countryCode' | 'postalCode' | 'formattedAddress'>
    )>>, phones?: Maybe<Array<(
      { __typename?: 'Phone' }
      & Pick<Phone, 'id' | 'isPrimary' | 'doNotCallFlag' | 'formattedPhoneAddress' | 'phoneAreaCode' | 'countryCode' | 'phoneCountryCode' | 'phoneExtension' | 'phoneNumber' | 'commLocationCodeString' | 'commTypeCodeString'>
    )>> }
  ) }
);

export type CommunicationPreferencesFragment = (
  { __typename?: 'User' }
  & { communicationPreferences: (
    { __typename?: 'CommunicationPreferences' }
    & Pick<CommunicationPreferences, 'automatedNewsletters' | 'isPostalMailOptedOut' | 'isPhoneOptedOut' | 'receivePromotionalCommunication' | 'bimonthlyDigest'>
    & { newsletters: Array<(
      { __typename?: 'NewsletterGroup' }
      & Pick<NewsletterGroup, 'newsletterGroupLabel'>
      & { newsletterGroup: Array<(
        { __typename?: 'NewsletterItem' }
        & Pick<NewsletterItem, 'key' | 'label' | 'value' | 'frequency'>
      )> }
    )> }
  ) }
);

export type MembershipFragment = (
  { __typename?: 'User' }
  & { membership: (
    { __typename?: 'Membership' }
    & Pick<Membership, 'id' | 'isMember' | 'isEnterpriseMember'>
    & { overview?: Maybe<(
      { __typename?: 'MembershipOverview' }
      & Pick<MembershipOverview, 'type' | 'label'>
      & { perks?: Maybe<Array<(
        { __typename?: 'Perk' }
        & Pick<Perk, 'label' | 'value'>
      )>> }
    )>, currentMembership?: Maybe<(
      { __typename?: 'CurrentMembership' }
      & Pick<CurrentMembership, 'id' | 'memberType' | 'memberNumber' | 'memberTypeLabel' | 'memberStatus' | 'orderNumber' | 'orderLineNumber' | 'autoRenew' | 'initialBeginDate' | 'cycleBeginDate' | 'cycleEndDate'>
    )>, futureMembership?: Maybe<Array<(
      { __typename?: 'FutureMembership' }
      & Pick<FutureMembership, 'id' | 'memberType' | 'memberTypeLabel' | 'memberNumber' | 'memberStatus' | 'autoRenew' | 'orderNumber' | 'orderLineNumber' | 'initialBeginDate' | 'cycleBeginDate' | 'cycleEndDate' | 'chapterName' | 'chapterURL' | 'recordTypeCode'>
    )>>, chapterMembership?: Maybe<Array<(
      { __typename?: 'ChapterMembership' }
      & Pick<ChapterMembership, 'id' | 'chapterName' | 'memberTypeLabel' | 'memberType' | 'memberNumber' | 'memberStatus' | 'autoRenew' | 'orderNumber' | 'orderLineNumber' | 'initialBeginDate' | 'cycleBeginDate' | 'cycleEndDate' | 'chapterURL'>
    )>>, benefitResources: Array<(
      { __typename?: 'Link' }
      & LinkNodeFragment
    )>, additionalMemberResources: Array<(
      { __typename?: 'Link' }
      & LinkNodeFragment
    )>, claimResource: (
      { __typename?: 'ClaimResource' }
      & Pick<ClaimResource, 'showBanner' | 'claimLink'>
    ) }
  ) }
);

export type CapabilityModelFragment = (
  { __typename?: 'User' }
  & { capabilityModel: (
    { __typename?: 'CapabilityModel' }
    & Pick<CapabilityModel, 'isSelfAssessmentStarted' | 'isSelfAssessmentComplete' | 'isLearningPathSelected' | 'isLearningPlanCreated' | 'isLearningPlanSelected' | 'isPartialPlan' | 'isLearningPlanComplete' | 'planActivitiesCount' | 'planActivitiesCompletedCount' | 'isPersonalCapabilityComplete' | 'isProfessionalCapabilityComplete' | 'isOrganizationalCapabilityComplete' | 'learningPathLabel' | 'totalScore'>
    & { domains: Array<(
      { __typename?: 'Domain' }
      & Pick<Domain, 'domainTitle' | 'totalProgress'>
      & { capabilities?: Maybe<Array<(
        { __typename?: 'Capability' }
        & Pick<Capability, 'capTitle' | 'capTotalScore'>
        & { statements?: Maybe<Array<(
          { __typename?: 'Statements' }
          & Pick<Statements, 'desc' | 'descScore'>
        )>> }
      )>> }
    )> }
  ) }
);

export type TopicsFragment = (
  { __typename?: 'User' }
  & { topics: Array<(
    { __typename?: 'Topic' }
    & Pick<Topic, 'id' | 'label' | 'key' | 'url'>
    & { userStatus: (
      { __typename?: 'UserTopicStatus' }
      & Pick<UserTopicStatus, 'id' | 'followed' | 'recommended'>
    ) }
  )> }
);

export type LearningActivityFragment = (
  { __typename?: 'User' }
  & { learningActivity?: Maybe<Array<(
    { __typename?: 'ArticleSearchItem' }
    & Pick<ArticleSearchItem, 'id' | 'title' | 'publishDate' | 'updateDate' | 'description' | 'bodyText' | 'imageUrl' | 'url' | 'viewCount' | 'topics' | 'industries' | 'roles' | 'keywords'>
    & { contentTypes?: Maybe<(
      { __typename?: 'TaxonomySearchItem' }
      & Pick<TaxonomySearchItem, 'lvl0' | 'lvl1' | 'lvl2'>
    )> }
  ) | (
    { __typename?: 'BlogSearchItem' }
    & Pick<BlogSearchItem, 'id' | 'title' | 'publishDate' | 'updateDate' | 'description' | 'bodyText' | 'imageUrl' | 'url' | 'viewCount' | 'topics' | 'industries' | 'roles' | 'keywords'>
    & { contentTypes?: Maybe<(
      { __typename?: 'TaxonomySearchItem' }
      & Pick<TaxonomySearchItem, 'lvl0' | 'lvl1' | 'lvl2'>
    )> }
  ) | (
    { __typename?: 'ConferenceSearchItem' }
    & Pick<ConferenceSearchItem, 'id' | 'title' | 'publishDate' | 'updateDate' | 'description' | 'bodyText' | 'imageUrl' | 'url' | 'viewCount' | 'topics' | 'industries' | 'roles' | 'keywords'>
    & { contentTypes?: Maybe<(
      { __typename?: 'TaxonomySearchItem' }
      & Pick<TaxonomySearchItem, 'lvl0' | 'lvl1' | 'lvl2'>
    )> }
  ) | (
    { __typename?: 'CourseSearchItem' }
    & Pick<CourseSearchItem, 'id' | 'title' | 'publishDate' | 'updateDate' | 'description' | 'bodyText' | 'imageUrl' | 'url' | 'viewCount' | 'topics' | 'industries' | 'roles' | 'keywords'>
    & { contentTypes?: Maybe<(
      { __typename?: 'TaxonomySearchItem' }
      & Pick<TaxonomySearchItem, 'lvl0' | 'lvl1' | 'lvl2'>
    )> }
  ) | (
    { __typename?: 'MeetingSessionSearchItem' }
    & Pick<MeetingSessionSearchItem, 'id' | 'title' | 'publishDate' | 'updateDate' | 'description' | 'bodyText' | 'imageUrl' | 'url' | 'viewCount' | 'topics' | 'industries' | 'roles' | 'keywords'>
    & { contentTypes?: Maybe<(
      { __typename?: 'TaxonomySearchItem' }
      & Pick<TaxonomySearchItem, 'lvl0' | 'lvl1' | 'lvl2'>
    )> }
  ) | (
    { __typename?: 'PageSearchItem' }
    & Pick<PageSearchItem, 'id' | 'title' | 'publishDate' | 'updateDate' | 'description' | 'bodyText' | 'imageUrl' | 'url' | 'viewCount' | 'topics' | 'industries' | 'roles' | 'keywords'>
    & { contentTypes?: Maybe<(
      { __typename?: 'TaxonomySearchItem' }
      & Pick<TaxonomySearchItem, 'lvl0' | 'lvl1' | 'lvl2'>
    )> }
  ) | (
    { __typename?: 'PodcastSearchItem' }
    & Pick<PodcastSearchItem, 'id' | 'title' | 'publishDate' | 'updateDate' | 'description' | 'bodyText' | 'imageUrl' | 'url' | 'viewCount' | 'topics' | 'industries' | 'roles' | 'keywords'>
    & { contentTypes?: Maybe<(
      { __typename?: 'TaxonomySearchItem' }
      & Pick<TaxonomySearchItem, 'lvl0' | 'lvl1' | 'lvl2'>
    )> }
  ) | (
    { __typename?: 'PublicationSearchItem' }
    & Pick<PublicationSearchItem, 'id' | 'title' | 'publishDate' | 'updateDate' | 'description' | 'bodyText' | 'imageUrl' | 'url' | 'viewCount' | 'topics' | 'industries' | 'roles' | 'keywords'>
    & { contentTypes?: Maybe<(
      { __typename?: 'TaxonomySearchItem' }
      & Pick<TaxonomySearchItem, 'lvl0' | 'lvl1' | 'lvl2'>
    )> }
  ) | (
    { __typename?: 'UserSearchItem' }
    & Pick<UserSearchItem, 'id' | 'title' | 'publishDate' | 'updateDate' | 'description' | 'bodyText' | 'imageUrl' | 'url' | 'viewCount' | 'topics' | 'industries' | 'roles' | 'keywords'>
    & { contentTypes?: Maybe<(
      { __typename?: 'TaxonomySearchItem' }
      & Pick<TaxonomySearchItem, 'lvl0' | 'lvl1' | 'lvl2'>
    )> }
  ) | (
    { __typename?: 'VideoSearchItem' }
    & Pick<VideoSearchItem, 'id' | 'title' | 'publishDate' | 'updateDate' | 'description' | 'bodyText' | 'imageUrl' | 'url' | 'viewCount' | 'topics' | 'industries' | 'roles' | 'keywords'>
    & { contentTypes?: Maybe<(
      { __typename?: 'TaxonomySearchItem' }
      & Pick<TaxonomySearchItem, 'lvl0' | 'lvl1' | 'lvl2'>
    )> }
  ) | (
    { __typename?: 'WebinarSearchItem' }
    & Pick<WebinarSearchItem, 'id' | 'title' | 'publishDate' | 'updateDate' | 'description' | 'bodyText' | 'imageUrl' | 'url' | 'viewCount' | 'topics' | 'industries' | 'roles' | 'keywords'>
    & { contentTypes?: Maybe<(
      { __typename?: 'TaxonomySearchItem' }
      & Pick<TaxonomySearchItem, 'lvl0' | 'lvl1' | 'lvl2'>
    )> }
  )>> }
);

export type CartFragment = (
  { __typename?: 'User' }
  & { cart?: Maybe<(
    { __typename?: 'Cart' }
    & Pick<Cart, 'id' | 'itemTotal'>
  )> }
);

export type UserNodeFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'staff' | 'isContributor' | 'customerClassCode' | 'isEnterpriseAdmin'>
  & ProfileFragment
  & JobFragment
  & CertificationFragment
  & BadgeFragment
  & VisibilityFragment
  & EducationFragment
  & ContactInfoFragment
  & CommunicationPreferencesFragment
  & LearningActivityFragment
  & MembershipFragment
  & CapabilityModelFragment
  & TopicsFragment
  & CartFragment
);

export type LoginMutationVariables = Exact<{
  input: LoginInput;
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'login'>
);

export type UserFollowTopicMutationVariables = Exact<{
  input: SubscribeTopicInput;
}>;


export type UserFollowTopicMutation = (
  { __typename?: 'Mutation' }
  & { subscribeTopic: (
    { __typename?: 'ResMsg' }
    & Pick<ResMsg, 'message' | 'status'>
  ) }
);

export type UserUnfollowTopicMutationVariables = Exact<{
  input: SubscribeTopicInput;
}>;


export type UserUnfollowTopicMutation = (
  { __typename?: 'Mutation' }
  & { unsubscribeTopic: (
    { __typename?: 'ResMsg' }
    & Pick<ResMsg, 'message' | 'status'>
  ) }
);

export type UserUpsertMutationVariables = Exact<{
  input: UpsertUserInput;
}>;


export type UserUpsertMutation = (
  { __typename?: 'Mutation' }
  & { userUpsert: (
    { __typename?: 'ResMsg' }
    & Pick<ResMsg, 'status' | 'message'>
  ) }
);

export type LandingPageContactFormUpdateMutationVariables = Exact<{
  input: LandingPageContactFormInput;
}>;


export type LandingPageContactFormUpdateMutation = (
  { __typename?: 'Mutation' }
  & { landingPageContactFormUpdate: (
    { __typename?: 'ResMsg' }
    & Pick<ResMsg, 'status' | 'message'>
  ) }
);

export type UserProfileUpdateMutationVariables = Exact<{
  input: ProfileInput;
}>;


export type UserProfileUpdateMutation = (
  { __typename?: 'Mutation' }
  & { userProfileUpdate: (
    { __typename?: 'ResMsg' }
    & Pick<ResMsg, 'status' | 'message'>
  ) }
);

export type UserJobUpdateMutationVariables = Exact<{
  input: JobInput;
}>;


export type UserJobUpdateMutation = (
  { __typename?: 'Mutation' }
  & { userJobUpdate: (
    { __typename?: 'ResMsg' }
    & Pick<ResMsg, 'status' | 'message'>
  ) }
);

export type UserEducationUpsertMutationVariables = Exact<{
  input: Array<EducationUpsertInput> | EducationUpsertInput;
}>;


export type UserEducationUpsertMutation = (
  { __typename?: 'Mutation' }
  & { userEducationUpsert: (
    { __typename?: 'ResMsg' }
    & Pick<ResMsg, 'status' | 'message'>
  ) }
);

export type UserEducationDeleteMutationVariables = Exact<{
  input: EducationDeleteInput;
}>;


export type UserEducationDeleteMutation = (
  { __typename?: 'Mutation' }
  & { userEducationDelete: (
    { __typename?: 'ResMsg' }
    & Pick<ResMsg, 'status' | 'message'>
  ) }
);

export type UserAddressDeleteMutationVariables = Exact<{
  input: AddressDeleteInput;
}>;


export type UserAddressDeleteMutation = (
  { __typename?: 'Mutation' }
  & { userAddressDelete: (
    { __typename?: 'ResMsg' }
    & Pick<ResMsg, 'status' | 'message'>
  ) }
);

export type UserPhoneDeleteMutationVariables = Exact<{
  input: PhoneDeleteInput;
}>;


export type UserPhoneDeleteMutation = (
  { __typename?: 'Mutation' }
  & { userPhoneDelete: (
    { __typename?: 'ResMsg' }
    & Pick<ResMsg, 'status' | 'message'>
  ) }
);

export type UserContactInfoUpdateMutationVariables = Exact<{
  input: ContactInfoInput;
}>;


export type UserContactInfoUpdateMutation = (
  { __typename?: 'Mutation' }
  & { userContactInfoUpdate: (
    { __typename?: 'ResMsg' }
    & Pick<ResMsg, 'status' | 'message'>
  ) }
);

export type UserCommunicationPreferencesUpdateMutationVariables = Exact<{
  input: CommunicationPreferencesInput;
}>;


export type UserCommunicationPreferencesUpdateMutation = (
  { __typename?: 'Mutation' }
  & { communicationPreferencesUpdate: (
    { __typename?: 'ResMsg' }
    & Pick<ResMsg, 'status' | 'message'>
  ) }
);

export type UserToggleProfilePrivacyMutationVariables = Exact<{
  input: Scalars['Boolean'];
}>;


export type UserToggleProfilePrivacyMutation = (
  { __typename?: 'Mutation' }
  & { toggleProfilePrivacy: (
    { __typename?: 'ResMsg' }
    & Pick<ResMsg, 'message' | 'status'>
  ) }
);

export type UserToggleMemberDirectoryPrivacyMutationVariables = Exact<{
  input: Scalars['Boolean'];
}>;


export type UserToggleMemberDirectoryPrivacyMutation = (
  { __typename?: 'Mutation' }
  & { toggleMemberDirectoryPrivacy: (
    { __typename?: 'ResMsg' }
    & Pick<ResMsg, 'message' | 'status'>
  ) }
);

export type UserUpdatePasswordMutationVariables = Exact<{
  input: UpdatePasswordInput;
}>;


export type UserUpdatePasswordMutation = (
  { __typename?: 'Mutation' }
  & { updatePassword: (
    { __typename?: 'ResMsg' }
    & Pick<ResMsg, 'message' | 'status'>
  ) }
);

export type UserUpdateEmailMutationVariables = Exact<{
  input: UpdateEmailInput;
}>;


export type UserUpdateEmailMutation = (
  { __typename?: 'Mutation' }
  & { updateEmail: (
    { __typename?: 'ResMsg' }
    & Pick<ResMsg, 'message' | 'status'>
  ) }
);

export type UploadFileMutationVariables = Exact<{
  input: UpdateProfilePicture;
}>;


export type UploadFileMutation = (
  { __typename?: 'Mutation' }
  & { uploadFile: (
    { __typename?: 'ResMsg' }
    & Pick<ResMsg, 'message' | 'status'>
  ) }
);

export type RemoveFileMutationVariables = Exact<{ [key: string]: never; }>;


export type RemoveFileMutation = (
  { __typename?: 'Mutation' }
  & { removeFile: (
    { __typename?: 'ResMsg' }
    & Pick<ResMsg, 'message' | 'status'>
  ) }
);

export type UserUpdateOrderAutorenewalMutationVariables = Exact<{
  input: UpdateOrderAutoRenewalInput;
}>;


export type UserUpdateOrderAutorenewalMutation = (
  { __typename?: 'Mutation' }
  & { updateOrderAutoRenewal: (
    { __typename?: 'ResMsg' }
    & Pick<ResMsg, 'status' | 'message'>
  ) }
);

export type CreateBookmarkMutationVariables = Exact<{
  input: UpdateBookmarkInput;
}>;


export type CreateBookmarkMutation = (
  { __typename?: 'Mutation' }
  & { createBookmark: (
    { __typename?: 'ResMsg' }
    & Pick<ResMsg, 'message' | 'status'>
  ) }
);

export type DeleteBookmarkMutationVariables = Exact<{
  input: UpdateBookmarkInput;
}>;


export type DeleteBookmarkMutation = (
  { __typename?: 'Mutation' }
  & { deleteBookmark: (
    { __typename?: 'ResMsg' }
    & Pick<ResMsg, 'message' | 'status'>
  ) }
);

export type UserFollowUserMutationVariables = Exact<{
  input: SubscribePublicUserInput;
}>;


export type UserFollowUserMutation = (
  { __typename?: 'Mutation' }
  & { subscribePublicUser: (
    { __typename?: 'ResMsg' }
    & Pick<ResMsg, 'message' | 'status'>
  ) }
);

export type UserUnfollowUserMutationVariables = Exact<{
  input: SubscribePublicUserInput;
}>;


export type UserUnfollowUserMutation = (
  { __typename?: 'Mutation' }
  & { unsubscribePublicUser: (
    { __typename?: 'ResMsg' }
    & Pick<ResMsg, 'message' | 'status'>
  ) }
);

export type UserJoinUserGroupMutationVariables = Exact<{
  input: SubscribeUserGroupInput;
}>;


export type UserJoinUserGroupMutation = (
  { __typename?: 'Mutation' }
  & { subscribeUserGroup: (
    { __typename?: 'ResMsg' }
    & Pick<ResMsg, 'message' | 'status'>
  ) }
);

export type UserLeaveUserGroupMutationVariables = Exact<{
  input: SubscribeUserGroupInput;
}>;


export type UserLeaveUserGroupMutation = (
  { __typename?: 'Mutation' }
  & { unsubscribeUserGroup: (
    { __typename?: 'ResMsg' }
    & Pick<ResMsg, 'message' | 'status'>
  ) }
);

export type GetAlgoliaContentQueryVariables = Exact<{
  input: AlgoliaContentInput;
}>;


export type GetAlgoliaContentQuery = (
  { __typename?: 'Query' }
  & { algoliaContent: (
    { __typename?: 'AlgoliaContentPayload' }
    & { meta: (
      { __typename?: 'AlgoliaContentMeta' }
      & Pick<AlgoliaContentMeta, 'page' | 'pageSize' | 'count' | 'totalPages'>
    ), data?: Maybe<Array<(
      { __typename?: 'ArticleSearchItem' }
      & AllContentItemNode_ArticleSearchItem_Fragment
    ) | (
      { __typename?: 'BlogSearchItem' }
      & AllContentItemNode_BlogSearchItem_Fragment
    ) | (
      { __typename?: 'ConferenceSearchItem' }
      & AllContentItemNode_ConferenceSearchItem_Fragment
    ) | (
      { __typename?: 'CourseSearchItem' }
      & AllContentItemNode_CourseSearchItem_Fragment
    ) | (
      { __typename?: 'MeetingSessionSearchItem' }
      & AllContentItemNode_MeetingSessionSearchItem_Fragment
    ) | (
      { __typename?: 'PageSearchItem' }
      & AllContentItemNode_PageSearchItem_Fragment
    ) | (
      { __typename?: 'PodcastSearchItem' }
      & AllContentItemNode_PodcastSearchItem_Fragment
    ) | (
      { __typename?: 'PublicationSearchItem' }
      & AllContentItemNode_PublicationSearchItem_Fragment
    ) | (
      { __typename?: 'UserSearchItem' }
      & AllContentItemNode_UserSearchItem_Fragment
    ) | (
      { __typename?: 'VideoSearchItem' }
      & AllContentItemNode_VideoSearchItem_Fragment
    ) | (
      { __typename?: 'WebinarSearchItem' }
      & AllContentItemNode_WebinarSearchItem_Fragment
    )>> }
  ) }
);

export type GetConstantsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetConstantsQuery = (
  { __typename?: 'Query' }
  & { constants: (
    { __typename?: 'ConstantsPayload' }
    & ConstantsNodeFragment
  ) }
);

export type GetFeaturedBenefitQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFeaturedBenefitQuery = (
  { __typename?: 'Query' }
  & { featuredBenefit: (
    { __typename?: 'FeaturedBenefitPayload' }
    & Pick<FeaturedBenefitPayload, 'title' | 'subTitle'>
    & { benefit: (
      { __typename?: 'ArticleSearchItem' }
      & AllContentItemNode_ArticleSearchItem_Fragment
    ) | (
      { __typename?: 'BlogSearchItem' }
      & AllContentItemNode_BlogSearchItem_Fragment
    ) | (
      { __typename?: 'ConferenceSearchItem' }
      & AllContentItemNode_ConferenceSearchItem_Fragment
    ) | (
      { __typename?: 'CourseSearchItem' }
      & AllContentItemNode_CourseSearchItem_Fragment
    ) | (
      { __typename?: 'MeetingSessionSearchItem' }
      & AllContentItemNode_MeetingSessionSearchItem_Fragment
    ) | (
      { __typename?: 'PageSearchItem' }
      & AllContentItemNode_PageSearchItem_Fragment
    ) | (
      { __typename?: 'PodcastSearchItem' }
      & AllContentItemNode_PodcastSearchItem_Fragment
    ) | (
      { __typename?: 'PublicationSearchItem' }
      & AllContentItemNode_PublicationSearchItem_Fragment
    ) | (
      { __typename?: 'UserSearchItem' }
      & AllContentItemNode_UserSearchItem_Fragment
    ) | (
      { __typename?: 'VideoSearchItem' }
      & AllContentItemNode_VideoSearchItem_Fragment
    ) | (
      { __typename?: 'WebinarSearchItem' }
      & AllContentItemNode_WebinarSearchItem_Fragment
    ) }
  ) }
);

export type GetFeaturedContentQueryVariables = Exact<{
  input?: Maybe<FeaturedContentInput>;
}>;


export type GetFeaturedContentQuery = (
  { __typename?: 'Query' }
  & { featuredContent: (
    { __typename?: 'FeaturedContentPayload' }
    & { meta: (
      { __typename?: 'FeaturedContentMeta' }
      & MetaNode_FeaturedContentMeta_Fragment
    ), data?: Maybe<Array<(
      { __typename?: 'ArticleSearchItem' }
      & AllContentItemNode_ArticleSearchItem_Fragment
    ) | (
      { __typename?: 'BlogSearchItem' }
      & AllContentItemNode_BlogSearchItem_Fragment
    ) | (
      { __typename?: 'ConferenceSearchItem' }
      & AllContentItemNode_ConferenceSearchItem_Fragment
    ) | (
      { __typename?: 'CourseSearchItem' }
      & AllContentItemNode_CourseSearchItem_Fragment
    ) | (
      { __typename?: 'MeetingSessionSearchItem' }
      & AllContentItemNode_MeetingSessionSearchItem_Fragment
    ) | (
      { __typename?: 'PageSearchItem' }
      & AllContentItemNode_PageSearchItem_Fragment
    ) | (
      { __typename?: 'PodcastSearchItem' }
      & AllContentItemNode_PodcastSearchItem_Fragment
    ) | (
      { __typename?: 'PublicationSearchItem' }
      & AllContentItemNode_PublicationSearchItem_Fragment
    ) | (
      { __typename?: 'UserSearchItem' }
      & AllContentItemNode_UserSearchItem_Fragment
    ) | (
      { __typename?: 'VideoSearchItem' }
      & AllContentItemNode_VideoSearchItem_Fragment
    ) | (
      { __typename?: 'WebinarSearchItem' }
      & AllContentItemNode_WebinarSearchItem_Fragment
    )>> }
  ) }
);

export type GetFeaturedContentHeaderQueryVariables = Exact<{
  input?: Maybe<FeaturedContentInput>;
}>;


export type GetFeaturedContentHeaderQuery = (
  { __typename?: 'Query' }
  & { featuredContent: (
    { __typename?: 'FeaturedContentPayload' }
    & { meta: (
      { __typename?: 'FeaturedContentMeta' }
      & MetaNode_FeaturedContentMeta_Fragment
    ), data?: Maybe<Array<(
      { __typename?: 'ArticleSearchItem' }
      & Pick<ArticleSearchItem, 'imageUrl' | 'title' | 'url'>
    ) | (
      { __typename?: 'BlogSearchItem' }
      & Pick<BlogSearchItem, 'imageUrl' | 'title' | 'url'>
    ) | (
      { __typename?: 'ConferenceSearchItem' }
      & Pick<ConferenceSearchItem, 'imageUrl' | 'title' | 'url'>
    ) | (
      { __typename?: 'CourseSearchItem' }
      & Pick<CourseSearchItem, 'imageUrl' | 'title' | 'url'>
    ) | (
      { __typename?: 'MeetingSessionSearchItem' }
      & Pick<MeetingSessionSearchItem, 'imageUrl' | 'title' | 'url'>
    ) | (
      { __typename?: 'PageSearchItem' }
      & Pick<PageSearchItem, 'imageUrl' | 'title' | 'url'>
    ) | (
      { __typename?: 'PodcastSearchItem' }
      & Pick<PodcastSearchItem, 'imageUrl' | 'title' | 'url'>
    ) | (
      { __typename?: 'PublicationSearchItem' }
      & Pick<PublicationSearchItem, 'imageUrl' | 'title' | 'url'>
    ) | (
      { __typename?: 'UserSearchItem' }
      & Pick<UserSearchItem, 'imageUrl' | 'title' | 'url'>
    ) | (
      { __typename?: 'VideoSearchItem' }
      & Pick<VideoSearchItem, 'imageUrl' | 'title' | 'url'>
    ) | (
      { __typename?: 'WebinarSearchItem' }
      & Pick<WebinarSearchItem, 'imageUrl' | 'title' | 'url'>
    )>> }
  ) }
);

export type LpSeoFragment = (
  { __typename?: 'LandingPages' }
  & { seo_fields?: Maybe<(
    { __typename?: 'Seo' }
    & Pick<Seo, 'meta_description' | 'meta_title' | 'no_follow' | 'no_index'>
    & { meta_imageConnection?: Maybe<(
      { __typename?: 'SysAssetConnection' }
      & { edges?: Maybe<Array<Maybe<(
        { __typename?: 'SysAssetEdge' }
        & { node?: Maybe<(
          { __typename?: 'SysAsset' }
          & Pick<SysAsset, 'title' | 'url' | 'content_type' | 'filename' | 'file_size' | 'description'>
        )> }
      )>>> }
    )> }
  )> }
);

export type BlockContentHeaderFragment = (
  { __typename: 'LandingPagesPageSectionSectionBlockBlockContentHeader' }
  & { header?: Maybe<(
    { __typename?: 'LandingPagesPageSectionSectionBlockBlockContentHeaderBlock' }
    & Pick<LandingPagesPageSectionSectionBlockBlockContentHeaderBlock, 'title' | 'subtitle'>
    & { imageConnection?: Maybe<(
      { __typename?: 'SysAssetConnection' }
      & { edges?: Maybe<Array<Maybe<(
        { __typename?: 'SysAssetEdge' }
        & { node?: Maybe<(
          { __typename?: 'SysAsset' }
          & Pick<SysAsset, 'title' | 'url' | 'content_type' | 'filename' | 'file_size' | 'description'>
        )> }
      )>>> }
    )>, button?: Maybe<(
      { __typename?: 'Link' }
      & Pick<Link, 'title' | 'href'>
    )> }
  )> }
);

export type BlockContentStepsFragment = (
  { __typename: 'LandingPagesPageSectionSectionBlockBlockContentStepsModule' }
  & { steps_module?: Maybe<(
    { __typename?: 'LandingPagesPageSectionSectionBlockBlockContentStepsModuleBlock' }
    & { button?: Maybe<(
      { __typename?: 'Link' }
      & Pick<Link, 'title' | 'href'>
    )>, step?: Maybe<Array<Maybe<(
      { __typename?: 'LandingPagesPageSectionSectionBlockBlockContentStepsModuleBlockStep' }
      & Pick<LandingPagesPageSectionSectionBlockBlockContentStepsModuleBlockStep, 'text'>
    )>>> }
  )> }
);

export type BlockContentFeaturedFragment = (
  { __typename: 'LandingPagesPageSectionSectionBlockBlockContentFeaturedModule' }
  & { featured_module?: Maybe<(
    { __typename?: 'LandingPagesPageSectionSectionBlockBlockContentFeaturedModuleBlock' }
    & Pick<LandingPagesPageSectionSectionBlockBlockContentFeaturedModuleBlock, 'subtitle' | 'title' | 'theme'>
    & { content?: Maybe<Array<Maybe<(
      { __typename: 'LandingPagesPageSectionSectionBlockBlockContentFeaturedModuleBlockContentRelationship' }
      & { relationship?: Maybe<(
        { __typename?: 'LandingPagesPageSectionSectionBlockBlockContentFeaturedModuleBlockContentRelationshipBlock' }
        & Pick<LandingPagesPageSectionSectionBlockBlockContentFeaturedModuleBlockContentRelationshipBlock, 'title' | 'subtitle' | 'featured_items'>
      )> }
    ) | (
      { __typename: 'LandingPagesPageSectionSectionBlockBlockContentFeaturedModuleBlockContentQuery' }
      & { query?: Maybe<(
        { __typename?: 'LandingPagesPageSectionSectionBlockBlockContentFeaturedModuleBlockContentQueryBlock' }
        & Pick<LandingPagesPageSectionSectionBlockBlockContentFeaturedModuleBlockContentQueryBlock, 'title' | 'subtitle' | 'taxonomy' | 'topic' | 'industry' | 'role' | 'keyword' | 'order_by' | 'order'>
      )> }
    )>>> }
  )> }
);

export type BlockContentImageCtaFragment = (
  { __typename: 'LandingPagesPageSectionSectionBlockBlockContentImageCtaModule' }
  & { image_cta_module?: Maybe<(
    { __typename?: 'LandingPagesPageSectionSectionBlockBlockContentImageCtaModuleBlock' }
    & Pick<LandingPagesPageSectionSectionBlockBlockContentImageCtaModuleBlock, 'title' | 'image_position' | 'eyebrow'>
    & { button?: Maybe<(
      { __typename?: 'Link' }
      & Pick<Link, 'title' | 'href'>
    )>, imageConnection?: Maybe<(
      { __typename?: 'SysAssetConnection' }
      & Pick<SysAssetConnection, 'totalCount'>
      & { edges?: Maybe<Array<Maybe<(
        { __typename?: 'SysAssetEdge' }
        & { node?: Maybe<(
          { __typename?: 'SysAsset' }
          & Pick<SysAsset, 'title' | 'url' | 'content_type' | 'filename' | 'file_size' | 'description'>
        )> }
      )>>> }
    )> }
  )> }
);

export type BlockContentColumnFragment = (
  { __typename: 'LandingPagesPageSectionSectionBlockBlockContentColumnModule' }
  & { column_module?: Maybe<(
    { __typename?: 'LandingPagesPageSectionSectionBlockBlockContentColumnModuleBlock' }
    & { column_item?: Maybe<Array<Maybe<(
      { __typename?: 'LandingPagesPageSectionSectionBlockBlockContentColumnModuleBlockColumnItem' }
      & Pick<LandingPagesPageSectionSectionBlockBlockContentColumnModuleBlockColumnItem, 'title' | 'subtitle'>
      & { button?: Maybe<(
        { __typename?: 'Link' }
        & Pick<Link, 'title' | 'href'>
      )> }
    )>>> }
  )> }
);

export type BlockContentTwoColumnHeaderFragment = (
  { __typename: 'LandingPagesPageSectionSectionBlockBlockContentTwoColumnHeaderModule' }
  & { two_column_header_module?: Maybe<(
    { __typename?: 'LandingPagesPageSectionSectionBlockBlockContentTwoColumnHeaderModuleBlock' }
    & Pick<LandingPagesPageSectionSectionBlockBlockContentTwoColumnHeaderModuleBlock, 'title'>
    & { column_item?: Maybe<Array<Maybe<(
      { __typename?: 'LandingPagesPageSectionSectionBlockBlockContentTwoColumnHeaderModuleBlockColumnItem' }
      & Pick<LandingPagesPageSectionSectionBlockBlockContentTwoColumnHeaderModuleBlockColumnItem, 'title' | 'subtitle'>
    )>>>, imageConnection?: Maybe<(
      { __typename?: 'SysAssetConnection' }
      & { edges?: Maybe<Array<Maybe<(
        { __typename?: 'SysAssetEdge' }
        & { node?: Maybe<(
          { __typename?: 'SysAsset' }
          & Pick<SysAsset, 'title' | 'url' | 'content_type' | 'filename' | 'file_size' | 'description'>
        )> }
      )>>> }
    )> }
  )> }
);

export type BlockContentImageAndTextFragment = (
  { __typename: 'LandingPagesPageSectionSectionBlockBlockContentImageAndTextModule' }
  & { image_and_text_module?: Maybe<(
    { __typename?: 'LandingPagesPageSectionSectionBlockBlockContentImageAndTextModuleBlock' }
    & { image_and_text_item?: Maybe<Array<Maybe<(
      { __typename?: 'LandingPagesPageSectionSectionBlockBlockContentImageAndTextModuleBlockImageAndTextItem' }
      & Pick<LandingPagesPageSectionSectionBlockBlockContentImageAndTextModuleBlockImageAndTextItem, 'text'>
      & { button?: Maybe<(
        { __typename?: 'Link' }
        & Pick<Link, 'title' | 'href'>
      )>, imageConnection?: Maybe<(
        { __typename?: 'SysAssetConnection' }
        & Pick<SysAssetConnection, 'totalCount'>
        & { edges?: Maybe<Array<Maybe<(
          { __typename?: 'SysAssetEdge' }
          & { node?: Maybe<(
            { __typename?: 'SysAsset' }
            & Pick<SysAsset, 'title' | 'url' | 'content_type' | 'filename' | 'file_size' | 'description'>
          )> }
        )>>> }
      )> }
    )>>> }
  )> }
);

export type BlockContentSingleTextFragment = (
  { __typename: 'LandingPagesPageSectionSectionBlockBlockContentSingleTextModule' }
  & { single_text_module?: Maybe<(
    { __typename?: 'LandingPagesPageSectionSectionBlockBlockContentSingleTextModuleBlock' }
    & Pick<LandingPagesPageSectionSectionBlockBlockContentSingleTextModuleBlock, 'text'>
  )> }
);

export type BlockContentFormFragment = (
  { __typename: 'LandingPagesPageSectionSectionBlockBlockContentFormModule' }
  & { form_module?: Maybe<(
    { __typename?: 'LandingPagesPageSectionSectionBlockBlockContentFormModuleBlock' }
    & Pick<LandingPagesPageSectionSectionBlockBlockContentFormModuleBlock, 'form_title'>
  )> }
);

export type GetLandingPageByIdQueryVariables = Exact<{
  uid: Scalars['String'];
}>;


export type GetLandingPageByIdQuery = (
  { __typename?: 'Query' }
  & { landing_pages?: Maybe<(
    { __typename?: 'LandingPages' }
    & Pick<LandingPages, 'title' | 'url' | 'hide_site_header'>
    & { page_section?: Maybe<Array<Maybe<(
      { __typename: 'LandingPagesPageSectionSectionBlock' }
      & { section_block?: Maybe<(
        { __typename?: 'LandingPagesPageSectionSectionBlockBlock' }
        & Pick<LandingPagesPageSectionSectionBlockBlock, 'section_id' | 'section_theme' | 'section_title'>
        & { content?: Maybe<Array<Maybe<(
          { __typename?: 'LandingPagesPageSectionSectionBlockBlockContentHeader' }
          & BlockContentHeaderFragment
        ) | (
          { __typename?: 'LandingPagesPageSectionSectionBlockBlockContentStepsModule' }
          & BlockContentStepsFragment
        ) | (
          { __typename?: 'LandingPagesPageSectionSectionBlockBlockContentFeaturedModule' }
          & BlockContentFeaturedFragment
        ) | (
          { __typename?: 'LandingPagesPageSectionSectionBlockBlockContentImageCtaModule' }
          & BlockContentImageCtaFragment
        ) | (
          { __typename?: 'LandingPagesPageSectionSectionBlockBlockContentColumnModule' }
          & BlockContentColumnFragment
        ) | (
          { __typename?: 'LandingPagesPageSectionSectionBlockBlockContentTwoColumnHeaderModule' }
          & BlockContentTwoColumnHeaderFragment
        ) | (
          { __typename?: 'LandingPagesPageSectionSectionBlockBlockContentImageAndTextModule' }
          & BlockContentImageAndTextFragment
        ) | (
          { __typename?: 'LandingPagesPageSectionSectionBlockBlockContentSingleTextModule' }
          & BlockContentSingleTextFragment
        ) | (
          { __typename?: 'LandingPagesPageSectionSectionBlockBlockContentFormModule' }
          & BlockContentFormFragment
        )>>> }
      )> }
    )>>> }
    & LpSeoFragment
  )> }
);

export type GetLandingPagesByUrlQueryVariables = Exact<{
  url: Scalars['String'];
}>;


export type GetLandingPagesByUrlQuery = (
  { __typename?: 'Query' }
  & { all_landing_pages?: Maybe<(
    { __typename?: 'AllLandingPages' }
    & Pick<AllLandingPages, 'total'>
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'LandingPages' }
      & Pick<LandingPages, 'title' | 'url' | 'hide_site_header'>
      & { page_section?: Maybe<Array<Maybe<(
        { __typename: 'LandingPagesPageSectionSectionBlock' }
        & { section_block?: Maybe<(
          { __typename?: 'LandingPagesPageSectionSectionBlockBlock' }
          & Pick<LandingPagesPageSectionSectionBlockBlock, 'section_id' | 'section_theme' | 'section_title'>
          & { content?: Maybe<Array<Maybe<(
            { __typename?: 'LandingPagesPageSectionSectionBlockBlockContentHeader' }
            & BlockContentHeaderFragment
          ) | (
            { __typename?: 'LandingPagesPageSectionSectionBlockBlockContentStepsModule' }
            & BlockContentStepsFragment
          ) | (
            { __typename?: 'LandingPagesPageSectionSectionBlockBlockContentFeaturedModule' }
            & BlockContentFeaturedFragment
          ) | (
            { __typename?: 'LandingPagesPageSectionSectionBlockBlockContentImageCtaModule' }
            & BlockContentImageCtaFragment
          ) | (
            { __typename?: 'LandingPagesPageSectionSectionBlockBlockContentColumnModule' }
            & BlockContentColumnFragment
          ) | (
            { __typename?: 'LandingPagesPageSectionSectionBlockBlockContentTwoColumnHeaderModule' }
            & BlockContentTwoColumnHeaderFragment
          ) | (
            { __typename?: 'LandingPagesPageSectionSectionBlockBlockContentImageAndTextModule' }
            & BlockContentImageAndTextFragment
          ) | (
            { __typename?: 'LandingPagesPageSectionSectionBlockBlockContentSingleTextModule' }
            & BlockContentSingleTextFragment
          ) | (
            { __typename?: 'LandingPagesPageSectionSectionBlockBlockContentFormModule' }
            & BlockContentFormFragment
          )>>> }
        )> }
      )>>> }
      & LpSeoFragment
    )>>> }
  )> }
);

export type LearningCourseQueryVariables = Exact<{
  input?: Maybe<LearningActivityInput>;
}>;


export type LearningCourseQuery = (
  { __typename?: 'Query' }
  & { learningCourse: (
    { __typename?: 'LearningCoursePayload' }
    & { data: Array<(
      { __typename?: 'UserLearningActivityItem' }
      & Pick<UserLearningActivityItem, 'id' | 'name' | 'completion' | 'startDate' | 'endDate' | 'calendarUrl' | 'courseStartDate' | 'courseEndDate' | 'score' | 'timeSpent' | 'viewUrl' | 'launchUrl' | 'code' | 'completionPercent' | 'section'>
      & { credits?: Maybe<Array<(
        { __typename?: 'CourseCredit' }
        & Pick<CourseCredit, 'type' | 'value' | 'creditYear'>
      )>>, certificates?: Maybe<Array<(
        { __typename?: 'CourseCertificate' }
        & Pick<CourseCertificate, 'name' | 'url' | 'issueDate' | 'expiryDate'>
      )>>, badges?: Maybe<Array<(
        { __typename?: 'CourseBadge' }
        & Pick<CourseBadge, 'image_url'>
      )>> }
    )>, meta?: Maybe<(
      { __typename?: 'LearningActivityMeta' }
      & Pick<LearningActivityMeta, 'count' | 'page' | 'pageSize' | 'totalPages'>
    )> }
  ) }
);

export type CourseCertificatesQueryVariables = Exact<{
  input?: Maybe<LearningActivityInput>;
}>;


export type CourseCertificatesQuery = (
  { __typename?: 'Query' }
  & { courseCertificates: (
    { __typename?: 'LearningCertificatePayload' }
    & { data: Array<(
      { __typename?: 'LearningCertificate' }
      & Pick<LearningCertificate, 'title'>
    )> }
  ) }
);

export type WebinarsQueryVariables = Exact<{
  input?: Maybe<LearningActivityInput>;
}>;


export type WebinarsQuery = (
  { __typename?: 'Query' }
  & { webinars: (
    { __typename?: 'WebinarsPayload' }
    & { data: Array<(
      { __typename?: 'WebinarItem' }
      & Pick<WebinarItem, 'webinarId' | 'date' | 'title' | 'role' | 'url'>
    )>, meta?: Maybe<(
      { __typename?: 'LearningActivityMeta' }
      & Pick<LearningActivityMeta, 'count' | 'page' | 'pageSize' | 'totalPages'>
    )> }
  ) }
);

export type ConferencesQueryVariables = Exact<{
  input?: Maybe<LearningActivityInput>;
}>;


export type ConferencesQuery = (
  { __typename?: 'Query' }
  & { conferences: (
    { __typename?: 'ConferencePayload' }
    & { data: Array<(
      { __typename?: 'Conference' }
      & Pick<Conference, 'eventName' | 'startDate' | 'endDate' | 'location' | 'productUrl'>
    )>, meta?: Maybe<(
      { __typename?: 'LearningActivityMeta' }
      & Pick<LearningActivityMeta, 'count' | 'page' | 'pageSize' | 'totalPages'>
    )> }
  ) }
);

export type LearningSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type LearningSummaryQuery = (
  { __typename?: 'Query' }
  & { learningSummary: (
    { __typename?: 'LearningSummaryPayload' }
    & { data: Array<(
      { __typename?: 'LearningSummaryItem' }
      & Pick<LearningSummaryItem, 'id' | 'url' | 'title' | 'type' | 'date'>
    )> }
  ) }
);

export type MenuChildNodeFragment = (
  { __typename?: 'MenuItem' }
  & Pick<MenuItem, 'type' | 'title' | 'description' | 'url' | 'target' | 'buttonText' | 'buttonUrl' | 'imageUrl' | 'flair'>
);

export type BannerNodeFragment = (
  { __typename?: 'Banner' }
  & Pick<Banner, 'name' | 'isCountdown' | 'countdownScript' | 'desktopImageUrl' | 'desktopLink' | 'mobileImageUrl' | 'mobileLink' | 'backgroundHexColor'>
);

export type GetMenuQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMenuQuery = (
  { __typename?: 'Query' }
  & { menu: (
    { __typename?: 'MenuPayload' }
    & { bannerData?: Maybe<(
      { __typename?: 'Banner' }
      & BannerNodeFragment
    )>, menuData: Array<(
      { __typename?: 'MenuItem' }
      & Pick<MenuItem, 'type' | 'title' | 'description' | 'url' | 'target'>
      & { children?: Maybe<Array<(
        { __typename?: 'MenuItem' }
        & { children?: Maybe<Array<(
          { __typename?: 'MenuItem' }
          & { children?: Maybe<Array<(
            { __typename?: 'MenuItem' }
            & MenuChildNodeFragment
          )>> }
          & MenuChildNodeFragment
        )>> }
        & MenuChildNodeFragment
      )>> }
    )> }
  ) }
);

export type GetOrdersQueryVariables = Exact<{
  input?: Maybe<OrdersInput>;
}>;


export type GetOrdersQuery = (
  { __typename?: 'Query' }
  & { orders: (
    { __typename?: 'OrdersPayload' }
    & Pick<OrdersPayload, 'years'>
    & { meta?: Maybe<(
      { __typename?: 'OrdersMeta' }
      & MetaNode_OrdersMeta_Fragment
    )>, data: Array<(
      { __typename?: 'Order' }
      & OrderNodeFragment
    )> }
  ) }
);

export type OrderInvoiceUrlQueryVariables = Exact<{
  input?: Maybe<OrdersInvoiceUrlInput>;
}>;


export type OrderInvoiceUrlQuery = (
  { __typename?: 'Query' }
  & { orderInvoiceUrl: (
    { __typename?: 'OrderInvoiceUrlPayload' }
    & Pick<OrderInvoiceUrlPayload, 'url'>
  ) }
);

export type OrderInfoQueryVariables = Exact<{
  input?: Maybe<OrderNumberInput>;
}>;


export type OrderInfoQuery = (
  { __typename?: 'Query' }
  & { orderInfo: (
    { __typename?: 'OrderInfoPayload' }
    & Pick<OrderInfoPayload, 'billAddressId'>
  ) }
);

export type GetRecommendedContentQueryVariables = Exact<{
  input?: Maybe<RecommendedContentInput>;
}>;


export type GetRecommendedContentQuery = (
  { __typename?: 'Query' }
  & { recommendedContent: (
    { __typename?: 'RecommendedContentPayload' }
    & { meta: (
      { __typename?: 'RecommendedContentMeta' }
      & MetaNode_RecommendedContentMeta_Fragment
    ), data: Array<(
      { __typename?: 'RecommendedContentItem' }
      & Pick<RecommendedContentItem, 'topic' | 'count'>
      & { data?: Maybe<Array<(
        { __typename?: 'ArticleSearchItem' }
        & AllContentItemNode_ArticleSearchItem_Fragment
      ) | (
        { __typename?: 'BlogSearchItem' }
        & AllContentItemNode_BlogSearchItem_Fragment
      ) | (
        { __typename?: 'ConferenceSearchItem' }
        & AllContentItemNode_ConferenceSearchItem_Fragment
      ) | (
        { __typename?: 'CourseSearchItem' }
        & AllContentItemNode_CourseSearchItem_Fragment
      ) | (
        { __typename?: 'MeetingSessionSearchItem' }
        & AllContentItemNode_MeetingSessionSearchItem_Fragment
      ) | (
        { __typename?: 'PageSearchItem' }
        & AllContentItemNode_PageSearchItem_Fragment
      ) | (
        { __typename?: 'PodcastSearchItem' }
        & AllContentItemNode_PodcastSearchItem_Fragment
      ) | (
        { __typename?: 'PublicationSearchItem' }
        & AllContentItemNode_PublicationSearchItem_Fragment
      ) | (
        { __typename?: 'UserSearchItem' }
        & AllContentItemNode_UserSearchItem_Fragment
      ) | (
        { __typename?: 'VideoSearchItem' }
        & AllContentItemNode_VideoSearchItem_Fragment
      ) | (
        { __typename?: 'WebinarSearchItem' }
        & AllContentItemNode_WebinarSearchItem_Fragment
      )>> }
    )> }
  ) }
);

export type GetUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & UserNodeFragment
  )> }
);

export type GetEmailValidatorQueryVariables = Exact<{
  input: EmailValidatorInput;
}>;


export type GetEmailValidatorQuery = (
  { __typename?: 'Query' }
  & { emailValidator: (
    { __typename?: 'EmailValidatorPayload' }
    & Pick<EmailValidatorPayload, 'isValid' | 'message'>
  ) }
);

export type GetEmailCommunicationPreferencesQueryVariables = Exact<{
  input: PreferencesInput;
}>;


export type GetEmailCommunicationPreferencesQuery = (
  { __typename?: 'Query' }
  & { emailCommunicationPreferences: (
    { __typename?: 'CommunicationPreferences' }
    & Pick<CommunicationPreferences, 'automatedNewsletters' | 'isPostalMailOptedOut' | 'isPhoneOptedOut' | 'bimonthlyDigest' | 'receivePromotionalCommunication'>
    & { newsletters: Array<(
      { __typename?: 'NewsletterGroup' }
      & Pick<NewsletterGroup, 'newsletterGroupLabel'>
      & { newsletterGroup: Array<(
        { __typename?: 'NewsletterItem' }
        & Pick<NewsletterItem, 'key' | 'value' | 'label' | 'frequency'>
      )> }
    )> }
  ) }
);

export type GetUserQueryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserQueryQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'customerClassCode'>
    & { profile: (
      { __typename?: 'Profile' }
      & Pick<Profile, 'firstName' | 'lastName' | 'profileImageUrl'>
    ), membership: (
      { __typename?: 'Membership' }
      & Pick<Membership, 'isMember'>
      & { overview?: Maybe<(
        { __typename?: 'MembershipOverview' }
        & Pick<MembershipOverview, 'label'>
      )> }
    ), cart?: Maybe<(
      { __typename?: 'Cart' }
      & Pick<Cart, 'itemTotal'>
    )> }
  )> }
);

export type GetUserContactInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserContactInfoQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & { contactInfo: (
      { __typename?: 'ContactInfo' }
      & Pick<ContactInfo, 'primaryEmailAddress'>
    ) }
  )> }
);

export type GetUserIdQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserIdQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
  )> }
);

export type GetUserMembershipStatusQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserMembershipStatusQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & { membership: (
      { __typename?: 'Membership' }
      & Pick<Membership, 'isMember'>
    ) }
  )> }
);

export type GetUserDinamicValuesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserDinamicValuesQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'isContributor'>
    & { membership: (
      { __typename?: 'Membership' }
      & Pick<Membership, 'isMember' | 'isEnterpriseMember'>
      & { currentMembership?: Maybe<(
        { __typename?: 'CurrentMembership' }
        & Pick<CurrentMembership, 'id' | 'autoRenew' | 'cycleEndDate' | 'initialBeginDate' | 'memberTypeLabel'>
      )>, futureMembership?: Maybe<Array<(
        { __typename?: 'FutureMembership' }
        & Pick<FutureMembership, 'id' | 'autoRenew' | 'cycleEndDate' | 'memberTypeLabel'>
      )>> }
    ) }
  )> }
);

export type GetUserTopicsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserTopicsQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & { topics: Array<(
      { __typename?: 'Topic' }
      & Pick<Topic, 'id' | 'label' | 'key' | 'url'>
      & { userStatus: (
        { __typename?: 'UserTopicStatus' }
        & Pick<UserTopicStatus, 'id' | 'followed' | 'recommended'>
      ) }
    )> }
  )> }
);

export type GetUserCapabilityModelQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserCapabilityModelQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & { capabilityModel: (
      { __typename?: 'CapabilityModel' }
      & Pick<CapabilityModel, 'isSelfAssessmentStarted' | 'isSelfAssessmentComplete' | 'isLearningPathSelected' | 'isLearningPlanCreated' | 'isLearningPlanSelected' | 'isPartialPlan' | 'isLearningPlanComplete' | 'planActivitiesCount' | 'planActivitiesCompletedCount' | 'isPersonalCapabilityComplete' | 'isProfessionalCapabilityComplete' | 'isOrganizationalCapabilityComplete' | 'learningPathLabel' | 'totalScore'>
      & { domains: Array<(
        { __typename?: 'Domain' }
        & Pick<Domain, 'domainTitle' | 'totalProgress'>
        & { capabilities?: Maybe<Array<(
          { __typename?: 'Capability' }
          & Pick<Capability, 'capTitle' | 'capTotalScore'>
          & { statements?: Maybe<Array<(
            { __typename?: 'Statements' }
            & Pick<Statements, 'desc' | 'descScore'>
          )>> }
        )>> }
      )> }
    ) }
  )> }
);

export type GetUserProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserProfileQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & { job: (
      { __typename?: 'Job' }
      & Pick<Job, 'userIndustry' | 'jobTitle' | 'jobFunctionCode' | 'jobIndustry' | 'companyName' | 'companySize' | 'trainingBudget' | 'directReportCount'>
    ), profile: (
      { __typename?: 'Profile' }
      & Pick<Profile, 'firstName' | 'lastName' | 'profileImageUrl'>
    ), certification: Array<(
      { __typename?: 'Certification' }
      & Pick<Certification, 'expiryDate' | 'earnedDate' | 'masters' | 'recertifyByDate' | 'certificationCode' | 'certificationStatus'>
    )>, badge: Array<(
      { __typename?: 'Badge' }
      & Pick<Badge, 'id' | 'badgeId' | 'imageUrl' | 'thumbnailUrl' | 'createdAt' | 'issuedAt' | 'title' | 'description' | 'url' | 'pending' | 'acceptUrl'>
    )> }
  )> }
);

export type GetUserContentDataQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserContentDataQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'isContributor'>
    & { visibility: (
      { __typename?: 'Visibility' }
      & Pick<Visibility, 'publicProfile' | 'memberDirectoryListing'>
    ) }
  )> }
);

export type GetUserAutoRenewLowValueTokenDetailsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserAutoRenewLowValueTokenDetailsQuery = (
  { __typename?: 'Query' }
  & { autoRenewLowValueTokenDetails: (
    { __typename?: 'AutoRenewLowValueTokenDetails' }
    & Pick<AutoRenewLowValueTokenDetails, 'merchantId' | 'payPageID'>
  ) }
);

export type GetUserBenefitsQueryVariables = Exact<{
  input?: Maybe<UserBenefitsInput>;
}>;


export type GetUserBenefitsQuery = (
  { __typename?: 'Query' }
  & { userBenefits: (
    { __typename?: 'UserBenefitsPayload' }
    & { meta: (
      { __typename?: 'UserBenefitsMeta' }
      & { orderBy?: Maybe<(
        { __typename?: 'UserBenefitsOrderByOutput' }
        & Pick<UserBenefitsOrderByOutput, 'value' | 'order'>
      )> }
      & MetaNode_UserBenefitsMeta_Fragment
    ), data: Array<(
      { __typename?: 'UserBenefitGroup' }
      & Pick<UserBenefitGroup, 'key' | 'url' | 'count' | 'remainingCount'>
      & { data: Array<(
        { __typename?: 'ArticleSearchItem' }
        & IAbstractSearchItem_ArticleSearchItem_Fragment
        & ArticleSearchItemNodeFragment
      ) | { __typename?: 'BlogSearchItem' } | { __typename?: 'ConferenceSearchItem' } | { __typename?: 'CourseSearchItem' } | { __typename?: 'MeetingSessionSearchItem' } | { __typename?: 'PageSearchItem' } | { __typename?: 'PodcastSearchItem' } | { __typename?: 'PublicationSearchItem' } | { __typename?: 'UserSearchItem' } | { __typename?: 'VideoSearchItem' } | { __typename?: 'WebinarSearchItem' }> }
    )> }
  ) }
);

export type GetUserContentQueryVariables = Exact<{
  input?: Maybe<UserContentInput>;
}>;


export type GetUserContentQuery = (
  { __typename?: 'Query' }
  & { userContent: (
    { __typename?: 'UserContentPayload' }
    & { meta: (
      { __typename?: 'UserContentMeta' }
      & { orderBy?: Maybe<(
        { __typename?: 'UserContentOrderByOutput' }
        & Pick<UserContentOrderByOutput, 'value' | 'order'>
      )>, allFilters?: Maybe<Array<(
        { __typename?: 'Filters' }
        & FiltersNodeFragment
      )>> }
      & MetaNode_UserContentMeta_Fragment
    ), data: Array<(
      { __typename?: 'ArticleSearchItem' }
      & IAbstractSearchItem_ArticleSearchItem_Fragment
      & IAbstractSearchItem_ArticleSearchItem_Fragment
      & ArticleSearchItemNodeFragment
    ) | (
      { __typename?: 'BlogSearchItem' }
      & IAbstractSearchItem_BlogSearchItem_Fragment
      & IAbstractSearchItem_BlogSearchItem_Fragment
      & BlogSearchItemNodeFragment
    ) | (
      { __typename?: 'ConferenceSearchItem' }
      & IAbstractSearchItem_ConferenceSearchItem_Fragment
      & IAbstractSearchItem_ConferenceSearchItem_Fragment
      & ConferenceSearchItemNodeFragment
    ) | (
      { __typename?: 'CourseSearchItem' }
      & IAbstractSearchItem_CourseSearchItem_Fragment
      & IAbstractSearchItem_CourseSearchItem_Fragment
      & CourseSearchItemNodeFragment
    ) | (
      { __typename?: 'MeetingSessionSearchItem' }
      & IAbstractSearchItem_MeetingSessionSearchItem_Fragment
    ) | (
      { __typename?: 'PageSearchItem' }
      & IAbstractSearchItem_PageSearchItem_Fragment
      & IAbstractSearchItem_PageSearchItem_Fragment
      & PageSearchItemNodeFragment
    ) | (
      { __typename?: 'PodcastSearchItem' }
      & IAbstractSearchItem_PodcastSearchItem_Fragment
    ) | (
      { __typename?: 'PublicationSearchItem' }
      & IAbstractSearchItem_PublicationSearchItem_Fragment
      & IAbstractSearchItem_PublicationSearchItem_Fragment
      & PublicationSearchItemNodeFragment
    ) | (
      { __typename?: 'UserSearchItem' }
      & IAbstractSearchItem_UserSearchItem_Fragment
      & IAbstractSearchItem_UserSearchItem_Fragment
      & UserSearchItemNodeFragment
    ) | (
      { __typename?: 'VideoSearchItem' }
      & IAbstractSearchItem_VideoSearchItem_Fragment
      & IAbstractSearchItem_VideoSearchItem_Fragment
      & VideoSearchItemNodeFragment
    ) | (
      { __typename?: 'WebinarSearchItem' }
      & IAbstractSearchItem_WebinarSearchItem_Fragment
      & IAbstractSearchItem_WebinarSearchItem_Fragment
      & WebinarSearchItemNodeFragment
    )> }
  ) }
);

export type GetAllBookmarksQueryVariables = Exact<{
  input?: Maybe<UserContentInput>;
}>;


export type GetAllBookmarksQuery = (
  { __typename?: 'Query' }
  & { userContent: (
    { __typename?: 'UserContentPayload' }
    & { data: Array<(
      { __typename?: 'ArticleSearchItem' }
      & Pick<ArticleSearchItem, 'id'>
    ) | (
      { __typename?: 'BlogSearchItem' }
      & Pick<BlogSearchItem, 'id'>
    ) | (
      { __typename?: 'ConferenceSearchItem' }
      & Pick<ConferenceSearchItem, 'id'>
    ) | (
      { __typename?: 'CourseSearchItem' }
      & Pick<CourseSearchItem, 'id'>
    ) | (
      { __typename?: 'MeetingSessionSearchItem' }
      & Pick<MeetingSessionSearchItem, 'id'>
    ) | (
      { __typename?: 'PageSearchItem' }
      & Pick<PageSearchItem, 'id'>
    ) | (
      { __typename?: 'PodcastSearchItem' }
      & Pick<PodcastSearchItem, 'id'>
    ) | (
      { __typename?: 'PublicationSearchItem' }
      & Pick<PublicationSearchItem, 'id'>
    ) | (
      { __typename?: 'UserSearchItem' }
      & Pick<UserSearchItem, 'id'>
    ) | (
      { __typename?: 'VideoSearchItem' }
      & Pick<VideoSearchItem, 'id'>
    ) | (
      { __typename?: 'WebinarSearchItem' }
      & Pick<WebinarSearchItem, 'id'>
    )> }
  ) }
);

export type GetNetworkSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetNetworkSummaryQuery = (
  { __typename?: 'Query' }
  & { publicUsersFollowing: (
    { __typename?: 'PublicUsersPayload' }
    & { meta?: Maybe<(
      { __typename?: 'PublicUsersMeta' }
      & MetaNode_PublicUsersMeta_Fragment
    )> }
  ), publicUsersFollowed: (
    { __typename?: 'PublicUsersPayload' }
    & { meta?: Maybe<(
      { __typename?: 'PublicUsersMeta' }
      & MetaNode_PublicUsersMeta_Fragment
    )> }
  ), userGroups: (
    { __typename?: 'UserGroupsPayload' }
    & { meta: (
      { __typename?: 'UserGroupMeta' }
      & Pick<UserGroupMeta, 'count'>
    ) }
  ) }
);

export type GetPublicUsersQueryVariables = Exact<{
  input: PublicUsersInput;
}>;


export type GetPublicUsersQuery = (
  { __typename?: 'Query' }
  & { publicUsers: (
    { __typename?: 'PublicUsersPayload' }
    & { meta?: Maybe<(
      { __typename?: 'PublicUsersMeta' }
      & { orderBy?: Maybe<(
        { __typename?: 'PublicUsersOrderByOutput' }
        & Pick<PublicUsersOrderByOutput, 'value' | 'order'>
      )> }
      & MetaNode_PublicUsersMeta_Fragment
    )>, data: Array<(
      { __typename?: 'UserSearchItem' }
      & IAbstractSearchItem_UserSearchItem_Fragment
      & UserSearchItemNodeFragment
    )> }
  ) }
);

export type GetUserGroupsQueryVariables = Exact<{
  input?: Maybe<UserGroupInput>;
}>;


export type GetUserGroupsQuery = (
  { __typename?: 'Query' }
  & { userGroups: (
    { __typename?: 'UserGroupsPayload' }
    & { meta: (
      { __typename?: 'UserGroupMeta' }
      & MetaNode_UserGroupMeta_Fragment
    ), data: Array<(
      { __typename?: 'UserGroup' }
      & Pick<UserGroup, 'id' | 'title' | 'avatarUrl'>
    )> }
  ) }
);

export type GetUserNetworkActivityQueryVariables = Exact<{
  input?: Maybe<NetworkActivityInput>;
}>;


export type GetUserNetworkActivityQuery = (
  { __typename?: 'Query' }
  & { activity: (
    { __typename?: 'NetworkActivityPayload' }
    & { meta?: Maybe<(
      { __typename?: 'NetworkActivityMeta' }
      & MetaNode_NetworkActivityMeta_Fragment
    )>, data: Array<(
      { __typename?: 'NetworkActivityItem' }
      & Pick<NetworkActivityItem, 'id' | 'action' | 'date'>
      & { content?: Maybe<(
        { __typename?: 'ArticleSearchItem' }
        & AllContentItemNode_ArticleSearchItem_Fragment
      ) | (
        { __typename?: 'BlogSearchItem' }
        & AllContentItemNode_BlogSearchItem_Fragment
      ) | (
        { __typename?: 'ConferenceSearchItem' }
        & AllContentItemNode_ConferenceSearchItem_Fragment
      ) | (
        { __typename?: 'CourseSearchItem' }
        & AllContentItemNode_CourseSearchItem_Fragment
      ) | (
        { __typename?: 'MeetingSessionSearchItem' }
        & AllContentItemNode_MeetingSessionSearchItem_Fragment
      ) | (
        { __typename?: 'PageSearchItem' }
        & AllContentItemNode_PageSearchItem_Fragment
      ) | (
        { __typename?: 'PodcastSearchItem' }
        & AllContentItemNode_PodcastSearchItem_Fragment
      ) | (
        { __typename?: 'PublicationSearchItem' }
        & AllContentItemNode_PublicationSearchItem_Fragment
      ) | (
        { __typename?: 'UserSearchItem' }
        & AllContentItemNode_UserSearchItem_Fragment
      ) | (
        { __typename?: 'VideoSearchItem' }
        & AllContentItemNode_VideoSearchItem_Fragment
      ) | (
        { __typename?: 'WebinarSearchItem' }
        & AllContentItemNode_WebinarSearchItem_Fragment
      )>, groupMeta?: Maybe<(
        { __typename?: 'GroupMeta' }
        & Pick<GroupMeta, 'groupAction' | 'groupUrl' | 'groupName'>
      )>, user: (
        { __typename?: 'UserSearchItem' }
        & Pick<UserSearchItem, 'id' | 'url' | 'firstName' | 'lastName' | 'imageUrl'>
      ) }
    )> }
  ) }
);

export const TaxonomyLevelsFragmentDoc = gql`
    fragment taxonomyLevels on TaxonomySearchItem {
  lvl0
  lvl1
  lvl2
}
    `;
export const IAbstractSearchItemFragmentDoc = gql`
    fragment IAbstractSearchItem on IAbstractSearchItem {
  id
  title
  publishDate
  updateDate
  description
  bodyText
  imageUrl
  url
  viewCount
  contentTypes {
    ...taxonomyLevels
  }
  topics
  primaryTopic
  topicCodes
  industryCodes
  roleCodes
  contentTypesArray
  industries
  roles
  keywords
  authors {
    ... on UserSearchItem {
      firstName
      lastName
    }
  }
  featured
  memberBenefit
  isBookmarkable
  _snippetResult {
    description {
      value
    }
  }
}
    ${TaxonomyLevelsFragmentDoc}`;
export const ContentSearchItemNodeFragmentDoc = gql`
    fragment ContentSearchItemNode on IContentSearchItem {
  periodical
  issue
  column
  part
}
    `;
export const BlogSearchItemNodeFragmentDoc = gql`
    fragment BlogSearchItemNode on BlogSearchItem {
  ...ContentSearchItemNode
  isBookmarked
}
    ${ContentSearchItemNodeFragmentDoc}`;
export const ArticleSearchItemNodeFragmentDoc = gql`
    fragment ArticleSearchItemNode on ArticleSearchItem {
  id
  ...ContentSearchItemNode
  isBookmarked
}
    ${ContentSearchItemNodeFragmentDoc}`;
export const PageSearchItemNodeFragmentDoc = gql`
    fragment PageSearchItemNode on PageSearchItem {
  ...ContentSearchItemNode
  isBookmarked
}
    ${ContentSearchItemNodeFragmentDoc}`;
export const VideoSearchItemNodeFragmentDoc = gql`
    fragment VideoSearchItemNode on VideoSearchItem {
  ...ContentSearchItemNode
  publisher
  videoUrl
  videoType
  brightcoveName
  brightcoveId
  shortDescription
  longDescription
  videoPublishDate
  videoUpdateDate
  speaker
  brightcoveState
  duration
  isBookmarked
}
    ${ContentSearchItemNodeFragmentDoc}`;
export const CommerceSearchItemNodeFragmentDoc = gql`
    fragment CommerceSearchItemNode on ICommerceSearchItem {
  listPrice
  memberPrice
  availableAsBenefit
}
    `;
export const PublicationSearchItemNodeFragmentDoc = gql`
    fragment PublicationSearchItemNode on PublicationSearchItem {
  ...CommerceSearchItemNode
  purchasableItems {
    productId
    productCode
    listPrice
    memberPrice
    externalUrl
    onSale
    memberBenefit
    numberAvailable
  }
  isBookmarked
}
    ${CommerceSearchItemNodeFragmentDoc}`;
export const UserSearchItemNodeFragmentDoc = gql`
    fragment UserSearchItemNode on UserSearchItem {
  firstName
  lastName
  fullName
  bio
  customerNumber
  member
  staff
  jobTitle
  company
  countryCode
  isFollowingCurrentUser
  isFollowedByCurrentUser
}
    `;
export const MeetingSearchItemNodeFragmentDoc = gql`
    fragment MeetingSearchItemNode on IMeetingSearchItem {
  ...CommerceSearchItemNode
  purchasableItems {
    productId
    productCode
    listPrice
    memberPrice
    externalUrl
    onSale
    memberBenefit
    numberAvailable
    ... on CoursePurchasableSearchItem {
      sessionDescription
      location
      beginDate
      endDate
      deliveryMethods
      duration
      facilityName
      facilityAddress
      facilityDescription
    }
  }
  programCode
  subTitle
  eventRegions
  levels
  languages
}
    ${CommerceSearchItemNodeFragmentDoc}`;
export const CourseSearchItemNodeFragmentDoc = gql`
    fragment CourseSearchItemNode on CourseSearchItem {
  ...MeetingSearchItemNode
  isBookmarked
}
    ${MeetingSearchItemNodeFragmentDoc}`;
export const ConferenceSearchItemNodeFragmentDoc = gql`
    fragment ConferenceSearchItemNode on ConferenceSearchItem {
  ...MeetingSearchItemNode
  isBookmarked
}
    ${MeetingSearchItemNodeFragmentDoc}`;
export const SponsorItemNodeFragmentDoc = gql`
    fragment SponsorItemNode on WebinarSearchItem {
  sponsors {
    organization
    website
    url
    logoUrl
  }
}
    `;
export const PresenterItemNodeFragmentDoc = gql`
    fragment PresenterItemNode on WebinarSearchItem {
  presenters {
    fullName
    email
    organization
    positionTitle
    biography
    profileImageUrl
  }
}
    `;
export const WebinarSearchItemNodeFragmentDoc = gql`
    fragment WebinarSearchItemNode on WebinarSearchItem {
  start
  end
  duration
  ...SponsorItemNode
  ...PresenterItemNode
  isPrivate
  host
  registeredCount
  webinarStatus
}
    ${SponsorItemNodeFragmentDoc}
${PresenterItemNodeFragmentDoc}`;
export const PodcastSearchItemNodeFragmentDoc = gql`
    fragment PodcastSearchItemNode on PodcastSearchItem {
  subTitle
  duration
  season
  episode
}
    `;
export const AllContentItemNodeFragmentDoc = gql`
    fragment AllContentItemNode on IAbstractSearchItem {
  ... on IAbstractSearchItem {
    ...IAbstractSearchItem
  }
  ... on BlogSearchItem {
    ...IAbstractSearchItem
    ...BlogSearchItemNode
  }
  ... on ArticleSearchItem {
    ...IAbstractSearchItem
    ...ArticleSearchItemNode
  }
  ... on PageSearchItem {
    ...IAbstractSearchItem
    ...PageSearchItemNode
  }
  ... on VideoSearchItem {
    ...IAbstractSearchItem
    ...VideoSearchItemNode
  }
  ... on PublicationSearchItem {
    ...IAbstractSearchItem
    ...PublicationSearchItemNode
  }
  ... on UserSearchItem {
    ...IAbstractSearchItem
    ...UserSearchItemNode
  }
  ... on CourseSearchItem {
    ...IAbstractSearchItem
    ...CourseSearchItemNode
  }
  ... on ConferenceSearchItem {
    ...IAbstractSearchItem
    ...ConferenceSearchItemNode
  }
  ... on WebinarSearchItem {
    ...IAbstractSearchItem
    ...WebinarSearchItemNode
  }
  ... on PodcastSearchItem {
    ...IAbstractSearchItem
    ...PodcastSearchItemNode
  }
}
    ${IAbstractSearchItemFragmentDoc}
${BlogSearchItemNodeFragmentDoc}
${ArticleSearchItemNodeFragmentDoc}
${PageSearchItemNodeFragmentDoc}
${VideoSearchItemNodeFragmentDoc}
${PublicationSearchItemNodeFragmentDoc}
${UserSearchItemNodeFragmentDoc}
${CourseSearchItemNodeFragmentDoc}
${ConferenceSearchItemNodeFragmentDoc}
${WebinarSearchItemNodeFragmentDoc}
${PodcastSearchItemNodeFragmentDoc}`;
export const ConstantsNodeFragmentDoc = gql`
    fragment ConstantsNode on ConstantsPayload {
  topics {
    id
    label
    key
    url
    userStatus {
      followed
      recommended
    }
  }
  industryCodes {
    key
    value
  }
  jobRoleCodes {
    key
    value
  }
  companySizeCodes {
    key
    value
  }
  directReportCountCodes {
    key
    value
  }
  budgetSizeCodes {
    key
    value
  }
  allDemographicCodes {
    key
    value
  }
  programDegreeCodes {
    key
    value
  }
  programTypeCodes {
    key
    value
  }
  gdprCountryCodes
}
    `;
export const FiltersNodeFragmentDoc = gql`
    fragment FiltersNode on Filters {
  key
  type
  label
  allLabel
  values {
    value
    label
    isActive
  }
}
    `;
export const MetaNodeFragmentDoc = gql`
    fragment MetaNode on IMeta {
  count
  pageSize
  page
  totalPages
}
    `;
export const IContentSearchItemFragmentDoc = gql`
    fragment IContentSearchItem on IContentSearchItem {
  periodical
  issue
  column
  part
}
    `;
export const OrderLineItemNodeFragmentDoc = gql`
    fragment OrderLineItemNode on Order {
  lineItems {
    id
    price
    title
    imageUrl
    itemUrl
    downloadUrl
    orderLineNumber
    orderNumber
    type
    autoRenew
  }
}
    `;
export const OrderNodeFragmentDoc = gql`
    fragment OrderNode on Order {
  id
  date
  totalPrice
  shippedTo
  orderNumber
  receiptUrl
  ...OrderLineItemNode
}
    ${OrderLineItemNodeFragmentDoc}`;
export const ProfileFragmentDoc = gql`
    fragment Profile on User {
  profile {
    userName
    firstName
    lastName
    profileImageUrl
    bio
    gender
    website
    specialNeeds
  }
}
    `;
export const JobFragmentDoc = gql`
    fragment Job on User {
  job {
    userIndustry
    jobTitle
    jobFunctionCode
    jobIndustry
    companySize
    companyName
    trainingBudget
    directReportCount
  }
}
    `;
export const CertificationFragmentDoc = gql`
    fragment Certification on User {
  certification {
    expiryDate
    earnedDate
    masters
    recertifyByDate
    certificationStatus
    certificationCode
  }
}
    `;
export const BadgeFragmentDoc = gql`
    fragment Badge on User {
  badge {
    id
    badgeId
    imageUrl
    thumbnailUrl
    createdAt
    issuedAt
    title
    description
    url
    pending
    acceptUrl
  }
}
    `;
export const VisibilityFragmentDoc = gql`
    fragment Visibility on User {
  visibility {
    publicProfile
    memberDirectoryListing
  }
}
    `;
export const EducationFragmentDoc = gql`
    fragment Education on User {
  education {
    id
    degreeType
    programType
    schoolName
    startDate
    endDate
  }
}
    `;
export const ContactInfoFragmentDoc = gql`
    fragment ContactInfo on User {
  contactInfo {
    primaryPhoneNumber
    secondaryPhoneNumber
    primaryEmailAddress
    address {
      id
      addressType
      isPrimary
      address1
      address2
      address3
      city
      state
      countryCode
      postalCode
      formattedAddress
    }
    phones {
      id
      isPrimary
      doNotCallFlag
      formattedPhoneAddress
      phoneAreaCode
      countryCode
      phoneCountryCode
      phoneExtension
      phoneNumber
      commLocationCodeString
      commTypeCodeString
    }
  }
}
    `;
export const CommunicationPreferencesFragmentDoc = gql`
    fragment CommunicationPreferences on User {
  communicationPreferences {
    automatedNewsletters
    isPostalMailOptedOut
    isPhoneOptedOut
    receivePromotionalCommunication
    bimonthlyDigest
    newsletters {
      newsletterGroup {
        key
        label
        value
        frequency
      }
      newsletterGroupLabel
    }
  }
}
    `;
export const LearningActivityFragmentDoc = gql`
    fragment LearningActivity on User {
  learningActivity {
    id
    title
    publishDate
    updateDate
    description
    bodyText
    imageUrl
    url
    viewCount
    contentTypes {
      lvl0
      lvl1
      lvl2
    }
    topics
    industries
    roles
    keywords
  }
}
    `;
export const LinkNodeFragmentDoc = gql`
    fragment LinkNode on Link {
  url
  title
  linkType
}
    `;
export const MembershipFragmentDoc = gql`
    fragment Membership on User {
  membership {
    id
    isMember
    isEnterpriseMember
    overview {
      type
      label
      perks {
        label
        value
      }
    }
    currentMembership {
      id
      memberType
      memberNumber
      memberTypeLabel
      memberStatus
      orderNumber
      orderLineNumber
      autoRenew
      initialBeginDate
      cycleBeginDate
      cycleEndDate
    }
    futureMembership {
      id
      memberType
      memberTypeLabel
      memberNumber
      memberStatus
      autoRenew
      orderNumber
      orderLineNumber
      initialBeginDate
      cycleBeginDate
      cycleEndDate
      chapterName
      chapterURL
      recordTypeCode
    }
    chapterMembership {
      id
      chapterName
      memberTypeLabel
      memberType
      memberNumber
      memberStatus
      autoRenew
      orderNumber
      orderLineNumber
      initialBeginDate
      cycleBeginDate
      cycleEndDate
      chapterURL
    }
    benefitResources {
      ...LinkNode
    }
    additionalMemberResources {
      ...LinkNode
    }
    claimResource {
      showBanner
      claimLink
    }
  }
}
    ${LinkNodeFragmentDoc}`;
export const CapabilityModelFragmentDoc = gql`
    fragment CapabilityModel on User {
  capabilityModel {
    domains {
      domainTitle
      totalProgress
      capabilities {
        capTitle
        capTotalScore
        statements {
          desc
          descScore
        }
      }
    }
    isSelfAssessmentStarted
    isSelfAssessmentComplete
    isLearningPathSelected
    isLearningPlanCreated
    isLearningPlanSelected
    isPartialPlan
    isLearningPlanComplete
    planActivitiesCount
    planActivitiesCompletedCount
    isPersonalCapabilityComplete
    isProfessionalCapabilityComplete
    isOrganizationalCapabilityComplete
    learningPathLabel
    totalScore
  }
}
    `;
export const TopicsFragmentDoc = gql`
    fragment Topics on User {
  topics {
    id
    label
    key
    url
    userStatus {
      id
      followed
      recommended
    }
  }
}
    `;
export const CartFragmentDoc = gql`
    fragment Cart on User {
  cart {
    id
    itemTotal
  }
}
    `;
export const UserNodeFragmentDoc = gql`
    fragment UserNode on User {
  id
  staff
  isContributor
  customerClassCode
  isEnterpriseAdmin
  ...Profile
  ...Job
  ...Certification
  ...Badge
  ...Visibility
  ...Education
  ...ContactInfo
  ...CommunicationPreferences
  ...LearningActivity
  ...Membership
  ...CapabilityModel
  ...Topics
  ...Cart
}
    ${ProfileFragmentDoc}
${JobFragmentDoc}
${CertificationFragmentDoc}
${BadgeFragmentDoc}
${VisibilityFragmentDoc}
${EducationFragmentDoc}
${ContactInfoFragmentDoc}
${CommunicationPreferencesFragmentDoc}
${LearningActivityFragmentDoc}
${MembershipFragmentDoc}
${CapabilityModelFragmentDoc}
${TopicsFragmentDoc}
${CartFragmentDoc}`;
export const LpSeoFragmentDoc = gql`
    fragment LpSeo on LandingPages {
  seo_fields {
    meta_description
    meta_title
    no_follow
    no_index
    meta_imageConnection {
      edges {
        node {
          title
          url
          content_type
          filename
          file_size
          description
        }
      }
    }
  }
}
    `;
export const BlockContentHeaderFragmentDoc = gql`
    fragment BlockContentHeader on LandingPagesPageSectionSectionBlockBlockContentHeader {
  __typename
  header {
    title
    imageConnection {
      edges {
        node {
          title
          url
          content_type
          filename
          file_size
          description
        }
      }
    }
    subtitle
    button {
      title
      href
    }
  }
}
    `;
export const BlockContentStepsFragmentDoc = gql`
    fragment BlockContentSteps on LandingPagesPageSectionSectionBlockBlockContentStepsModule {
  __typename
  steps_module {
    button {
      title
      href
    }
    step {
      text
    }
  }
}
    `;
export const BlockContentFeaturedFragmentDoc = gql`
    fragment BlockContentFeatured on LandingPagesPageSectionSectionBlockBlockContentFeaturedModule {
  __typename
  featured_module {
    subtitle
    title
    theme
    content {
      ... on LandingPagesPageSectionSectionBlockBlockContentFeaturedModuleBlockContentRelationship {
        __typename
        relationship {
          title
          subtitle
          featured_items
        }
      }
      ... on LandingPagesPageSectionSectionBlockBlockContentFeaturedModuleBlockContentQuery {
        __typename
        query {
          title
          subtitle
          taxonomy
          topic
          industry
          role
          keyword
          order_by
          order
        }
      }
    }
  }
}
    `;
export const BlockContentImageCtaFragmentDoc = gql`
    fragment BlockContentImageCta on LandingPagesPageSectionSectionBlockBlockContentImageCtaModule {
  __typename
  image_cta_module {
    title
    image_position
    eyebrow
    button {
      title
      href
    }
    imageConnection {
      totalCount
      edges {
        node {
          title
          url
          content_type
          filename
          file_size
          description
        }
      }
      totalCount
    }
  }
}
    `;
export const BlockContentColumnFragmentDoc = gql`
    fragment BlockContentColumn on LandingPagesPageSectionSectionBlockBlockContentColumnModule {
  __typename
  column_module {
    column_item {
      title
      subtitle
      button {
        title
        href
      }
    }
  }
}
    `;
export const BlockContentTwoColumnHeaderFragmentDoc = gql`
    fragment BlockContentTwoColumnHeader on LandingPagesPageSectionSectionBlockBlockContentTwoColumnHeaderModule {
  __typename
  two_column_header_module {
    title
    column_item {
      title
      subtitle
    }
    imageConnection {
      edges {
        node {
          title
          url
          content_type
          filename
          file_size
          description
        }
      }
    }
  }
}
    `;
export const BlockContentImageAndTextFragmentDoc = gql`
    fragment BlockContentImageAndText on LandingPagesPageSectionSectionBlockBlockContentImageAndTextModule {
  __typename
  image_and_text_module {
    image_and_text_item {
      text
      button {
        title
        href
      }
      imageConnection {
        totalCount
        edges {
          node {
            title
            url
            content_type
            filename
            file_size
            description
          }
        }
        totalCount
      }
    }
  }
}
    `;
export const BlockContentSingleTextFragmentDoc = gql`
    fragment BlockContentSingleText on LandingPagesPageSectionSectionBlockBlockContentSingleTextModule {
  __typename
  single_text_module {
    text
  }
}
    `;
export const BlockContentFormFragmentDoc = gql`
    fragment BlockContentForm on LandingPagesPageSectionSectionBlockBlockContentFormModule {
  __typename
  form_module {
    form_title
  }
}
    `;
export const MenuChildNodeFragmentDoc = gql`
    fragment MenuChildNode on MenuItem {
  type
  title
  description
  url
  target
  buttonText
  buttonUrl
  imageUrl
  flair
}
    `;
export const BannerNodeFragmentDoc = gql`
    fragment BannerNode on Banner {
  name
  isCountdown
  countdownScript
  desktopImageUrl
  desktopLink
  mobileImageUrl
  mobileLink
  backgroundHexColor
}
    `;
export const LoginDocument = gql`
    mutation Login($input: LoginInput!) {
  login(input: $input)
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const UserFollowTopicDocument = gql`
    mutation UserFollowTopic($input: SubscribeTopicInput!) {
  subscribeTopic(input: $input) {
    message
    status
  }
}
    `;
export type UserFollowTopicMutationFn = Apollo.MutationFunction<UserFollowTopicMutation, UserFollowTopicMutationVariables>;

/**
 * __useUserFollowTopicMutation__
 *
 * To run a mutation, you first call `useUserFollowTopicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserFollowTopicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userFollowTopicMutation, { data, loading, error }] = useUserFollowTopicMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserFollowTopicMutation(baseOptions?: Apollo.MutationHookOptions<UserFollowTopicMutation, UserFollowTopicMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserFollowTopicMutation, UserFollowTopicMutationVariables>(UserFollowTopicDocument, options);
      }
export type UserFollowTopicMutationHookResult = ReturnType<typeof useUserFollowTopicMutation>;
export type UserFollowTopicMutationResult = Apollo.MutationResult<UserFollowTopicMutation>;
export type UserFollowTopicMutationOptions = Apollo.BaseMutationOptions<UserFollowTopicMutation, UserFollowTopicMutationVariables>;
export const UserUnfollowTopicDocument = gql`
    mutation UserUnfollowTopic($input: SubscribeTopicInput!) {
  unsubscribeTopic(input: $input) {
    message
    status
  }
}
    `;
export type UserUnfollowTopicMutationFn = Apollo.MutationFunction<UserUnfollowTopicMutation, UserUnfollowTopicMutationVariables>;

/**
 * __useUserUnfollowTopicMutation__
 *
 * To run a mutation, you first call `useUserUnfollowTopicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserUnfollowTopicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userUnfollowTopicMutation, { data, loading, error }] = useUserUnfollowTopicMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserUnfollowTopicMutation(baseOptions?: Apollo.MutationHookOptions<UserUnfollowTopicMutation, UserUnfollowTopicMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserUnfollowTopicMutation, UserUnfollowTopicMutationVariables>(UserUnfollowTopicDocument, options);
      }
export type UserUnfollowTopicMutationHookResult = ReturnType<typeof useUserUnfollowTopicMutation>;
export type UserUnfollowTopicMutationResult = Apollo.MutationResult<UserUnfollowTopicMutation>;
export type UserUnfollowTopicMutationOptions = Apollo.BaseMutationOptions<UserUnfollowTopicMutation, UserUnfollowTopicMutationVariables>;
export const UserUpsertDocument = gql`
    mutation UserUpsert($input: UpsertUserInput!) {
  userUpsert(input: $input) {
    status
    message
  }
}
    `;
export type UserUpsertMutationFn = Apollo.MutationFunction<UserUpsertMutation, UserUpsertMutationVariables>;

/**
 * __useUserUpsertMutation__
 *
 * To run a mutation, you first call `useUserUpsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserUpsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userUpsertMutation, { data, loading, error }] = useUserUpsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserUpsertMutation(baseOptions?: Apollo.MutationHookOptions<UserUpsertMutation, UserUpsertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserUpsertMutation, UserUpsertMutationVariables>(UserUpsertDocument, options);
      }
export type UserUpsertMutationHookResult = ReturnType<typeof useUserUpsertMutation>;
export type UserUpsertMutationResult = Apollo.MutationResult<UserUpsertMutation>;
export type UserUpsertMutationOptions = Apollo.BaseMutationOptions<UserUpsertMutation, UserUpsertMutationVariables>;
export const LandingPageContactFormUpdateDocument = gql`
    mutation LandingPageContactFormUpdate($input: LandingPageContactFormInput!) {
  landingPageContactFormUpdate(input: $input) {
    status
    message
  }
}
    `;
export type LandingPageContactFormUpdateMutationFn = Apollo.MutationFunction<LandingPageContactFormUpdateMutation, LandingPageContactFormUpdateMutationVariables>;

/**
 * __useLandingPageContactFormUpdateMutation__
 *
 * To run a mutation, you first call `useLandingPageContactFormUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLandingPageContactFormUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [landingPageContactFormUpdateMutation, { data, loading, error }] = useLandingPageContactFormUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLandingPageContactFormUpdateMutation(baseOptions?: Apollo.MutationHookOptions<LandingPageContactFormUpdateMutation, LandingPageContactFormUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LandingPageContactFormUpdateMutation, LandingPageContactFormUpdateMutationVariables>(LandingPageContactFormUpdateDocument, options);
      }
export type LandingPageContactFormUpdateMutationHookResult = ReturnType<typeof useLandingPageContactFormUpdateMutation>;
export type LandingPageContactFormUpdateMutationResult = Apollo.MutationResult<LandingPageContactFormUpdateMutation>;
export type LandingPageContactFormUpdateMutationOptions = Apollo.BaseMutationOptions<LandingPageContactFormUpdateMutation, LandingPageContactFormUpdateMutationVariables>;
export const UserProfileUpdateDocument = gql`
    mutation UserProfileUpdate($input: ProfileInput!) {
  userProfileUpdate(input: $input) {
    status
    message
  }
}
    `;
export type UserProfileUpdateMutationFn = Apollo.MutationFunction<UserProfileUpdateMutation, UserProfileUpdateMutationVariables>;

/**
 * __useUserProfileUpdateMutation__
 *
 * To run a mutation, you first call `useUserProfileUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserProfileUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userProfileUpdateMutation, { data, loading, error }] = useUserProfileUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserProfileUpdateMutation(baseOptions?: Apollo.MutationHookOptions<UserProfileUpdateMutation, UserProfileUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserProfileUpdateMutation, UserProfileUpdateMutationVariables>(UserProfileUpdateDocument, options);
      }
export type UserProfileUpdateMutationHookResult = ReturnType<typeof useUserProfileUpdateMutation>;
export type UserProfileUpdateMutationResult = Apollo.MutationResult<UserProfileUpdateMutation>;
export type UserProfileUpdateMutationOptions = Apollo.BaseMutationOptions<UserProfileUpdateMutation, UserProfileUpdateMutationVariables>;
export const UserJobUpdateDocument = gql`
    mutation UserJobUpdate($input: JobInput!) {
  userJobUpdate(input: $input) {
    status
    message
  }
}
    `;
export type UserJobUpdateMutationFn = Apollo.MutationFunction<UserJobUpdateMutation, UserJobUpdateMutationVariables>;

/**
 * __useUserJobUpdateMutation__
 *
 * To run a mutation, you first call `useUserJobUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserJobUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userJobUpdateMutation, { data, loading, error }] = useUserJobUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserJobUpdateMutation(baseOptions?: Apollo.MutationHookOptions<UserJobUpdateMutation, UserJobUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserJobUpdateMutation, UserJobUpdateMutationVariables>(UserJobUpdateDocument, options);
      }
export type UserJobUpdateMutationHookResult = ReturnType<typeof useUserJobUpdateMutation>;
export type UserJobUpdateMutationResult = Apollo.MutationResult<UserJobUpdateMutation>;
export type UserJobUpdateMutationOptions = Apollo.BaseMutationOptions<UserJobUpdateMutation, UserJobUpdateMutationVariables>;
export const UserEducationUpsertDocument = gql`
    mutation UserEducationUpsert($input: [EducationUpsertInput!]!) {
  userEducationUpsert(input: $input) {
    status
    message
  }
}
    `;
export type UserEducationUpsertMutationFn = Apollo.MutationFunction<UserEducationUpsertMutation, UserEducationUpsertMutationVariables>;

/**
 * __useUserEducationUpsertMutation__
 *
 * To run a mutation, you first call `useUserEducationUpsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserEducationUpsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userEducationUpsertMutation, { data, loading, error }] = useUserEducationUpsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserEducationUpsertMutation(baseOptions?: Apollo.MutationHookOptions<UserEducationUpsertMutation, UserEducationUpsertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserEducationUpsertMutation, UserEducationUpsertMutationVariables>(UserEducationUpsertDocument, options);
      }
export type UserEducationUpsertMutationHookResult = ReturnType<typeof useUserEducationUpsertMutation>;
export type UserEducationUpsertMutationResult = Apollo.MutationResult<UserEducationUpsertMutation>;
export type UserEducationUpsertMutationOptions = Apollo.BaseMutationOptions<UserEducationUpsertMutation, UserEducationUpsertMutationVariables>;
export const UserEducationDeleteDocument = gql`
    mutation UserEducationDelete($input: EducationDeleteInput!) {
  userEducationDelete(input: $input) {
    status
    message
  }
}
    `;
export type UserEducationDeleteMutationFn = Apollo.MutationFunction<UserEducationDeleteMutation, UserEducationDeleteMutationVariables>;

/**
 * __useUserEducationDeleteMutation__
 *
 * To run a mutation, you first call `useUserEducationDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserEducationDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userEducationDeleteMutation, { data, loading, error }] = useUserEducationDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserEducationDeleteMutation(baseOptions?: Apollo.MutationHookOptions<UserEducationDeleteMutation, UserEducationDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserEducationDeleteMutation, UserEducationDeleteMutationVariables>(UserEducationDeleteDocument, options);
      }
export type UserEducationDeleteMutationHookResult = ReturnType<typeof useUserEducationDeleteMutation>;
export type UserEducationDeleteMutationResult = Apollo.MutationResult<UserEducationDeleteMutation>;
export type UserEducationDeleteMutationOptions = Apollo.BaseMutationOptions<UserEducationDeleteMutation, UserEducationDeleteMutationVariables>;
export const UserAddressDeleteDocument = gql`
    mutation UserAddressDelete($input: AddressDeleteInput!) {
  userAddressDelete(input: $input) {
    status
    message
  }
}
    `;
export type UserAddressDeleteMutationFn = Apollo.MutationFunction<UserAddressDeleteMutation, UserAddressDeleteMutationVariables>;

/**
 * __useUserAddressDeleteMutation__
 *
 * To run a mutation, you first call `useUserAddressDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserAddressDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userAddressDeleteMutation, { data, loading, error }] = useUserAddressDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserAddressDeleteMutation(baseOptions?: Apollo.MutationHookOptions<UserAddressDeleteMutation, UserAddressDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserAddressDeleteMutation, UserAddressDeleteMutationVariables>(UserAddressDeleteDocument, options);
      }
export type UserAddressDeleteMutationHookResult = ReturnType<typeof useUserAddressDeleteMutation>;
export type UserAddressDeleteMutationResult = Apollo.MutationResult<UserAddressDeleteMutation>;
export type UserAddressDeleteMutationOptions = Apollo.BaseMutationOptions<UserAddressDeleteMutation, UserAddressDeleteMutationVariables>;
export const UserPhoneDeleteDocument = gql`
    mutation UserPhoneDelete($input: PhoneDeleteInput!) {
  userPhoneDelete(input: $input) {
    status
    message
  }
}
    `;
export type UserPhoneDeleteMutationFn = Apollo.MutationFunction<UserPhoneDeleteMutation, UserPhoneDeleteMutationVariables>;

/**
 * __useUserPhoneDeleteMutation__
 *
 * To run a mutation, you first call `useUserPhoneDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserPhoneDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userPhoneDeleteMutation, { data, loading, error }] = useUserPhoneDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserPhoneDeleteMutation(baseOptions?: Apollo.MutationHookOptions<UserPhoneDeleteMutation, UserPhoneDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserPhoneDeleteMutation, UserPhoneDeleteMutationVariables>(UserPhoneDeleteDocument, options);
      }
export type UserPhoneDeleteMutationHookResult = ReturnType<typeof useUserPhoneDeleteMutation>;
export type UserPhoneDeleteMutationResult = Apollo.MutationResult<UserPhoneDeleteMutation>;
export type UserPhoneDeleteMutationOptions = Apollo.BaseMutationOptions<UserPhoneDeleteMutation, UserPhoneDeleteMutationVariables>;
export const UserContactInfoUpdateDocument = gql`
    mutation UserContactInfoUpdate($input: ContactInfoInput!) {
  userContactInfoUpdate(input: $input) {
    status
    message
  }
}
    `;
export type UserContactInfoUpdateMutationFn = Apollo.MutationFunction<UserContactInfoUpdateMutation, UserContactInfoUpdateMutationVariables>;

/**
 * __useUserContactInfoUpdateMutation__
 *
 * To run a mutation, you first call `useUserContactInfoUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserContactInfoUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userContactInfoUpdateMutation, { data, loading, error }] = useUserContactInfoUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserContactInfoUpdateMutation(baseOptions?: Apollo.MutationHookOptions<UserContactInfoUpdateMutation, UserContactInfoUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserContactInfoUpdateMutation, UserContactInfoUpdateMutationVariables>(UserContactInfoUpdateDocument, options);
      }
export type UserContactInfoUpdateMutationHookResult = ReturnType<typeof useUserContactInfoUpdateMutation>;
export type UserContactInfoUpdateMutationResult = Apollo.MutationResult<UserContactInfoUpdateMutation>;
export type UserContactInfoUpdateMutationOptions = Apollo.BaseMutationOptions<UserContactInfoUpdateMutation, UserContactInfoUpdateMutationVariables>;
export const UserCommunicationPreferencesUpdateDocument = gql`
    mutation UserCommunicationPreferencesUpdate($input: CommunicationPreferencesInput!) {
  communicationPreferencesUpdate(input: $input) {
    status
    message
  }
}
    `;
export type UserCommunicationPreferencesUpdateMutationFn = Apollo.MutationFunction<UserCommunicationPreferencesUpdateMutation, UserCommunicationPreferencesUpdateMutationVariables>;

/**
 * __useUserCommunicationPreferencesUpdateMutation__
 *
 * To run a mutation, you first call `useUserCommunicationPreferencesUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserCommunicationPreferencesUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userCommunicationPreferencesUpdateMutation, { data, loading, error }] = useUserCommunicationPreferencesUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserCommunicationPreferencesUpdateMutation(baseOptions?: Apollo.MutationHookOptions<UserCommunicationPreferencesUpdateMutation, UserCommunicationPreferencesUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserCommunicationPreferencesUpdateMutation, UserCommunicationPreferencesUpdateMutationVariables>(UserCommunicationPreferencesUpdateDocument, options);
      }
export type UserCommunicationPreferencesUpdateMutationHookResult = ReturnType<typeof useUserCommunicationPreferencesUpdateMutation>;
export type UserCommunicationPreferencesUpdateMutationResult = Apollo.MutationResult<UserCommunicationPreferencesUpdateMutation>;
export type UserCommunicationPreferencesUpdateMutationOptions = Apollo.BaseMutationOptions<UserCommunicationPreferencesUpdateMutation, UserCommunicationPreferencesUpdateMutationVariables>;
export const UserToggleProfilePrivacyDocument = gql`
    mutation UserToggleProfilePrivacy($input: Boolean!) {
  toggleProfilePrivacy(input: $input) {
    message
    status
  }
}
    `;
export type UserToggleProfilePrivacyMutationFn = Apollo.MutationFunction<UserToggleProfilePrivacyMutation, UserToggleProfilePrivacyMutationVariables>;

/**
 * __useUserToggleProfilePrivacyMutation__
 *
 * To run a mutation, you first call `useUserToggleProfilePrivacyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserToggleProfilePrivacyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userToggleProfilePrivacyMutation, { data, loading, error }] = useUserToggleProfilePrivacyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserToggleProfilePrivacyMutation(baseOptions?: Apollo.MutationHookOptions<UserToggleProfilePrivacyMutation, UserToggleProfilePrivacyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserToggleProfilePrivacyMutation, UserToggleProfilePrivacyMutationVariables>(UserToggleProfilePrivacyDocument, options);
      }
export type UserToggleProfilePrivacyMutationHookResult = ReturnType<typeof useUserToggleProfilePrivacyMutation>;
export type UserToggleProfilePrivacyMutationResult = Apollo.MutationResult<UserToggleProfilePrivacyMutation>;
export type UserToggleProfilePrivacyMutationOptions = Apollo.BaseMutationOptions<UserToggleProfilePrivacyMutation, UserToggleProfilePrivacyMutationVariables>;
export const UserToggleMemberDirectoryPrivacyDocument = gql`
    mutation UserToggleMemberDirectoryPrivacy($input: Boolean!) {
  toggleMemberDirectoryPrivacy(input: $input) {
    message
    status
  }
}
    `;
export type UserToggleMemberDirectoryPrivacyMutationFn = Apollo.MutationFunction<UserToggleMemberDirectoryPrivacyMutation, UserToggleMemberDirectoryPrivacyMutationVariables>;

/**
 * __useUserToggleMemberDirectoryPrivacyMutation__
 *
 * To run a mutation, you first call `useUserToggleMemberDirectoryPrivacyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserToggleMemberDirectoryPrivacyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userToggleMemberDirectoryPrivacyMutation, { data, loading, error }] = useUserToggleMemberDirectoryPrivacyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserToggleMemberDirectoryPrivacyMutation(baseOptions?: Apollo.MutationHookOptions<UserToggleMemberDirectoryPrivacyMutation, UserToggleMemberDirectoryPrivacyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserToggleMemberDirectoryPrivacyMutation, UserToggleMemberDirectoryPrivacyMutationVariables>(UserToggleMemberDirectoryPrivacyDocument, options);
      }
export type UserToggleMemberDirectoryPrivacyMutationHookResult = ReturnType<typeof useUserToggleMemberDirectoryPrivacyMutation>;
export type UserToggleMemberDirectoryPrivacyMutationResult = Apollo.MutationResult<UserToggleMemberDirectoryPrivacyMutation>;
export type UserToggleMemberDirectoryPrivacyMutationOptions = Apollo.BaseMutationOptions<UserToggleMemberDirectoryPrivacyMutation, UserToggleMemberDirectoryPrivacyMutationVariables>;
export const UserUpdatePasswordDocument = gql`
    mutation UserUpdatePassword($input: UpdatePasswordInput!) {
  updatePassword(input: $input) {
    message
    status
  }
}
    `;
export type UserUpdatePasswordMutationFn = Apollo.MutationFunction<UserUpdatePasswordMutation, UserUpdatePasswordMutationVariables>;

/**
 * __useUserUpdatePasswordMutation__
 *
 * To run a mutation, you first call `useUserUpdatePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserUpdatePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userUpdatePasswordMutation, { data, loading, error }] = useUserUpdatePasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserUpdatePasswordMutation(baseOptions?: Apollo.MutationHookOptions<UserUpdatePasswordMutation, UserUpdatePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserUpdatePasswordMutation, UserUpdatePasswordMutationVariables>(UserUpdatePasswordDocument, options);
      }
export type UserUpdatePasswordMutationHookResult = ReturnType<typeof useUserUpdatePasswordMutation>;
export type UserUpdatePasswordMutationResult = Apollo.MutationResult<UserUpdatePasswordMutation>;
export type UserUpdatePasswordMutationOptions = Apollo.BaseMutationOptions<UserUpdatePasswordMutation, UserUpdatePasswordMutationVariables>;
export const UserUpdateEmailDocument = gql`
    mutation UserUpdateEmail($input: UpdateEmailInput!) {
  updateEmail(input: $input) {
    message
    status
  }
}
    `;
export type UserUpdateEmailMutationFn = Apollo.MutationFunction<UserUpdateEmailMutation, UserUpdateEmailMutationVariables>;

/**
 * __useUserUpdateEmailMutation__
 *
 * To run a mutation, you first call `useUserUpdateEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserUpdateEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userUpdateEmailMutation, { data, loading, error }] = useUserUpdateEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserUpdateEmailMutation(baseOptions?: Apollo.MutationHookOptions<UserUpdateEmailMutation, UserUpdateEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserUpdateEmailMutation, UserUpdateEmailMutationVariables>(UserUpdateEmailDocument, options);
      }
export type UserUpdateEmailMutationHookResult = ReturnType<typeof useUserUpdateEmailMutation>;
export type UserUpdateEmailMutationResult = Apollo.MutationResult<UserUpdateEmailMutation>;
export type UserUpdateEmailMutationOptions = Apollo.BaseMutationOptions<UserUpdateEmailMutation, UserUpdateEmailMutationVariables>;
export const UploadFileDocument = gql`
    mutation uploadFile($input: UpdateProfilePicture!) {
  uploadFile(input: $input) {
    message
    status
  }
}
    `;
export type UploadFileMutationFn = Apollo.MutationFunction<UploadFileMutation, UploadFileMutationVariables>;

/**
 * __useUploadFileMutation__
 *
 * To run a mutation, you first call `useUploadFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadFileMutation, { data, loading, error }] = useUploadFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadFileMutation(baseOptions?: Apollo.MutationHookOptions<UploadFileMutation, UploadFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadFileMutation, UploadFileMutationVariables>(UploadFileDocument, options);
      }
export type UploadFileMutationHookResult = ReturnType<typeof useUploadFileMutation>;
export type UploadFileMutationResult = Apollo.MutationResult<UploadFileMutation>;
export type UploadFileMutationOptions = Apollo.BaseMutationOptions<UploadFileMutation, UploadFileMutationVariables>;
export const RemoveFileDocument = gql`
    mutation removeFile {
  removeFile {
    message
    status
  }
}
    `;
export type RemoveFileMutationFn = Apollo.MutationFunction<RemoveFileMutation, RemoveFileMutationVariables>;

/**
 * __useRemoveFileMutation__
 *
 * To run a mutation, you first call `useRemoveFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFileMutation, { data, loading, error }] = useRemoveFileMutation({
 *   variables: {
 *   },
 * });
 */
export function useRemoveFileMutation(baseOptions?: Apollo.MutationHookOptions<RemoveFileMutation, RemoveFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveFileMutation, RemoveFileMutationVariables>(RemoveFileDocument, options);
      }
export type RemoveFileMutationHookResult = ReturnType<typeof useRemoveFileMutation>;
export type RemoveFileMutationResult = Apollo.MutationResult<RemoveFileMutation>;
export type RemoveFileMutationOptions = Apollo.BaseMutationOptions<RemoveFileMutation, RemoveFileMutationVariables>;
export const UserUpdateOrderAutorenewalDocument = gql`
    mutation userUpdateOrderAutorenewal($input: UpdateOrderAutoRenewalInput!) {
  updateOrderAutoRenewal(input: $input) {
    status
    message
  }
}
    `;
export type UserUpdateOrderAutorenewalMutationFn = Apollo.MutationFunction<UserUpdateOrderAutorenewalMutation, UserUpdateOrderAutorenewalMutationVariables>;

/**
 * __useUserUpdateOrderAutorenewalMutation__
 *
 * To run a mutation, you first call `useUserUpdateOrderAutorenewalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserUpdateOrderAutorenewalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userUpdateOrderAutorenewalMutation, { data, loading, error }] = useUserUpdateOrderAutorenewalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserUpdateOrderAutorenewalMutation(baseOptions?: Apollo.MutationHookOptions<UserUpdateOrderAutorenewalMutation, UserUpdateOrderAutorenewalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserUpdateOrderAutorenewalMutation, UserUpdateOrderAutorenewalMutationVariables>(UserUpdateOrderAutorenewalDocument, options);
      }
export type UserUpdateOrderAutorenewalMutationHookResult = ReturnType<typeof useUserUpdateOrderAutorenewalMutation>;
export type UserUpdateOrderAutorenewalMutationResult = Apollo.MutationResult<UserUpdateOrderAutorenewalMutation>;
export type UserUpdateOrderAutorenewalMutationOptions = Apollo.BaseMutationOptions<UserUpdateOrderAutorenewalMutation, UserUpdateOrderAutorenewalMutationVariables>;
export const CreateBookmarkDocument = gql`
    mutation CreateBookmark($input: UpdateBookmarkInput!) {
  createBookmark(input: $input) {
    message
    status
  }
}
    `;
export type CreateBookmarkMutationFn = Apollo.MutationFunction<CreateBookmarkMutation, CreateBookmarkMutationVariables>;

/**
 * __useCreateBookmarkMutation__
 *
 * To run a mutation, you first call `useCreateBookmarkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBookmarkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBookmarkMutation, { data, loading, error }] = useCreateBookmarkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBookmarkMutation(baseOptions?: Apollo.MutationHookOptions<CreateBookmarkMutation, CreateBookmarkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBookmarkMutation, CreateBookmarkMutationVariables>(CreateBookmarkDocument, options);
      }
export type CreateBookmarkMutationHookResult = ReturnType<typeof useCreateBookmarkMutation>;
export type CreateBookmarkMutationResult = Apollo.MutationResult<CreateBookmarkMutation>;
export type CreateBookmarkMutationOptions = Apollo.BaseMutationOptions<CreateBookmarkMutation, CreateBookmarkMutationVariables>;
export const DeleteBookmarkDocument = gql`
    mutation DeleteBookmark($input: UpdateBookmarkInput!) {
  deleteBookmark(input: $input) {
    message
    status
  }
}
    `;
export type DeleteBookmarkMutationFn = Apollo.MutationFunction<DeleteBookmarkMutation, DeleteBookmarkMutationVariables>;

/**
 * __useDeleteBookmarkMutation__
 *
 * To run a mutation, you first call `useDeleteBookmarkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBookmarkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBookmarkMutation, { data, loading, error }] = useDeleteBookmarkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteBookmarkMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBookmarkMutation, DeleteBookmarkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBookmarkMutation, DeleteBookmarkMutationVariables>(DeleteBookmarkDocument, options);
      }
export type DeleteBookmarkMutationHookResult = ReturnType<typeof useDeleteBookmarkMutation>;
export type DeleteBookmarkMutationResult = Apollo.MutationResult<DeleteBookmarkMutation>;
export type DeleteBookmarkMutationOptions = Apollo.BaseMutationOptions<DeleteBookmarkMutation, DeleteBookmarkMutationVariables>;
export const UserFollowUserDocument = gql`
    mutation UserFollowUser($input: SubscribePublicUserInput!) {
  subscribePublicUser(input: $input) {
    message
    status
  }
}
    `;
export type UserFollowUserMutationFn = Apollo.MutationFunction<UserFollowUserMutation, UserFollowUserMutationVariables>;

/**
 * __useUserFollowUserMutation__
 *
 * To run a mutation, you first call `useUserFollowUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserFollowUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userFollowUserMutation, { data, loading, error }] = useUserFollowUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserFollowUserMutation(baseOptions?: Apollo.MutationHookOptions<UserFollowUserMutation, UserFollowUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserFollowUserMutation, UserFollowUserMutationVariables>(UserFollowUserDocument, options);
      }
export type UserFollowUserMutationHookResult = ReturnType<typeof useUserFollowUserMutation>;
export type UserFollowUserMutationResult = Apollo.MutationResult<UserFollowUserMutation>;
export type UserFollowUserMutationOptions = Apollo.BaseMutationOptions<UserFollowUserMutation, UserFollowUserMutationVariables>;
export const UserUnfollowUserDocument = gql`
    mutation UserUnfollowUser($input: SubscribePublicUserInput!) {
  unsubscribePublicUser(input: $input) {
    message
    status
  }
}
    `;
export type UserUnfollowUserMutationFn = Apollo.MutationFunction<UserUnfollowUserMutation, UserUnfollowUserMutationVariables>;

/**
 * __useUserUnfollowUserMutation__
 *
 * To run a mutation, you first call `useUserUnfollowUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserUnfollowUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userUnfollowUserMutation, { data, loading, error }] = useUserUnfollowUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserUnfollowUserMutation(baseOptions?: Apollo.MutationHookOptions<UserUnfollowUserMutation, UserUnfollowUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserUnfollowUserMutation, UserUnfollowUserMutationVariables>(UserUnfollowUserDocument, options);
      }
export type UserUnfollowUserMutationHookResult = ReturnType<typeof useUserUnfollowUserMutation>;
export type UserUnfollowUserMutationResult = Apollo.MutationResult<UserUnfollowUserMutation>;
export type UserUnfollowUserMutationOptions = Apollo.BaseMutationOptions<UserUnfollowUserMutation, UserUnfollowUserMutationVariables>;
export const UserJoinUserGroupDocument = gql`
    mutation UserJoinUserGroup($input: SubscribeUserGroupInput!) {
  subscribeUserGroup(input: $input) {
    message
    status
  }
}
    `;
export type UserJoinUserGroupMutationFn = Apollo.MutationFunction<UserJoinUserGroupMutation, UserJoinUserGroupMutationVariables>;

/**
 * __useUserJoinUserGroupMutation__
 *
 * To run a mutation, you first call `useUserJoinUserGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserJoinUserGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userJoinUserGroupMutation, { data, loading, error }] = useUserJoinUserGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserJoinUserGroupMutation(baseOptions?: Apollo.MutationHookOptions<UserJoinUserGroupMutation, UserJoinUserGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserJoinUserGroupMutation, UserJoinUserGroupMutationVariables>(UserJoinUserGroupDocument, options);
      }
export type UserJoinUserGroupMutationHookResult = ReturnType<typeof useUserJoinUserGroupMutation>;
export type UserJoinUserGroupMutationResult = Apollo.MutationResult<UserJoinUserGroupMutation>;
export type UserJoinUserGroupMutationOptions = Apollo.BaseMutationOptions<UserJoinUserGroupMutation, UserJoinUserGroupMutationVariables>;
export const UserLeaveUserGroupDocument = gql`
    mutation UserLeaveUserGroup($input: SubscribeUserGroupInput!) {
  unsubscribeUserGroup(input: $input) {
    message
    status
  }
}
    `;
export type UserLeaveUserGroupMutationFn = Apollo.MutationFunction<UserLeaveUserGroupMutation, UserLeaveUserGroupMutationVariables>;

/**
 * __useUserLeaveUserGroupMutation__
 *
 * To run a mutation, you first call `useUserLeaveUserGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserLeaveUserGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userLeaveUserGroupMutation, { data, loading, error }] = useUserLeaveUserGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserLeaveUserGroupMutation(baseOptions?: Apollo.MutationHookOptions<UserLeaveUserGroupMutation, UserLeaveUserGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserLeaveUserGroupMutation, UserLeaveUserGroupMutationVariables>(UserLeaveUserGroupDocument, options);
      }
export type UserLeaveUserGroupMutationHookResult = ReturnType<typeof useUserLeaveUserGroupMutation>;
export type UserLeaveUserGroupMutationResult = Apollo.MutationResult<UserLeaveUserGroupMutation>;
export type UserLeaveUserGroupMutationOptions = Apollo.BaseMutationOptions<UserLeaveUserGroupMutation, UserLeaveUserGroupMutationVariables>;
export const GetAlgoliaContentDocument = gql`
    query GetAlgoliaContent($input: AlgoliaContentInput!) {
  algoliaContent(input: $input) {
    meta {
      page
      pageSize
      count
      totalPages
    }
    data {
      ...AllContentItemNode
    }
  }
}
    ${AllContentItemNodeFragmentDoc}`;

/**
 * __useGetAlgoliaContentQuery__
 *
 * To run a query within a React component, call `useGetAlgoliaContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAlgoliaContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAlgoliaContentQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAlgoliaContentQuery(baseOptions: Apollo.QueryHookOptions<GetAlgoliaContentQuery, GetAlgoliaContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAlgoliaContentQuery, GetAlgoliaContentQueryVariables>(GetAlgoliaContentDocument, options);
      }
export function useGetAlgoliaContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAlgoliaContentQuery, GetAlgoliaContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAlgoliaContentQuery, GetAlgoliaContentQueryVariables>(GetAlgoliaContentDocument, options);
        }
export type GetAlgoliaContentQueryHookResult = ReturnType<typeof useGetAlgoliaContentQuery>;
export type GetAlgoliaContentLazyQueryHookResult = ReturnType<typeof useGetAlgoliaContentLazyQuery>;
export type GetAlgoliaContentQueryResult = Apollo.QueryResult<GetAlgoliaContentQuery, GetAlgoliaContentQueryVariables>;
export const GetConstantsDocument = gql`
    query GetConstants {
  constants {
    ...ConstantsNode
  }
}
    ${ConstantsNodeFragmentDoc}`;

/**
 * __useGetConstantsQuery__
 *
 * To run a query within a React component, call `useGetConstantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConstantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConstantsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetConstantsQuery(baseOptions?: Apollo.QueryHookOptions<GetConstantsQuery, GetConstantsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetConstantsQuery, GetConstantsQueryVariables>(GetConstantsDocument, options);
      }
export function useGetConstantsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetConstantsQuery, GetConstantsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetConstantsQuery, GetConstantsQueryVariables>(GetConstantsDocument, options);
        }
export type GetConstantsQueryHookResult = ReturnType<typeof useGetConstantsQuery>;
export type GetConstantsLazyQueryHookResult = ReturnType<typeof useGetConstantsLazyQuery>;
export type GetConstantsQueryResult = Apollo.QueryResult<GetConstantsQuery, GetConstantsQueryVariables>;
export const GetFeaturedBenefitDocument = gql`
    query GetFeaturedBenefit {
  featuredBenefit {
    title
    subTitle
    benefit {
      ...AllContentItemNode
    }
  }
}
    ${AllContentItemNodeFragmentDoc}`;

/**
 * __useGetFeaturedBenefitQuery__
 *
 * To run a query within a React component, call `useGetFeaturedBenefitQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeaturedBenefitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeaturedBenefitQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFeaturedBenefitQuery(baseOptions?: Apollo.QueryHookOptions<GetFeaturedBenefitQuery, GetFeaturedBenefitQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFeaturedBenefitQuery, GetFeaturedBenefitQueryVariables>(GetFeaturedBenefitDocument, options);
      }
export function useGetFeaturedBenefitLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFeaturedBenefitQuery, GetFeaturedBenefitQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFeaturedBenefitQuery, GetFeaturedBenefitQueryVariables>(GetFeaturedBenefitDocument, options);
        }
export type GetFeaturedBenefitQueryHookResult = ReturnType<typeof useGetFeaturedBenefitQuery>;
export type GetFeaturedBenefitLazyQueryHookResult = ReturnType<typeof useGetFeaturedBenefitLazyQuery>;
export type GetFeaturedBenefitQueryResult = Apollo.QueryResult<GetFeaturedBenefitQuery, GetFeaturedBenefitQueryVariables>;
export const GetFeaturedContentDocument = gql`
    query GetFeaturedContent($input: FeaturedContentInput) {
  featuredContent(input: $input) {
    meta {
      ...MetaNode
    }
    data {
      ...AllContentItemNode
    }
  }
}
    ${MetaNodeFragmentDoc}
${AllContentItemNodeFragmentDoc}`;

/**
 * __useGetFeaturedContentQuery__
 *
 * To run a query within a React component, call `useGetFeaturedContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeaturedContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeaturedContentQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetFeaturedContentQuery(baseOptions?: Apollo.QueryHookOptions<GetFeaturedContentQuery, GetFeaturedContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFeaturedContentQuery, GetFeaturedContentQueryVariables>(GetFeaturedContentDocument, options);
      }
export function useGetFeaturedContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFeaturedContentQuery, GetFeaturedContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFeaturedContentQuery, GetFeaturedContentQueryVariables>(GetFeaturedContentDocument, options);
        }
export type GetFeaturedContentQueryHookResult = ReturnType<typeof useGetFeaturedContentQuery>;
export type GetFeaturedContentLazyQueryHookResult = ReturnType<typeof useGetFeaturedContentLazyQuery>;
export type GetFeaturedContentQueryResult = Apollo.QueryResult<GetFeaturedContentQuery, GetFeaturedContentQueryVariables>;
export const GetFeaturedContentHeaderDocument = gql`
    query GetFeaturedContentHeader($input: FeaturedContentInput) {
  featuredContent(input: $input) {
    meta {
      ...MetaNode
    }
    data {
      imageUrl
      title
      url
    }
  }
}
    ${MetaNodeFragmentDoc}`;

/**
 * __useGetFeaturedContentHeaderQuery__
 *
 * To run a query within a React component, call `useGetFeaturedContentHeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeaturedContentHeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeaturedContentHeaderQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetFeaturedContentHeaderQuery(baseOptions?: Apollo.QueryHookOptions<GetFeaturedContentHeaderQuery, GetFeaturedContentHeaderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFeaturedContentHeaderQuery, GetFeaturedContentHeaderQueryVariables>(GetFeaturedContentHeaderDocument, options);
      }
export function useGetFeaturedContentHeaderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFeaturedContentHeaderQuery, GetFeaturedContentHeaderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFeaturedContentHeaderQuery, GetFeaturedContentHeaderQueryVariables>(GetFeaturedContentHeaderDocument, options);
        }
export type GetFeaturedContentHeaderQueryHookResult = ReturnType<typeof useGetFeaturedContentHeaderQuery>;
export type GetFeaturedContentHeaderLazyQueryHookResult = ReturnType<typeof useGetFeaturedContentHeaderLazyQuery>;
export type GetFeaturedContentHeaderQueryResult = Apollo.QueryResult<GetFeaturedContentHeaderQuery, GetFeaturedContentHeaderQueryVariables>;
export const GetLandingPageByIdDocument = gql`
    query GetLandingPageByID($uid: String!) {
  landing_pages(uid: $uid) {
    title
    url
    hide_site_header
    ...LpSeo
    page_section {
      ... on LandingPagesPageSectionSectionBlock {
        __typename
        section_block {
          section_id
          section_theme
          section_title
          content {
            ... on LandingPagesPageSectionSectionBlockBlockContentHeader {
              ...BlockContentHeader
            }
            ... on LandingPagesPageSectionSectionBlockBlockContentStepsModule {
              ...BlockContentSteps
            }
            ... on LandingPagesPageSectionSectionBlockBlockContentFeaturedModule {
              ...BlockContentFeatured
            }
            ... on LandingPagesPageSectionSectionBlockBlockContentImageCtaModule {
              ...BlockContentImageCta
            }
            ... on LandingPagesPageSectionSectionBlockBlockContentColumnModule {
              ...BlockContentColumn
            }
            ... on LandingPagesPageSectionSectionBlockBlockContentTwoColumnHeaderModule {
              ...BlockContentTwoColumnHeader
            }
            ... on LandingPagesPageSectionSectionBlockBlockContentImageAndTextModule {
              ...BlockContentImageAndText
            }
            ... on LandingPagesPageSectionSectionBlockBlockContentSingleTextModule {
              ...BlockContentSingleText
            }
            ... on LandingPagesPageSectionSectionBlockBlockContentFormModule {
              ...BlockContentForm
            }
          }
        }
      }
    }
  }
}
    ${LpSeoFragmentDoc}
${BlockContentHeaderFragmentDoc}
${BlockContentStepsFragmentDoc}
${BlockContentFeaturedFragmentDoc}
${BlockContentImageCtaFragmentDoc}
${BlockContentColumnFragmentDoc}
${BlockContentTwoColumnHeaderFragmentDoc}
${BlockContentImageAndTextFragmentDoc}
${BlockContentSingleTextFragmentDoc}
${BlockContentFormFragmentDoc}`;

/**
 * __useGetLandingPageByIdQuery__
 *
 * To run a query within a React component, call `useGetLandingPageByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLandingPageByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLandingPageByIdQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useGetLandingPageByIdQuery(baseOptions: Apollo.QueryHookOptions<GetLandingPageByIdQuery, GetLandingPageByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLandingPageByIdQuery, GetLandingPageByIdQueryVariables>(GetLandingPageByIdDocument, options);
      }
export function useGetLandingPageByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLandingPageByIdQuery, GetLandingPageByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLandingPageByIdQuery, GetLandingPageByIdQueryVariables>(GetLandingPageByIdDocument, options);
        }
export type GetLandingPageByIdQueryHookResult = ReturnType<typeof useGetLandingPageByIdQuery>;
export type GetLandingPageByIdLazyQueryHookResult = ReturnType<typeof useGetLandingPageByIdLazyQuery>;
export type GetLandingPageByIdQueryResult = Apollo.QueryResult<GetLandingPageByIdQuery, GetLandingPageByIdQueryVariables>;
export const GetLandingPagesByUrlDocument = gql`
    query GetLandingPagesByUrl($url: String!) {
  all_landing_pages(where: {url: $url}) {
    total
    items {
      title
      url
      hide_site_header
      ...LpSeo
      page_section {
        ... on LandingPagesPageSectionSectionBlock {
          __typename
          section_block {
            section_id
            section_theme
            section_title
            content {
              ... on LandingPagesPageSectionSectionBlockBlockContentHeader {
                ...BlockContentHeader
              }
              ... on LandingPagesPageSectionSectionBlockBlockContentStepsModule {
                ...BlockContentSteps
              }
              ... on LandingPagesPageSectionSectionBlockBlockContentFeaturedModule {
                ...BlockContentFeatured
              }
              ... on LandingPagesPageSectionSectionBlockBlockContentImageCtaModule {
                ...BlockContentImageCta
              }
              ... on LandingPagesPageSectionSectionBlockBlockContentColumnModule {
                ...BlockContentColumn
              }
              ... on LandingPagesPageSectionSectionBlockBlockContentTwoColumnHeaderModule {
                ...BlockContentTwoColumnHeader
              }
              ... on LandingPagesPageSectionSectionBlockBlockContentImageAndTextModule {
                ...BlockContentImageAndText
              }
              ... on LandingPagesPageSectionSectionBlockBlockContentSingleTextModule {
                ...BlockContentSingleText
              }
              ... on LandingPagesPageSectionSectionBlockBlockContentFormModule {
                ...BlockContentForm
              }
            }
          }
        }
      }
    }
  }
}
    ${LpSeoFragmentDoc}
${BlockContentHeaderFragmentDoc}
${BlockContentStepsFragmentDoc}
${BlockContentFeaturedFragmentDoc}
${BlockContentImageCtaFragmentDoc}
${BlockContentColumnFragmentDoc}
${BlockContentTwoColumnHeaderFragmentDoc}
${BlockContentImageAndTextFragmentDoc}
${BlockContentSingleTextFragmentDoc}
${BlockContentFormFragmentDoc}`;

/**
 * __useGetLandingPagesByUrlQuery__
 *
 * To run a query within a React component, call `useGetLandingPagesByUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLandingPagesByUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLandingPagesByUrlQuery({
 *   variables: {
 *      url: // value for 'url'
 *   },
 * });
 */
export function useGetLandingPagesByUrlQuery(baseOptions: Apollo.QueryHookOptions<GetLandingPagesByUrlQuery, GetLandingPagesByUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLandingPagesByUrlQuery, GetLandingPagesByUrlQueryVariables>(GetLandingPagesByUrlDocument, options);
      }
export function useGetLandingPagesByUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLandingPagesByUrlQuery, GetLandingPagesByUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLandingPagesByUrlQuery, GetLandingPagesByUrlQueryVariables>(GetLandingPagesByUrlDocument, options);
        }
export type GetLandingPagesByUrlQueryHookResult = ReturnType<typeof useGetLandingPagesByUrlQuery>;
export type GetLandingPagesByUrlLazyQueryHookResult = ReturnType<typeof useGetLandingPagesByUrlLazyQuery>;
export type GetLandingPagesByUrlQueryResult = Apollo.QueryResult<GetLandingPagesByUrlQuery, GetLandingPagesByUrlQueryVariables>;
export const LearningCourseDocument = gql`
    query learningCourse($input: LearningActivityInput) {
  learningCourse(input: $input) {
    data {
      id
      name
      completion
      startDate
      endDate
      calendarUrl
      courseStartDate
      courseEndDate
      score
      timeSpent
      viewUrl
      launchUrl
      code
      completionPercent
      section
      credits {
        type
        value
        creditYear
      }
      certificates {
        name
        url
        issueDate
        expiryDate
      }
      badges {
        image_url
      }
    }
    meta {
      count
      page
      pageSize
      totalPages
    }
  }
}
    `;

/**
 * __useLearningCourseQuery__
 *
 * To run a query within a React component, call `useLearningCourseQuery` and pass it any options that fit your needs.
 * When your component renders, `useLearningCourseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLearningCourseQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLearningCourseQuery(baseOptions?: Apollo.QueryHookOptions<LearningCourseQuery, LearningCourseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LearningCourseQuery, LearningCourseQueryVariables>(LearningCourseDocument, options);
      }
export function useLearningCourseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LearningCourseQuery, LearningCourseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LearningCourseQuery, LearningCourseQueryVariables>(LearningCourseDocument, options);
        }
export type LearningCourseQueryHookResult = ReturnType<typeof useLearningCourseQuery>;
export type LearningCourseLazyQueryHookResult = ReturnType<typeof useLearningCourseLazyQuery>;
export type LearningCourseQueryResult = Apollo.QueryResult<LearningCourseQuery, LearningCourseQueryVariables>;
export const CourseCertificatesDocument = gql`
    query courseCertificates($input: LearningActivityInput) {
  courseCertificates(input: $input) {
    data {
      title
    }
  }
}
    `;

/**
 * __useCourseCertificatesQuery__
 *
 * To run a query within a React component, call `useCourseCertificatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseCertificatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseCertificatesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCourseCertificatesQuery(baseOptions?: Apollo.QueryHookOptions<CourseCertificatesQuery, CourseCertificatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CourseCertificatesQuery, CourseCertificatesQueryVariables>(CourseCertificatesDocument, options);
      }
export function useCourseCertificatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CourseCertificatesQuery, CourseCertificatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CourseCertificatesQuery, CourseCertificatesQueryVariables>(CourseCertificatesDocument, options);
        }
export type CourseCertificatesQueryHookResult = ReturnType<typeof useCourseCertificatesQuery>;
export type CourseCertificatesLazyQueryHookResult = ReturnType<typeof useCourseCertificatesLazyQuery>;
export type CourseCertificatesQueryResult = Apollo.QueryResult<CourseCertificatesQuery, CourseCertificatesQueryVariables>;
export const WebinarsDocument = gql`
    query webinars($input: LearningActivityInput) {
  webinars(input: $input) {
    data {
      webinarId
      date
      title
      role
      url
    }
    meta {
      count
      page
      pageSize
      totalPages
    }
  }
}
    `;

/**
 * __useWebinarsQuery__
 *
 * To run a query within a React component, call `useWebinarsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebinarsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebinarsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWebinarsQuery(baseOptions?: Apollo.QueryHookOptions<WebinarsQuery, WebinarsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WebinarsQuery, WebinarsQueryVariables>(WebinarsDocument, options);
      }
export function useWebinarsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WebinarsQuery, WebinarsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WebinarsQuery, WebinarsQueryVariables>(WebinarsDocument, options);
        }
export type WebinarsQueryHookResult = ReturnType<typeof useWebinarsQuery>;
export type WebinarsLazyQueryHookResult = ReturnType<typeof useWebinarsLazyQuery>;
export type WebinarsQueryResult = Apollo.QueryResult<WebinarsQuery, WebinarsQueryVariables>;
export const ConferencesDocument = gql`
    query conferences($input: LearningActivityInput) {
  conferences(input: $input) {
    data {
      eventName
      startDate
      endDate
      location
      productUrl
    }
    meta {
      count
      page
      pageSize
      totalPages
    }
  }
}
    `;

/**
 * __useConferencesQuery__
 *
 * To run a query within a React component, call `useConferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useConferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConferencesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConferencesQuery(baseOptions?: Apollo.QueryHookOptions<ConferencesQuery, ConferencesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConferencesQuery, ConferencesQueryVariables>(ConferencesDocument, options);
      }
export function useConferencesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConferencesQuery, ConferencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConferencesQuery, ConferencesQueryVariables>(ConferencesDocument, options);
        }
export type ConferencesQueryHookResult = ReturnType<typeof useConferencesQuery>;
export type ConferencesLazyQueryHookResult = ReturnType<typeof useConferencesLazyQuery>;
export type ConferencesQueryResult = Apollo.QueryResult<ConferencesQuery, ConferencesQueryVariables>;
export const LearningSummaryDocument = gql`
    query learningSummary {
  learningSummary {
    data {
      id
      url
      title
      type
      date
    }
  }
}
    `;

/**
 * __useLearningSummaryQuery__
 *
 * To run a query within a React component, call `useLearningSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useLearningSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLearningSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useLearningSummaryQuery(baseOptions?: Apollo.QueryHookOptions<LearningSummaryQuery, LearningSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LearningSummaryQuery, LearningSummaryQueryVariables>(LearningSummaryDocument, options);
      }
export function useLearningSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LearningSummaryQuery, LearningSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LearningSummaryQuery, LearningSummaryQueryVariables>(LearningSummaryDocument, options);
        }
export type LearningSummaryQueryHookResult = ReturnType<typeof useLearningSummaryQuery>;
export type LearningSummaryLazyQueryHookResult = ReturnType<typeof useLearningSummaryLazyQuery>;
export type LearningSummaryQueryResult = Apollo.QueryResult<LearningSummaryQuery, LearningSummaryQueryVariables>;
export const GetMenuDocument = gql`
    query GetMenu {
  menu {
    bannerData {
      ...BannerNode
    }
    menuData {
      type
      title
      description
      url
      target
      children {
        ...MenuChildNode
        children {
          ...MenuChildNode
          children {
            ...MenuChildNode
          }
        }
      }
    }
  }
}
    ${BannerNodeFragmentDoc}
${MenuChildNodeFragmentDoc}`;

/**
 * __useGetMenuQuery__
 *
 * To run a query within a React component, call `useGetMenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMenuQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMenuQuery(baseOptions?: Apollo.QueryHookOptions<GetMenuQuery, GetMenuQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMenuQuery, GetMenuQueryVariables>(GetMenuDocument, options);
      }
export function useGetMenuLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMenuQuery, GetMenuQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMenuQuery, GetMenuQueryVariables>(GetMenuDocument, options);
        }
export type GetMenuQueryHookResult = ReturnType<typeof useGetMenuQuery>;
export type GetMenuLazyQueryHookResult = ReturnType<typeof useGetMenuLazyQuery>;
export type GetMenuQueryResult = Apollo.QueryResult<GetMenuQuery, GetMenuQueryVariables>;
export const GetOrdersDocument = gql`
    query GetOrders($input: OrdersInput) {
  orders(input: $input) {
    meta {
      ...MetaNode
    }
    data {
      ...OrderNode
    }
    years
  }
}
    ${MetaNodeFragmentDoc}
${OrderNodeFragmentDoc}`;

/**
 * __useGetOrdersQuery__
 *
 * To run a query within a React component, call `useGetOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrdersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetOrdersQuery(baseOptions?: Apollo.QueryHookOptions<GetOrdersQuery, GetOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrdersQuery, GetOrdersQueryVariables>(GetOrdersDocument, options);
      }
export function useGetOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrdersQuery, GetOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrdersQuery, GetOrdersQueryVariables>(GetOrdersDocument, options);
        }
export type GetOrdersQueryHookResult = ReturnType<typeof useGetOrdersQuery>;
export type GetOrdersLazyQueryHookResult = ReturnType<typeof useGetOrdersLazyQuery>;
export type GetOrdersQueryResult = Apollo.QueryResult<GetOrdersQuery, GetOrdersQueryVariables>;
export const OrderInvoiceUrlDocument = gql`
    query orderInvoiceUrl($input: OrdersInvoiceUrlInput) {
  orderInvoiceUrl(input: $input) {
    url
  }
}
    `;

/**
 * __useOrderInvoiceUrlQuery__
 *
 * To run a query within a React component, call `useOrderInvoiceUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderInvoiceUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderInvoiceUrlQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrderInvoiceUrlQuery(baseOptions?: Apollo.QueryHookOptions<OrderInvoiceUrlQuery, OrderInvoiceUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderInvoiceUrlQuery, OrderInvoiceUrlQueryVariables>(OrderInvoiceUrlDocument, options);
      }
export function useOrderInvoiceUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderInvoiceUrlQuery, OrderInvoiceUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderInvoiceUrlQuery, OrderInvoiceUrlQueryVariables>(OrderInvoiceUrlDocument, options);
        }
export type OrderInvoiceUrlQueryHookResult = ReturnType<typeof useOrderInvoiceUrlQuery>;
export type OrderInvoiceUrlLazyQueryHookResult = ReturnType<typeof useOrderInvoiceUrlLazyQuery>;
export type OrderInvoiceUrlQueryResult = Apollo.QueryResult<OrderInvoiceUrlQuery, OrderInvoiceUrlQueryVariables>;
export const OrderInfoDocument = gql`
    query orderInfo($input: OrderNumberInput) {
  orderInfo(input: $input) {
    billAddressId
  }
}
    `;

/**
 * __useOrderInfoQuery__
 *
 * To run a query within a React component, call `useOrderInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderInfoQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrderInfoQuery(baseOptions?: Apollo.QueryHookOptions<OrderInfoQuery, OrderInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderInfoQuery, OrderInfoQueryVariables>(OrderInfoDocument, options);
      }
export function useOrderInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderInfoQuery, OrderInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderInfoQuery, OrderInfoQueryVariables>(OrderInfoDocument, options);
        }
export type OrderInfoQueryHookResult = ReturnType<typeof useOrderInfoQuery>;
export type OrderInfoLazyQueryHookResult = ReturnType<typeof useOrderInfoLazyQuery>;
export type OrderInfoQueryResult = Apollo.QueryResult<OrderInfoQuery, OrderInfoQueryVariables>;
export const GetRecommendedContentDocument = gql`
    query GetRecommendedContent($input: RecommendedContentInput) {
  recommendedContent(input: $input) {
    meta {
      ...MetaNode
    }
    data {
      topic
      count
      data {
        ...AllContentItemNode
      }
    }
  }
}
    ${MetaNodeFragmentDoc}
${AllContentItemNodeFragmentDoc}`;

/**
 * __useGetRecommendedContentQuery__
 *
 * To run a query within a React component, call `useGetRecommendedContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecommendedContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecommendedContentQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetRecommendedContentQuery(baseOptions?: Apollo.QueryHookOptions<GetRecommendedContentQuery, GetRecommendedContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRecommendedContentQuery, GetRecommendedContentQueryVariables>(GetRecommendedContentDocument, options);
      }
export function useGetRecommendedContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRecommendedContentQuery, GetRecommendedContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRecommendedContentQuery, GetRecommendedContentQueryVariables>(GetRecommendedContentDocument, options);
        }
export type GetRecommendedContentQueryHookResult = ReturnType<typeof useGetRecommendedContentQuery>;
export type GetRecommendedContentLazyQueryHookResult = ReturnType<typeof useGetRecommendedContentLazyQuery>;
export type GetRecommendedContentQueryResult = Apollo.QueryResult<GetRecommendedContentQuery, GetRecommendedContentQueryVariables>;
export const GetUserDocument = gql`
    query GetUser {
  user {
    ...UserNode
  }
}
    ${UserNodeFragmentDoc}`;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserQuery(baseOptions?: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const GetEmailValidatorDocument = gql`
    query GetEmailValidator($input: EmailValidatorInput!) {
  emailValidator(input: $input) {
    isValid
    message
  }
}
    `;

/**
 * __useGetEmailValidatorQuery__
 *
 * To run a query within a React component, call `useGetEmailValidatorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmailValidatorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmailValidatorQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetEmailValidatorQuery(baseOptions: Apollo.QueryHookOptions<GetEmailValidatorQuery, GetEmailValidatorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmailValidatorQuery, GetEmailValidatorQueryVariables>(GetEmailValidatorDocument, options);
      }
export function useGetEmailValidatorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmailValidatorQuery, GetEmailValidatorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmailValidatorQuery, GetEmailValidatorQueryVariables>(GetEmailValidatorDocument, options);
        }
export type GetEmailValidatorQueryHookResult = ReturnType<typeof useGetEmailValidatorQuery>;
export type GetEmailValidatorLazyQueryHookResult = ReturnType<typeof useGetEmailValidatorLazyQuery>;
export type GetEmailValidatorQueryResult = Apollo.QueryResult<GetEmailValidatorQuery, GetEmailValidatorQueryVariables>;
export const GetEmailCommunicationPreferencesDocument = gql`
    query GetEmailCommunicationPreferences($input: PreferencesInput!) {
  emailCommunicationPreferences(input: $input) {
    automatedNewsletters
    isPostalMailOptedOut
    isPhoneOptedOut
    bimonthlyDigest
    receivePromotionalCommunication
    newsletters {
      newsletterGroup {
        key
        value
        label
        frequency
      }
      newsletterGroupLabel
    }
  }
}
    `;

/**
 * __useGetEmailCommunicationPreferencesQuery__
 *
 * To run a query within a React component, call `useGetEmailCommunicationPreferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmailCommunicationPreferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmailCommunicationPreferencesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetEmailCommunicationPreferencesQuery(baseOptions: Apollo.QueryHookOptions<GetEmailCommunicationPreferencesQuery, GetEmailCommunicationPreferencesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmailCommunicationPreferencesQuery, GetEmailCommunicationPreferencesQueryVariables>(GetEmailCommunicationPreferencesDocument, options);
      }
export function useGetEmailCommunicationPreferencesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmailCommunicationPreferencesQuery, GetEmailCommunicationPreferencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmailCommunicationPreferencesQuery, GetEmailCommunicationPreferencesQueryVariables>(GetEmailCommunicationPreferencesDocument, options);
        }
export type GetEmailCommunicationPreferencesQueryHookResult = ReturnType<typeof useGetEmailCommunicationPreferencesQuery>;
export type GetEmailCommunicationPreferencesLazyQueryHookResult = ReturnType<typeof useGetEmailCommunicationPreferencesLazyQuery>;
export type GetEmailCommunicationPreferencesQueryResult = Apollo.QueryResult<GetEmailCommunicationPreferencesQuery, GetEmailCommunicationPreferencesQueryVariables>;
export const GetUserQueryDocument = gql`
    query GetUserQuery {
  user {
    id
    profile {
      firstName
      lastName
      profileImageUrl
    }
    membership {
      isMember
      overview {
        label
      }
    }
    customerClassCode
    cart {
      itemTotal
    }
  }
}
    `;

/**
 * __useGetUserQueryQuery__
 *
 * To run a query within a React component, call `useGetUserQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserQueryQuery(baseOptions?: Apollo.QueryHookOptions<GetUserQueryQuery, GetUserQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQueryQuery, GetUserQueryQueryVariables>(GetUserQueryDocument, options);
      }
export function useGetUserQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQueryQuery, GetUserQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQueryQuery, GetUserQueryQueryVariables>(GetUserQueryDocument, options);
        }
export type GetUserQueryQueryHookResult = ReturnType<typeof useGetUserQueryQuery>;
export type GetUserQueryLazyQueryHookResult = ReturnType<typeof useGetUserQueryLazyQuery>;
export type GetUserQueryQueryResult = Apollo.QueryResult<GetUserQueryQuery, GetUserQueryQueryVariables>;
export const GetUserContactInfoDocument = gql`
    query GetUserContactInfo {
  user {
    contactInfo {
      primaryEmailAddress
    }
  }
}
    `;

/**
 * __useGetUserContactInfoQuery__
 *
 * To run a query within a React component, call `useGetUserContactInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserContactInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserContactInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserContactInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetUserContactInfoQuery, GetUserContactInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserContactInfoQuery, GetUserContactInfoQueryVariables>(GetUserContactInfoDocument, options);
      }
export function useGetUserContactInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserContactInfoQuery, GetUserContactInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserContactInfoQuery, GetUserContactInfoQueryVariables>(GetUserContactInfoDocument, options);
        }
export type GetUserContactInfoQueryHookResult = ReturnType<typeof useGetUserContactInfoQuery>;
export type GetUserContactInfoLazyQueryHookResult = ReturnType<typeof useGetUserContactInfoLazyQuery>;
export type GetUserContactInfoQueryResult = Apollo.QueryResult<GetUserContactInfoQuery, GetUserContactInfoQueryVariables>;
export const GetUserIdDocument = gql`
    query GetUserId {
  user {
    id
  }
}
    `;

/**
 * __useGetUserIdQuery__
 *
 * To run a query within a React component, call `useGetUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserIdQuery(baseOptions?: Apollo.QueryHookOptions<GetUserIdQuery, GetUserIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserIdQuery, GetUserIdQueryVariables>(GetUserIdDocument, options);
      }
export function useGetUserIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserIdQuery, GetUserIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserIdQuery, GetUserIdQueryVariables>(GetUserIdDocument, options);
        }
export type GetUserIdQueryHookResult = ReturnType<typeof useGetUserIdQuery>;
export type GetUserIdLazyQueryHookResult = ReturnType<typeof useGetUserIdLazyQuery>;
export type GetUserIdQueryResult = Apollo.QueryResult<GetUserIdQuery, GetUserIdQueryVariables>;
export const GetUserMembershipStatusDocument = gql`
    query GetUserMembershipStatus {
  user {
    membership {
      isMember
    }
  }
}
    `;

/**
 * __useGetUserMembershipStatusQuery__
 *
 * To run a query within a React component, call `useGetUserMembershipStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserMembershipStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserMembershipStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserMembershipStatusQuery(baseOptions?: Apollo.QueryHookOptions<GetUserMembershipStatusQuery, GetUserMembershipStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserMembershipStatusQuery, GetUserMembershipStatusQueryVariables>(GetUserMembershipStatusDocument, options);
      }
export function useGetUserMembershipStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserMembershipStatusQuery, GetUserMembershipStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserMembershipStatusQuery, GetUserMembershipStatusQueryVariables>(GetUserMembershipStatusDocument, options);
        }
export type GetUserMembershipStatusQueryHookResult = ReturnType<typeof useGetUserMembershipStatusQuery>;
export type GetUserMembershipStatusLazyQueryHookResult = ReturnType<typeof useGetUserMembershipStatusLazyQuery>;
export type GetUserMembershipStatusQueryResult = Apollo.QueryResult<GetUserMembershipStatusQuery, GetUserMembershipStatusQueryVariables>;
export const GetUserDinamicValuesDocument = gql`
    query GetUserDinamicValues {
  user {
    id
    membership {
      isMember
      currentMembership {
        id
        autoRenew
        cycleEndDate
        initialBeginDate
        memberTypeLabel
      }
      futureMembership {
        id
        autoRenew
        cycleEndDate
        memberTypeLabel
      }
      isEnterpriseMember
    }
    isContributor
  }
}
    `;

/**
 * __useGetUserDinamicValuesQuery__
 *
 * To run a query within a React component, call `useGetUserDinamicValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserDinamicValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserDinamicValuesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserDinamicValuesQuery(baseOptions?: Apollo.QueryHookOptions<GetUserDinamicValuesQuery, GetUserDinamicValuesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserDinamicValuesQuery, GetUserDinamicValuesQueryVariables>(GetUserDinamicValuesDocument, options);
      }
export function useGetUserDinamicValuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserDinamicValuesQuery, GetUserDinamicValuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserDinamicValuesQuery, GetUserDinamicValuesQueryVariables>(GetUserDinamicValuesDocument, options);
        }
export type GetUserDinamicValuesQueryHookResult = ReturnType<typeof useGetUserDinamicValuesQuery>;
export type GetUserDinamicValuesLazyQueryHookResult = ReturnType<typeof useGetUserDinamicValuesLazyQuery>;
export type GetUserDinamicValuesQueryResult = Apollo.QueryResult<GetUserDinamicValuesQuery, GetUserDinamicValuesQueryVariables>;
export const GetUserTopicsDocument = gql`
    query GetUserTopics {
  user {
    topics {
      id
      label
      key
      url
      userStatus {
        id
        followed
        recommended
      }
    }
  }
}
    `;

/**
 * __useGetUserTopicsQuery__
 *
 * To run a query within a React component, call `useGetUserTopicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserTopicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserTopicsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserTopicsQuery(baseOptions?: Apollo.QueryHookOptions<GetUserTopicsQuery, GetUserTopicsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserTopicsQuery, GetUserTopicsQueryVariables>(GetUserTopicsDocument, options);
      }
export function useGetUserTopicsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserTopicsQuery, GetUserTopicsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserTopicsQuery, GetUserTopicsQueryVariables>(GetUserTopicsDocument, options);
        }
export type GetUserTopicsQueryHookResult = ReturnType<typeof useGetUserTopicsQuery>;
export type GetUserTopicsLazyQueryHookResult = ReturnType<typeof useGetUserTopicsLazyQuery>;
export type GetUserTopicsQueryResult = Apollo.QueryResult<GetUserTopicsQuery, GetUserTopicsQueryVariables>;
export const GetUserCapabilityModelDocument = gql`
    query GetUserCapabilityModel {
  user {
    capabilityModel {
      isSelfAssessmentStarted
      isSelfAssessmentComplete
      isLearningPathSelected
      isLearningPlanCreated
      isLearningPlanSelected
      isPartialPlan
      isLearningPlanComplete
      planActivitiesCount
      planActivitiesCompletedCount
      isPersonalCapabilityComplete
      isProfessionalCapabilityComplete
      isOrganizationalCapabilityComplete
      learningPathLabel
      domains {
        domainTitle
        totalProgress
        capabilities {
          capTitle
          capTotalScore
          statements {
            desc
            descScore
          }
        }
      }
      totalScore
    }
  }
}
    `;

/**
 * __useGetUserCapabilityModelQuery__
 *
 * To run a query within a React component, call `useGetUserCapabilityModelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserCapabilityModelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserCapabilityModelQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserCapabilityModelQuery(baseOptions?: Apollo.QueryHookOptions<GetUserCapabilityModelQuery, GetUserCapabilityModelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserCapabilityModelQuery, GetUserCapabilityModelQueryVariables>(GetUserCapabilityModelDocument, options);
      }
export function useGetUserCapabilityModelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserCapabilityModelQuery, GetUserCapabilityModelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserCapabilityModelQuery, GetUserCapabilityModelQueryVariables>(GetUserCapabilityModelDocument, options);
        }
export type GetUserCapabilityModelQueryHookResult = ReturnType<typeof useGetUserCapabilityModelQuery>;
export type GetUserCapabilityModelLazyQueryHookResult = ReturnType<typeof useGetUserCapabilityModelLazyQuery>;
export type GetUserCapabilityModelQueryResult = Apollo.QueryResult<GetUserCapabilityModelQuery, GetUserCapabilityModelQueryVariables>;
export const GetUserProfileDocument = gql`
    query GetUserProfile {
  user {
    job {
      userIndustry
      jobTitle
      jobFunctionCode
      jobIndustry
      companyName
      companySize
      trainingBudget
      directReportCount
    }
    profile {
      firstName
      lastName
      profileImageUrl
    }
    certification {
      expiryDate
      earnedDate
      masters
      recertifyByDate
      certificationCode
      certificationStatus
    }
    badge {
      id
      badgeId
      imageUrl
      thumbnailUrl
      createdAt
      issuedAt
      title
      description
      url
      pending
      acceptUrl
    }
  }
}
    `;

/**
 * __useGetUserProfileQuery__
 *
 * To run a query within a React component, call `useGetUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserProfileQuery(baseOptions?: Apollo.QueryHookOptions<GetUserProfileQuery, GetUserProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserProfileQuery, GetUserProfileQueryVariables>(GetUserProfileDocument, options);
      }
export function useGetUserProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserProfileQuery, GetUserProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserProfileQuery, GetUserProfileQueryVariables>(GetUserProfileDocument, options);
        }
export type GetUserProfileQueryHookResult = ReturnType<typeof useGetUserProfileQuery>;
export type GetUserProfileLazyQueryHookResult = ReturnType<typeof useGetUserProfileLazyQuery>;
export type GetUserProfileQueryResult = Apollo.QueryResult<GetUserProfileQuery, GetUserProfileQueryVariables>;
export const GetUserContentDataDocument = gql`
    query GetUserContentData {
  user {
    isContributor
    visibility {
      publicProfile
      memberDirectoryListing
    }
  }
}
    `;

/**
 * __useGetUserContentDataQuery__
 *
 * To run a query within a React component, call `useGetUserContentDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserContentDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserContentDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserContentDataQuery(baseOptions?: Apollo.QueryHookOptions<GetUserContentDataQuery, GetUserContentDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserContentDataQuery, GetUserContentDataQueryVariables>(GetUserContentDataDocument, options);
      }
export function useGetUserContentDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserContentDataQuery, GetUserContentDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserContentDataQuery, GetUserContentDataQueryVariables>(GetUserContentDataDocument, options);
        }
export type GetUserContentDataQueryHookResult = ReturnType<typeof useGetUserContentDataQuery>;
export type GetUserContentDataLazyQueryHookResult = ReturnType<typeof useGetUserContentDataLazyQuery>;
export type GetUserContentDataQueryResult = Apollo.QueryResult<GetUserContentDataQuery, GetUserContentDataQueryVariables>;
export const GetUserAutoRenewLowValueTokenDetailsDocument = gql`
    query GetUserAutoRenewLowValueTokenDetails {
  autoRenewLowValueTokenDetails {
    merchantId
    payPageID
  }
}
    `;

/**
 * __useGetUserAutoRenewLowValueTokenDetailsQuery__
 *
 * To run a query within a React component, call `useGetUserAutoRenewLowValueTokenDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserAutoRenewLowValueTokenDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserAutoRenewLowValueTokenDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserAutoRenewLowValueTokenDetailsQuery(baseOptions?: Apollo.QueryHookOptions<GetUserAutoRenewLowValueTokenDetailsQuery, GetUserAutoRenewLowValueTokenDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserAutoRenewLowValueTokenDetailsQuery, GetUserAutoRenewLowValueTokenDetailsQueryVariables>(GetUserAutoRenewLowValueTokenDetailsDocument, options);
      }
export function useGetUserAutoRenewLowValueTokenDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserAutoRenewLowValueTokenDetailsQuery, GetUserAutoRenewLowValueTokenDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserAutoRenewLowValueTokenDetailsQuery, GetUserAutoRenewLowValueTokenDetailsQueryVariables>(GetUserAutoRenewLowValueTokenDetailsDocument, options);
        }
export type GetUserAutoRenewLowValueTokenDetailsQueryHookResult = ReturnType<typeof useGetUserAutoRenewLowValueTokenDetailsQuery>;
export type GetUserAutoRenewLowValueTokenDetailsLazyQueryHookResult = ReturnType<typeof useGetUserAutoRenewLowValueTokenDetailsLazyQuery>;
export type GetUserAutoRenewLowValueTokenDetailsQueryResult = Apollo.QueryResult<GetUserAutoRenewLowValueTokenDetailsQuery, GetUserAutoRenewLowValueTokenDetailsQueryVariables>;
export const GetUserBenefitsDocument = gql`
    query GetUserBenefits($input: UserBenefitsInput) {
  userBenefits(input: $input) {
    meta {
      ...MetaNode
      orderBy {
        value
        order
      }
    }
    data {
      key
      url
      count
      remainingCount
      data {
        ... on ArticleSearchItem {
          ...IAbstractSearchItem
          ...ArticleSearchItemNode
        }
      }
    }
  }
}
    ${MetaNodeFragmentDoc}
${IAbstractSearchItemFragmentDoc}
${ArticleSearchItemNodeFragmentDoc}`;

/**
 * __useGetUserBenefitsQuery__
 *
 * To run a query within a React component, call `useGetUserBenefitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserBenefitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserBenefitsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetUserBenefitsQuery(baseOptions?: Apollo.QueryHookOptions<GetUserBenefitsQuery, GetUserBenefitsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserBenefitsQuery, GetUserBenefitsQueryVariables>(GetUserBenefitsDocument, options);
      }
export function useGetUserBenefitsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserBenefitsQuery, GetUserBenefitsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserBenefitsQuery, GetUserBenefitsQueryVariables>(GetUserBenefitsDocument, options);
        }
export type GetUserBenefitsQueryHookResult = ReturnType<typeof useGetUserBenefitsQuery>;
export type GetUserBenefitsLazyQueryHookResult = ReturnType<typeof useGetUserBenefitsLazyQuery>;
export type GetUserBenefitsQueryResult = Apollo.QueryResult<GetUserBenefitsQuery, GetUserBenefitsQueryVariables>;
export const GetUserContentDocument = gql`
    query GetUserContent($input: UserContentInput) {
  userContent(input: $input) {
    meta {
      ...MetaNode
      orderBy {
        value
        order
      }
      allFilters {
        ...FiltersNode
      }
    }
    data {
      ... on IAbstractSearchItem {
        ...IAbstractSearchItem
      }
      ... on BlogSearchItem {
        ...IAbstractSearchItem
        ...BlogSearchItemNode
      }
      ... on ArticleSearchItem {
        ...IAbstractSearchItem
        ...ArticleSearchItemNode
      }
      ... on PageSearchItem {
        ...IAbstractSearchItem
        ...PageSearchItemNode
      }
      ... on VideoSearchItem {
        ...IAbstractSearchItem
        ...VideoSearchItemNode
      }
      ... on PublicationSearchItem {
        ...IAbstractSearchItem
        ...PublicationSearchItemNode
      }
      ... on UserSearchItem {
        ...IAbstractSearchItem
        ...UserSearchItemNode
      }
      ... on CourseSearchItem {
        ...IAbstractSearchItem
        ...CourseSearchItemNode
      }
      ... on ConferenceSearchItem {
        ...IAbstractSearchItem
        ...ConferenceSearchItemNode
      }
      ... on WebinarSearchItem {
        ...IAbstractSearchItem
        ...WebinarSearchItemNode
      }
    }
  }
}
    ${MetaNodeFragmentDoc}
${FiltersNodeFragmentDoc}
${IAbstractSearchItemFragmentDoc}
${BlogSearchItemNodeFragmentDoc}
${ArticleSearchItemNodeFragmentDoc}
${PageSearchItemNodeFragmentDoc}
${VideoSearchItemNodeFragmentDoc}
${PublicationSearchItemNodeFragmentDoc}
${UserSearchItemNodeFragmentDoc}
${CourseSearchItemNodeFragmentDoc}
${ConferenceSearchItemNodeFragmentDoc}
${WebinarSearchItemNodeFragmentDoc}`;

/**
 * __useGetUserContentQuery__
 *
 * To run a query within a React component, call `useGetUserContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserContentQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetUserContentQuery(baseOptions?: Apollo.QueryHookOptions<GetUserContentQuery, GetUserContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserContentQuery, GetUserContentQueryVariables>(GetUserContentDocument, options);
      }
export function useGetUserContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserContentQuery, GetUserContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserContentQuery, GetUserContentQueryVariables>(GetUserContentDocument, options);
        }
export type GetUserContentQueryHookResult = ReturnType<typeof useGetUserContentQuery>;
export type GetUserContentLazyQueryHookResult = ReturnType<typeof useGetUserContentLazyQuery>;
export type GetUserContentQueryResult = Apollo.QueryResult<GetUserContentQuery, GetUserContentQueryVariables>;
export const GetAllBookmarksDocument = gql`
    query GetAllBookmarks($input: UserContentInput) {
  userContent(input: $input) {
    data {
      ... on IAbstractSearchItem {
        id
      }
    }
  }
}
    `;

/**
 * __useGetAllBookmarksQuery__
 *
 * To run a query within a React component, call `useGetAllBookmarksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllBookmarksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllBookmarksQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAllBookmarksQuery(baseOptions?: Apollo.QueryHookOptions<GetAllBookmarksQuery, GetAllBookmarksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllBookmarksQuery, GetAllBookmarksQueryVariables>(GetAllBookmarksDocument, options);
      }
export function useGetAllBookmarksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllBookmarksQuery, GetAllBookmarksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllBookmarksQuery, GetAllBookmarksQueryVariables>(GetAllBookmarksDocument, options);
        }
export type GetAllBookmarksQueryHookResult = ReturnType<typeof useGetAllBookmarksQuery>;
export type GetAllBookmarksLazyQueryHookResult = ReturnType<typeof useGetAllBookmarksLazyQuery>;
export type GetAllBookmarksQueryResult = Apollo.QueryResult<GetAllBookmarksQuery, GetAllBookmarksQueryVariables>;
export const GetNetworkSummaryDocument = gql`
    query GetNetworkSummary {
  publicUsersFollowing: publicUsers(
    input: {pageSize: 2, page: 0, filters: {type: FOLLOWING}, orderBy: {value: CREATED_DATE, order: ASC}}
  ) {
    meta {
      ...MetaNode
    }
  }
  publicUsersFollowed: publicUsers(
    input: {pageSize: 2, page: 0, filters: {type: FOLLOWED}, orderBy: {value: CREATED_DATE, order: ASC}}
  ) {
    meta {
      ...MetaNode
    }
  }
  userGroups {
    meta {
      count
    }
  }
}
    ${MetaNodeFragmentDoc}`;

/**
 * __useGetNetworkSummaryQuery__
 *
 * To run a query within a React component, call `useGetNetworkSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNetworkSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNetworkSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetNetworkSummaryQuery(baseOptions?: Apollo.QueryHookOptions<GetNetworkSummaryQuery, GetNetworkSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNetworkSummaryQuery, GetNetworkSummaryQueryVariables>(GetNetworkSummaryDocument, options);
      }
export function useGetNetworkSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNetworkSummaryQuery, GetNetworkSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNetworkSummaryQuery, GetNetworkSummaryQueryVariables>(GetNetworkSummaryDocument, options);
        }
export type GetNetworkSummaryQueryHookResult = ReturnType<typeof useGetNetworkSummaryQuery>;
export type GetNetworkSummaryLazyQueryHookResult = ReturnType<typeof useGetNetworkSummaryLazyQuery>;
export type GetNetworkSummaryQueryResult = Apollo.QueryResult<GetNetworkSummaryQuery, GetNetworkSummaryQueryVariables>;
export const GetPublicUsersDocument = gql`
    query GetPublicUsers($input: PublicUsersInput!) {
  publicUsers(input: $input) {
    meta {
      ...MetaNode
      orderBy {
        value
        order
      }
    }
    data {
      ...IAbstractSearchItem
      ...UserSearchItemNode
    }
  }
}
    ${MetaNodeFragmentDoc}
${IAbstractSearchItemFragmentDoc}
${UserSearchItemNodeFragmentDoc}`;

/**
 * __useGetPublicUsersQuery__
 *
 * To run a query within a React component, call `useGetPublicUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublicUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublicUsersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetPublicUsersQuery(baseOptions: Apollo.QueryHookOptions<GetPublicUsersQuery, GetPublicUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPublicUsersQuery, GetPublicUsersQueryVariables>(GetPublicUsersDocument, options);
      }
export function useGetPublicUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPublicUsersQuery, GetPublicUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPublicUsersQuery, GetPublicUsersQueryVariables>(GetPublicUsersDocument, options);
        }
export type GetPublicUsersQueryHookResult = ReturnType<typeof useGetPublicUsersQuery>;
export type GetPublicUsersLazyQueryHookResult = ReturnType<typeof useGetPublicUsersLazyQuery>;
export type GetPublicUsersQueryResult = Apollo.QueryResult<GetPublicUsersQuery, GetPublicUsersQueryVariables>;
export const GetUserGroupsDocument = gql`
    query GetUserGroups($input: UserGroupInput) {
  userGroups(input: $input) {
    meta {
      ...MetaNode
    }
    data {
      id
      title
      avatarUrl
    }
  }
}
    ${MetaNodeFragmentDoc}`;

/**
 * __useGetUserGroupsQuery__
 *
 * To run a query within a React component, call `useGetUserGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserGroupsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetUserGroupsQuery(baseOptions?: Apollo.QueryHookOptions<GetUserGroupsQuery, GetUserGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserGroupsQuery, GetUserGroupsQueryVariables>(GetUserGroupsDocument, options);
      }
export function useGetUserGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserGroupsQuery, GetUserGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserGroupsQuery, GetUserGroupsQueryVariables>(GetUserGroupsDocument, options);
        }
export type GetUserGroupsQueryHookResult = ReturnType<typeof useGetUserGroupsQuery>;
export type GetUserGroupsLazyQueryHookResult = ReturnType<typeof useGetUserGroupsLazyQuery>;
export type GetUserGroupsQueryResult = Apollo.QueryResult<GetUserGroupsQuery, GetUserGroupsQueryVariables>;
export const GetUserNetworkActivityDocument = gql`
    query GetUserNetworkActivity($input: NetworkActivityInput) {
  activity(input: $input) {
    meta {
      ...MetaNode
    }
    data {
      id
      content {
        ...AllContentItemNode
      }
      action
      date
      groupMeta {
        groupAction
        groupUrl
        groupName
      }
      user {
        id
        url
        firstName
        lastName
        imageUrl
      }
    }
  }
}
    ${MetaNodeFragmentDoc}
${AllContentItemNodeFragmentDoc}`;

/**
 * __useGetUserNetworkActivityQuery__
 *
 * To run a query within a React component, call `useGetUserNetworkActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserNetworkActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserNetworkActivityQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetUserNetworkActivityQuery(baseOptions?: Apollo.QueryHookOptions<GetUserNetworkActivityQuery, GetUserNetworkActivityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserNetworkActivityQuery, GetUserNetworkActivityQueryVariables>(GetUserNetworkActivityDocument, options);
      }
export function useGetUserNetworkActivityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserNetworkActivityQuery, GetUserNetworkActivityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserNetworkActivityQuery, GetUserNetworkActivityQueryVariables>(GetUserNetworkActivityDocument, options);
        }
export type GetUserNetworkActivityQueryHookResult = ReturnType<typeof useGetUserNetworkActivityQuery>;
export type GetUserNetworkActivityLazyQueryHookResult = ReturnType<typeof useGetUserNetworkActivityLazyQuery>;
export type GetUserNetworkActivityQueryResult = Apollo.QueryResult<GetUserNetworkActivityQuery, GetUserNetworkActivityQueryVariables>;