// @react
import React, { ReactElement, useState } from 'react'
// @types
import { PropsType as LozengeProps } from '@bit/atd.web.lozenge'
import { PropsType as IconProps } from '@bit/atd.web.icons'
import { PropsType as ThumbnailProps } from '@bit/atd.web.thumbnail'
// @libraries
import { makeStyles } from '@material-ui/core/styles'
import useResizeObserver from 'use-resize-observer'
import clsx from 'clsx'
// @components
import Grid from '@bit/atd.web.grid'
// @card parts
import Wrap from './Parts/Wrap'
import Thumbnail from './Parts/Thumbnail'
import Content from './Parts/Content'
import Details from './Parts/Details'
import FooterBar from './Parts/FooterBar'
import Avatar from './Parts/Avatar'
import { SponsorItem } from 'lib/graphql/graphqlTypes'
import Loader from '@bit/atd.web.loaders.base'
import { GetUserQuery, GetUserQueryVariables } from 'lib/graphql/graphqlTypes'
import { GET_USER } from 'graphql/queries/user.query'
import { useQuery } from '@apollo/client'

export const useLoaderStyles = makeStyles({
  wrapper: {
    position: 'relative',
  },
  loader: {
    position: 'absolute',
    top: 'calc(50% - 20px)',
    right: 'calc(50% - 20px)',
    zIndex: 0,
  },
})

const useStyles = makeStyles({
  relative: {
    position: 'relative',
  },
  opacity: {
    opacity: 0.5,
  },
  mainWrapper: {
    transition: 'opacity 0.1s',
    padding: '16px 16px 12px',
    overflowWrap: 'anywhere',
  },
  thumbnailWrapper: {
    padding: '0 0 16px',
  },
  avatarWrapper: {
    padding: '0 0 16px',
  },
  textWrapper: {
    width: '100%',
    flexGrow: 1,
    '& $MuiGrid-item:first-child': {
      flexGrow: 1,
    },
  },
  contentWrapper: {
    flexGrow: 1,
    padding: '0 0 16px',
  },
  detailsWrapper: {
    padding: '0 0 8px',
    minWidth: 'initial',
    maxWidth: 'initial',
    width: '100%',
  },
  thumbnailTypeVideo: {
    width: '100%',
  },
  isLayoutHorizontal: {
    '& $mainWrapper': {
      flexWrap: 'noWrap',
    },
    '& $thumbnailWrapper': {
      padding: '0 16px 0 0',
    },
    '& $avatarWrapper': {
      padding: '0 16px 0 0',
    },
    '& $textWrapper': {
      width: 'initial',
    },
    '& $contentWrapper': {
      padding: '0',
    },
    '& $detailsWrapper': {
      padding: '0 0 0 16px',
      minWidth: '180px',
      maxWidth: '180px',
    },
  },
  isVariantDashboard: {
    '& $mainWrapper': {
      padding: '12px 12px 6px',
    },
    '& $thumbnailWrapper': {
      padding: '0 0 12px',
    },
    '& $thumbnailWrapper > div > div[class*="makeStyles-thumbnailVideo-"]': {
      minWidth: '100%',
    },
    '& $contentWrapper': {
      padding: '0 0 12px',
    },
    '& $contentWrapper h3': {
      fontSize: '14px',
      lineHeight: '16px',
    },
    '& $detailsWrapper': {
      padding: '0 0 4px',
    },
    '&$isLayoutHorizontal $thumbnailWrapper': {
      padding: '0 12px 0 0',
      width: 'initial',
    },
    '&$isLayoutHorizontal $thumbnailWrapper > div > div': {
      minWidth: '104px',
    },
    '&$isLayoutHorizontal $textWrapper': {
      padding: '0',
    },
    '&$isLayoutHorizontal $contentWrapper': {
      padding: '0',
    },
    '&$isLayoutHorizontal $detailsWrapper': {
      padding: '0 0 0 12px',
    },
  },
  isLayoutThumbnailFull: {
    '& $mainWrapper': {
      padding: 0,
    },
    '& $textWrapper': {
      padding: '16px',
    },
    '& $thumbnailWrapper': {
      padding: 0,
      width: '100%',
      '& img': {
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
      },
    },
    '&$isVariantDashboard $textWrapper': {
      padding: '12px',
    },
    '&$isLayoutHorizontal $mainWrapper': {
      padding: '16px',
    },
    '&$isLayoutHorizontal $textWrapper': {
      padding: '0',
    },
    '&$isLayoutHorizontal $thumbnailWrapper': {
      padding: '0 12px 0 0',
      width: 'initial',
      '& img': {
        borderBottomRightRadius: 'initial',
        borderBottomLeftRadius: 'initial',
      },
    },
  },
})
export type Tag =
  | {
      text: string
      variant?: LozengeProps['variant']
      color?: LozengeProps['color']
    }
  | undefined

export type ByLine = {
  authors?: {
    author: string
    url?: string
  }[]
  pubDate?: string
}

export interface PropsType {
  /** Card type - one of 'default' | 'dashboard' | 'search' */
  type?: 'default' | 'dashboard' | 'search'
  /** Card layout - one of 'vertical' | 'horizontal' */
  layout?: 'vertical' | 'horizontal' | 'auto'
  /** Auto layout width - number */
  autoLayoutWidth?: number
  isLoading?: boolean
  /** Thumbnail image */
  thumbnail: {
    /** Type */
    type?: 'publication' | 'video' | 'square' | 'circle'
    /** Image Source */
    src?: string
    /** Alt text */
    alt?: string
    /** Image ratio */
    ratio?: ThumbnailProps['ratio']
    /** True if thumbnail should be full width and above the card. False if it should be contained within the card */
    fullWidth?: boolean
    /** Small size of type */
    smallerSize?: boolean
    /** Floating left-aligned label over image */
    label?: string
    /** Floating Timestamp */
    timestamp?: string
    /** Floating icon */
    icon?: IconProps['glyph']
  }
  /** Avatar - Takes over thumbnail (if active) */
  avatar?: {
    size?: number
    src?: string
    alt?: string
    bgColor?: string
    textAs?: ReactElement | string
  }
  /** Title/Content Info - title/subtitle/description/byline */
  content: {
    position?: number
    index?: string
    queryId?: string
    objectId?: string
    searchQuery?: string
    source?: string
    title: string | ReactElement
    name?: string
    titleUrl?: string
    titleTruncate?: boolean
    dateAlert?: string | ReactElement
    subTitle?: string | ReactElement
    subTitleTruncate?: boolean
    description?: string | ReactElement
    byLine?: ByLine
    sponsors?: SponsorItem[]
  }
  /** Details/Info Lines & Bullets - location/date/extra */
  details: {
    detailListTitle?: string
    detailList?: {
      icon?: IconProps['glyph']
      label: string | ReactElement
    }[]
    viewAllLink?: {
      callback?: () => void
      label: string
    }
    sponsors?: SponsorItem[]
  }
  /** Footer/Chin - content type/tag/bookmark */
  footer?: {
    removeBG?: boolean
    communityContent?: boolean
    contentType?: string
    tag?: Tag
    bookmark?: {
      isBookmarkable?: boolean
      value: boolean
      callback?: (content: any) => Promise<void>
    }
  }
}

const Card = ({
  type,
  layout,
  autoLayoutWidth,
  thumbnail,
  avatar,
  content,
  details,
  footer,
  isLoading = false,
}: PropsType): ReactElement => {
  /*******************************
   * STATE
   ********************************/

  const [watchImageWidth, setWatchImageWidth] = useState(false)
  const [switchToHorizontal, setSwitchToHorizontal] = useState(false)
  const [shouldWrap, setShouldWrap] = useState(false)
  const [cardLayout, setCardLayout] = useState(layout)

  /*******************************
   * HOOKS
   ********************************/

  const classes = useStyles()
  const loaderClasses = useLoaderStyles()

  const { ref } = useResizeObserver<HTMLDivElement>({
    onResize: ({ width }) => {
      if (width) {
        window.requestAnimationFrame(() => {
          setCardLayout(width < 320 ? 'vertical' : layout)
          setWatchImageWidth(width < 624 && width > (autoLayoutWidth || 480))
          setShouldWrap(width < 500)
          setSwitchToHorizontal(width > (autoLayoutWidth || 480))
        })
      }
    },
  })

  let isShrinkImage = false
  let isAutoLayoutHorizontal = false
  if (watchImageWidth) isShrinkImage = true
  if (switchToHorizontal || cardLayout === 'horizontal') {
    isAutoLayoutHorizontal = true
  }

  // handles truncation of lines for title and subtitle text
  // rules are based on design system
  let titleTruncateLines = 0
  let subTitleTruncateLines = 0
  if (content.titleTruncate) {
    if (switchToHorizontal || cardLayout === 'horizontal') {
      titleTruncateLines = 2
      if (!content.description) titleTruncateLines = 4
    } else {
      titleTruncateLines = 3
      if (!content.description) titleTruncateLines = 5
      if (type === 'dashboard') titleTruncateLines = 2
    }
  }
  if (content.subTitleTruncate) {
    if (switchToHorizontal || cardLayout === 'horizontal') {
      subTitleTruncateLines = 2
      if (type === 'search') subTitleTruncateLines = 2
    } else {
      subTitleTruncateLines = 4
      if (type === 'dashboard') subTitleTruncateLines = 2
      if (type === 'search') subTitleTruncateLines = 3
    }
  }

  const { data } = useQuery<GetUserQuery, GetUserQueryVariables>(GET_USER, {
    fetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
  })
  /*******************************
   * RENDER
   ********************************/
  return (
    <Wrap
      ref={ref}
      className={clsx({
        [classes.relative]: true,
        [classes.isVariantDashboard]: type === 'dashboard',
        [classes.isLayoutHorizontal]: isAutoLayoutHorizontal,
        [classes.isLayoutThumbnailFull]:
          !isAutoLayoutHorizontal &&
          thumbnail?.fullWidth &&
          thumbnail?.type === 'video',
      })}
    >
      {isLoading && (
        <div className={loaderClasses.loader}>
          <Loader />
        </div>
      )}
      {/* Thumbnail / Title & Content / Info Lines & Bullets */}
      <Grid
        container
        wrap={shouldWrap ? 'wrap' : 'nowrap'}
        className={clsx(classes.mainWrapper, {
          [classes.opacity]: isLoading,
        })}
      >
        {/* Avatar */}
        {avatar && (
          <Grid item className={classes.avatarWrapper}>
            <Avatar
              size={avatar.size || 80}
              imgSrc={avatar.src}
              imgAlt={avatar.alt}
              bgColor={avatar.bgColor}
            >
              {avatar.textAs && avatar.textAs}
            </Avatar>
          </Grid>
        )}

        {/* Thumbnail */}
        {!avatar && thumbnail && thumbnail?.src && (
          <Grid
            item
            className={clsx(classes.thumbnailWrapper, {
              [classes.thumbnailTypeVideo]:
                thumbnail?.type === 'video' && !isAutoLayoutHorizontal,
            })}
          >
            <Thumbnail
              alt={thumbnail?.alt}
              type={thumbnail?.type}
              src={thumbnail?.src}
              label={thumbnail?.label}
              timestamp={thumbnail?.timestamp}
              smallerSize={
                thumbnail?.smallerSize ||
                (isAutoLayoutHorizontal && isShrinkImage)
              }
              ratio={thumbnail?.ratio}
            />
          </Grid>
        )}

        {/* Content/Details */}
        <Grid item className={classes.textWrapper}>
          <Grid container wrap={shouldWrap ? 'wrap' : 'nowrap'}>
            {/* Content */}
            {content && content?.title && (
              <Grid item className={classes.contentWrapper}>
                <Content
                  position={content?.position}
                  name={content?.name}
                  queryId={content?.queryId}
                  objectId={content?.objectId}
                  searchQuery={content?.searchQuery}
                  index={content?.index}
                  title={content?.title}
                  source={content?.source}
                  titleUrl={content?.titleUrl}
                  titleTruncateLines={titleTruncateLines}
                  dateAlert={content?.dateAlert}
                  subTitle={content?.subTitle}
                  subTitleTruncateLines={subTitleTruncateLines}
                  description={content?.description}
                  byLine={content?.byLine}
                  sponsors={content?.sponsors}
                />
              </Grid>
            )}

            {/* Details */}
            {(details?.detailListTitle ||
              (details?.detailList?.length !== 0 &&
                Object.keys(details).length !== 0)) && (
              <Grid item className={classes.detailsWrapper}>
                <Details
                  detailListTitle={details?.detailListTitle}
                  detailList={details?.detailList}
                  viewAllLink={details?.viewAllLink}
                  sponsors={details?.sponsors}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>

      {/* Footer */}
      <FooterBar
        horizontal={switchToHorizontal || cardLayout === 'horizontal'}
        footer={{
          removeBG: footer?.removeBG || type === 'dashboard',
          communityContent: footer?.communityContent,
          contentType: footer?.contentType,
          tag: footer?.tag,
          bookmark: data?.user?.id !== undefined ? footer?.bookmark : undefined,
        }}
      />
    </Wrap>
  )
}

Card.defaultProps = {
  type: 'default',
  thumbnail: {
    fullWidth: false,
  },
}

export default Card
