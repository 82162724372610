import React, { ReactElement } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@bit/atd.web.button'
import Link from '@bit/atd.web.header-link'
import { MediaLinkType } from '../../../../types'
import MediaLink from '@bit/atd.web.cta.media-link'
import { MenuItem } from '../../../../../../../lib/graphql/graphqlTypes'

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    '& ul': {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  descriptionText: {
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '15px',
  },
  superFlair: {
    fontWeight: 'bold',
    fontSize: '11px',
    textTransform: 'uppercase',
    marginTop: '-2px',
    marginLeft: '2px',
    position: 'absolute',
  },
  group: {
    marginRight: '48px',
    marginBottom: '32px',
    maxWidth: '250px',
  },
  linkWrap: {
    marginBottom: '16px',
  },
})

type PropsType = {
  data?: MenuItem
  key: number
}

const RenderLink = (linkItem: MenuItem, key: number) => {
  const classes = useStyles()

  switch (linkItem.type) {
    case 'MEDIA':
      const media = linkItem as MediaLinkType
      return (
        <ul>
          <li key={key} className={classes.linkWrap}>
            <MediaLink
              key={key}
              url={media.url || undefined}
              buttonText={media.buttonText}
              title={media.title || undefined}
              imageUrl={media.imageUrl}
              buttonURL={media.buttonUrl}
            />
          </li>
        </ul>
      )
    case 'BUTTON':
      return (
        <Button
          href={linkItem.url || undefined}
          variant="secondary"
          size="small"
        >
          {linkItem.title || undefined}
        </Button>
      )
    case 'DEFAULT':
    default:
      return (
        <>
          <div className={classes.descriptionText}>{linkItem.description}</div>
          <Link href={linkItem.url || ''}>
            <>
              {linkItem.title}{' '}
              <span className={classes.superFlair}>{linkItem.flair}</span>
            </>
          </Link>
        </>
      )
  }
}

const SecondaryLinks = ({ data, key }: PropsType): ReactElement => {
  if (data) {
    return RenderLink(data, key)
  }
  return <></>
}

export default SecondaryLinks
