import { useState } from 'react'
import ShowMore, { ShowMoreType } from 'modules/common/components/ShowMore'

type PropTypes = {
  showMore?: boolean
  showMoreLimit: number
  items: any[]
  limit: number
}

const useShowMore = ({ showMore, showMoreLimit, items, limit }: PropTypes) => {
  const [isExtended, setIsExtended] = useState(false)

  const maxItemsCount =
    items.length > showMoreLimit ? showMoreLimit : items.length
  const amountShowMoreItems = items.length > limit ? maxItemsCount - limit : 0
  const isHiddenShowMore = !showMore || limit >= items.length
  const visibleItems = items.slice(0, isExtended ? maxItemsCount : limit)

  const showMoreProps: ShowMoreType = {
    isHiddenShowMore,
    isExtended,
    onClick: () => setIsExtended(!isExtended),
    amountShowMoreItems,
  }

  return {
    ShowMore,
    showMoreProps,
    visibleItems,
  }
}

export default useShowMore
