import { useHistory } from 'react-router-dom'
import { useEffect } from 'react'

const History = () => {
  analytics.ready(() => {
    analytics.page()
  })

  const history = useHistory()

  useEffect(() => {
    let prevPath = ''
    history.listen((location) => {
      if (location.pathname !== prevPath) {
        prevPath = location.pathname
        window.analytics.page()
      }
    })
  }, [history])
  return <></>
}

export default History
