// @react
import React, { ReactNode } from 'react'
import { useState } from 'react'
// @common
import SnackbarComponent, { SnackbarTypes } from '@bit/atd.web.snackbar'
import { SnackbarContext } from 'modules/common/context/snackbar'
import { SnackbarMessageType } from 'modules/common/context/snackbar'

type PropTypes = {
  children: ReactNode
}

const SnackbarProvider = (props: PropTypes) => {
  const { children } = props
  const snackBarDefaults = {
    message: '',
    icon: undefined,
    type: SnackbarTypes.SUCCESS,
    show: false,
    anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
  }

  const [snackbarData, setSnackbarData] = useState<SnackbarMessageType>(
    snackBarDefaults,
  )

  return (
    <SnackbarContext.Provider
      value={{
        setMessage: setSnackbarData,
      }}
    >
      <SnackbarComponent
        anchorOrigin={{ vertical: 'top' }}
        icon={snackbarData.icon}
        message={snackbarData.message}
        onClose={() => {
          setSnackbarData(snackBarDefaults)
        }}
        open={snackbarData.show}
        type={snackbarData.type}
      />
      {children}
    </SnackbarContext.Provider>
  )
}

export default SnackbarProvider
