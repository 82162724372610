import React from 'react'
import ButtonDropdown from '@bit/atd.web.button-dropdown'
import Button from '@bit/atd.web.button'
import { translatable } from 'react-instantsearch-core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  simple: {
    '&:hover': {
      background: 'none',
    },
  },
}))

export type ShowMoreType = {
  isHiddenShowMore: boolean
  isExtended: boolean
  onClick: () => void
  amountShowMoreItems: number
  label?: string
  variant?: 'default' | 'simple'
  classes?: string
}

type PropTypes = ShowMoreType & {
  translate: (key: string, ...params: any[]) => string
}

const ShowMore = (props: PropTypes) => {
  /************************
   * PROPS
   *************************/
  const {
    isHiddenShowMore,
    isExtended,
    onClick,
    amountShowMoreItems,
    translate,
    label,
    variant = 'default',
    classes,
  } = props

  const styles = useStyles()

  const btnProps = {
    size: 'medium',
    noBorder: true,
    open: isExtended,
    onClick,
    className: classes,
  }

  const renderDropDownBtn = (btnLabel: any) => (
    <ButtonDropdown {...btnProps} size="medium">
      {btnLabel}
    </ButtonDropdown>
  )

  const renderSimpleBtn = (btnLabel: any) => (
    <Button
      {...btnProps}
      size="medium"
      variant="tertiary"
      className={`${styles.simple} ${classes}`}
    >
      {btnLabel}
    </Button>
  )

  const standardLabel = (
    <>
      {translate('showMore', isExtended)}
      {amountShowMoreItems && !isExtended && ` (${amountShowMoreItems})`}
    </>
  )

  const btnLabel = isExtended ? standardLabel : label || standardLabel

  const renderBtn = () => {
    let btn
    switch (variant) {
      case 'simple':
        btn = renderSimpleBtn(btnLabel)
        break
      default:
        btn = renderDropDownBtn(btnLabel)
    }

    return btn
  }

  return <>{!isHiddenShowMore && renderBtn()}</>
}

export default translatable({
  showMore: (extended) => (extended ? 'Show less' : 'Show more'),
  // @ts-ignore
})(ShowMore)
