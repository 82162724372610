export const contentLabels = [
  {
    key: 'Case Study',
    label: 'Case Studies',
  },
  {
    key: 'TD Magazine',
    label: 'TD Magazines',
  },
  {
    key: 'Whitepaper',
    label: 'White Papers',
  },
  {
    key: 'Video',
    label: 'Videos',
  },
  {
    key: 'TD at Work Guide',
    label: 'TD at Work Guides',
  },
  {
    key: 'Templates and Tools',
    label: 'Templates and Tools',
  },
  {
    key: 'Newsletter Article',
    label: 'Newsletter Articles',
  },
  {
    key: 'TD Magazine Article',
    label: 'TD Magazine Articles',
  },
  {
    key: 'Webinar',
    label: 'Webinars',
  },
  {
    key: 'Course',
    label: 'Courses',
  },
  {
    key: 'Performance Resource',
    label: 'Performance Resources',
  },
]
