// @react
import React from 'react'
// @types
import type { ReactElement } from 'react'
import type {
  GetUserQuery,
  GetUserQueryVariables,
} from 'lib/graphql/graphqlTypes'
// @graphql
import { useQuery } from '@apollo/client'
import { GET_USER_ID } from 'graphql/queries/user.query'
// @libraries
import { matchPath, useLocation } from 'react-router-dom'
// @components
import Footer from 'modules/global/Footer'

const FooterWrap = (): ReactElement => {
  /********************************************
   *  HOOKS
   *********************************************/

  const location = useLocation()
  const isOnboarding = !!matchPath(location.pathname, {
    path: '/onboarding',
    exact: false,
    strict: false,
  })

  /********************************************
   *  GQL HOOKS
   *********************************************/

  const { data: userData } = useQuery<GetUserQuery, GetUserQueryVariables>(
    GET_USER_ID,
  )

  /********************************************
   *  RENDER
   *********************************************/

  return !isOnboarding ? <Footer isLoggedIn={!!userData?.user} /> : <></>
}

export default FooterWrap
