import {
  CreateBookmarkMutation,
  CreateBookmarkMutationVariables,
  DeleteBookmarkMutation,
  DeleteBookmarkMutationVariables,
} from 'lib/graphql/graphqlTypes'
import { useMutation } from '@apollo/client'
import {
  CREATE_BOOKMARK,
  DELETE_BOOKMARK,
} from 'graphql/mutations/userBookmark.mutation'

/**
 *
 */
const useBookmark = () => {
  const [deleteBookmark] = useMutation<
    DeleteBookmarkMutation,
    DeleteBookmarkMutationVariables
  >(DELETE_BOOKMARK)

  const [createBookmark] = useMutation<
    CreateBookmarkMutation,
    CreateBookmarkMutationVariables
  >(CREATE_BOOKMARK)

  /**
   *
   * @param bookmarkId
   * @param isBookmarked
   */
  const toggleBookmarkAction = async (
    bookmarkId: string,
    isBookmarked: boolean,
  ) => {
    const bookmarkMutation = {
      variables: {
        input: {
          bookmarkId,
        },
      },
    }
    const { errors } = isBookmarked
      ? await deleteBookmark(bookmarkMutation)
      : await createBookmark(bookmarkMutation)

    return errors
  }

  return {
    toggleBookmarkAction,
  }
}

export default useBookmark
