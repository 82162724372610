import ReactGA from 'react-ga'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { GetUserQuery, GetUserQueryVariables } from 'lib/graphql/graphqlTypes'
import { GET_USER_DINAMIC_VALUES } from 'graphql/queries/user.query'

const DIMENSIONS = {
  USER_ID: 'dimension6',
  IS_MEMBER: 'dimension4',
  // TRACKING_VERSION: 'dimension10',
  // CLIENT_ID: 'dimension11',
  // WINDOW_ID: 'dimension12',
  // PUBLISH_DATE: 'dimension13',
  // HIT_TIME: 'dimension14',
  // HIT_TYPE: 'dimension15',
  // HIT_SOURCE: 'dimension16',
  // VISIBILITY_STATE: 'dimension17',
  // URL_QUERY_PARAMS: 'dimension9',
  // PAGE_ID: 'dimension7',
}

export const googleAnalyticsInit = () => {
  ReactGA.initialize(process.env.REACT_APP_GA_ID as string, {
    gaOptions: {
      allowLinker: true,
      cookieDomain: 'auto',
    },
  })
}

const GoogleAnalytics = () => {
  const location = useLocation()

  const { data } = useQuery<GetUserQuery, GetUserQueryVariables>(
    GET_USER_DINAMIC_VALUES,
    {
      fetchPolicy: 'cache-first',
    },
  )

  useEffect(() => {
    if (data) {
      const DYNAMIC_VARIABLES = {
        USER_ID: data.user?.id,
        IS_MEMBER: data.user?.membership.isMember,
        IS_CONTRIBUTOR: data.user?.isContributor,
      }

      // User ID GA field and custom dimension
      if (DYNAMIC_VARIABLES && DYNAMIC_VARIABLES.USER_ID) {
        ReactGA.ga('set', 'userId', DYNAMIC_VARIABLES.USER_ID)
        ReactGA.ga('set', DIMENSIONS.USER_ID, DYNAMIC_VARIABLES.USER_ID)
      }

      // Is the customer a member
      if (DYNAMIC_VARIABLES && DYNAMIC_VARIABLES.IS_MEMBER) {
        ReactGA.ga('set', DIMENSIONS.IS_MEMBER, DYNAMIC_VARIABLES.IS_MEMBER)
      }
    }
  }, [data])

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [location])

  return <></>
}

export default GoogleAnalytics
