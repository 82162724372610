import React from 'react'

import { PropsType as HeaderProps } from './index'

const HeaderDataContext = React.createContext<Partial<HeaderProps>>({
  data: [],
  condensed: false,
  isLoggedIn: false,
  isNewSearch: false,
  isLoginInProcess: false,
  isUserLoading: false,
})

export default HeaderDataContext
