import { gql } from '@apollo/client'

export const TAXONOMY_LEVELS = gql`
  fragment taxonomyLevels on TaxonomySearchItem {
    lvl0
    lvl1
    lvl2
  }
`

export const ABSTRACT_SEARCH_ITEM = gql`
  fragment IAbstractSearchItem on IAbstractSearchItem {
    id
    title
    publishDate
    updateDate
    description
    bodyText
    imageUrl
    url
    viewCount
    contentTypes {
      ...taxonomyLevels
    }
    topics
    primaryTopic
    topicCodes
    industryCodes
    roleCodes
    contentTypesArray
    industries
    roles
    keywords
    authors {
      ... on UserSearchItem {
        firstName
        lastName
      }
    }
    featured
    memberBenefit
    isBookmarkable
    _snippetResult {
      description {
        value
      }
    }
  }
  ${TAXONOMY_LEVELS}
`

export const CONTENT_SEARCH_ITEM = gql`
  fragment IContentSearchItem on IContentSearchItem {
    periodical
    issue
    column
    part
  }
`
