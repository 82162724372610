import { useLayoutEffect } from 'react'

const UseBodyScrollLock = (enable = true) => {
  useLayoutEffect(() => {
    // Get original body overflow
    if (enable) {
      const originalStyle = window.getComputedStyle(document.body).overflow
      // Prevent scrolling on mount
      document.body.style.overflow = 'hidden'
      // Re-enable scrolling when component unmounts
      return () => {
        document.body.style.overflow = originalStyle
      }
    }
  }, [enable]) // Empty array ensures effect is only run on mount and unmount
}

export default UseBodyScrollLock
