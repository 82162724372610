import { gql } from '@apollo/client'

export const CONSTANTS_NODE = gql`
  fragment ConstantsNode on ConstantsPayload {
    topics {
      id
      label
      key
      url
      userStatus {
        followed
        recommended
      }
    }
    industryCodes {
      key
      value
    }
    jobRoleCodes {
      key
      value
    }
    companySizeCodes {
      key
      value
    }
    directReportCountCodes {
      key
      value
    }
    budgetSizeCodes {
      key
      value
    }
    #specialNeeds
    allDemographicCodes {
      key
      value
    }
    programDegreeCodes {
      key
      value
    }
    programTypeCodes {
      key
      value
    }
    gdprCountryCodes
  }
`
