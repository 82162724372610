// @types
import type { ReactElement } from 'react'
import type PropsType from './_types'
// @libraries
import clsx from 'clsx'
// @components
import Container from '@bit/atd.web.container'
import TextLink from '@bit/atd.web.text-link'
// @styles
import useStyles from './_styles'

const ImageText = ({
  className,
  sections,
  isReverse,
  isOnLightBG,
}: PropsType): ReactElement => {
  /******************************************
   * HOOKS
   ******************************************/

  const classes = useStyles()

  /******************************************
   * RENDER
   ******************************************/

  return (
    <Container
      className={clsx(
        {
          [classes.isReverse]: isReverse,
        },
        className && className,
      )}
    >
      {sections.map((section, sectionIndex) => {
        let showCard
        if (
          section?.content?.text ||
          (section?.content?.button?.href && section?.content?.button?.label)
        ) {
          showCard = true
        }

        return (
          <div key={`sectionKey__${sectionIndex}`} className={classes.section}>
            <div className={classes.image}>
              <picture>
                <img
                  src={section?.image?.src || ''}
                  alt={section?.image?.alt || ''}
                />
              </picture>
            </div>
            {showCard && (
              <div className={classes.card}>
                {section?.content?.text && (
                  <div className={classes.text}>{section.content.text}</div>
                )}
                {section?.content?.button?.href &&
                  section?.content?.button?.label && (
                    <TextLink
                      variant={'standalone'}
                      size={'large'}
                      href={section.content.button.href}
                    >
                      {section.content.button.label}
                    </TextLink>
                  )}
              </div>
            )}
          </div>
        )
      })}
    </Container>
  )
}

ImageText.defaultProps = {}

export default ImageText
