// @react
import React, { ReactElement, MouseEvent } from 'react'
// @libraries
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
// @components
import Icon from '@bit/atd.web.icons'
import colors from '@bit/atd.web.colors'
import Button from '@bit/atd.web.button'

const useStyles = makeStyles({
  root: {
    display: 'inline-flex',
    verticalAlign: 'middle',
    alignItems: 'center',
    cursor: 'pointer',
    transition: 'color 0.3s ease',
  },
  big: {
    '& $label': {
      fontSize: '20px',
      lineHeight: '24px',
      marginRight: '8px',
    },
  },
  small: {
    '& $label': {
      fontSize: '14px',
      lineHeight: '16px',
      fontWeight: 500,
    },
  },
  glyph: {
    transition: 'all 0.3s ease-out',
  },
  active: {
    '& $glyph': {
      transform: 'rotate(-180deg)',
    },
  },
  label: {
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '18px',
  },
})

type PropsType = {
  big?: boolean
  small?: boolean
  children: ReactElement | string
  className?: string
  onClick?: (e?: MouseEvent) => void
  isMegaMenuVisible: boolean
}

const MegaMenuLink = ({
  children,
  big,
  small,
  className,
  onClick,
  isMegaMenuVisible,
}: PropsType): ReactElement => {
  /********************************
   * HOOKS
   ********************************/

  const classes = useStyles()

  /********************************
   * RENDER
   ********************************/

  return (
    <>
      <Button
        variant={'secondaryTextLink'}
        className={clsx({
          [classes.root]: true,
          [classes.big]: big,
          [classes.small]: small,
          [classes.active]: className === 'active',
          [className || '']: className,
        })}
        onClick={onClick}
        aria-expanded={isMegaMenuVisible}
      >
        <span className={classes.label}>{children}</span>
        <Icon
          className={classes.glyph}
          glyph={big ? 'caret-down' : 'caret-down-small'}
          color={colors.ctaPrimary}
          size={24}
        />
      </Button>
    </>
  )
}

export default MegaMenuLink
