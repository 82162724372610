import { lazy } from 'react'
import { RouteNamesInternal } from 'constants/routeNamesInternal'
// import OnBoarding from './pages/OnBoarding'
const OnBoarding = lazy(() => import('./pages/OnBoarding'))

//@todo: added other inner pages to routes
const OnBoardingRoutes = {
  routes: [
    {
      key: 'ONBOARDING_STEP',
      title: 'OnBoarding',
      type: 'public',
      path: `/${RouteNamesInternal.ONBOARDING}/step/:id`,
      component: OnBoarding,
      exact: true,
    },
    {
      key: 'ONBOARDING',
      title: 'OnBoarding',
      type: 'public',
      path: `/${RouteNamesInternal.ONBOARDING}`,
      component: OnBoarding,
      exact: true,
    },
  ],
}

export default OnBoardingRoutes
