import { lazy } from 'react'
import { RouteNamesInternal } from 'constants/routeNamesInternal'
// import BenefitsCenter from './pages/BenefitsCenter'
import { userTypes } from 'modules/user'

const BenefitsCenter = lazy(() => import('./pages/BenefitsCenter'))
const routes = {
  routes: [
    {
      key: 'MYATD_BENEFITS_CENTER',
      type: 'private',
      allowedUserTypes: [userTypes.MEMBER],
      title: 'MyATD - Benefits Center',
      path: `/${RouteNamesInternal.MYATD}/${RouteNamesInternal.BENEFITS_CENTER}`,
      component: BenefitsCenter,
      exact: true,
    },
  ],
}

export default routes
