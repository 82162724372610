import { gql } from '@apollo/client'

export const LP_SEO = gql`
  fragment LpSeo on LandingPages {
    seo_fields {
      meta_description
      meta_title
      no_follow
      no_index
      meta_imageConnection {
        edges {
          node {
            title
            url
            content_type
            filename
            file_size
            description
          }
        }
      }
    }
  }
`

export const BLOCK_CONTENT_HEADER = gql`
  fragment BlockContentHeader on LandingPagesPageSectionSectionBlockBlockContentHeader {
    __typename
    header {
      title
      imageConnection {
        edges {
          node {
            title
            url
            content_type
            filename
            file_size
            description
          }
        }
      }
      subtitle
      button {
        title
        href
      }
    }
  }
`

export const BLOCK_CONTENT_STEPS = gql`
  fragment BlockContentSteps on LandingPagesPageSectionSectionBlockBlockContentStepsModule {
    __typename
    steps_module {
      button {
        title
        href
      }
      step {
        text
      }
    }
  }
`

export const BLOCK_CONTENT_FEATURED = gql`
  fragment BlockContentFeatured on LandingPagesPageSectionSectionBlockBlockContentFeaturedModule {
    __typename
    featured_module {
      subtitle
      title
      theme
      content {
        ... on LandingPagesPageSectionSectionBlockBlockContentFeaturedModuleBlockContentRelationship {
          __typename
          relationship {
            title
            subtitle
            featured_items
          }
        }
        ... on LandingPagesPageSectionSectionBlockBlockContentFeaturedModuleBlockContentQuery {
          __typename
          query {
            title
            subtitle
            taxonomy
            topic
            industry
            role
            keyword
            order_by
            order
          }
        }
      }
    }
  }
`

export const BLOCK_CONTENT_IMAGE_CTA = gql`
  fragment BlockContentImageCta on LandingPagesPageSectionSectionBlockBlockContentImageCtaModule {
    __typename
    image_cta_module {
      title
      image_position
      eyebrow
      button {
        title
        href
      }
      imageConnection {
        totalCount
        edges {
          node {
            title
            url
            content_type
            filename
            file_size
            description
          }
        }
        totalCount
      }
    }
  }
`

export const BLOCK_CONTENT_COLUMN = gql`
  fragment BlockContentColumn on LandingPagesPageSectionSectionBlockBlockContentColumnModule {
    __typename
    column_module {
      column_item {
        title
        subtitle
        button {
          title
          href
        }
      }
    }
  }
`

export const BLOCK_CONTENT_TWO_COLUMN_HEADER = gql`
  fragment BlockContentTwoColumnHeader on LandingPagesPageSectionSectionBlockBlockContentTwoColumnHeaderModule {
    __typename
    two_column_header_module {
      title
      column_item {
        title
        subtitle
      }
      imageConnection {
        edges {
          node {
            title
            url
            content_type
            filename
            file_size
            description
          }
        }
      }
    }
  }
`

export const BLOCK_CONTENT_IMAGE_AND_TEXT = gql`
  fragment BlockContentImageAndText on LandingPagesPageSectionSectionBlockBlockContentImageAndTextModule {
    __typename
    image_and_text_module {
      image_and_text_item {
        text
        button {
          title
          href
        }
        imageConnection {
          totalCount
          edges {
            node {
              title
              url
              content_type
              filename
              file_size
              description
            }
          }
          totalCount
        }
      }
    }
  }
`

export const BLOCK_CONTENT_SINGLE_TEXT = gql`
  fragment BlockContentSingleText on LandingPagesPageSectionSectionBlockBlockContentSingleTextModule {
    __typename
    single_text_module {
      text
    }
  }
`

export const BLOCK_CONTENT_FORM = gql`
  fragment BlockContentForm on LandingPagesPageSectionSectionBlockBlockContentFormModule {
    __typename
    form_module {
      form_title
    }
  }
`

export const GET_LANDING_PAGE = gql`
  query GetLandingPageByID($uid: String!) {
    landing_pages(uid: $uid) {
      title
      url
      hide_site_header
      ...LpSeo
      page_section {
        ... on LandingPagesPageSectionSectionBlock {
          __typename
          section_block {
            section_id
            section_theme
            section_title
            content {
              ... on LandingPagesPageSectionSectionBlockBlockContentHeader {
                ...BlockContentHeader
              }
              ... on LandingPagesPageSectionSectionBlockBlockContentStepsModule {
                ...BlockContentSteps
              }
              ... on LandingPagesPageSectionSectionBlockBlockContentFeaturedModule {
                ...BlockContentFeatured
              }
              ... on LandingPagesPageSectionSectionBlockBlockContentImageCtaModule {
                ...BlockContentImageCta
              }
              ... on LandingPagesPageSectionSectionBlockBlockContentColumnModule {
                ...BlockContentColumn
              }
              ... on LandingPagesPageSectionSectionBlockBlockContentTwoColumnHeaderModule {
                ...BlockContentTwoColumnHeader
              }
              ... on LandingPagesPageSectionSectionBlockBlockContentImageAndTextModule {
                ...BlockContentImageAndText
              }
              ... on LandingPagesPageSectionSectionBlockBlockContentSingleTextModule {
                ...BlockContentSingleText
              }
              ... on LandingPagesPageSectionSectionBlockBlockContentFormModule {
                ...BlockContentForm
              }
            }
          }
        }
      }
    }
  }
  ${LP_SEO}
  ${BLOCK_CONTENT_HEADER}
  ${BLOCK_CONTENT_STEPS}
  ${BLOCK_CONTENT_FEATURED}
  ${BLOCK_CONTENT_COLUMN}
  ${BLOCK_CONTENT_IMAGE_CTA}
  ${BLOCK_CONTENT_TWO_COLUMN_HEADER}
  ${BLOCK_CONTENT_IMAGE_AND_TEXT}
  ${BLOCK_CONTENT_SINGLE_TEXT}
  ${BLOCK_CONTENT_FORM}
`

export const GET_LANDING_PAGE_BY_URL = gql`
  query GetLandingPagesByUrl($url: String!) {
    all_landing_pages(where: { url: $url }) {
      total
      items {
        title
        url
        hide_site_header
        ...LpSeo
        page_section {
          ... on LandingPagesPageSectionSectionBlock {
            __typename
            section_block {
              section_id
              section_theme
              section_title
              content {
                ... on LandingPagesPageSectionSectionBlockBlockContentHeader {
                  ...BlockContentHeader
                }
                ... on LandingPagesPageSectionSectionBlockBlockContentStepsModule {
                  ...BlockContentSteps
                }
                ... on LandingPagesPageSectionSectionBlockBlockContentFeaturedModule {
                  ...BlockContentFeatured
                }
                ... on LandingPagesPageSectionSectionBlockBlockContentImageCtaModule {
                  ...BlockContentImageCta
                }
                ... on LandingPagesPageSectionSectionBlockBlockContentColumnModule {
                  ...BlockContentColumn
                }
                ... on LandingPagesPageSectionSectionBlockBlockContentTwoColumnHeaderModule {
                  ...BlockContentTwoColumnHeader
                }
                ... on LandingPagesPageSectionSectionBlockBlockContentImageAndTextModule {
                  ...BlockContentImageAndText
                }
                ... on LandingPagesPageSectionSectionBlockBlockContentSingleTextModule {
                  ...BlockContentSingleText
                }
                ... on LandingPagesPageSectionSectionBlockBlockContentFormModule {
                  ...BlockContentForm
                }
              }
            }
          }
        }
      }
    }
  }

  ${LP_SEO}
  ${BLOCK_CONTENT_HEADER}
  ${BLOCK_CONTENT_STEPS}
  ${BLOCK_CONTENT_FEATURED}
  ${BLOCK_CONTENT_COLUMN}
  ${BLOCK_CONTENT_IMAGE_CTA}
  ${BLOCK_CONTENT_TWO_COLUMN_HEADER}
  ${BLOCK_CONTENT_IMAGE_AND_TEXT}
  ${BLOCK_CONTENT_SINGLE_TEXT}
  ${BLOCK_CONTENT_FORM}
`
