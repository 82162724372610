// @react
import React, { useContext, useEffect, useRef, useState } from 'react'
// @libraries
import {
  Control,
  Controller,
  UseFormMethods,
  FieldErrors,
} from 'react-hook-form'
// @components
import Grid from '@bit/atd.web.grid'
import InputCheckbox from '@bit/atd.web.forms.input-checkbox'
import TextLink from '@bit/atd.web.text-link'
// @module components
import { RouteNamesExternal } from 'constants/routeNamesExternal'
import { LandingPageFormValues } from 'modules/pageBuilder/types/landingPageForm'

type PropsType = {
  formMethods: UseFormMethods<LandingPageFormValues>
  control: Control<LandingPageFormValues>
  errors: FieldErrors
}

const LandingPageAcceptTermsFields = ({
  formMethods,
  control,
  errors,
}: PropsType) => {
  /******************************************
   * REFS
   ******************************************/

  const termsRef = useRef<HTMLInputElement | null>(null)
  const emailConsentRef = useRef<HTMLInputElement | null>(null)
  const thirdPartyConsentRef = useRef<HTMLInputElement | null>(null)

  const [countryChosen, setCountryChosen] = useState(false)
  const countryCode = formMethods.watch('countryCode')
  useEffect(() => {
    if (countryCode !== '') {
      setCountryChosen(true)
    } else {
      setCountryChosen(false)
    }
  })
  /******************************************
   * RENDER
   ******************************************/

  const emailConsentCanada = (
    <Grid item xs={12}>
      <Controller
        control={control}
        name="acceptEmailConsent"
        onFocus={() => emailConsentRef?.current?.focus()}
        render={({ onBlur, value, name }) => (
          <>
            <InputCheckbox
              name={name}
              label={
                <>
                  I consent to receive communications by email and/or postal
                  mail from ATD about its products and services. I understand
                  that I can withdraw this consent at any time, by contacting
                  ATD via postal mail at 1640 King Street, Alexandria, VA 22314
                  or by email at{' '}
                  <TextLink
                    href={RouteNamesExternal.ATD_MAIL_CUSTOMER_CARE}
                    target="_blank"
                    inline
                  >
                    customercare@td.org
                  </TextLink>
                  . Please see ATD's{' '}
                  <TextLink
                    href={RouteNamesExternal.ATD_TOS_PRIVACY}
                    target="_blank"
                    inline
                  >
                    Privacy Notice
                  </TextLink>{' '}
                  for more information.
                </>
              }
              labelPlacement="right"
              size="small"
              checked={value as boolean}
              inputRef={emailConsentRef}
              onBlur={onBlur}
              onChange={() =>
                formMethods.setValue('acceptEmailConsent', !value, {
                  shouldValidate: false,
                  shouldDirty: true,
                })
              }
              error={!!errors?.acceptEmailConsent}
              errorText={errors?.acceptEmailConsent?.message}
            />
          </>
        )}
      />
    </Grid>
  )

  const thirdPartyConsentCanada = (
    <Grid item xs={12}>
      <Controller
        control={control}
        name="acceptThirdPartyConsent"
        onFocus={() => thirdPartyConsentRef?.current?.focus()}
        render={({ onBlur, value, name }) => (
          <>
            <InputCheckbox
              name={name}
              label={
                <>
                  I consent to receive communications by email and/or postal
                  mail from ATD's exhibitors and sponsors about content and
                  offerings that may be of interest to me.
                </>
              }
              labelPlacement="right"
              size="small"
              checked={value as boolean}
              inputRef={thirdPartyConsentRef}
              onBlur={onBlur}
              onChange={() =>
                formMethods.setValue('acceptThirdPartyConsent', !value, {
                  shouldValidate: false,
                  shouldDirty: true,
                })
              }
              error={!!errors?.acceptThirdPartyConsent}
              errorText={errors?.acceptThirdPartyConsent?.message}
            />
          </>
        )}
      />
    </Grid>
  )

  const emailConsentSouthAfrica = (
    <Grid item xs={12}>
      <Controller
        control={control}
        name="acceptEmailConsent"
        onFocus={() => emailConsentRef?.current?.focus()}
        render={({ onBlur, value, name }) => (
          <>
            <InputCheckbox
              name={name}
              label={
                <>
                  Click here if you wish to opt-out of receiving electronic
                  marketing communications from ATD about similar products and
                  services they offer. Please see ATD's{' '}
                  <TextLink
                    href={RouteNamesExternal.ATD_TOS_PRIVACY}
                    target="_blank"
                    inline
                  >
                    Privacy Notice
                  </TextLink>{' '}
                  for more information.
                </>
              }
              labelPlacement="right"
              size="small"
              checked={value as boolean}
              inputRef={emailConsentRef}
              onBlur={onBlur}
              onChange={() =>
                formMethods.setValue('acceptEmailConsent', !value, {
                  shouldValidate: false,
                  shouldDirty: true,
                })
              }
              error={!!errors?.acceptEmailConsent}
              errorText={errors?.acceptEmailConsent?.message}
            />
          </>
        )}
      />
    </Grid>
  )

  const emailConsentGeneral = (
    <Grid item xs={12}>
      <Controller
        control={control}
        name="acceptEmailConsent"
        onFocus={() => emailConsentRef?.current?.focus()}
        render={({ onBlur, value, name }) => (
          <>
            <InputCheckbox
              name={name}
              label={
                <>
                  I consent to receive email communications from ATD about its
                  products and services. I understand that I can withdraw this
                  consent at any time, by contacting ATD via postal mail at 1640
                  King Street, Alexandria, VA 22314 or by email at{' '}
                  <TextLink
                    href={RouteNamesExternal.ATD_MAIL_CUSTOMER_CARE}
                    target="_blank"
                    inline
                  >
                    customercare@td.org
                  </TextLink>
                  . Please see ATD's{' '}
                  <TextLink
                    href={RouteNamesExternal.ATD_TOS_PRIVACY}
                    target="_blank"
                    inline
                  >
                    Privacy Notice
                  </TextLink>{' '}
                  for more information.
                </>
              }
              labelPlacement="right"
              size="small"
              checked={value as boolean}
              inputRef={emailConsentRef}
              onBlur={onBlur}
              onChange={() =>
                formMethods.setValue('acceptEmailConsent', !value, {
                  shouldValidate: false,
                  shouldDirty: true,
                })
              }
              error={!!errors?.acceptEmailConsent}
              errorText={errors?.acceptEmailConsent?.message}
            />
          </>
        )}
      />
    </Grid>
  )

  const thirdPartyConsentGeneral = (
    <Grid item xs={12}>
      <Controller
        control={control}
        name="acceptThirdPartyConsent"
        onFocus={() => thirdPartyConsentRef?.current?.focus()}
        render={({ onBlur, value, name }) => (
          <>
            <InputCheckbox
              name={name}
              label={
                <>
                  I consent to receive email communications from ATD's
                  exhibitors and sponsors about content and offerings that may
                  be of interest to me.
                </>
              }
              labelPlacement="right"
              size="small"
              checked={value as boolean}
              inputRef={thirdPartyConsentRef}
              onBlur={onBlur}
              onChange={() =>
                formMethods.setValue('acceptThirdPartyConsent', !value, {
                  shouldValidate: false,
                  shouldDirty: true,
                })
              }
              error={!!errors?.acceptThirdPartyConsent}
              errorText={errors?.acceptThirdPartyConsent?.message}
            />
          </>
        )}
      />
    </Grid>
  )

  return (
    <>
      {countryCode === 'CA' && (
        <Grid item xs={12} spacing={0} container>
          {emailConsentCanada} {thirdPartyConsentCanada}
        </Grid>
      )}

      {countryCode === 'ZA' && (
        <Grid item xs={12} spacing={0} container>
          {emailConsentSouthAfrica}
        </Grid>
      )}

      {countryChosen &&
        !countryCode.includes('CA') &&
        !countryCode.includes('ZA') &&
        !countryCode.includes('US') && (
          <Grid item xs={12} spacing={0} container>
            {emailConsentGeneral} {thirdPartyConsentGeneral}
          </Grid>
        )}

      <Grid item xs={12} spacing={0} container>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="acceptTerms"
            onFocus={() => termsRef?.current?.focus()}
            render={({ onBlur, value, name }) => (
              <>
                <InputCheckbox
                  name={name}
                  label={
                    <>
                      I agree to ATD's{' '}
                      <TextLink
                        href={RouteNamesExternal.ATD_TOS}
                        target="_blank"
                        inline
                      >
                        Terms of Use
                      </TextLink>{' '}
                      and I have reviewed the{' '}
                      <TextLink
                        href={RouteNamesExternal.ATD_TOS_PRIVACY}
                        target="_blank"
                        inline
                      >
                        Privacy Notice
                      </TextLink>
                      .
                    </>
                  }
                  labelPlacement="right"
                  size="small"
                  checked={value as boolean}
                  inputRef={termsRef}
                  onBlur={onBlur}
                  onChange={() =>
                    formMethods.setValue('acceptTerms', !value, {
                      shouldValidate: false,
                      shouldDirty: true,
                    })
                  }
                  error={!!errors?.acceptTerms}
                  errorText={errors?.acceptTerms?.message}
                />
              </>
            )}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default LandingPageAcceptTermsFields
