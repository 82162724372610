// @react
import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/browser'
import * as serviceWorker from './serviceWorker'

import FooterApp from './appFooter'
import HeaderApp from './appHeader'
import App from './app'

if (
  process.env.REACT_APP_HOST_ENV === 'PROD' ||
  process.env.REACT_APP_HOST_ENV === 'QA' ||
  process.env.REACT_APP_HOST_ENV === 'UAT'
) {
  Sentry.init({
    environment: process.env.REACT_APP_HOST_ENV,
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.REACT_APP_VERSION,
  })
}
const is_header_mode = parseInt(process.env.REACT_APP_HEADER_MODE as string)
/****************************************
 * FULL APP MODE
 *****************************************/
if (!is_header_mode) {
  ReactDOM.render(<App />, document.getElementById('root'))
  serviceWorker.register()
}

/******************************************
 * HEADER ONLY MODE
 *****************************************/
if (is_header_mode) {
  const rootHeader = document.getElementById('reactHeader')
  if (rootHeader) {
    ReactDOM.render(<HeaderApp />, document.getElementById('reactHeader'))
  }

  const rootFooter = document.getElementById('reactFooter')
  if (rootFooter) {
    ReactDOM.render(<FooterApp />, document.getElementById('reactFooter'))
  }
}
