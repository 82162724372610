// @types
import type { ReactElement } from 'react'
import type PropsType from './_types'
// @libraries
import clsx from 'clsx'
import Typography from '@material-ui/core/Typography'
// @components
import Container from '@bit/atd.web.container'
import Grid from '@bit/atd.web.grid'
import Button from '@bit/atd.web.button'
// @styles
import useStyles from './_styles'

const PageHeader = ({
  className,
  variant,
  backgroundImage,
  header,
  columns,
}: PropsType): ReactElement => {
  /******************************************
   * PROPS
   ******************************************/

  const isVariantCentered = variant === 'centered'
  const isVariantBottom = variant === 'bottom'
  const columnCount = columns?.length

  /******************************************
   * HOOKS
   ******************************************/

  const classes = useStyles()

  /******************************************
   * RENDER
   ******************************************/

  return (
    <>
      <header
        className={clsx(
          classes.root,
          {
            [classes.isVariantCentered]: isVariantCentered,
            [classes.isVariantBottom]: isVariantBottom,
          },
          className && className,
        )}
      >
        {/* Background */}
        <div
          className={clsx(classes.background, {
            [classes.backgroundLimitHeight]: columnCount && columnCount > 0,
          })}
        >
          {backgroundImage && (
            <>
              <picture>
                <img src={backgroundImage.src} alt={backgroundImage.alt} />
              </picture>
              {isVariantBottom && <div className={classes.overlay} />}
            </>
          )}
        </div>

        {/* Content */}
        <div className={classes.content}>
          <Container>
            <div className={classes.contentInner}>
              {isVariantCentered && header && (
                <div className={classes.card}>
                  {header?.title && (
                    <Typography variant={'h3'} component={'h1'}>
                      {header.title}
                    </Typography>
                  )}
                  {header?.subtitle && (
                    <div className={classes.text}>{header.subtitle}</div>
                  )}
                  {header?.button && (
                    <Button href={header.button.href || ''}>
                      {header.button.label}
                    </Button>
                  )}
                </div>
              )}

              {isVariantBottom && (
                <div
                  className={clsx({
                    [classes.columnCountZero]: columnCount === 0,
                    [classes.columnCountOne]: columnCount === 1,
                    [classes.columnCountTwo]: columnCount === 2,
                  })}
                >
                  {header?.title && (
                    <Typography variant={'h2'} component={'h1'}>
                      {header.title}
                    </Typography>
                  )}
                  {columnCount && columnCount > 0 ? (
                    <div className={clsx(classes.card)}>
                      <Grid container spacing={3}>
                        {columns?.map((column, i) => {
                          const titleVariant = columnCount === 1 ? 'h3' : 'h4'
                          const sm = columnCount === 1 ? 12 : 6
                          return (
                            <Grid key={`columnKey_${i}`} item xs={12} sm={sm}>
                              <Typography
                                variant={titleVariant}
                                component={'h2'}
                              >
                                {column.title}
                              </Typography>
                              <div className={classes.text}>{column.text}</div>
                            </Grid>
                          )
                        })}
                      </Grid>
                    </div>
                  ) : null}
                </div>
              )}
            </div>
          </Container>
        </div>
      </header>
    </>
  )
}

PageHeader.defaultProps = {
  variant: 'centered',
}

export default PageHeader
