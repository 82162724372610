import { ReactElement, useEffect, useState } from 'react'
import Icon from '@bit/atd.web.icons'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  alertContainer: {
    opacity: '0',
    visibility: 'hidden',
    position: 'absolute',
    backgroundColor: 'red',
    width: '17px',
    height: '17px',
    color: 'white',
    textAlign: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    fontSize: '14px',
    left: '65%',
    top: '-10%',
    '& $numb': {},
    '&:before': {
      content: '',
      position: 'absolute',
      width: '100%',
      height: '100%',
      backgroundColor: '#f72918',
      borderRadius: '50%',
      zIndex: '0',
      transform: 'scale(0)',
      animationDuration: '800ms',
      animationFillMode: 'both',
    },
  },
  alertAmount: {
    position: 'relative',
    top: '0%',
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 'bolder',
    fontFamily: 'Copse, serif',
    fontSize: '12px',
  },
  bell: {
    display: 'inline-block',
    position: 'relative',
    cursor: 'pointer',
    '& $alertContainer': {
      opacity: '1',
      visibility: 'visible',
    },
    '&:hover $alertContainer': {
      animation: '$bounce 2.7s',
    },
  },
  '@keyframes bounce': {
    '0%': {
      transform: 'scale(0)',
    },

    '14%': {
      transform: 'scale(1.15)',
    },

    '28%': {
      transform: 'scale(1)',
    },

    '42%': {
      transform: 'scale(1.15)',
    },

    '70%': {
      transform: 'scale(1)',
    },
    '100%': {
      transform: 'scale(1)',
    },
  },
  '@global': {
    body: {
      '& .ab-feed-buttons-wrapper': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
    },
  },
})

declare global {
  interface Window {
    appboy: any
  }
}

const Bell = (): ReactElement => {
  const classes = useStyles()
  const [aboy, setAboy] = useState(false)
  const [isViewd, setIsViewd] = useState(true)

  analytics.ready(() => {
    setAboy(true)
  })

  useEffect(() => {
    if (window?.appboy) {
      if (window?.appboy?.getCachedContentCards()?.getUnviewedCardCount() < 1) {
        setIsViewd(true)
      } else if (
        window?.appboy?.getCachedContentCards()?.getUnviewedCardCount() > 0
      ) {
        setIsViewd(false)
      } else {
        setIsViewd(false)
        setAboy(false)
      }
    } else {
      setAboy(false)
    }
  }, [isViewd])

  if (!window?.appboy) {
    console.log('Header Not Loaded Due To Braze', window?.appboy)
  }

  if (!aboy) return <></>

  return (
    <>
      {window?.appboy?.getCachedContentCards()?.cards?.length > 0 ? (
        <div
          className={classes.bell}
          onClick={() => {
            setIsViewd(true)
            window?.appboy?.display?.toggleContentCards()
          }}
        >
          <Icon glyph="bell" size={34} />
          {!isViewd ? (
            <span className={classes.alertContainer}>
              <span className={classes.alertAmount}>
                {window?.appboy
                  ?.getCachedContentCards()
                  ?.getUnviewedCardCount()}
              </span>
            </span>
          ) : null}
        </div>
      ) : null}
    </>
  )
}

export default Bell
