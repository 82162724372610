import gql from 'graphql-tag'

export const FILTERS_FRAGMENT = gql`
  fragment FiltersNode on Filters {
    key
    type
    label
    allLabel
    values {
      value
      label
      isActive
    }
  }
`

export const META_FRAGMENT = gql`
  fragment MetaNode on IMeta {
    count
    pageSize
    page
    totalPages
  }
`
