import React, { useState } from 'react'
import Popover from '@mui/material/Popover'
import Typography from '@mui/material/Typography'
import ContactSupportIcon from '@mui/icons-material/ContactSupport'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import InfoIcon from '@mui/icons-material/Info'
import Button from '@mui/material/Button'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@mui/icons-material/Close'

const REACT_APP_BASE_DOMAIN = process.env.REACT_APP_BASE_DOMAIN as string

const useStyles = makeStyles({
  supportButton: {
    '&.MuiButton-root': {
      color: 'black',
      padding: 0,
      minWidth: 0,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
  closeButton: {
    '&.MuiButton-root': {
      color: 'rgb(114,113,117)',
      padding: 0,
      minWidth: 0,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
  popUpMessage: {
    padding: '10px',
    backgroundColor: 'rgb(250,250,250)',
    color: 'black',
  },
  popUpMessageFlex: {
    display: 'flex',
    justifyContent: 'space-between',
  },
})

type PropTypes = {
  timezone: string
  filled?: boolean
}

const SupportIconMessage = (props: PropTypes): React.ReactElement => {
  const { timezone, filled = true } = props
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)

  const handlePopoverOpen = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  return (
    <Button
      disableElevation={true}
      disableFocusRipple={true}
      disableRipple={true}
      className={classes.supportButton}
      onMouseDown={(event) => handlePopoverOpen(event)}
      onMouseEnter={(event) => handlePopoverOpen(event)}
      onMouseLeave={handlePopoverClose}
    >
      {filled ? <InfoIcon sx={{ color: '#636363' }} /> : <InfoOutlinedIcon />}

      <Popover
        id="mouse-over-popover"
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <div className={classes.popUpMessage}>
          <div className={classes.popUpMessageFlex}>
            <div>
              <Typography sx={{ fontSize: '14px' }}>
                Why am I seeing this time zone?
              </Typography>
            </div>
            <Button
              className={classes.closeButton}
              onClick={handlePopoverClose}
            >
              <CloseIcon fontSize="small" />
            </Button>
          </div>
          <Typography sx={{ fontSize: '12px' }}>
            We use your device settings to determine and display times in your
            local time zone.
            <br />
            You have the option to manually{' '}
            <a
              style={{ textDecoration: 'none' }}
              target="_self"
              href={`${REACT_APP_BASE_DOMAIN}/profile/time-zone`}
              rel="noreferrer"
            >
              change the time zone displayed on td.org.
            </a>
          </Typography>
        </div>
      </Popover>
    </Button>
  )
}

export default SupportIconMessage
