import React, { ReactElement } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Banner } from 'lib/graphql/graphqlTypes'
import Grid from '@bit/atd.web.grid/dist'
import Helmet from 'react-helmet'
import { useMediaQuery } from '@material-ui/core'
import config from 'constants/config'

const useStyles = makeStyles({
  root: {
    backgroundColor: (props: { bgColor?: string }) => {
      return props?.bgColor ? props?.bgColor : 'white'
    },
    position: 'relative',
    zIndex: 100,
  },
  anchor: {
    lineHeight: 0,
    display: 'block',
  },
})

type PropsType = {
  data?: Banner
}

const TopBanner = ({ data }: PropsType): ReactElement => {
  /********************************************
   *  HOOKS
   *********************************************/
  const theme = useTheme()
  const isMobile = useMediaQuery(
    theme.breakpoints.down('sm'),
    config.mediaQuery,
  )
  const classes = useStyles({ bgColor: data?.backgroundHexColor })

  if (!data) return <></>

  const content = data.isCountdown ? (
    <div>
      {/*
    @todo countdown script injection does not work yet
    */}
      <Helmet>
        <script src={data?.countdownScript || ''} />
        <script src={'https://apps.elfsight.com/p/platform.js'} />
      </Helmet>
      <div className="elfsight-app-92929508-a276-4471-b88b-149e198bc182"></div>
      {/*<div id={'test'} />*/}
    </div>
  ) : (
    <Grid
      container
      justify={'center'}
      alignItems={'center'}
      className={classes.root}
    >
      {!isMobile ? (
        <Grid item>
          <a href={data.desktopLink} className={classes.anchor}>
            <img alt={data.name} src={data.desktopImageUrl} />
          </a>
        </Grid>
      ) : (
        <Grid item xs={12}>
          <a href={data.mobileLink} className={classes.anchor}>
            <img width="100%" alt={data.name} src={data.mobileImageUrl} />
          </a>
        </Grid>
      )}
    </Grid>
  )

  return content
}
export default TopBanner
