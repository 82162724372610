import { useLocation } from 'react-router'
import queryString from 'query-string'
import { detectAnyAdblocker } from 'just-detect-adblock'
import { TrackingEvents } from 'constants/trackingEvents'

/**
 *
 * @param clickedResultId
 * @param clickedResultName
 * @param algoliaIndex
 * @param queryID
 * @param cardPosition
 * @param searchText
 * @param url
 * @param newTab
 * @param source
 */
const useSegmentAlgoliaApi = (
  clickedResultId?: string,
  clickedResultName?: string,
  algoliaIndex?: string,
  queryID?: string,
  cardPosition?: number,
  searchText?: string,
  url?: string,
  newTab?: boolean,
  source?: string,
) => {
  /********************************
   * HOOKS
   *********************************/
  const location = useLocation()

  /********************************
   * FUNCTIONS
   *********************************/

  /**
   *
   */
  const analytics = () => {
    const parsed = queryString.parse(location.search)
    const isNewTab = newTab
      ? openNewTapContentCardClicked
      : redirectContentCardClicked
    detectAnyAdblocker()
      .then((detected: boolean) => {
        if (detected) {
          isNewTab()
        } else {
          window.analytics.track(
            TrackingEvents.PRODUCTS_CLICKED,
            {
              objectID: clickedResultId,
              index: algoliaIndex,
              objectName: clickedResultName,
              queryID: queryID,
              query: searchText,
              position: cardPosition ?? 0,
              page: parsed.page ?? '1',
              source: source,
            },
            isNewTab,
          )
        }
      })
      .catch(() =>
        // fallback in case both the callback fails AND the adblock detection fails
        setTimeout(() => {
          isNewTab()
        }, 3000),
      )
  }

  /**
   *
   */
  const openNewTapContentCardClicked = () => {
    if (url) {
      window.open(url, '_blank')
    }
  }

  const redirectContentCardClicked = () => {
    if (url) {
      window.location.assign(url)
    }
  }
  return analytics
}

export default useSegmentAlgoliaApi
