// @react
import React, {
  forwardRef,
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
// @types
// @libraries
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
// @components
import colors from '@bit/atd.web.colors'
import Button from '@bit/atd.web.button'
import Icon from '@bit/atd.web.icons'
// @context
import HeaderDataContext from '../../context'
// @module components
import Header from './Header'
import SecondaryLinks from './SecondaryLinks'
import PrimaryLink from './PrimaryLink'
import MediaLink from './MediaLink'
import { MenuItem } from 'lib/graphql/graphqlTypes'

const useStyles = makeStyles({
  root: {
    position: 'absolute',
    zIndex: 10,
    top: '0px',
    overflowY: 'scroll',
    maxHeight: 'calc(95vh - 240px)',
    left: 0,
    right: 0,
    width: 'fit-content',
    minWidth: 850,
    boxShadow: `0px 6px 10px ${colors.gray400}`,
  },
  wrap: {
    display: 'flex',
    boxSizing: 'border-box',
    backgroundColor: colors.white,
    boxShadow: '0px 6px 10px rgba(49, 49, 51, 0.15)',
  },
  closeBtn: {
    position: 'absolute',
    top: '16px',
    right: '16px',
    minWidth: 0,
    padding: 0,
    '&::before': {
      content: '""',
      position: 'absolute',
      top: '-1em',
      left: '-1em',
      right: '-1em',
      bottom: '-1em',
    },
  },
  sidebar: {
    width: '250px',
    flexShrink: 0,
    backgroundColor: `${colors.gray200}`,
  },
  content: {
    padding: '40px 40px 32px 32px',
    flexGrow: 1,
    borderLeft: `1px solid ${colors.gray400}`,
    // overflow: 'auto',
    // maxHeight: (props: { contentMaxHeight?: string | number }) => {
    //   return props?.contentMaxHeight === 0 ? 'auto' : props?.contentMaxHeight
    // },
  },
  mediaLinkColumn: {
    width: '180px',
    '& > div': {
      marginTop: 88,
    },
  },
  hasMediaLink: {
    display: 'flex',
    '& > div:first-child': {
      flex: 1,
      paddingRight: 24,
    },
  },
  isCondensed: {
    top: 'calc(100% + 18px)',
    overflow: 'auto',
    maxHeight: 'calc(95vh - 70px)',
    width: '100%',
    minWidth: 'initial',
    '& $content': {
      padding: '32px 48px 24px 24px',
    },
    '& $hasMediaLink > div:first-child': {
      paddingRight: 16,
    },
    '& $mediaLinkColumn': {
      width: '160px',
    },
  },
})

type PropsType = {
  asCondensedMenu?: boolean
  toggleMegaMenu: () => void
  ref?: any
}

const MegaMenu = forwardRef(
  ({ toggleMegaMenu, asCondensedMenu }: PropsType, ref): ReactElement => {
    /********************************
     * STATE
     ********************************/

    const [active, setActive] = useState(0)
    const [contentMaxHeight, setContentMaxHeight] = useState<
      number | undefined
    >(0)

    /********************************
     * HOOKS
     ********************************/
    const classes = useStyles({ contentMaxHeight })
    const headerContext = useContext(HeaderDataContext)
    const rootMenu = headerContext.data

    const sideBarRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
      setContentMaxHeight(sideBarRef?.current?.clientHeight)
    }, [])

    const menuData = rootMenu && rootMenu[0] && rootMenu[0].children

    const activeMenu = (menuData && menuData[active]) || null
    const header =
      (activeMenu && activeMenu.children && activeMenu.children[0]) || null

    const activeMediaLink: MenuItem | undefined = activeMenu?.children
      ?.slice(1)
      .filter((items) => items.type === 'MEDIA')[0]

    /********************************
     * RENDER
     ********************************/
    return (
      <div
        ref={ref as string | null}
        className={clsx(classes.root, {
          [classes.isCondensed]: asCondensedMenu,
        })}
        role="menu"
      >
        <div className={classes.wrap}>
          {/* Sidebar */}
          <div className={classes.sidebar}>
            <div ref={sideBarRef}>
              {menuData &&
                menuData.map((item, idx) => (
                  <PrimaryLink
                    key={idx}
                    title={item.title || ''}
                    subtitle={item.description || ''}
                    active={active === idx}
                    onClick={() => {
                      setActive(idx)
                    }}
                  />
                ))}
            </div>
          </div>

          {/* Main Content */}
          <div className={clsx(classes.content)}>
            {activeMenu && header && (
              <>
                {/* Close Mega Menu */}
                {/* <Button
                  size="small"
                  variant="icon"
                  className={classes.closeBtn}
                  onClick={() => {
                    toggleMegaMenu()
                    setActive(0)
                  }}
                >
                  <Icon glyph="close" size={16} />
                </Button> */}

                <div
                  className={clsx({ [classes.hasMediaLink]: activeMediaLink })}
                >
                  <div>
                    <Header
                      title={activeMenu.title || ''}
                      url={header.url || ''}
                      target={header.target || ''}
                    />

                    <SecondaryLinks
                      activeTitle={activeMenu.title || ''}
                      data={activeMenu.children}
                      isCondensed={asCondensedMenu}
                    />
                  </div>

                  {activeMediaLink && (
                    <div className={classes.mediaLinkColumn}>
                      <MediaLink
                        url={activeMediaLink?.url}
                        buttonText={activeMediaLink.buttonText as string}
                        title={activeMediaLink.title}
                        imageUrl={activeMediaLink.imageUrl}
                        buttonURL={activeMediaLink.buttonUrl as string}
                      />
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    )
  },
)

export default MegaMenu
