// @types
import type { ReactElement } from 'react'
import type PropsType from './_types'
// @libraries
import clsx from 'clsx'
import Typography from '@material-ui/core/Typography'
// @components
import Container from '@bit/atd.web.container'
import TextLink from '@bit/atd.web.text-link'
// @styles
import useStyles from './_styles'

const MultiColumnTextLink = ({
  className,
  content,
}: PropsType): ReactElement => {
  /******************************************
   * PROPS
   ******************************************/
  const contentCount = content?.length

  /******************************************
   * HOOKS
   ******************************************/

  const classes = useStyles({ contentCount })

  /******************************************
   * RENDER
   ******************************************/

  return (
    <section className={clsx(className && className)}>
      <Container>
        {content ? (
          <ul className={classes.list}>
            {content.map((column, columnIndex) => {
              const isTitleOrSubtitle = !column.title || !column.subtitle

              return (
                <li
                  key={`multiColumnTextLinkItem_${columnIndex}`}
                  className={classes.item}
                >
                  <div className={classes.itemInner}>
                    <div
                      className={clsx(classes.text, {
                        [classes.isTitleOrSubtitle]: isTitleOrSubtitle,
                      })}
                    >
                      {column.title && (
                        <Typography
                          className={classes.title}
                          component={'p'}
                          variant={'h5'}
                        >
                          {column.title}
                        </Typography>
                      )}
                      {column.subtitle && (
                        <div className={classes.subtitle}>
                          {column.subtitle}
                        </div>
                      )}
                    </div>

                    {column.link && (
                      <TextLink
                        className={classes.cta}
                        size={'medium'}
                        variant={'standalone'}
                        href={column.link.href || ''}
                      >
                        {column.link.label}
                      </TextLink>
                    )}
                  </div>
                </li>
              )
            })}
          </ul>
        ) : (
          <></>
        )}
      </Container>
    </section>
  )
}

MultiColumnTextLink.defaultProps = {}

export default MultiColumnTextLink
