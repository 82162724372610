// @types
import type { LandingPagesPageSectionSectionBlockBlockContentFeaturedModule } from 'lib/graphql/graphqlTypes'
import type { ReactElement } from 'react'
// @libraries
import React from 'react'
import clsx from 'clsx'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
// @components
import Container from '@bit/atd.web.container'
import { isOfType } from '../../typeguard'
// @styles
import useStyles from './_styles'

type PropsType = {
  children: ReactElement
  title?: string | null
  theme?: string | null
  className?: string
  isHeader?: boolean
}

const PageSection = ({
  children,
  title,
  className,
  theme,
  isHeader,
}: PropsType): ReactElement => {
  const classes = useStyles()

  let hasChildren
  if (children?.props?.children?.length > 0) hasChildren = true

  if (!hasChildren) return <></>

  let isFeaturedContent
  let featuredTitle
  let featuredSubtitle
  if (
    isOfType<LandingPagesPageSectionSectionBlockBlockContentFeaturedModule>(
      children?.props?.children[0]?.props?.content,
      '__typename',
      'LandingPagesPageSectionSectionBlockBlockContentFeaturedModule',
    )
  ) {
    isFeaturedContent = true
    featuredTitle =
      children?.props?.children[0]?.props?.content?.featured_module?.title
    featuredSubtitle =
      children?.props?.children[0]?.props?.content?.featured_module?.subtitle
  }

  return (
    <Box
      className={clsx(
        classes.root,
        {
          [classes.theme1]: theme === 'THEME1',
          [classes.theme2]: theme === 'THEME2',
          [classes.isPadless]: isHeader,
          [classes.isFeaturedContent]: isFeaturedContent,
        },
        className && className,
      )}
      component={isHeader ? 'header' : 'section'}
    >
      <div>
        {((title && !isHeader) || featuredTitle) && (
          <Container>
            <div className={classes.header}>
              <Typography
                className={classes.title}
                variant={'h4'}
                component={'h2'}
              >
                {title || featuredTitle}
              </Typography>
              {featuredSubtitle && (
                <Typography
                  className={classes.subTitle}
                  variant={'body1'}
                  component={'p'}
                >
                  {featuredSubtitle}
                </Typography>
              )}
            </div>
          </Container>
        )}
        <div className={classes.content}>{children}</div>
      </div>
    </Box>
  )
}

export default PageSection
