import React, { ReactElement } from 'react'
import Container from '@bit/atd.web.container'
import Grid from '@bit/atd.web.grid'
import Box from '@bit/atd.web.box'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  containerForm: {
    maxWidth: '100%',
    margin: '0 auto',
    '& .MuiPaper-root': {
      width: '100%',
    },
    boxShadow: '0px 2px 0px #dbd9d6',
    border: '1px solid #dbd9d6',
  },
  removeBorderBox: {
    border: 'none',
  },
}))

const FormSubmitted = () => {
  const classes = useStyles()

  const box = (title?: string, subtitle?: string): ReactElement => {
    return (
      <Box
        classNames={{ root: classes.removeBorderBox }}
        header={{
          title: title,
          subtitle: subtitle,
        }}
      >
        <></>
      </Box>
    )
  }

  return (
    <>
      <Container>
        <Grid className={classes.containerForm} justify="center" container>
          {box(
            'Thank You!',
            'An ATD Representative will reach out to you shortly regarding your interests.',
          )}
        </Grid>
      </Container>
    </>
  )
}

export default FormSubmitted
