// @types
import type { ReactElement } from 'react'
import type PropsType from './_types'
// @libraries
import clsx from 'clsx'
import Typography from '@material-ui/core/Typography'
// @components
import Container from '@bit/atd.web.container'
import Button from '@bit/atd.web.button'
// @styles
import useStyles from './_styles'

const ImageCTA = ({
  className,
  image,
  content,
  isReverse,
}: PropsType): ReactElement => {
  /******************************************
   * PROPS
   ******************************************/
  const isOnlyImage = content?.eyebrow || content?.headline || content?.button

  /******************************************
   * HOOKS
   ******************************************/

  const classes = useStyles()

  /******************************************
   * RENDER
   ******************************************/

  return (
    <Container
      className={clsx(classes.root, {
        [classes.isReverse]: isReverse,
        [classes.isOnlyImage]: !isOnlyImage,
      })}
    >
      <div className={classes.section}>
        {isOnlyImage && (
          <div className={classes.content}>
            {(content?.eyebrow || content?.headline) && (
              <div className={classes.text}>
                {content?.eyebrow && (
                  <Typography
                    className={classes.eyebrow}
                    variant={'h6'}
                    component={'p'}
                  >
                    {content.eyebrow}
                  </Typography>
                )}
                {content?.headline && (
                  <Typography
                    className={classes.headline}
                    variant={'h5'}
                    component={'h2'}
                  >
                    {content.headline}
                  </Typography>
                )}
              </div>
            )}

            {content?.button && (
              <Button
                href={content.button?.href}
                size={'medium'}
                variant={'secondary'}
              >
                {content.button?.label}
              </Button>
            )}
          </div>
        )}

        <div className={classes.image}>
          {image.href ? (
            <a href={image.href}>
              <picture>
                <img src={image.src} alt={image.alt} />
              </picture>
            </a>
          ) : (
            <picture>
              <img src={image.src} alt={image.alt} />
            </picture>
          )}
        </div>
      </div>
    </Container>
  )
}

ImageCTA.defaultProps = {}

export default ImageCTA
