// @react
import React, { useContext, ReactNode, ReactElement } from 'react'
// @libraries
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
// @components
import Container from '@bit/atd.web.container'
import Grid from '@bit/atd.web.grid'
import colors from '@bit/atd.web.colors'
import SearchPanel, {
  PropsType as SearchPanelProps,
} from 'modules/global/SearchPanel'
// @module components
import TopLinks from './TopLinks'
import UserLinks from './UserLinks'
import HeaderDataContext from '../../context'
import { RouteNamesExternal } from 'constants/routeNamesExternal'
// @assets
import atdLogo from '../../assets/atd-logo.svg'
import atdLogoSmall from '../../assets/atd-logo-small.svg'

const useStyles = makeStyles({
  root: {
    backgroundColor: colors.white,
    // position: 'relative',
  },
  mainWrapper: {
    position: 'relative',
    background: colors.white,
    zIndex: 2,
    display: 'flex',
    flexDirection: 'column',
  },
  mainWrapperExpanded: {
    height: 'calc(100vh - 60px)',
  },
  topWrapper: {
    zIndex: 3,
    position: 'relative',
    background: 'white',
    paddingTop: '16px',
    paddingBottom: '24px',
    fontSize: 0,
    width: '100%',
    maxWidth: 'none',
    '& > div': {
      maxWidth: '1188px',
      margin: '0 auto',
    },
  },
  logo: {
    '& img': {
      display: 'inline-block',
      width: '305px',
      height: '85px',
      transition: 'all 0.15s',
    },
    overflow: 'hidden',
  },
  searchPanel: (isShowSearch) => ({
    display: isShowSearch ? 'flex' : 'flex',
    flexDirection: 'column',
    zIndex: 2,
    flex: 1,
  }),
  isCondensed: {
    '& $topWrapper': {
      paddingTop: '16px',
      paddingBottom: '16px',
      '& > div': {
        position: 'relative',
      },
    },
    '& $logo img': {
      width: '48px',
      height: '31px',
      transition: 'none',
    },
  },
})

type PropsType = {
  /** Condensed Menu array */
  condensedMenu?: ReactNode | ReactNode[]
  /** Search Panel props */
  searchPanelProps: SearchPanelProps
}

const Top = ({ condensedMenu, searchPanelProps }: PropsType): ReactElement => {
  /********************************
   * HOOKS
   ********************************/

  const classes = useStyles(searchPanelProps.isShowSearch)
  const headerContext = useContext(HeaderDataContext)
  const { condensed } = headerContext

  /********************************
   * RENDER
   ********************************/
  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.isCondensed]: condensed,
      })}
    >
      <div
        className={clsx({
          [classes.mainWrapper]: true,
          [classes.mainWrapperExpanded]: searchPanelProps.isShowSearch,
        })}
      >
        <Container className={classes.topWrapper}>
          <Grid
            container
            justify="space-between"
            alignItems={condensed ? 'center' : 'flex-end'}
            wrap={'nowrap'}
          >
            <Grid item xs={'auto'}>
              <Grid container alignItems="center" wrap={'nowrap'}>
                <div className={classes.logo}>
                  <a href={RouteNamesExternal.ATD_WEB_URL}>
                    <img src={condensed ? atdLogoSmall : atdLogo} alt="ATD" />
                  </a>
                </div>

                {condensed && condensedMenu}
              </Grid>
            </Grid>

            <Grid item>
              <Grid container justify="flex-end">
                <Grid item>
                  {!condensed && <TopLinks />}

                  <UserLinks
                    showSearch={searchPanelProps.isShowSearch}
                    toggleSearch={(open: boolean) =>
                      searchPanelProps.setIsShowSearch &&
                      searchPanelProps.setIsShowSearch(open)
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
        <SearchPanel className={classes.searchPanel} {...searchPanelProps} />
      </div>
    </div>
  )
}

export default Top
