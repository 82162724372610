// @types
import type { FeaturedSectionBlock } from '../FeaturedContent'
// @libraries
import React from 'react'
import clsx from 'clsx'
import { Typography, useMediaQuery } from '@material-ui/core'
// @common
// @components
import Grid from '@bit/atd.web.grid'
import Accordion from '@bit/atd.web.accordion'
import Box from '@bit/atd.web.box'
// @module components
// @styles
import { useStyles } from './_styles'
import SkeletonLoader from '@bit/atd.web.loaders.skeleton'
import RenderContentCarousel from '../CarouselView'
import RenderContentGrid from '../GridView'

type PropsType = {
  limit: number
  content: FeaturedSectionBlock
}

/**
 *
 * @param content
 * @param limit
 * @constructor
 */
const AccordionWrapper = ({ content, limit }: PropsType) => {
  /******************************************
   * HOOKS
   ******************************************/

  const classes = useStyles()
  const showCarousel = useMediaQuery('(max-width: 580px)')

  /******************************************
   * RENDER
   ******************************************/

  if (!content) {
    return (
      <Box variant={'subModule'}>
        <Grid container>
          <Grid item xs={12}>
            <SkeletonLoader />
          </Grid>
        </Grid>
      </Box>
    )
  }

  return (
    <Box
      classNames={{
        root: classes.boxBody,
      }}
      variant={'simple'}
    >
      <Accordion
        className={clsx(classes.accordionRoot)}
        defaultPanel={0}
        iconSize={32}
        panels={content.content.map((item) => {
          return {
            title: (
              <Typography variant={'h4'} component={'h3'}>
                {item.title}
              </Typography>
            ),
            content: showCarousel ? (
              <RenderContentCarousel
                contentItems={item.content}
                subTitle={item.subTitle}
                limit={limit}
                viewMoreLink={item.viewMoreLink}
              />
            ) : (
              <div className={classes.accordionBody}>
                <RenderContentGrid
                  contentItems={item.content}
                  subTitle={item.subTitle}
                  limit={limit}
                  viewMoreLink={item.viewMoreLink}
                />
              </div>
            ),
          }
        })}
      />
    </Box>
  )
}

export default AccordionWrapper
